// @ts-nocheck
import React, { useEffect, useMemo, useRef } from 'react';

import { Chart, ChartDataset } from 'chart.js';
import classnames from 'classnames';
import { EChartName } from 'src/components/_charts/chartsData';
import { BaseIcon } from 'src/components/BaseIcon';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { ChartSavingsColors } from 'src/constants/chart';
import { EChartTooltips, getChartTooltip } from 'src/constants/chartTooltips';
import { useChartJS } from 'src/hooks/useChartJS';
import { formatter } from 'src/utils/formatter';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

import s from './ChartSavings.module.scss';
import { ESavingType, TChartSavingsProps, TCustomData } from './ChartSavings.types';

export const getSavingTypeAdvanced = (
  latestSavingsKpi: NonNullable<TChartSavingsProps['latestSavingsKpi']>,
): Record<ESavingType, number> | undefined => {
  const { saving_absolute, gsy_e_cost, base_case_cost } = latestSavingsKpi;
  const result = {
    [ESavingType.Saving]: 0,
    [ESavingType.Earning]: 0,
    [ESavingType.Losses]: 0,
  };

  if (saving_absolute > 0) {
    // positive
    if (gsy_e_cost < 0 && base_case_cost > 0) {
      result[ESavingType.Saving] = base_case_cost;
      result[ESavingType.Earning] = Math.abs(gsy_e_cost); // As per PH-947
    } else if (gsy_e_cost < 0 && base_case_cost < 0) {
      result[ESavingType.Saving] = 0;
      result[ESavingType.Earning] = base_case_cost - gsy_e_cost;
    } else {
      result[ESavingType.Saving] = saving_absolute;
      result[ESavingType.Earning] = 0;
    }
  } else if (saving_absolute < 0) {
    // negative
    result[ESavingType.Losses] = saving_absolute;
    result[ESavingType.Earning] = 0;
  }

  return result;
};

function getSavingType(
  trades: NonNullable<TChartSavingsProps['latestSavingsKpi']>,
): ESavingType | undefined {
  const { gsy_e_cost, saving_absolute } = trades || {};
  if (saving_absolute === null) return;
  if (saving_absolute === 0) return ESavingType.Neutral;
  if (gsy_e_cost && gsy_e_cost < 0 /* && trades.saving_absolute > 0 */) return ESavingType.Earning;
  if (saving_absolute > 0) return ESavingType.Saving;
  else return ESavingType.Losses;
}

const DifferenceInfoTag = ({ latestSavingsKpi, currencySymbol }) => {
  const { saving_absolute } = latestSavingsKpi;
  const result = getSavingTypeAdvanced(latestSavingsKpi);

  const showProfit = saving_absolute > 0 && result[ESavingType.Earning] > 0;
  const showSaving = saving_absolute > 0 && result[ESavingType.Saving] > 0;

  return saving_absolute !== null ? (
    <>
      {showProfit && (
        <div className={classnames(s.differenceInfoTag, s.earning, s.mb)}>
          <BaseIcon icon={'rocket'} size={14} />
          <p>
            Profit{' '}
            <span>
              {result[ESavingType.Earning].toFixed(2)}
              {currencySymbol} !
            </span>
          </p>
        </div>
      )}
      {showSaving && (
        <div className={classnames(s.differenceInfoTag, s.saving, s.mb)}>
          <BaseIcon icon={'brightness-high'} size={14} />
          <p>
            You saved{' '}
            <span>
              {result[ESavingType.Saving].toFixed(2)}
              {currencySymbol} !
            </span>
          </p>
        </div>
      )}
      {!showProfit && !showSaving && saving_absolute < 0 && (
        <div className={classnames(s.differenceInfoTag, s.losses, s.mb)}>
          <BaseIcon icon={'lightning-no'} size={14} />
          <p>
            You Lost{' '}
            <span>
              {result[ESavingType.Losses].toFixed(2)}
              {currencySymbol} !
            </span>
          </p>
        </div>
      )}
      {!showProfit && !showSaving && saving_absolute === 0 && (
        <div className={classnames(s.differenceInfoTag, s.neutral, s.mb)}>
          <BaseIcon icon={'bell-alert'} size={14} />
          <p>You had no savings or losses</p>
        </div>
      )}
    </>
  ) : null;
};

export const ChartSavings: React.FC<TChartSavingsProps> = ({
  assetValues,
  childrenCount,
  latestSavingsKpi,
  savingsKpiProfile,
  currency,
}) => {
  const currencySymbol = formatter.getCurrencySymbol(currency);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<Chart | null>(null);

  const { datasets, savingTypes } = useMemo(() => {
    const common = {
      barThickness: 8,
      borderRadius: 1,
    };

    const savingTypes: ESavingType[] = [];
    const datasets: ChartDataset<'bar'>[] = [
      {
        // Earning
        ...common,
        data: [],
        backgroundColor: ChartSavingsColors[ESavingType.Earning],
        hoverBackgroundColor: ChartSavingsColors[ESavingType.Earning],
        label: ESavingType.Earning,
      },
      {
        // Neutral
        ...common,
        data: [],
        backgroundColor: ChartSavingsColors[ESavingType.Neutral],
        hoverBackgroundColor: ChartSavingsColors[ESavingType.Neutral],
        label: ESavingType.Neutral,
        minBarLength: 1,
      },
      {
        // Saving
        ...common,
        data: [],
        backgroundColor: ChartSavingsColors[ESavingType.Saving],
        hoverBackgroundColor: ChartSavingsColors[ESavingType.Saving],
        label: ESavingType.Saving,
      },
      {
        // Losses
        ...common,
        data: [],
        backgroundColor: ChartSavingsColors[ESavingType.Losses],
        hoverBackgroundColor: ChartSavingsColors[ESavingType.Losses],
        label: ESavingType.Losses,
      },
    ];

    if (savingsKpiProfile) {
      Object.entries(savingsKpiProfile).forEach(([date, trades], dataIndex) => {
        const result = getSavingTypeAdvanced(trades);
        const savingValue = (result[ESavingType.Saving] / 100).toFixed(2);
        const profitValue = (result[ESavingType.Earning] / 100).toFixed(2);

        const datasetIndexesToFill: number[] = [];
        const type = getSavingType(trades);
        if (!type) return;
        savingTypes[dataIndex] = type;

        switch (type) {
          case ESavingType.Earning:
            if (savingValue > 0 && profitValue > 0) {
              // show blue and green bars stacked
              datasetIndexesToFill.push(0, 2);
            } else {
              datasetIndexesToFill.push(0);
            }
            break;
          case ESavingType.Neutral:
            datasetIndexesToFill.push(1);
            break;
          case ESavingType.Saving:
            datasetIndexesToFill.push(2);
            break;
          case ESavingType.Losses:
            datasetIndexesToFill.push(3);
            break;
        }

        const savingAbsoluteFromTrade = trades.saving_absolute || 0;

        datasets.forEach((dataset, index) => {
          // const y = trades.saving_absolute;
          const y = savingAbsoluteFromTrade / 100; // converting cents to euros
          const x = UTCMoment.fromBackend(date, BACKEND_DATE_FORMATS.KPI_SAVINGS).format('MMM D');
          // @ts-ignore
          dataset.data[dataIndex] = datasetIndexesToFill.includes(index)
            ? {
                x,
                y: index === 0 ? profitValue : index === 2 ? savingValue : y,
                saving_percentage: trades.saving_percentage,
                profit: profitValue,
                saving: savingValue,
              }
            : {
                x,
                y: null,
                saving_percentage: trades.saving_percentage,
                pprofit: profitValue,
                saving: savingValue,
              };
        });
      });
    }

    return { datasets, savingTypes };
  }, [savingsKpiProfile]);

  useChartJS(EChartName.Savings, canvasRef, chartRef, {
    datasets,
  });

  useEffect(() => {
    if (chartRef.current) {
      const customData: TCustomData = { savingTypes, currencySymbol };
      // @ts-ignore
      chartRef.current.tooltip.customData = customData;
    }
  }, [currencySymbol, savingTypes]);

  useEffect(() => {
    // Hack: For some reason when all days are Neutral bars don't show up, this line fixes it
    chartRef.current?.update();
  }, []);

  return (
    <div className={s.container}>
      {latestSavingsKpi && latestSavingsKpi.saving_absolute !== null && (
        <div className={classnames(s.assetInfo, s.block)}>
          <header>
            <div className={s.leftValues}>
              <div className={s.boxValues}>
                <p>
                  {latestSavingsKpi?.base_case_cost && latestSavingsKpi?.base_case_cost < 0
                    ? 'Revenue'
                    : 'Owed'}
                </p>

                <p className={s.cost}>
                  {latestSavingsKpi.base_case_cost && Math.abs(latestSavingsKpi.base_case_cost)}
                  {currencySymbol}
                </p>
                <p>Utility Bill</p>
              </div>

              <div className={s.divider}></div>

              <div className={s.boxValues}>
                <p>
                  {latestSavingsKpi.gsy_e_cost && latestSavingsKpi?.gsy_e_cost < 0
                    ? 'Revenue'
                    : 'Owed'}
                </p>
                <p>
                  {latestSavingsKpi.gsy_e_cost && Math.abs(latestSavingsKpi.gsy_e_cost)}
                  {currencySymbol}
                </p>
                <p>LEM Bill</p>
              </div>
            </div>

            <div className={s.rightTag}>
              <DifferenceInfoTag
                latestSavingsKpi={latestSavingsKpi}
                currencySymbol={currencySymbol}
              />
            </div>
          </header>

          <div className={s.assetIcon}>
            <BaseIcon icon="house-3d" size={210} />
          </div>
          <footer>
            <p className={s.assetName}>{assetValues?.name}</p>
            <span className={s.assetsCount}>Assets: {childrenCount}</span>
          </footer>
        </div>
      )}

      {savingsKpiProfile && (
        <ChartWrapper
          title="Savings"
          info={getChartTooltip(EChartTooltips.SAVINGS)}
          className={s.block}>
          <ChartDataLoadingWrapper loading={false} className={s.chartLoadingWrapper}>
            <div />
            <canvas className={s.canvas} ref={canvasRef} height={100} />
          </ChartDataLoadingWrapper>
        </ChartWrapper>
      )}
    </div>
  );
};
