import React, { useRef } from 'react';

import { useSelector } from 'react-redux';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { useLaunchToCanary } from 'src/hooks/useLaunchToCanary';
import { selectOpenedConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { setOpenedConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';

import { BaseButton } from '../BaseButton';
import { BaseClose } from '../BaseClose';
import { BaseGenericModal } from '../BaseGenericModal';
import s from './ModalCanaryRequest.module.scss';
import { TModalCanaryRequestProps } from './ModalCanaryRequest.types';


export const ModalCanaryRequest: React.FC<TModalCanaryRequestProps> = () => {
  const isModalCanaryRequestOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_CANARY_REQUEST),
  );

  const openedConfigurationUuid = useSelector(selectOpenedConfigurationUuid);

  const { launchToCanary } = useLaunchToCanary();

  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  function closeCanaryRequestModal() {
    dispatch(setOpenedConfigurationUuid(undefined));
    dispatch(closeModal(EPredefinedModalIds.MODAL_CANARY_REQUEST));
  }

  function finishCanaryRequest({ openedConfigurationUuid }) {
    dispatch(setOpenedConfigurationUuid(undefined));
    dispatch(closeModal(EPredefinedModalIds.MODAL_CANARY_REQUEST));
    launchToCanary({ openedConfigurationUuid });
  }

  if (!isModalCanaryRequestOpened) {
    return null;
  }

  return (
    <>
      <div className={s.background} />
      <BaseGenericModal
        size={EModalSize.XL650}
        modalId={EPredefinedModalIds.MODAL_CANARY_REQUEST}
        className={s.modal}
        modalRef={modalRef}>
        <BaseClose className={s.closeBtn} onClick={closeCanaryRequestModal} />
        <div className={s.modalWrapper}>
          <h2>An agent will get in touch with you shortly.</h2>
          <div className={s.buttonsWrapper}>
            <BaseButton
              className={s.back}
              theme="quaternary"
              type="button"
              onClick={closeCanaryRequestModal}>
              Back to My Simulation
            </BaseButton>
            <BaseButton
              className={s.finish}
              onClick={() => finishCanaryRequest({ openedConfigurationUuid })}>
              Finish
            </BaseButton>
          </div>
        </div>
      </BaseGenericModal>
    </>
  );
};
