
import React from 'react';

import { BaseButtonRound } from 'src/components/BaseButtonRound';
import { BaseIcon } from 'src/components/BaseIcon';

import s from './TimelineArrowButtons.module.scss';
import { EButtonType, TSimulationProgressModalExpandedProps } from './TimelineArrowButtons.types';

export const TimelineArrowButtons: React.FC<TSimulationProgressModalExpandedProps> = ({
  handleArrowButtonClick,
}) => {
  return (
    <div className={s.container}>
      <div className={s.simActionButtons}>
        <BaseButtonRound
          className={s.actionButton}
          onClick={() => handleArrowButtonClick(EButtonType.prev)}>
          <BaseIcon icon="arrow-left-full" size={10} />
        </BaseButtonRound>

        <BaseButtonRound
          className={s.actionButton}
          onClick={() => handleArrowButtonClick(EButtonType.next)}>
          <BaseIcon icon="arrow-right-full" size={10} />
        </BaseButtonRound>
      </div>
    </div>
  );
};
