import React, { FunctionComponent } from 'react';

import classnames from 'classnames';

import s from './BaseIcon.module.scss';
import { TBaseIconProps } from './BaseIcon.types';

export const BaseIcon: FunctionComponent<TBaseIconProps> = function BaseIcon({
  icon,
  className = '',
  style,
  size,
  svgRef,
  onClick,
  svgProps,
}) {
  return (
    <svg
      ref={svgRef}
      className={classnames(className, `icon-${icon}`, s.svg, { [s.clickable]: !!onClick })}
      key={icon}
      style={{
        ...(style || {}),
        maxWidth: `${size}px`, // This should be inline style in order to have higher specificity
        maxHeight: `${size}px`, // This should be inline style in order to have higher specificity
      }}
      // @ts-ignore
      onClick={onClick}
      {...svgProps}>
      <use xlinkHref={`#icon-${icon}`} />
    </svg>
  );
};
