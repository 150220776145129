import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { DashboardAsset } from 'src/components/AdminDashboards/DashboardAsset';
import { DashboardAssetsGridItem } from 'src/components/AdminDashboards/DashboardAssets/DashboardAssetsGridItem';
import { DashboardAssetsTableItem } from 'src/components/AdminDashboards/DashboardAssets/DashboardAssetsTableItem';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TContextMenuProps } from 'src/components/ContextMenu';
import { DataGrid, TDataGridProps } from 'src/components/DataGrid';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { LibraryOutput, LibraryTypesGraphql, useListLibrariesLazyQuery } from 'src/graphql';
import { useLibraryHelpers } from 'src/hooks/useLibraryHelpers';
import { useModal } from 'src/hooks/useModal';
import { selectAddNewLibrary } from 'src/redux/application/application.selectors';
import { setAddNewLibrary } from 'src/redux/application/application.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { TLibrariesTags } from 'src/typings/base-types';

import s from './DashboardAssets.module.scss';
import { TDashboardAssetsProps } from './DashboardAssets.types';


const ITEMS_PER_PAGE = 21;

const FILTERS = [
  { name: 'All', value: undefined },
  {
    name: 'Public Library',
    value: LibraryTypesGraphql.PublicLibrary,
  },
  {
    name: 'GS_y Library',
    value: LibraryTypesGraphql.GsyLibrary,
  },
  {
    name: 'My Library',
    value: LibraryTypesGraphql.MyLibrary,
  },
];

const TAGS_FILTERS: TDataGridProps<{
  tagsFilters: string[];
}>['tagsFilters'] = [
  {
    id: 'home',
    icon: 'house',
    name: 'Home',
    value: LIBRARY_FILTERS_MAPPING.Area,
  },
  {
    id: 'pv',
    icon: 'pv',
    name: 'Solar Panels (PVs)',
    value: LIBRARY_FILTERS_MAPPING.PV,
  },
  {
    id: 'consumption',
    icon: 'plug',
    name: 'Consumption',
    value: LIBRARY_FILTERS_MAPPING.Load,
  },
  {
    id: 'battery',
    icon: 'battery',
    name: 'Battery',
    value: LIBRARY_FILTERS_MAPPING.Storage,
  },
];

export const DashboardAssets: React.FC<TDashboardAssetsProps> = ({
  selectedItem,
  onAssetSelect,
}) => {
  const addNewLibrary = useSelector(selectAddNewLibrary);
  const [selectedItemData, setSelectedItemData] = useState<LibraryOutput>();
  const [activeFilter, setActiveFilter] = useState<LibraryTypesGraphql | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [selectedTagsFilters, setSelectedTagsFilters] = useState<string[]>([]);
  const [listLibraries, { data }] = useListLibrariesLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const libraries = data?.listLibraries?.libraries?.map((item) => ({ ...item }));
  const totalLibraries = data?.listLibraries?.totalCount || undefined;

  const dispatch = useAppDispatch();
  const { id: contextMenuModalId } = useModal();
  const isContextMenuOpened = useSelector(selectIsModalOpened(contextMenuModalId));
  const closeContextMenu = () => dispatch(closeModal(contextMenuModalId));
  const { deleteLibrary, duplicateLibrary } = useLibraryHelpers();
  const initialContextMenuState: {
    modalRef: React.RefObject<HTMLElement | null>;
    lib: LibraryOutput | undefined;
  } = { modalRef: { current: null }, lib: undefined };
  const [contextMenuState, setContextMenuState] = useState<{
    modalRef: React.RefObject<HTMLElement | null>;
    lib: LibraryOutput | undefined;
  }>(initialContextMenuState);
  const contextMenuItems: TContextMenuProps['items'] = [
    {
      title: 'edit',
      icon: 'pencil-edit',
      onClick: () => {
        if (contextMenuState?.lib?.uuid) {
          onAssetSelect(contextMenuState.lib.uuid);
          setSelectedItemData(contextMenuState.lib);
          closeContextMenu();
        }
      },
    },
    {
      title: 'Duplicate',
      icon: 'duplicate',
      onClick: async () => {
        if (contextMenuState?.lib?.uuid) {
          await duplicateLibrary({
            uuid: contextMenuState?.lib?.uuid,
            isPrivate: true,
          });
          dataFetch();
          closeContextMenu();
        }
      },
    },
    {
      title: 'Delete',
      icon: 'custom-trash',
      onClick: async () => {
        if (contextMenuState?.lib?.uuid) {
          await deleteLibrary({
            uuid: contextMenuState?.lib?.uuid,
          });
          dataFetch();
          closeContextMenu();
        }
      },
    },
  ];
  const manageContexMenu = (event, { target, lib }) => {
    if (isContextMenuOpened) {
      closeContextMenu();
    } else {
      setContextMenuState({
        modalRef: { current: target as HTMLElement },
        lib,
      });
      dispatch(openModal(contextMenuModalId));
    }
    event.stopPropagation();
  };

  const getSelectedTags = useCallback(
    () =>
      selectedTagsFilters.reduce<TLibrariesTags[]>((acc, id) => {
        const tag = TAGS_FILTERS.find((item) => item.id === id)?.value as TLibrariesTags[];

        if (tag) {
          acc = [...acc, ...tag];
        }

        return acc;
      }, []),
    [selectedTagsFilters],
  );

  const dataFetch = useCallback(
    async (data?: { searchVal?: string; page?: number }) => {
      return await listLibraries({
        variables: {
          search: data?.searchVal || searchValue,
          skip: (typeof data?.page === 'number' ? data?.page : pageNumber) * ITEMS_PER_PAGE,
          first: ITEMS_PER_PAGE,
          libraryTypes: activeFilter,
          tags: getSelectedTags(),
        },
      });
    },
    [activeFilter, listLibraries, pageNumber, searchValue, getSelectedTags],
  );

  const librariesRefetch = async () => {
    await dataFetch();
  };

  const getAssetIcon = (assetType): TIconNames => {
    switch (assetType) {
      case 'Area':
        return 'house-3d';
      case 'PV':
        return 'solar-3d';
      case 'Load':
        return 'plug-3d';
      case 'Storage':
        return 'battery-3d';
      case 'FiniteDieselGenerator':
      case 'MarketMaker':
      case 'InfiniteBus':
      case 'AreaOutput':
      case 'SmartMeter':
        return 'question-mark';
      default:
        return 'info';
    }
  };

  const changeTab = (tab) => {
    setActiveFilter(tab);
    setPageNumber(0);
    dataFetch({ page: 0 });
  };

  return (
    <div className={s.container}>
      {(selectedItem || addNewLibrary) && (
        <DashboardAsset
          data={selectedItemData}
          librariesRefetch={librariesRefetch}
          onBackToDashboard={() => onAssetSelect(undefined)}
          changeTab={changeTab}
        />
      )}
      <DataGrid<LibraryOutput>
        className={s.dataGrid}
        headerTitle="Assets"
        activeFilter={activeFilter}
        filters={FILTERS}
        selectedTagsFilters={selectedTagsFilters}
        setSelectedTagsFilters={(val) => {
          setSelectedTagsFilters(val);
          setPageNumber(0);
          dataFetch({ page: 0 });
        }}
        tagsFilters={TAGS_FILTERS}
        tableColumns={[]}
        allItemsCount={totalLibraries}
        data={libraries}
        dataFetch={dataFetch}
        itemsPerPage={ITEMS_PER_PAGE}
        onFilterChange={(val: typeof activeFilter) => {
          changeTab(val);
          // setActiveFilter(val);
          // setPageNumber(0);
          // dataFetch({ page: 0 });
        }}
        pageNumber={pageNumber}
        searchValue={searchValue}
        setPageNumber={(val) => setPageNumber(val)}
        setSearchValue={(val) => setSearchValue(val)}
        gridItemRenderFn={(data, className) => (
          <DashboardAssetsGridItem
            key={data.uuid}
            className={className}
            data={data}
            updateGridData={dataFetch}
            assetIcon={getAssetIcon(data.scenarioData?.representation?.type)}
            onClick={() => {
              if (data.uuid) {
                if (addNewLibrary) {
                  dispatch(setAddNewLibrary(false));
                }
                onAssetSelect(data.uuid);
                setSelectedItemData(data);
              }
            }}
            onThreeDotsClick={manageContexMenu}
          />
        )}
        tableItemRenderFn={(data, className) => (
          <DashboardAssetsTableItem
            key={data.uuid}
            className={className}
            data={data}
            updateGridData={dataFetch}
            assetIcon={getAssetIcon(data.scenarioData?.representation?.type)}
            onClick={() => {
              if (data.uuid) {
                onAssetSelect(data.uuid);
                setSelectedItemData(data);
              }
            }}
            onThreeDotsClick={manageContexMenu}
          />
        )}
        contextMenu={{
          isOpen: isContextMenuOpened,
          ref: contextMenuState.modalRef,
          items: contextMenuItems,
          id: contextMenuModalId,
        }}
      />
    </div>
  );
};
