import React from 'react';

import { SingleNotificationWrapper } from 'src/components/SingleNotifications/SingleNotificationWrapper';
import { deleteSingleNotification } from 'src/redux/notifications/notificaitons.slice';
import { TSingleNotification } from 'src/redux/notifications/notifications.types';
import { useAppDispatch } from 'src/redux/store';

import s from './RequestSentNotification.module.scss';


export type TRequestSentNotificationProps = {
  singleNotification: TSingleNotification;
};

export const RequestSentNotification: React.FC<TRequestSentNotificationProps> = ({
  singleNotification,
}) => {
  const dispatch = useAppDispatch();

  return (
    <SingleNotificationWrapper
      className={s.requestSentNotificationContainer}
      onClick={() => {
        dispatch(deleteSingleNotification());
      }}>
      <div className={s.singleNotificationContainer}>
        {singleNotification.characterIcon && (
          <span className={s.singleCharacterContainer}>{singleNotification.characterIcon}</span>
        )}
        {singleNotification.description && <span>{singleNotification.description}</span>}
      </div>
    </SingleNotificationWrapper>
  );
};
