import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MapSidebarCommunitiesHeaderProps } from 'src/components/MapSidebar/components/MapSidebarCommunitiesList/MapSidebarCommunitiesList.types';
import { selectCommunitiesCount } from 'src/redux/communities/communities.selectors';
import { formatter } from 'src/utils/formatter';

import s from './MapSidebarCommunitiesList.module.scss';

export const MapSidebarCommunitiesHeader: React.FC<MapSidebarCommunitiesHeaderProps> = () => {
  const { t } = useTranslation();

  const communitiesCount = useSelector(selectCommunitiesCount);

  return (
    <>
      <div className={s.header}>
        <h2>{t('navigation.MY_COMMUNITIES')}</h2>
        <h3>{formatter.prefixWithZeros(communitiesCount)}</h3>
      </div>
    </>
  );
};
