import React from 'react';

import classnames from 'classnames';

import s from './TextBrick.module.scss';
import { TTextBrickProps } from './TextBrick.types';

export const TextBrick: React.FC<TTextBrickProps> = ({ children, className }) => {
  return (
    <div className={classnames(s.box, className)}>
      <span>{children}</span>
    </div>
  );
};
