import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import {
  TAssetsViewUserTileProps,
  TFormDataState,
  TFormErrorAreas,
} from 'src/components/MapSidebar/components/MapSidebarCanary/components/AssetsView/AssetsView.types';
import { UserTileForm } from 'src/components/MapSidebar/components/MapSidebarCanary/components/AssetsView/UserTileForm';
import { UserTileShowData } from 'src/components/MapSidebar/components/MapSidebarCanary/components/AssetsView/UserTileShowData';
import { useCreateCommunityMemberMutation, useUpdateCommunityMemberMutation } from 'src/graphql';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useMemberEvents } from 'src/hooks/useMemberEvents';
import { selectSelectedMember } from 'src/redux/application/application.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectAssetsValues } from 'src/redux/configuration/configuration.selectors';

import s from './AssetsView.module.scss';

const EMAIL_VALIDATION = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const AssetsViewUserTile: React.FC<TAssetsViewUserTileProps> = ({
  uuid: uuidProp,
  name,
  email,
  address,
  zipCode,
  toggleAssetView,
}) => {
  const { t } = useTranslation();

  const { zoomIntoConfiguration } = useConfigurationUtils();

  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const selectedMember = useSelector(selectSelectedMember);
  const assetsValues = useSelector(selectAssetsValues);

  const [updateCommunityMemberMutation] = useUpdateCommunityMemberMutation();
  const [, { data: createData }] = useCreateCommunityMemberMutation();
  const { handleRemoveCommunityMember, handleCreateCommunityMember } = useMemberEvents({
    toggleAssetView,
  });

  const { isOwnerAdmin, isOwnerCM } = useIsAdminInspecting();

  const [formData, setFormData] = useState<TFormDataState>({ name, email, address, zipCode });
  const [formStatus, setFormStatus] = useState<boolean>(selectedMember === 'NEW_MEMBER');
  const [uuid, setUuid] = useState<string>(uuidProp);
  const [formErrorAreas, setFormErrorAreas] = useState<TFormErrorAreas>({});

  useEffect(() => {
    if (createData?.createCommunityMember?.uuid) {
      setUuid(createData.createCommunityMember.uuid);
    }
  }, [createData]);

  const onSubmit = useCallback(() => {
    if (!activeConfigurationUuid) return;

    const formKeys = ['name', 'email', 'address'];

    const isFormValid = formKeys.every((key) => formData[key]);

    if (!activeConfigurationUuid) return;

    if (!isFormValid) {
      // find the first empty field and focus on it
      const emptyFields = formKeys.filter((key) => !formData[key]);
      if (emptyFields.length > 0) {
        const errorAreas: TFormErrorAreas = {};
        emptyFields.forEach((field) => {
          errorAreas[field] = 'This field is required';
        });
        setFormErrorAreas(errorAreas);
      }
      return;
    }

    if (selectedMember === 'NEW_MEMBER') {
      handleCreateCommunityMember(formData);
    } else {
      updateCommunityMemberMutation({
        variables: {
          name: formData.name,
          email: formData.email,
          zipcode: formData.zipCode,
          address: formData.address,
          memberUuid: uuid,
          configUuid: activeConfigurationUuid || '',
        },
      });
    }

    setFormStatus(false);
  }, [
    formData,
    updateCommunityMemberMutation,
    handleCreateCommunityMember,
    activeConfigurationUuid,
    uuid,
    selectedMember,
  ]);

  const onClickRemoveMember = () => {
    handleRemoveCommunityMember(uuid);
    zoomIntoConfiguration({ assetsValues });
  };

  const canSave =
    formData.name && formData.email && formData.address && EMAIL_VALIDATION.test(formData.email);

  return (
    <>
      <div
        className={classNames(s.userTileContainer, {
          [s.newUser]: selectedMember === 'NEW_MEMBER',
        })}>
        <div className={s.infoContainer}>
          <div className={s.actionsContainer}>
            {!formStatus && (
              <BaseButtonSquare
                onClick={() => {
                  setFormStatus(true);
                }}
                className={s.button}
                icon="pencil-edit"
                size="2"
              />
            )}
            {!formStatus && (isOwnerAdmin || isOwnerCM) && (
              <BaseButtonSquare
                onClick={onClickRemoveMember}
                className={s.button}
                icon="trash"
                size="2"
              />
            )}
            {formStatus && !(selectedMember === 'NEW_MEMBER') && (
              <BaseButtonSquare
                onClick={() => setFormStatus(false)}
                className={s.button}
                icon="close"
                size="2"
                svgSize="3"
              />
            )}
          </div>
          {formStatus ? (
            <>
              <UserTileForm
                formErrorAreas={formErrorAreas}
                setFormData={setFormData}
                isNewUser={selectedMember === 'NEW_MEMBER'}
                {...formData}
              />
              <BaseButton
                type="button"
                onClick={onSubmit}
                className={s.formButton}
                disabled={!canSave}>
                {t('commands.SAVE')}
              </BaseButton>
            </>
          ) : (
            <UserTileShowData {...formData} setFormData={setFormData} onSubmit={onSubmit} />
          )}
        </div>
      </div>
    </>
  );
};
