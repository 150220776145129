import React from 'react';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';

import s from './BaseBanner.module.scss';
import { TBaseBannerProps } from './BaseBanner.types';

export const BaseBanner: React.FC<TBaseBannerProps> = ({
  cta,
  iconStart,
  textHeading: heading,
  text,
}) => {
  return (
    <div className={s.baseBanner}>
      {iconStart && (
        <div className={s.iconWrapper}>
          <BaseIcon icon={iconStart} size={15} />
        </div>
      )}
      <div className={s.text}>
        <p className={s.bannerHeading}>{heading}</p>
        <p>{text}</p>
      </div>
      {cta && (
        <div className={s.bannerCTA}>
          <BaseButton size="normal" onClick={cta.handler}>
            {cta.label}
          </BaseButton>
        </div>
      )}
    </div>
  );
};
