import { useMemo, useRef } from 'react';

import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { EHeaderMode } from 'src/components/Header';
import { useAppLocation } from 'src/hooks/useAppLocation';
import {
  selectIsEmbed,
  selectModalMapHeroDismissed,
  selectUserClickedBuildOnWelcomeScreen,
} from 'src/redux/application/application.selectors';
import {
  selectActiveConfigurationUuid,
  selectAssetsAmountBelowCommunity,
  selectRootAsset,
  selectSelectedAssetUuid,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { routesConfig } from 'src/routes/routes.config';

export enum EConfigurationFlowState {
  // Show MapHero
  WelcomeScreen = 'WELCOME_SCREEN',
  // Show Search modal after clicking on a Build button in MapHero
  WelcomeBuild = 'WELCOME_BUILD',
  // Simulations map view (default after enter)
  DefaultPreview = 'DEFAULT_PREVIEW',
  // Create New Community (before save to local storage)
  CreateNewCommunity = 'CREATE_NEW_COMMUNITY',
  // Community is created only in local storage
  OfflineBuildMode = 'OFFLINE_BUILD_MODE',
  // Community is created in DB but not run yet
  OnlineBuildMode = 'ONLINE_BUILD_MODE',
  // Simulation is run
  SimulationInProgress = 'SIMULATION_IN_PROGRESS',
  // Simulation is finished
  SimulationCompleted = 'SIMULATION_COMPLETED',
  // Simulation is edited
  SimulationEdit = 'SIMULATION_EDIT',
  // Simulation is failed
  SimulationFailed = 'SIMULATION_FAILED',
  // Embed mode
  Embed = 'EMBED',
}

export type TConfigurationFlowState = {
  configurationFlowState: EConfigurationFlowState;
  isBuildMode: boolean;
  sidebarLocked: boolean;
  sidebarOpen: boolean;
  modalSearchLocationShow: boolean;
  modalAddMoreShow: boolean;
  modalRunSimulationShow: boolean;
  modalMapHeroShow: boolean;
  modalCanaryShow: boolean;
  modalsEmbedShow: boolean;
  headerMode: EHeaderMode;
  graphSimulationProgressShow: boolean;
  modalCommunityAssetsShow: boolean;
  communityIsReadyToSave: boolean;
  modalOnboarding: boolean;
};

export const useConfigurationFlowState = (): TConfigurationFlowState => {
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const rootAsset = useSelector(selectRootAsset);
  const simulationStatus = useSelector(selectSimulationStatus);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const modalMapHeroDismissed = useSelector(selectModalMapHeroDismissed);
  const userClickedBuildOnWelcomeScreen = useSelector(selectUserClickedBuildOnWelcomeScreen);
  const assetsAmountBelowCommunity = useSelector(selectAssetsAmountBelowCommunity);
  const location = useAppLocation();
  const isEmbed = useSelector(selectIsEmbed);

  const prevConfigurationFlowState = useRef<EConfigurationFlowState>(
    EConfigurationFlowState.WelcomeScreen,
  );
  const configurationFlowState = useMemo(() => {
    let output: EConfigurationFlowState = prevConfigurationFlowState.current;
    const isProgressStatus: boolean =
      !!simulationStatus &&
      ['started', 'running', 'queued', 'loading', 'initializing'].includes(simulationStatus);

    const isCompletedStatus: boolean =
      !!simulationStatus &&
      ['finished', 'paused', 'stopped', 'stopping'].includes(simulationStatus);

    const isFailedStatus: boolean =
      !!simulationStatus && ['failed', 'error', 'timed-out'].includes(simulationStatus);

    const noConfiguration = !activeConfigurationUuid && !rootAsset;
    const hasOnlineConfiguration = activeConfigurationUuid && rootAsset;
    const configurationHasSimulation = activeConfigurationUuid && rootAsset && simulationStatus;
    const createRouteMatch = matchPath(location.pathname, {
      path: routesConfig.scmMapCreate(),
    });

    // State conditions
    const isEmbedMode = isEmbed;
    const isWelcomeScreen = noConfiguration && !modalMapHeroDismissed;
    const isWelcomeBuild = noConfiguration && userClickedBuildOnWelcomeScreen && !createRouteMatch;
    const isDefaultPreview = noConfiguration && !createRouteMatch;
    const isCreateNewCommunity = noConfiguration && !!createRouteMatch;
    const isOfflineBuildMode = !activeConfigurationUuid && rootAsset;
    const isOnlineBuildMode = hasOnlineConfiguration && !simulationStatus;
    const isSimulationEditMode = configurationHasSimulation && selectedAssetUuid && !isFailedStatus;
    const isSimulationInProgress = configurationHasSimulation && isProgressStatus;
    const isSimulationCompleted = configurationHasSimulation && isCompletedStatus;
    const isSimulationFailed = configurationHasSimulation && isFailedStatus;

    const statesMap: Map<EConfigurationFlowState, boolean> = new Map([
      [EConfigurationFlowState.Embed, !!isEmbedMode],
      [EConfigurationFlowState.WelcomeScreen, !!isWelcomeScreen],
      [EConfigurationFlowState.WelcomeBuild, !!isWelcomeBuild],
      [EConfigurationFlowState.DefaultPreview, !!isDefaultPreview],
      [EConfigurationFlowState.CreateNewCommunity, !!isCreateNewCommunity],
      [EConfigurationFlowState.OfflineBuildMode, !!isOfflineBuildMode],
      [EConfigurationFlowState.OnlineBuildMode, !!isOnlineBuildMode],
      [EConfigurationFlowState.SimulationEdit, !!isSimulationEditMode],
      [EConfigurationFlowState.SimulationInProgress, !!isSimulationInProgress],
      [EConfigurationFlowState.SimulationCompleted, !!isSimulationCompleted],
      [EConfigurationFlowState.SimulationFailed, !!isSimulationFailed],
    ]);

    const thruthlyStatus: EConfigurationFlowState | undefined = Array.from(statesMap).find(
      ([key, value]) => value && key,
    )?.[0];

    if (thruthlyStatus) {
      output = thruthlyStatus;
    } else {
      // console.error('Combination is not handled');
    }

    prevConfigurationFlowState.current = output;
    return output;
  }, [
    activeConfigurationUuid,
    isEmbed,
    location.pathname,
    modalMapHeroDismissed,
    rootAsset,
    selectedAssetUuid,
    simulationStatus,
    userClickedBuildOnWelcomeScreen,
  ]);

  switch (configurationFlowState) {
    case EConfigurationFlowState.Embed:
      return {
        configurationFlowState,
        isBuildMode: false,
        sidebarLocked: false,
        sidebarOpen: true,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: false,
        modalCanaryShow: false,
        graphSimulationProgressShow: false,
        headerMode: EHeaderMode.Community,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: true,
        communityIsReadyToSave: false,
        modalOnboarding: true,
      };
    case EConfigurationFlowState.CreateNewCommunity:
      return {
        configurationFlowState,
        isBuildMode: true,
        sidebarLocked: false,
        sidebarOpen: false,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: false,
        modalCanaryShow: false,
        graphSimulationProgressShow: false,
        headerMode: EHeaderMode.Community,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: false,
        communityIsReadyToSave: false,
        modalOnboarding: true,
      };
    case EConfigurationFlowState.OfflineBuildMode:
      return {
        configurationFlowState,
        isBuildMode: true,
        sidebarLocked: false,
        sidebarOpen: false,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: false,
        modalCanaryShow: false,
        graphSimulationProgressShow: false,
        headerMode: EHeaderMode.Community,
        modalCommunityAssetsShow: true,
        modalsEmbedShow: true,
        communityIsReadyToSave: assetsAmountBelowCommunity >= 2,
        modalOnboarding: true,
      };
    case EConfigurationFlowState.OnlineBuildMode:
      return {
        configurationFlowState,
        isBuildMode: true,
        sidebarLocked: false,
        sidebarOpen: true,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: true,
        modalMapHeroShow: false,
        modalCanaryShow: false,
        graphSimulationProgressShow: false,
        headerMode: EHeaderMode.Community,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: true,
        communityIsReadyToSave: false,
        modalOnboarding: true,
      };
    case EConfigurationFlowState.SimulationEdit:
      return {
        configurationFlowState,
        isBuildMode: true,
        sidebarLocked: false,
        sidebarOpen: true,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: false,
        modalCanaryShow: false,
        graphSimulationProgressShow: true,
        headerMode: EHeaderMode.Community,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: true,
        communityIsReadyToSave: false,
        modalOnboarding: true,
      };
    case EConfigurationFlowState.SimulationInProgress:
      return {
        configurationFlowState,
        isBuildMode: false,
        sidebarLocked: false,
        sidebarOpen: true,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: false,
        modalCanaryShow: false,
        graphSimulationProgressShow: true,
        headerMode: EHeaderMode.Community,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: true,
        communityIsReadyToSave: false,
        modalOnboarding: false,
      };
    case EConfigurationFlowState.SimulationCompleted:
      return {
        configurationFlowState,
        isBuildMode: false,
        sidebarLocked: false,
        sidebarOpen: true,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: false,
        modalCanaryShow: true,
        graphSimulationProgressShow: true,
        headerMode: EHeaderMode.Community,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: true,
        communityIsReadyToSave: false,
        modalOnboarding: false,
      };
    case EConfigurationFlowState.SimulationFailed:
      return {
        configurationFlowState,
        isBuildMode: false,
        sidebarLocked: false,
        sidebarOpen: false,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: false,
        modalCanaryShow: false,
        graphSimulationProgressShow: true,
        headerMode: EHeaderMode.Community,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: false,
        communityIsReadyToSave: false,
        modalOnboarding: false,
      };
    case EConfigurationFlowState.WelcomeScreen:
      return {
        configurationFlowState,
        isBuildMode: false,
        sidebarLocked: false,
        sidebarOpen: false,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: true,
        modalCanaryShow: false,
        graphSimulationProgressShow: false,
        headerMode: EHeaderMode.Default,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: false,
        communityIsReadyToSave: false,
        modalOnboarding: false,
      };
    case EConfigurationFlowState.WelcomeBuild:
      return {
        configurationFlowState,
        isBuildMode: false,
        sidebarLocked: false,
        sidebarOpen: false,
        modalSearchLocationShow: true,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: true,
        modalCanaryShow: false,
        graphSimulationProgressShow: false,
        headerMode: EHeaderMode.Default,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: false,
        communityIsReadyToSave: false,
        modalOnboarding: false,
      };
    case EConfigurationFlowState.DefaultPreview:
    default:
      return {
        configurationFlowState,
        isBuildMode: false,
        sidebarLocked: false,
        sidebarOpen: false,
        modalSearchLocationShow: false,
        modalAddMoreShow: false,
        modalRunSimulationShow: false,
        modalMapHeroShow: true,
        modalCanaryShow: false,
        graphSimulationProgressShow: false,
        headerMode: EHeaderMode.Default,
        modalCommunityAssetsShow: false,
        modalsEmbedShow: true,
        communityIsReadyToSave: false,
        modalOnboarding: true,
      };
  }
};
