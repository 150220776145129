import 'chartjs-adapter-moment';

import { useEffect, useRef } from 'react';

import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartTypeRegistry,
  DoughnutController,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  Plugin,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import { merge } from 'lodash';
import { EChartName, getChartOptions, getChartType } from 'src/components/_charts/chartsData';


Chart.register(
  BubbleController,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  BarController,
  BarElement,
  DoughnutController,
  ArcElement,
  Filler,
  Tooltip,
);

export function useChartJS(
  chartName: EChartName,
  canvasElementRef: React.MutableRefObject<HTMLCanvasElement | null>,
  chartRef: React.MutableRefObject<Chart | null>,
  data: ChartConfiguration['data'],
  options?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartOptions?: any;
    datasetsLengthWillChange?: boolean;
    preventReRender?: boolean;
    plugins?: Plugin<keyof ChartTypeRegistry>[];
  },
): void {
  const reRenderRef = useRef<number>(0);
  const dataRef = useRef<ChartConfiguration['data']>(data);
  const { chartOptions, plugins, datasetsLengthWillChange, preventReRender } = options || {};
  // Update datasets
  useEffect(() => {
    if (!chartRef.current) return;

    if (!datasetsLengthWillChange) {
      if (chartRef.current.data.datasets.length !== data.datasets.length) {
        return console.error('You must pass datasets in the same lenght and the same order!');
      }

      chartRef.current.data.datasets.forEach((dataset, index) => {
        dataset.data = data.datasets[index].data;
        dataset.hidden = data.datasets[index].hidden;
      });
    } else {
      chartRef.current.data.datasets = data.datasets;
    }

    if (data.labels) {
      chartRef.current.data.labels = data.labels;
    }

    chartRef.current.update();
  }, [chartRef, data.datasets, data.labels, datasetsLengthWillChange]);

  // Initialize effect should be the last one
  useEffect(() => {
    if (!canvasElementRef.current) return;

    let shouldRunRender = true;
    if (preventReRender && reRenderRef.current > 0) shouldRunRender = false;

    if (shouldRunRender) {
      if (chartRef.current) {
        chartRef.current.clear();
        chartRef.current.destroy();
      }

      chartRef.current = new Chart(canvasElementRef.current, {
        type: getChartType(chartName),
        data: dataRef.current,
        options: merge(getChartOptions(chartName), chartOptions),
        plugins: plugins,
      });

      chartRef.current.resize();

      if (reRenderRef.current === 0) reRenderRef.current = 1;
    }

    return () => {
      chartRef.current?.reset();
      chartRef.current?.clear();
    };
  }, [
    chartRef,
    canvasElementRef,
    chartName,
    chartOptions,
    plugins,
    preventReRender,
    datasetsLengthWillChange,
    reRenderRef,
  ]);
}
