import React, { useContext, useRef, useState } from 'react';

import classnames from 'classnames';
import { BaseButton } from 'src/components/BaseButton';
import {
  FormSettingsData,
  TFormSettingsDataProps,
} from 'src/components/MapSidebar/components/FormSettingsData';
import { TabNav } from 'src/components/TabNav';
import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { v4 } from 'uuid';

import s from './MapSidebarConfigurationSettings.module.scss';
import {
  EActiveTabState,
  EFormVariant,
  TMapSidebarConfigurationSettingsProps,
} from './MapSidebarConfigurationSettings.types';

export const MapSidebarConfigurationSettings: React.FC<TMapSidebarConfigurationSettingsProps> = ({
  onSettingsDataSave,
}) => {
  const { triggerResultsLossAlert } = useContext(ApplicationContext);
  const [activeTab, setActiveTab] = useState<EActiveTabState>(EActiveTabState.General);
  const [formVariant, setFormVariant] = useState(EFormVariant.Basic);
  const formId = useRef(v4());
  const formHasErrorsRef = useRef(false);

  const handleSettingsDataSave: TFormSettingsDataProps['onSubmit'] = async (payload) => {
    await triggerResultsLossAlert();

    onSettingsDataSave?.(payload);
  };

  return (
    <div className={s.container}>
      <div className={s.header}>
        <ul className={s.headerNav}>
          <li
            className={classnames({
              [s.active]: activeTab === EActiveTabState.General,
            })}>
            <button
              type="button"
              onClick={() => setActiveTab(EActiveTabState.General)}
              title="Profile">
              General Information
            </button>
          </li>
        </ul>
      </div>

      <div className={s.tabsContainer}>
        <TabNav
          theme="1"
          nav={[
            {
              label: 'Basic',
              onClick() {
                setFormVariant(EFormVariant.Basic);
              },
              isActive: formVariant === EFormVariant.Basic,
            },
          ]}
        />
      </div>
      {(() => {
        switch (formVariant) {
          case EFormVariant.Basic:
            return (
              <div className={s.basic}>
                <FormSettingsData
                  className={s.formMaxHeight}
                  id={formId.current}
                  hasErrorsRef={formHasErrorsRef}
                  onSubmit={handleSettingsDataSave}
                  disableLocationField={false}
                  theme="dark"
                />
              </div>
            );
        }
      })()}
      <div className={s.formButtonsWrapper}>
        <BaseButton type="submit" className={s.formButton} form={formId.current}>
          Save
        </BaseButton>
      </div>
    </div>
  );
};
