import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  TResponseBill,
  TTableBillsState,
} from 'src/components/_charts/TableBills/TableBills.types';
import { DataAggregationResolution } from 'src/graphql';
import { ScmAccumulatedBillsDifferences } from 'src/graphql/subscriptions/scmAccumulatedBillsDifferences';
import { ScmAccumulatedBillsDifferencesCommunity } from 'src/graphql/subscriptions/scmAccumulatedBillsDifferencesCommunity';
import { ScmAccumulatedKpiDifferences } from 'src/graphql/subscriptions/scmAccumulatedKpiDifferences';
import { ScmProfileResultsStats } from 'src/graphql/subscriptions/scmProfileResults';
import {
  TUseApolloSubscriptionResult,
  useApolloSubscription,
} from 'src/hooks/useApolloSubscription';
import {
  selectActiveConfigurationJobUuid,
  selectCommunityAsset,
  selectRawSelectedAssetUuid,
  selectResultsStartTime,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectActiveLiveAccumulatedSubscriptionId,
  selectDataResolution,
  selectIsOperationalCommunity,
} from 'src/redux/scm/scm.selectors';
import {
  setActiveLiveAccumulatedSubscriptionId,
  setCommunityBillsDifferences,
  setCommunityKpi,
  setCommunityKpiDifference,
  setHomeBillsDifferences,
  setHomeKpi,
  setIsAppLoading,
  setKpiDifference,
  setSCMBillTableData,
} from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { KeysToSnakeCase } from 'src/typings/helpers';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { pickKpiData } from 'src/utils/pickKpiData';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import { v4 } from 'uuid';

export type useAccumulatedResultsWithSubscriptionArgs = {
  //initialRunQueries: Array<keyof TUseAccumulatedResultsWithSubscriptionResponse['queries']>;
  mode: 'home' | 'community';
};

export const useAccumulatedResultsWithSubscription = ({
  //initialRunQueries,
  mode,
}: useAccumulatedResultsWithSubscriptionArgs): void => {
  const dispatch = useAppDispatch();
  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const rawSelectedAssetUuid = useSelector(selectRawSelectedAssetUuid);
  const communityUuid = useSelector(selectCommunityAsset)?.uuid;
  const dataResolution = useSelector(selectDataResolution);
  const activeLiveAccumulatedSubscriptionId = useSelector(
    selectActiveLiveAccumulatedSubscriptionId,
  );
  const resultsStartTimeState = useSelector(selectResultsStartTime);

  const apolloClient = useApolloClient();

  const simulationStatus = useSelector(selectSimulationStatus);
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);

  const currentSubscriptionId = useRef<string | null>(null);
  const runChecksRef = useRef<boolean | null>(null);
  const subscriptionRefs = useRef<{
    ScmAccumulatedResultsStats: TUseApolloSubscriptionResult | null;
    ScmAccumulatedKpiDifferences: TUseApolloSubscriptionResult | null;
    ScmAccumulatedBillsDifferences: TUseApolloSubscriptionResult | null;
    ScmAccumulatedBillsDifferencesCommunity: TUseApolloSubscriptionResult | null;
  }>({
    ScmAccumulatedResultsStats: null,
    ScmAccumulatedKpiDifferences: null,
    ScmAccumulatedBillsDifferences: null,
    ScmAccumulatedBillsDifferencesCommunity: null,
  });

  const [allRun, setAllRun] = useState<boolean>(false);

  const uuidForQueries = useMemo(() => {
    if (mode === 'home') {
      return rawSelectedAssetUuid;
    }
    return communityUuid;
  }, [mode, communityUuid, rawSelectedAssetUuid]);

  const shouldSkip = useMemo(
    () =>
      !activeJobUuid ||
      !uuidForQueries ||
      !activeLiveAccumulatedSubscriptionId ||
      simulationStatus !== 'running' ||
      !isOperationCommunity ||
      currentSubscriptionId.current !== activeLiveAccumulatedSubscriptionId,
    [
      activeJobUuid,
      uuidForQueries,
      activeLiveAccumulatedSubscriptionId,
      simulationStatus,
      isOperationCommunity,
    ],
  );

  const resultsStartTimeRef = useRef<string | null>(null);

  const calculateResultsStartTime = useCallback(
    (resolution: DataAggregationResolution) => {
      const calculatedTime = UTCMoment.utc(Date.now()).startOf(
        resolution === DataAggregationResolution.Daily ? 'day' : 'month',
      );
      //      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);

      const defaultStartTime = UTCMoment.utc(resultsStartTimeState);

      return (defaultStartTime.isBefore(calculatedTime) ? calculatedTime : defaultStartTime).format(
        BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
      );
    },
    [resultsStartTimeState],
  );

  const resultsStartTime = useMemo(() => {
    return calculateResultsStartTime(dataResolution);
  }, [dataResolution, calculateResultsStartTime]);

  //const resultsStartTime = useMemo(() => {
  //  return UTCMoment.utc(Date.now())
  //    .startOf(dataResolution === DataAggregationResolution.Daily ? 'day' : 'month')
  //    .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  //}, [dataResolution]);

  const commonVariables = useMemo(
    () => ({
      variables: {
        jobId: activeJobUuid,
        areaUUID: uuidForQueries,
        startTime: resultsStartTime,
      },
    }),
    [activeJobUuid, uuidForQueries, resultsStartTime],
  );

  useEffect(() => {
    const tempId = v4();
    currentSubscriptionId.current = tempId;
    dispatch(setActiveLiveAccumulatedSubscriptionId(tempId));
  }, [dispatch]);

  subscriptionRefs.current.ScmAccumulatedResultsStats = useApolloSubscription<{
    scmProfileResultsStats: { kpi: string; billsEnergy: string };
  }>(
    apolloClient,
    {
      ...commonVariables,
      query: ScmProfileResultsStats,
      errorPolicy: 'ignore',
    },
    {
      onSubscriptionData(data) {
        const dispatchTarget = mode === 'community' ? setCommunityKpi : setHomeKpi;
        if (data?.scmProfileResultsStats?.kpi && data?.scmProfileResultsStats?.billsEnergy) {
          dispatch(
            dispatchTarget(
              pickKpiData({
                kpi: JSON.parse(data.scmProfileResultsStats.kpi),
                billsEnergy: JSON.parse(data.scmProfileResultsStats.billsEnergy),
              }),
            ),
          );
        }
        dispatch(setIsAppLoading(false));
      },
    },
  );

  subscriptionRefs.current.ScmAccumulatedKpiDifferences = useApolloSubscription<{
    scmAccumulatedKpiDifferences: string;
  }>(
    apolloClient,
    {
      ...commonVariables,
      query: ScmAccumulatedKpiDifferences,
      errorPolicy: 'ignore',
    },
    {
      onSubscriptionData(data) {
        const dispatchTarget = mode === 'community' ? setCommunityKpiDifference : setKpiDifference;
        if (data.scmAccumulatedKpiDifferences) {
          dispatch(dispatchTarget(objectCamelCase(JSON.parse(data.scmAccumulatedKpiDifferences))));
        }
      },
    },
  );

  subscriptionRefs.current.ScmAccumulatedBillsDifferences = useApolloSubscription<{
    scmAccumulatedBillsDifferences: string;
  }>(
    apolloClient,
    {
      ...commonVariables,
      query: ScmAccumulatedBillsDifferences,
      errorPolicy: 'ignore',
    },
    {
      onSubscriptionData(data) {
        const dispatchTarget =
          mode === 'community' ? setCommunityBillsDifferences : setHomeBillsDifferences;
        if (data.scmAccumulatedBillsDifferences) {
          dispatch(
            dispatchTarget(objectCamelCase(JSON.parse(data.scmAccumulatedBillsDifferences))),
          );
        }
      },
    },
  );

  subscriptionRefs.current.ScmAccumulatedBillsDifferencesCommunity = useApolloSubscription<{
    scmAccumulatedBillsDifferencesCommunity: string;
  }>(
    apolloClient,
    {
      ...commonVariables,
      query: ScmAccumulatedBillsDifferencesCommunity,
      errorPolicy: 'ignore',
    },
    {
      onSubscriptionData(data) {
        if (data.scmAccumulatedBillsDifferencesCommunity) {
          const responseObj: Record<
            string,
            { name: string; bills: KeysToSnakeCase<TResponseBill> }
          > = JSON.parse(data.scmAccumulatedBillsDifferencesCommunity);

          const tempBills: TTableBillsState['bills'] = [];

          for (const key in responseObj) {
            const partialBill = objectCamelCase<TResponseBill>(responseObj[key].bills);
            tempBills.push({
              ...partialBill,
              assetName: responseObj[key].name,
              uuid: key,
            });
          }

          dispatch(setSCMBillTableData(tempBills));
        }
      },
    },
  );

  const runChecks = useMemo(() => {
    return (
      !shouldSkip &&
      Object.values(subscriptionRefs.current).every((item) => item !== null) &&
      currentSubscriptionId.current !== null &&
      currentSubscriptionId.current === activeLiveAccumulatedSubscriptionId
    );
  }, [
    subscriptionRefs,
    //allRun,
    currentSubscriptionId,
    activeLiveAccumulatedSubscriptionId,
    shouldSkip,
  ]);

  const batchAction = useCallback(
    (action: keyof TUseApolloSubscriptionResult) => {
      subscriptionRefs.current.ScmAccumulatedResultsStats?.[action]();
      subscriptionRefs.current.ScmAccumulatedKpiDifferences?.[action]();
      subscriptionRefs.current.ScmAccumulatedBillsDifferences?.[action]();
      if (mode === 'community') {
        subscriptionRefs.current.ScmAccumulatedBillsDifferencesCommunity?.[action]();
      }
    },
    [subscriptionRefs, mode],
  );

  useEffect(() => {
    if (runChecksRef.current === runChecks || allRun) return;

    runChecksRef.current = runChecks;
    if (runChecks && allRun === false) {
      setAllRun(runChecks);
      batchAction(runChecks ? 'subscribe' : 'unsubscribe');
    }

    return () => {
      if (runChecks && allRun) {
        batchAction('unsubscribe');
      }
    };
  }, [batchAction, runChecks, allRun]);

  useEffect(() => {
    if (resultsStartTimeRef.current === resultsStartTime) return;

    if (resultsStartTimeRef.current === null) {
      resultsStartTimeRef.current = resultsStartTime;
      return;
    }

    if (resultsStartTimeRef.current !== resultsStartTime) {
      resultsStartTimeRef.current = resultsStartTime;
      //setAllRun(false);
      batchAction('unsubscribe');
      batchAction('subscribe');
    }
  }, [resultsStartTime, resultsStartTimeRef, runChecks, batchAction]);
};
