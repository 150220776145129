import { useCallback, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { TResolutionButtonState } from 'src/components/SimulationResultsPresentation/SingleHome';
import {
  DataAggregationResolution,
  ScmAccumulatedBillsDifferencesCommunityLazyQueryHookResult,
  ScmAccumulatedBillsDifferencesCommunityQuery,
  ScmAccumulatedBillsDifferencesLazyQueryHookResult,
  ScmAccumulatedBillsDifferencesQuery,
  ScmAccumulatedKpiDifferencesLazyQueryHookResult,
  ScmAccumulatedKpiDifferencesQuery,
  ScmProfileResultsLazyQueryHookResult,
  ScmProfileResultsQuery,
  useScmAccumulatedBillsDifferencesCommunityLazyQuery,
  useScmAccumulatedBillsDifferencesLazyQuery,
  useScmAccumulatedKpiDifferencesLazyQuery,
  useScmProfileResultsLazyQuery,
} from 'src/graphql';
import {
  selectActiveConfigurationJobUuid,
  selectCommunityAsset,
  selectRawSelectedAssetUuid,
  selectResultsEndTime,
  selectResultsStartTime,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectDataResolution,
  selectIsOperationalCommunity,
  selectSelectedDailyIndicator,
  selectSelectedMonthlyIndicator,
} from 'src/redux/scm/scm.selectors';
import { setDataResolution } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

export type TUseAccumulatedResultsResponse = {
  queries: {
    getAccumulatedResults: ScmProfileResultsLazyQueryHookResult[0];
    getAccumulatedBillsDifferences: ScmAccumulatedBillsDifferencesLazyQueryHookResult[0];
    getAccumulatedKpiDifferences: ScmAccumulatedKpiDifferencesLazyQueryHookResult[0];
    getAccumulatedResultsDaily: ScmProfileResultsLazyQueryHookResult[0];
    getAccumulatedResultsMonthly: ScmProfileResultsLazyQueryHookResult[0];
    getAccumulatedBillsDifferencesCommunity: ScmAccumulatedBillsDifferencesCommunityLazyQueryHookResult[0];
  };
  calledInfo: {
    billsDifferencesCommunityResponseCalled: boolean;
  };
  resultsStartTime: string | null;
  resultsEndTime: string | null;
  resultsData: ScmProfileResultsQuery['scmProfileResults'];
  resultsDataMonthly: ScmProfileResultsQuery['scmProfileResults'];
  resultsDataDaily: ScmProfileResultsQuery['scmProfileResults'];
  billsDifferenceData: ScmAccumulatedBillsDifferencesQuery['scmAccumulatedBillsDifferences'];
  kpiDifferenceData: ScmAccumulatedKpiDifferencesQuery['scmAccumulatedKpiDifferences'];
  billsDifferencesCommunityData: ScmAccumulatedBillsDifferencesCommunityQuery['scmAccumulatedBillsDifferencesCommunity'];
  handleResolutionButton: (value: TResolutionButtonState) => void;
};

export type TUseAccumulatedResultsArgs = {
  initialRunQueries: Array<keyof TUseAccumulatedResultsResponse['queries']>;
  mode: 'home' | 'community';
};

export const useAccumulatedResults = ({
  initialRunQueries,
  mode,
}: TUseAccumulatedResultsArgs): TUseAccumulatedResultsResponse => {
  const dispatch = useAppDispatch();

  const dataResolution = useSelector(selectDataResolution);
  const resultsStartTimeState = useSelector(selectResultsStartTime);
  const resultsEndTimeState = useSelector(selectResultsEndTime);
  const rawSelectedAssetUuid = useSelector(selectRawSelectedAssetUuid);
  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const communityUuid = useSelector(selectCommunityAsset)?.uuid;
  const isOperationCommunity = useSelector(selectIsOperationalCommunity);
  const selectedMonthlyIndicator = useSelector(selectSelectedMonthlyIndicator);
  const selectedDailyIndicator = useSelector(selectSelectedDailyIndicator);
  const RESULTFORMAT = useMemo(() => BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME, []);

  const uuidForQueries = useMemo(() => {
    if (mode === 'home') {
      return rawSelectedAssetUuid;
    }
    return communityUuid;
  }, [mode, communityUuid, rawSelectedAssetUuid]);

  const yesterdayTimeForResults = useMemo(
    () => UTCMoment.fromBackend(Date.now()).subtract(3, 'day').format(RESULTFORMAT),
    [RESULTFORMAT],
  );

  const resultsEndTime = useMemo(() => {
    if (!resultsEndTimeState && !isOperationCommunity) return null;
    if (selectedDailyIndicator && dataResolution === DataAggregationResolution.Daily) {
      if (UTCMoment.fromBackend(selectedDailyIndicator).isSame(yesterdayTimeForResults, 'day')) {
        return UTCMoment.fromBackend(yesterdayTimeForResults).format(RESULTFORMAT);
      }
      return UTCMoment.fromBackend(selectedDailyIndicator).endOf('day').format(RESULTFORMAT);
    }

    if (selectedMonthlyIndicator && dataResolution === DataAggregationResolution.Monthly) {
      return UTCMoment.fromBackend(
        selectedMonthlyIndicator && !selectedMonthlyIndicator.isCurrent
          ? selectedMonthlyIndicator.endUtc
          : yesterdayTimeForResults,
      ).format(RESULTFORMAT);
    }

    return isOperationCommunity
      ? UTCMoment.fromBackend(yesterdayTimeForResults).format(RESULTFORMAT)
      : UTCMoment.fromBackend(resultsEndTimeState)
          .subtract(1, 'day')
          .endOf('day')
          .format(RESULTFORMAT);
  }, [
    yesterdayTimeForResults,
    resultsEndTimeState,
    isOperationCommunity,
    selectedMonthlyIndicator,
    dataResolution,
    selectedDailyIndicator,
    RESULTFORMAT,
  ]);

  const calculateResultsStartTime = useCallback(
    (resolution: DataAggregationResolution) => {
      /*      const getResultsEndTime = () => {
        const endTime = dataResolution === dataResolution === DataAggregationResolution.Monthly && selectedMonthlyIndicator && !selectedMonthlyIndicator.isCurrent ?
        selectedMonthlyIndicator.endUtc : 

        if (selectedMonthlyIndicator && !selectedMonthlyIndicator.isCurrent) {
          return UTCMoment.utc(selectedMonthlyIndicator.endUtc).format(
            BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
          );
        }

        return UTCMoment.utc(isOperationCommunity ? Date.now() : resultsEndTime).format(
          BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
        );
      };*/

      const calculatedTime = UTCMoment.utc(resultsEndTime).startOf(
        resolution === DataAggregationResolution.Daily ? 'day' : 'month',
      );
      //      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);

      const defaultStartTime = UTCMoment.utc(resultsStartTimeState);

      return (defaultStartTime.isBefore(calculatedTime) ? calculatedTime : defaultStartTime).format(
        RESULTFORMAT,
      );
    },
    [resultsEndTime, RESULTFORMAT, resultsStartTimeState],
  );

  const resultsStartTime = useMemo(() => {
    if (!resultsEndTime) return null;
    return calculateResultsStartTime(dataResolution);
  }, [resultsEndTime, dataResolution, calculateResultsStartTime]);

  const isQueryParametersOK = activeJobUuid && uuidForQueries && resultsStartTime && resultsEndTime;

  //ACCUMULATED RESULTS

  const [
    getAccumulatedResults,
    { data: resultsResponse, called: resultsCalled },
  ] = useScmProfileResultsLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });

  const [
    getAccumulatedResultsMonthly,
    { data: resultsResponseMonthly },
  ] = useScmProfileResultsLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: calculateResultsStartTime(DataAggregationResolution.Monthly),
      endTime: resultsEndTime,
    },
  });

  const [
    getAccumulatedResultsDaily,
    { data: resultsResponseDaily },
  ] = useScmProfileResultsLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: calculateResultsStartTime(DataAggregationResolution.Daily),
      endTime: resultsEndTime,
    },
  });

  useEffect(() => {
    if (
      isQueryParametersOK &&
      initialRunQueries.includes('getAccumulatedResults') &&
      !resultsResponse &&
      !resultsCalled
    ) {
      getAccumulatedResults();
    }
  }, [
    isQueryParametersOK,
    initialRunQueries,
    getAccumulatedResults,
    resultsResponse,
    resultsCalled,
  ]);

  // ACCUMULATED RESULTS FOR WHOLE COMMUNITY

  const [
    getAccumulatedBillsDifferencesCommunity,
    { data: billsDifferencesCommunityResponse, called: billsDifferencesCommunityResponseCalled },
  ] = useScmAccumulatedBillsDifferencesCommunityLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });

  //BILLS DIFFERENCES

  const [
    getAccumulatedBillsDifferences,
    { data: billsDifferenceResponse, called: billsDifferenceCalled },
  ] = useScmAccumulatedBillsDifferencesLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });

  useEffect(() => {
    if (
      isQueryParametersOK &&
      initialRunQueries.includes('getAccumulatedBillsDifferences') &&
      !billsDifferenceResponse &&
      !billsDifferenceCalled
    ) {
      getAccumulatedBillsDifferences();
    }
  }, [
    isQueryParametersOK,
    initialRunQueries,
    getAccumulatedBillsDifferences,
    billsDifferenceResponse,
    billsDifferenceCalled,
  ]);

  //KPI DIFFERENCES

  const [
    getAccumulatedKpiDifferences,
    { data: kpiDifferenceResponse, called: isKpiDifferenceCalled },
  ] = useScmAccumulatedKpiDifferencesLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });

  useEffect(() => {
    if (
      activeJobUuid &&
      uuidForQueries &&
      initialRunQueries.includes('getAccumulatedKpiDifferences') &&
      !kpiDifferenceResponse &&
      !isKpiDifferenceCalled
    ) {
      getAccumulatedKpiDifferences();
    }
    //
  }, [
    activeJobUuid,
    uuidForQueries,
    initialRunQueries,
    getAccumulatedKpiDifferences,
    kpiDifferenceResponse,
    isKpiDifferenceCalled,
  ]);

  const handleResolutionButton = useCallback<(value: TResolutionButtonState) => void>(
    (value) => {
      dispatch(setDataResolution(value));
    },
    [dispatch],
  );

  return {
    queries: {
      getAccumulatedResults,
      getAccumulatedBillsDifferences,
      getAccumulatedKpiDifferences,
      getAccumulatedResultsDaily,
      getAccumulatedResultsMonthly,
      getAccumulatedBillsDifferencesCommunity,
    },
    calledInfo: {
      billsDifferencesCommunityResponseCalled,
    },
    resultsStartTime,
    resultsEndTime,
    resultsData: resultsResponse?.scmProfileResults,
    resultsDataMonthly: resultsResponseMonthly?.scmProfileResults,
    resultsDataDaily: resultsResponseDaily?.scmProfileResults,
    billsDifferenceData: billsDifferenceResponse?.scmAccumulatedBillsDifferences,
    kpiDifferenceData: kpiDifferenceResponse?.scmAccumulatedKpiDifferences,
    billsDifferencesCommunityData:
      billsDifferencesCommunityResponse?.scmAccumulatedBillsDifferencesCommunity,
    handleResolutionButton,
  };
};
