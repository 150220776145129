import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  TNotification,
  TNotificationsState,
  TSingleNotification,
} from 'src/redux/notifications/notifications.types';
import { v4 } from 'uuid';

const initialState: TNotificationsState = {
  localNotificationsList: [],
  globalNotificationsList: [],
  singleNotification: null,
  measurementNotificationList: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addLocalNotification(state, action: PayloadAction<Omit<TNotification, 'uuid'>>) {
      state.localNotificationsList.push({ uuid: v4(), ...action.payload });
    },
    deleteLocalNotification(state, action: PayloadAction<TNotification['uuid']>) {
      const index = state.localNotificationsList.findIndex((n) => n.uuid === action.payload);
      state.localNotificationsList.splice(index, 1);
    },
    deleteAllLocalNotifications(state) {
      state.localNotificationsList = [];
    },
    addGlobalNotification(state, action: PayloadAction<Omit<TNotification, 'uuid' | 'id'>>) {
      // @ts-ignore
      const index = state.globalNotificationsList.findIndex((n) => n.id === action.payload.id);
      if (index === -1) {
        state.globalNotificationsList.push({ uuid: v4(), ...action.payload });
      }
    },
    deleteGlobalNotification(state, action: PayloadAction<TNotification['uuid']>) {
      const index = state.globalNotificationsList.findIndex((n) => n.uuid === action.payload);
      state.globalNotificationsList.splice(index, 1);
    },
    deleteAllGlobalNotifications(state) {
      state.globalNotificationsList = [];
    },
    addSingleNotification(state, action: PayloadAction<TSingleNotification>) {
      state.singleNotification = { ...action.payload };
    },
    deleteSingleNotification(state) {
      state.singleNotification = null;
    },
    setMeasurementNotificationList(
      state,
      action: PayloadAction<TNotificationsState['measurementNotificationList']>,
    ) {
      state.measurementNotificationList = action.payload;
    },
    clearMeasurementNotificationList(state) {
      state.measurementNotificationList = [];
    },
  },
});

// Actions
export const {
  addLocalNotification,
  deleteLocalNotification,
  deleteAllLocalNotifications,
  addGlobalNotification,
  deleteGlobalNotification,
  deleteAllGlobalNotifications,
  addSingleNotification,
  deleteSingleNotification,
  setMeasurementNotificationList,
  clearMeasurementNotificationList,
} = notificationsSlice.actions;

// Reducer
export const notificationsReducer = notificationsSlice.reducer;
