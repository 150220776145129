import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseSelect } from 'src/components/BaseSelect';
import { BaseSwitch } from 'src/components/BaseSwitch';
import {
  FormAssetsParams,
  TAssetsSaveProps,
  TFormAssetsParamsProps,
} from 'src/components/FormAssetsParams';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { GridMarketModalHeader } from 'src/components/MapSidebar/components/GridMarket/GridMarketModalHeader';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';
import {
  useUpdateCommunityFeesTariffsMutation,
  useUpdateCommunityMemberMutation,
} from 'src/graphql';
import { useAssetsData } from 'src/hooks/useAssetsData';
import { useMemberEvents } from 'src/hooks/useMemberEvents';
import {
  selectActiveConfigurationUuid,
  selectAssets,
} from 'src/redux/configuration/configuration.selectors';
import { selectCommunityMembers } from 'src/redux/scm/scm.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { EFormVariant, TAssetType } from 'src/typings/base-types';
import { TAsset } from 'src/typings/configuration.types';
import { areObjectsEqualByFields } from 'src/utils/general';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';
import { v4 } from 'uuid';

import s from './GridMarket.module.scss';

type GridMarketFormProps = {
  hostAssetUuid: TAsset['uuid'];
  handleAssetValuesSavePromise: TFormAssetsParamsProps['onSubmitPromise'];
  theme?: 'dark' | 'light';
  formId?: React.MutableRefObject<string>;
  readOnly?: boolean;
};

export const GridMarketForm: React.FC<GridMarketFormProps> = ({
  theme = 'light',
  formId: formIdProp,
  readOnly,
}) => {
  const { t } = useTranslation();

  const tempFormId = useRef(v4());
  const ECBFormId = useRef(v4());
  const formHasErrorsRef = useRef(false);
  const firstFormValues = useRef({});
  const submitFormRef = useRef<HTMLInputElement | null>(null);

  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const communityMembers = useSelector(selectCommunityMembers);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const assets = useSelector(selectAssets);

  const rootUuid = Object.values(assets).find((item) => item.type === 'InfiniteBus')?.uuid || '';
  const [areaUuid, setAreaUuid] = useState<string>(
    isOperationalCommunity ? communityMembers[0].uuid : rootUuid || '',
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newCommunityMember, setNewCommunityMember] = useState<any>(
    communityMembers.find((member) => member.uuid === areaUuid),
  );
  const [originalCommunityMember, setOriginalCommunityMember] = useState(
    communityMembers.find((member) => member.uuid === areaUuid),
  );
  const [overwriteSettings, setOverwriteSettings] = useState(false);

  const formId = formIdProp ? formIdProp : tempFormId;
  const formVariant = areaUuid === rootUuid ? EFormVariant.GridAgent : EFormVariant.Advanced;
  const gridMarketTitle =
    areaUuid === rootUuid ? t('navigation.MARKET') : t('navigation.MARKET_SETTINGS');

  const { assetsData } = useAssetsData();
  const { handleReadConfiguration } = useMemberEvents({});
  const [updateCommunityFeesTariffs] = useUpdateCommunityFeesTariffsMutation({
    errorPolicy: 'all',
    onCompleted: () => {
      dispatchSuccessToast();
      handleReadConfiguration({
        variables: {
          uuid: configUuid!,
        },
      });
    },
    onError: (err) => dispatchErrorToast(err),
  });
  const [updateCommunityMember] = useUpdateCommunityMemberMutation({
    errorPolicy: 'all',
    onCompleted: () => {
      setOriginalCommunityMember(newCommunityMember);
      dispatchSuccessToast();
      handleReadConfiguration({
        variables: {
          uuid: configUuid!,
        },
      });
    },
    onError: (err) => dispatchErrorToast(err),
  });

  const onFieldChange = ({ fieldName, value }) => {
    const updatedCommunityMember = {
      ...newCommunityMember,
      [fieldName]: value,
    };
    setNewCommunityMember(updatedCommunityMember!);
  };

  const onBillComponentsSubmit = useCallback<
    ({ assetUuid, values, assetType }: TAssetsSaveProps) => void
  >(
    async ({ assetUuid, assetType }) => {
      if (assetType === 'Area') {
        updateCommunityMember({
          variables: {
            configUuid: configUuid,
            memberUuid: assetUuid,
            fees: {
              assistanceMonthlyFee: newCommunityMember.assistanceMonthlyFee,
              serviceMonthlyFee: newCommunityMember.serviceMonthlyFee,
              fixedMonthlyFee: newCommunityMember.fixedMonthlyFee,
              gridImportFeeConst: newCommunityMember.gridImportFeeConst,
              gridExportFeeConst: newCommunityMember.gridExportFeeConst,
              taxesSurcharges: newCommunityMember.taxesSurcharges,
              contractedPowerMonthlyFee: newCommunityMember.contractedPowerMonthlyFee,
              contractedPowerCargoMonthlyFee: newCommunityMember.contractedPowerCargoMonthlyFee,
              energyCargoFee: newCommunityMember.energyCargoFee,
            },
            ...newCommunityMember,
          },
        });
      }

      if (assetType === 'InfiniteBus') {
        if (!configUuid || !overwriteSettings) return;
        updateCommunityFeesTariffs({
          variables: {
            configUUID: configUuid,
            ...firstFormValues.current,
          },
        });
      }
    },
    [
      configUuid,
      overwriteSettings,
      updateCommunityFeesTariffs,
      updateCommunityMember,
      newCommunityMember,
    ],
  );

  const triggerNextForm = useCallback<() => void>(() => {
    if (submitFormRef && submitFormRef.current && submitFormRef.current.form) {
      submitFormRef.current.setAttribute('form', ECBFormId.current);
      submitFormRef.current.click();
      submitFormRef.current.setAttribute('form', formId.current);
    }
  }, [submitFormRef, ECBFormId, formId]);

  // @ts-ignore-start
  const gridMarketFormSave = useCallback<
    ({ assetUuid, values, assetType }: TAssetsSaveProps) => void
  >(
    ({ values }: TAssetsSaveProps) => {
      let newValues = values;
      newValues = {};
      // TODO: IMPORTANT, we have to fix here,
      // It was so hurry, sorry so much

      // keys that we need to delete
      Object.keys(values).forEach((item) => {
        newValues[item] = values[item];
      });

      firstFormValues.current = newValues;

      triggerNextForm();
    },
    [triggerNextForm],
  );

  const handleSelectChange = ({ value }) => {
    setAreaUuid(value);
    setNewCommunityMember(communityMembers.find((member) => member.uuid === value));
    setOriginalCommunityMember(communityMembers.find((member) => member.uuid === value));
  };

  const handleCheckboxChange = ({ value }) => {
    setOverwriteSettings(value);
  };
  const assetList: Array<{ label: string; value: string; type: TAssetType }> = useMemo(() => {
    if (isOperationalCommunity) {
      return assetsData
        .filter(
          (item) =>
            item.type === 'Area' && item.name !== 'Community' && item.name !== 'Grid Market',
        )
        .map((item) => ({ label: item.name, value: item.uuid, type: item.type as TAssetType }));
    }
    return assetsData
      .filter((item) => item.type === 'Area' && item.name !== 'Community')
      .map((item) => ({
        label: item.name === 'Grid Market' ? t('labels.GENERAL_MARKET') : item.name,
        value: item.name === 'Grid Market' ? rootUuid : item.uuid,
        type: item.type as TAssetType,
      }));
  }, [assetsData, rootUuid, isOperationalCommunity, t]);

  const showGridMarketForm = (overwriteSettings && rootUuid === areaUuid) || rootUuid !== areaUuid;

  const formAssetType = useMemo(() => {
    if (areaUuid === rootUuid) return 'InfiniteBus';
    const asset = assetsData.find((item) => item.uuid === areaUuid);

    return (asset?.type || 'Area') as TAssetType;
  }, [assetsData, areaUuid, rootUuid]);

  const canSave =
    !areObjectsEqualByFields(newCommunityMember, originalCommunityMember) &&
    (overwriteSettings || areaUuid !== rootUuid);

  const commmunityMember = useMemo(
    () => communityMembers.find((member) => member.uuid === areaUuid),
    [communityMembers, areaUuid],
  );
  console.log('commmunityMember', commmunityMember);
  return (
    <>
      <div className={s.formWrapper}>
        <FieldContainer className={s.selectField}>
          <BaseSelect
            onChange={(val) => handleSelectChange(val)}
            name={'gridMarket'}
            value={areaUuid}
            options={assetList}
            theme={'filled-gray'}
            showTooltip
            tooltipText={''}
          />
        </FieldContainer>
        {rootUuid === areaUuid && (
          <div className={s.row}>
            <FieldContainer className={s.switchWrapper} inlineAlign="right">
              <BaseSwitch
                onChange={(val) => handleCheckboxChange(val)}
                name={'gridMarket-check'}
                className={s.switch}
                value={overwriteSettings}
                options={[
                  { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false, text: 'Off' },
                  {
                    icon: 'check-mark',
                    iconSize: SWITCHER_ICON_SIZES.tick,
                    value: true,
                    text: 'On',
                  },
                ]}
                theme={'gray-custom'}
                variant="horizontal-edge"
                showTooltip
                tooltipText={''}
              />
            </FieldContainer>
            <div className={s.label}>{t('tooltips.GENERAL_MARKET_SETTINGS_INFO')}</div>
          </div>
        )}
        {showGridMarketForm && (
          <>
            <GridMarketModalHeader formTitle={gridMarketTitle} />
            <FormAssetsParams
              key={`${formId.current}${areaUuid}`}
              hasErrorsRef={formHasErrorsRef}
              id={formId.current}
              assetUuid={areaUuid}
              formVariant={formVariant}
              onSubmit={gridMarketFormSave}
              assetType={formAssetType}
              currentValues={commmunityMember}
              disableLocationField={false}
              isCustomPV={false}
              isEdit={overwriteSettings || areaUuid !== rootUuid}
              theme={theme}
              fieldProps={{ forceTopLabel: true }}
              onChange={onFieldChange}
            />
            <GridMarketModalHeader formTitle="Energy Bill Components" />
            <FormAssetsParams
              key={`${ECBFormId.current}${areaUuid}`}
              hasErrorsRef={formHasErrorsRef}
              id={ECBFormId.current}
              assetUuid={areaUuid}
              formVariant={EFormVariant.GridMarketEnergy}
              onSubmit={onBillComponentsSubmit}
              assetType={formAssetType}
              currentValues={commmunityMember}
              disableLocationField={false}
              isCustomPV={false}
              isEdit={overwriteSettings || areaUuid !== rootUuid}
              theme={theme}
              forceAll={true}
              isSCM={true}
              fieldProps={{ forceTopLabel: true }}
              onChange={onFieldChange}
            />
          </>
        )}
      </div>

      <div className={s.formButtonsWrapper}>
        <BaseButton
          propRef={(ref) => {
            submitFormRef.current = ref;
          }}
          type="submit"
          className={s.formButton}
          form={formId.current}
          disabled={readOnly || !canSave}>
          Save
        </BaseButton>
      </div>
    </>
  );
};
