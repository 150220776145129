import React from 'react';

import classnames from 'classnames';
import { AssetsEnergyExportIndicator } from 'src/components/AssetsEnergyExportIndicator';
import { BaseIcon } from 'src/components/BaseIcon';

import { BaseClose } from '../BaseClose';
import s from './AssetListItem.module.scss';
import { TAssetListItemProps } from './AssetListItem.types';

export const AssetListItem: React.FC<TAssetListItemProps> = ({
  className,
  theme = 'dark',
  badgeColor = 'primary',
  draggable,
  icon,
  title,
  subtitle,
  onClick,
  onCloseClick,
  onThreeDotsClick,
  energyExportStatus,
}: TAssetListItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MainComponent: any = onClick ? 'button' : 'div';

  return (
    <div
      className={classnames(className, s.container, {
        [s.themeDark]: theme === 'dark',
        [s.themeLight]: theme === 'light',
        [s[`badge-color-${badgeColor}`]]: badgeColor,
      })}>
      {draggable && (
        <button type="button" className={s.dragButton}>
          <BaseIcon className={s.dragIcon} icon="drag" size={8} />
        </button>
      )}
      <MainComponent type="button" onClick={onClick} className={s.mainButton}>
        <div className={s.icon}>
          <BaseIcon icon={icon} size={16} />
        </div>
        <div className={s.textWrapper}>
          <div className={classnames(s.title, 'ellipsis')}>{title}</div>
          <div className={s.details}>{subtitle}</div>
        </div>
        {energyExportStatus && (
          <div className={s.exportIndicator}>
            <AssetsEnergyExportIndicator exportStatus={energyExportStatus} />
          </div>
        )}
      </MainComponent>

      {onThreeDotsClick && (
        <button onClick={onThreeDotsClick} type="button" className={s.threeDots}>
          <BaseIcon icon="more-horizontal" size={10} />
        </button>
      )}

      {onCloseClick && <BaseClose className={s.closeBtn} onClick={onCloseClick} />}
    </div>
  );
};
