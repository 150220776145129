import React from 'react';

import { MobileHeader } from 'src/components/MobileView/components/MobileHeader';
import { ModalMobileWarning } from 'src/components/ModalMobileWarning';

import s from './MobileWarning.module.scss';

export const MobileWarning: React.FC = () => (
  <div className={s.container}>
    <MobileHeader />
    <ModalMobileWarning />
  </div>
);
