import React, { useCallback, useMemo, useState } from 'react';


import classnames from 'classnames';
import { meanBy } from 'lodash';
import { useSelector } from 'react-redux';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { SimulationProgressModalExpanded } from 'src/components/SimulationProgressModal/components/SimulationProgressModalExpanded';
import { SimulationProgressModalSmall } from 'src/components/SimulationProgressModal/components/SimulationProgressModalSmall';
import { EButtonType } from 'src/components/SimulationProgressModal/components/TimelineArrowButtons/TimelineArrowButtons.types';
import { EDurationType } from 'src/components/SimulationProgressModal/components/TimelineFilters/TimelineFilters.types';
import { EDomIds } from 'src/constants/domSelectors';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { ConfigType } from 'src/graphql';
import { useHouseSummaryResults } from 'src/hooks/useHouseSummaryResults';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectCommunityAsset,
  selectConfigType,
  selectRawCommunitySimulationResults,
  selectSettingsData,
  selectSimulationProgress,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { formatter } from 'src/utils/formatter';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

import s from './SimulationProgressModal.module.scss';
import { TSimulationProgressModalProps } from './SimulationProgressModal.types';

export type TSimulationProgressModalDetailsData = {
  title: string;
  value: string;
  unit: string;
  icon: TIconNames;
}[];

export const SimulationProgressModal: React.FC<TSimulationProgressModalProps> = () => {
  const simulationProgress = useSelector(selectSimulationProgress);
  const simulationStatus = useSelector(selectSimulationStatus);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const simulationResults = useSelector(selectRawCommunitySimulationResults);
  const communityAsset = useSelector(selectCommunityAsset);

  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const sidebarOpened = useSelector(selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
  const summaryResults = useHouseSummaryResults({ assetUuid: communityAsset?.uuid });
  const configType = useSelector(selectConfigType);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);

  const isCanaryNetwork = configType === ConfigType.CanaryNetwork;

  const [durationFilter, setDurationFilter] = useState<string>('');
  const [graphFilter, setGraphFilter] = useState<number[]>([]);
  const [expanded, setExpanded] = useState(false);
  const { runButtonOptions, stopButtonOptions, runButtonState } = useSimulationButtons({
    jobUuid: activeConfigurationJobUuid,
    configurationUuid: activeConfigurationUuid,
  });
  const { currency, startDate, endDate } = useSelector(selectSettingsData);
  const [graphStartDate, setGraphStartDate] = useState(startDate);
  const [graphEndDate, setGraphEndDate] = useState(endDate);

  const getFilterDate = useCallback((date, durationFilter, move) => {
    let newDate;
    switch (durationFilter) {
      case EDurationType.OneDay:
        newDate = UTCMoment.dateDifferenceMove(
          date,
          1,
          'days',
          BACKEND_DATE_FORMATS.SETTINGS_DATA,
          move,
        );
        break;
      case EDurationType.OneWeek:
        newDate = UTCMoment.dateDifferenceMove(
          date,
          7,
          'days',
          BACKEND_DATE_FORMATS.SETTINGS_DATA,
          move,
        );
        break;
      case EDurationType.OneMonth:
        newDate = UTCMoment.dateDifferenceMove(
          date,
          1,
          'months',
          BACKEND_DATE_FORMATS.SETTINGS_DATA,
          move,
        );
        break;
    }
    return newDate;
  }, []);

  const setGraphDatesForDurationFilter = useCallback(
    (durationFilter) => {
      let finalDate = ``;
      if (durationFilter?.length && durationFilter !== EDurationType.All) {
        finalDate = getFilterDate(graphStartDate, durationFilter, EButtonType.next);
      } else {
        finalDate = endDate;
        setGraphStartDate(startDate);
      }
      setGraphEndDate(finalDate);
    },
    [getFilterDate, graphStartDate, endDate, startDate],
  );

  const handleDurationFilter = (value) => {
    setDurationFilter(value);
    setGraphDatesForDurationFilter(value);
  };

  const handleArrowButtonClick = useCallback(
    (value: string) => {
      if (durationFilter?.length && durationFilter !== EDurationType.All) {
        if (value === EButtonType.prev) {
          const nextStart = getFilterDate(graphStartDate, durationFilter, EButtonType.prev);
          setGraphStartDate(nextStart);
          const nextEnd = getFilterDate(graphEndDate, durationFilter, EButtonType.prev);
          setGraphEndDate(nextEnd);
        } else if (value === EButtonType.next) {
          const nextStart = getFilterDate(graphStartDate, durationFilter, EButtonType.next);
          setGraphStartDate(nextStart);
          const nextEnd = getFilterDate(graphEndDate, durationFilter, EButtonType.next);
          setGraphEndDate(nextEnd);
        }
      }
    },
    [durationFilter, getFilterDate, graphStartDate, graphEndDate],
  );

  const detailsData: TSimulationProgressModalDetailsData = useMemo(() => {
    const lastMarketSummaryItem =
      simulationResults?.marketSummary &&
      simulationResults?.marketSummary[simulationResults?.marketSummary.length - 1];

    return [
      {
        title: 'Price',
        value: formatter.formatCents(
          formatter.toOptionalFixed(
            meanBy(summaryResults.pricesData, (p) => p.y as number) || 0,
            2,
          ),
        ),
        unit: `${formatter.getCurrencySymbol(currency)} / kWh`,
        icon: 'stock-bolt',
      },
      {
        title: 'Volume',
        value: `${formatter.toOptionalFixed(lastMarketSummaryItem?.tradedVolume || 0)}`,
        unit: 'kWh',
        icon: 'volume',
      },
    ];
  }, [currency, simulationResults?.marketSummary, summaryResults.pricesData]);

  const simulationStatusTitle = useMemo(() => {
    switch (simulationStatus) {
      case 'stopped':
        return isOperationalCommunity ? 'Operation Stopped' : 'Simulation Stopped';
      case 'finished':
        return isOperationalCommunity ? 'Operation Stopped' : 'Simulation Finished';
      case 'failed':
        return isOperationalCommunity ? 'Operation Failed' : 'Simulation Failed';
      case 'timed-out':
        return isOperationalCommunity ? 'Operation Timed-Out' : 'Simulation Timed-Out';
      case 'queued':
        return isOperationalCommunity ? 'Operation Queued' : 'Simulation Queued';
      case 'running':
        return isOperationalCommunity ? 'Operation Running' : 'Simulation Running';
      case 'started':
        return isOperationalCommunity ? 'Operation Started' : 'Simulation Started';
      case 'initializing':
        return isOperationalCommunity ? 'Operation Simulation' : 'Initializing Simulation';
      case 'paused':
        return isOperationalCommunity ? 'Operation Paused' : 'Simulation Paused';
      default:
        return isOperationalCommunity ? 'Operation Loading' : 'Simulation Loading';
    }
  }, [simulationStatus, isOperationalCommunity]);

  return (
    <div
      style={{
        ['--sidebarWidth' as string]: (sidebarExpanded ? EModalSize.XL795 : EModalSize.L425) + 'px',
      }}
      className={classnames(s.container, {
        [s.expanded]: expanded,
        [s.expandedFull]: expanded && !sidebarOpened,
        [s.expandedMedium]: expanded && !sidebarExpanded && sidebarOpened,
        [s.expandedSmall]: expanded && sidebarExpanded && sidebarOpened,
      })}
      id={EDomIds.SIMULATION_PROGRESS_MODAL}>
      {!expanded && (
        <SimulationProgressModalSmall
          simulationStatusTitle={simulationStatusTitle}
          simulationProgress={simulationProgress}
          runButtonState={runButtonState}
          runButtonOptions={runButtonOptions}
          stopButtonOptions={stopButtonOptions}
          detailsData={detailsData}
          toggleExpanded={() => setExpanded(!expanded)}
          graphStartDate={graphStartDate}
          graphEndDate={graphEndDate}
          showProgressPercentage={!isCanaryNetwork}
        />
      )}
      {expanded && (
        <SimulationProgressModalExpanded
          simulationStatusTitle={simulationStatusTitle}
          simulationProgress={simulationProgress}
          runButtonState={runButtonState}
          runButtonOptions={runButtonOptions}
          stopButtonOptions={stopButtonOptions}
          detailsData={detailsData}
          toggleExpanded={() => setExpanded(!expanded)}
          filter={graphFilter}
          setFilter={(value) => setGraphFilter(value)}
          durationFilter={durationFilter}
          setDurationFilter={(value) => handleDurationFilter(value)}
          graphStartDate={graphStartDate}
          graphEndDate={graphEndDate}
          handleArrowButtonClick={handleArrowButtonClick}
          showProgressPercentage={!isCanaryNetwork}
        />
      )}
    </div>
  );
};
