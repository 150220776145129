import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import mapboxgl, { CustomLayerInterface, Map } from 'mapbox-gl';
import { TSimulationResultData } from 'src/hooks/useConfigurationEffects';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TMapboxLocation } from 'src/services/api/mapbox.api';
import { TViewport } from 'src/services/map/map.service';
import { Color, Material, Clock, Group, Vector2, Object3D } from 'src/three';
import { TLngLat } from 'src/typings/base-types';
import { TAsset } from 'src/typings/configuration.types';
import CanvasStats from 'src/utils/canvasStats';
import {
  disposeObject,
  generateTBAssetPin,
  generateTBCircle,
  generateTBPin,
  generateTBRingBase,
  randomOffsetVal,
  removeChildren,
} from 'src/utils/canvasUtils';
import { getConfigurationLocation } from 'src/utils/configuration/getConfigurationLocation';
import { compareOrdinaryJSONObjects } from 'src/utils/fieldValidation';
import { Threebox } from 'threebox-plugin';

import {
  EAssetType,
  TCommunityListProps,
  TLayerEntityInputProps,
  TUpdateLayerScaleInputProps,
} from './ThreeboxController.types';

declare global {
  interface Window {
    tb: Threebox;
  }
}

mapboxgl.accessToken = process.env.REACT_APP_D3A_MAPBOX_KEY as string;

class ThreeboxController {
  public communityList: TCommunityListProps = [];
  public assets: TConfigurationState['assets'] = {};
  public assetValues: TConfigurationState['assetsValues'] = {};
  public communityAsset: TAsset | undefined = undefined;
  public simulationResults:
    | Pick<TSimulationResultData, 'bills' | 'cumulativeNetEnergyFlow'>
    | undefined = undefined;
  public configurationCenter: TLngLat | undefined = undefined;

  private threeboxLayer: CustomLayerInterface; // Custom layer containing 3D
  private map: Map; // Data of communitys and details
  private positiveCol: Color; // Positive color (Green)
  private negativeCol: Color; // Negative color (Red)
  private middleCol: Color; // Middle color (Blue)
  private initialMapScale: number; // Initial map scale
  private tbPositivePlot: Threebox.Object3D; // Plot model for cloning
  private tbZeroPlot: Threebox.Object3D; // Plot model for cloning
  private tbNegativePlot: Threebox.Object3D; // Plot model for cloning
  private tbPlotInitialScale: number; // Initial scale of plot
  private tbPositivePin: Threebox.Object3D; // Pin model for cloning
  private tbZeroPin: Threebox.Object3D; // Pin model for cloning
  private tbNegativePin: Threebox.Object3D; // Pin model for cloning
  private positivePinTowerMaterial: Material; // Material of positive ping
  private negativePinTowerMaterial: Material; // Material of negative ping
  private tbPinInitialScale: number; // Initial scale of pin
  private tbPinMaxScale: number; // Max scale of pin
  private tbPinMinScale: number; // Min scale of pin
  private tbPositiveAssetPin: Threebox.Object3D; // Asset pin model for cloning
  private tbZeroAssetPin: Threebox.Object3D; // Asset pin model for cloning
  private tbNegativeAssetPin: Threebox.Object3D; // Asset pin model for cloning
  private positiveAssetPinTowerMaterial: Material; // Material of positive ping
  private negativeAssetPinTowerMaterial: Material; // Material of negative ping
  private tbAssetPinInitialScale: number; // Initial scale of asset pin
  private tbAssetPinMaxScale: number; // Max scale of asset pin. Should be dynamic by community ring radius
  private tbAssetPinMinScale: number; // Min scale of asset pin
  private tbSmallAssetPinInitialScale: number; // Initial scale of small asset pin
  private tbSmallAssetPinMaxScale: number; // Max scale of small asset pin. Should be dynamic by community ring radius
  private tbSmallAssetPinMinScale: number; // Min scale of small asset pin
  private scatterPlotLayer: Group; // Layer of plot plots
  private pinLayer: Group; // Layer of pins
  private communityLayer: Group; // Layer of community
  private communityRingLayer: Group; // Layer of community ring
  private communityAssetsLayer: Group; // Layer of community assets(House)
  private communitySmallAssetsLayer: Group; // Layer of community small assets(PV/Load/Battery)
  private communityTempAssetsLayer: Group; // Layer of temporary community assets
  private currentViewPort!: TViewport; // Current viewport object
  private isBuildMode: boolean; // Flag of disabled status
  private maxZoomGlobalViz: number; // Max zoom level of global viz
  private maxZoomContinentalViz: number; // Max zoom level of continental viz
  private maxZoomRegionViz1: number; // Max zoom level of region viz1
  private maxZoomRegionViz2: number; // Max zoom level of region viz2
  private maxZoomCityViz1: number; // Max zoom level of city viz1
  private maxZoomCityViz2: number; // Max zoom level of city viz2
  private maxZoomCommunityViz: number; // Max zoom level of community viz
  private username: string; // User name who authorized to manipulate
  private clock: Clock; // Three.js clock
  private pixelSortingOffsetY: number; // offset Y of pixel sorting texture
  private requestID: number | undefined = undefined; // id of current tick
  private disposed: boolean; // Flag for indicating disposal state

  // Dev mode
  private devMode = process.env.NODE_ENV === 'development';
  private enableStats = true; // Enable stats view
  private stats: CanvasStats | null = null; // Stats

  constructor(map: Map) {
    this.map = map;
    this.positiveCol = new Color(0, 1, 0);
    this.negativeCol = new Color(1, 0, 0);
    this.middleCol = new Color(0, 0, 1);
    this.initialMapScale = 1.7812;
    this.scatterPlotLayer = new Group();
    this.pinLayer = new Group();
    this.communityLayer = new Group();
    this.communityRingLayer = new Group();
    this.communityAssetsLayer = new Group();
    this.communitySmallAssetsLayer = new Group();
    this.communityTempAssetsLayer = new Group();
    this.communityLayer.add(this.communityRingLayer);
    this.communityLayer.add(this.communityAssetsLayer);
    this.communityLayer.add(this.communitySmallAssetsLayer);
    this.communityLayer.add(this.communityTempAssetsLayer);
    this.tbPlotInitialScale = 5000;
    this.tbPinInitialScale = 300;
    this.tbPinMaxScale = 10;
    this.tbPinMinScale = 0.13;
    this.tbAssetPinInitialScale = 120;
    this.tbAssetPinMaxScale = 1000;
    this.tbAssetPinMinScale = 0.0075;
    this.tbSmallAssetPinInitialScale = 90;
    this.tbSmallAssetPinMaxScale = 750;
    this.tbSmallAssetPinMinScale = 0.0056;
    this.isBuildMode = false;
    this.maxZoomGlobalViz = 4.3;
    this.maxZoomContinentalViz = 5.5;
    this.maxZoomRegionViz1 = 6.4;
    this.maxZoomRegionViz2 = 7.2;
    this.maxZoomCityViz1 = 10.5;
    this.maxZoomCityViz2 = 14.9;
    this.maxZoomCommunityViz = 18;
    this.clock = new Clock();
    this.pixelSortingOffsetY = 0;
    this.username = '';
    this.disposed = false;

    this.createThreebox();

    // Dev mode helpers
    if (this.devMode) {
      if (this.enableStats) {
        this.stats = new CanvasStats(this.map.getCanvasContainer());
      }
    }

    this.tbPositivePlot = generateTBCircle({
      tb: window.tb,
      color: this.positiveCol,
    });
    this.tbZeroPlot = generateTBCircle({
      tb: window.tb,
      color: this.middleCol,
    });
    this.tbNegativePlot = generateTBCircle({
      tb: window.tb,
      color: this.negativeCol,
    });
    this.tbPositivePin = generateTBPin({
      tb: window.tb,
      pinGradientStartColor: this.middleCol,
      pinGradientEndColor: this.positiveCol,
      baseGradientStartColor: this.middleCol,
      baseGradientEndColor: this.positiveCol,
      positive: true,
    });
    this.tbZeroPin = generateTBPin({
      tb: window.tb,
      pinGradientStartColor: this.middleCol,
      pinGradientEndColor: this.middleCol,
      baseGradientStartColor: this.middleCol,
      baseGradientEndColor: this.middleCol,
      positive: null,
    });
    this.tbNegativePin = generateTBPin({
      tb: window.tb,
      pinGradientStartColor: this.middleCol,
      pinGradientEndColor: this.negativeCol,
      baseGradientStartColor: this.middleCol,
      baseGradientEndColor: this.negativeCol,
      positive: false,
    });
    this.tbPositiveAssetPin = generateTBAssetPin({
      tb: window.tb,
      pinGradientStartColor: this.middleCol,
      pinGradientEndColor: this.positiveCol,
      positive: true,
    });
    this.tbZeroAssetPin = generateTBAssetPin({
      tb: window.tb,
      pinGradientStartColor: this.middleCol,
      pinGradientEndColor: this.middleCol,
      positive: null,
    });
    this.tbNegativeAssetPin = generateTBAssetPin({
      tb: window.tb,
      pinGradientStartColor: this.middleCol,
      pinGradientEndColor: this.negativeCol,
      positive: false,
    });

    // Extract materials
    this.positivePinTowerMaterial = this.tbPositivePin.getObjectByName('pin-tower').material;
    this.negativePinTowerMaterial = this.tbNegativePin.getObjectByName('pin-tower').material;
    this.positiveAssetPinTowerMaterial = this.tbPositiveAssetPin.getObjectByName(
      'pin-tower',
    ).material;
    this.negativeAssetPinTowerMaterial = this.tbNegativeAssetPin.getObjectByName(
      'pin-tower',
    ).material;

    this.threeboxLayer = this.createLayer();
  }

  /**
   * Initialize threebox
   */
  createThreebox(): void {
    window.tb = new Threebox(this.map, this.map.getCanvas().getContext('webgl'), {
      defaultLights: true,
      enableSelectingFeatures: false,
      enableSelectingObjects: true,
      enableTooltips: true,
    });
  }

  /**
   * Create custom 3D layer
   * @returns mapbox custom 3D layer created
   */
  createLayer(): mapboxgl.CustomLayerInterface {
    return {
      id: '3d-model',
      type: 'custom',
      renderingMode: '3d',
      onAdd: () => {
        this.showGroup(this.scatterPlotLayer, true);
        this.viewportChanged(this.currentViewPort);

        this.tick();
      },
      render: () => {
        window.tb.update?.();
      },
    };
  }

  /**
   * Get threebox layer, not mapbox layer
   * @returns threebox layer
   */
  getLayer(): mapboxgl.CustomLayerInterface {
    return this.threeboxLayer;
  }

  /**
   * Set username
   * @param username username
   */
  setUsername(username: string): void {
    this.username = username;
  }

  /**
   * Set community list data
   * @param communityList data
   */
  setCommunityList(communityList: TCommunityListProps): void {
    if (compareOrdinaryJSONObjects(this.communityList, communityList)) return;

    this.communityList = communityList;
    this.updateCommunityListLayer();
  }

  /**
   * Set community assets data
   * @param communityAssets data
   * @param configurationCenter center of community
   */
  setCommunityAssets(
    assets: TConfigurationState['assets'],
    assetValues: TConfigurationState['assetsValues'],
    communityAsset: TAsset | undefined,
    configurationCenter: TLngLat | undefined,
  ): void {
    this.assets = assets;
    this.assetValues = assetValues;
    this.communityAsset = communityAsset;
    this.configurationCenter = configurationCenter;
    this.updateCommunityAssetsLayer();
  }

  /**
   * Set simulation result
   * @param simulationResults simulation result of selected community
   */
  setSimulationResults(
    simulationResults: Pick<TSimulationResultData, 'bills' | 'cumulativeNetEnergyFlow'> | undefined,
  ): void {
    this.simulationResults = simulationResults;
    this.updateCommunityAssetsLayer();
  }

  /**
   * Update 3D layers for community list representation
   */
  updateCommunityListLayer(): void {
    if (isEmpty(window.tb)) return;

    this.emptyGroup(this.scatterPlotLayer);
    this.emptyGroup(this.pinLayer);

    this.communityList.forEach((project) => {
      const location = getConfigurationLocation(project);
      const globalCumulativeNetEnergyFlow =
        project['configurations'][0]?.['globalCumulativeNetEnergyFlow'];

      if (location) {
        const origin = [location.lng, location.lat, randomOffsetVal(0)];

        let positive: boolean | null = null;
        if (!isNil(globalCumulativeNetEnergyFlow) && globalCumulativeNetEnergyFlow > 0) {
          positive = false;
        } else if (!isNil(globalCumulativeNetEnergyFlow) && globalCumulativeNetEnergyFlow < 0) {
          positive = true;
        }

        const additionalProps = {
          _configuration: {
            owner: project.user,
            configurationUuid: project.configurations[0]?.uuid,
          },
        };

        this.addLayerEntities({
          group: this.scatterPlotLayer,
          origin,
          positive,
          scale: this.tbPlotInitialScale,
          additionalProps,
          layerType: 1,
        });
        this.addLayerEntities({
          group: this.pinLayer,
          origin,
          positive,
          scale: this.tbPinMaxScale,
          additionalProps,
          layerType: 2,
        });
      }
    });

    this.updateLayerScale({
      group: this.scatterPlotLayer,
      initialScale: this.tbPlotInitialScale,
    });
    this.updateLayerScale({
      group: this.pinLayer,
      initialScale: this.tbPinInitialScale,
      maxScale: this.tbPinMaxScale,
      minScale: this.tbPinMinScale,
    });
  }

  /**
   * Update 3D layers for community assets representation
   */
  updateCommunityAssetsLayer(): void {
    if (isEmpty(window.tb)) return;

    this.emptyGroup(this.communityRingLayer);
    this.emptyGroup(this.communityAssetsLayer);
    this.emptyGroup(this.communitySmallAssetsLayer);

    Object.values(this.assets).forEach((asset) => {
      if (!asset) return;
      const { uuid, parentUuid, type: assetType } = asset;
      const { geoTagLocation, name } = this.assetValues[uuid];

      if (parentUuid !== this.communityAsset?.uuid || !geoTagLocation) return;

      const origin = [...geoTagLocation, randomOffsetVal(0)];

      let positive: boolean | null = null;
      if (this.simulationResults && this.simulationResults?.bills && name) {
        const totalEnergy = this.simulationResults.bills[name]?.total_energy;

        if (totalEnergy > 0) {
          positive = false;
        } else if (totalEnergy < 0) {
          positive = true;
        }
      }

      const additionalProps = {
        _asset: {
          uuid,
          center: geoTagLocation,
        },
      };

      if (assetType === EAssetType.AREA) {
        this.addLayerEntities({
          group: this.communityAssetsLayer,
          origin,
          positive,
          scale: this.tbAssetPinInitialScale,
          additionalProps,
          layerType: 3,
        });
      } else if (
        assetType === EAssetType.PV ||
        assetType === EAssetType.LOAD ||
        assetType === EAssetType.STORAGE
      ) {
        this.addLayerEntities({
          group: this.communitySmallAssetsLayer,
          origin,
          positive,
          scale: this.tbSmallAssetPinInitialScale,
          additionalProps,
          layerType: 3,
        });
      }
    });

    this.initCommunityRingLayerProps();
    this.updateLayerScale({
      group: this.communityAssetsLayer,
      initialScale: this.tbAssetPinInitialScale,
      maxScale: this.tbAssetPinMaxScale,
      minScale: this.tbAssetPinMinScale,
    });
    this.updateLayerScale({
      group: this.communitySmallAssetsLayer,
      initialScale: this.tbSmallAssetPinInitialScale,
      maxScale: this.tbSmallAssetPinMaxScale,
      minScale: this.tbSmallAssetPinMinScale,
    });
  }

  /**
   * Add 3D entity for viz
   * @param group group
   * @param origin coordinates of entity
   * @param positive flag for positive/negative
   * @param scale scale of tb entity
   * @param additionalProps extra properties for entity
   * @param layerType type of layer (1: scatterplot, 2: pin, 3: community)
   */
  addLayerEntities({
    group,
    origin = [0, 0, 0],
    positive = null,
    scale = 1,
    additionalProps = null,
    layerType = 1,
  }: TLayerEntityInputProps): void {
    let entity: Threebox.Object3D;

    // Determine type of layer
    switch (layerType) {
      case 1:
        if (positive === null) {
          entity = this.tbZeroPlot.duplicate();
        } else {
          entity = positive ? this.tbPositivePlot.duplicate() : this.tbNegativePlot.duplicate();
        }
        break;
      case 2:
        if (positive === null) {
          entity = this.tbZeroPin.duplicate();
        } else {
          entity = positive ? this.tbPositivePin.duplicate() : this.tbNegativePin.duplicate();
        }
        break;
      case 3:
        if (positive === null) {
          entity = this.tbZeroAssetPin.duplicate();
        } else {
          entity = positive
            ? this.tbPositiveAssetPin.duplicate()
            : this.tbNegativeAssetPin.duplicate();
        }
        break;
    }

    entity.children[0].scale.set(scale, scale, scale);
    entity.setCoords(origin);

    if (additionalProps) entity = Object.assign(entity, additionalProps);

    // Add event listener for entity
    entity.addEventListener(
      'SelectedChange',
      () => {
        // TODO select
      },
      false,
    );
    entity.addEventListener(
      'ObjectMouseOver',
      () => {
        // TODO mouse over
      },
      false,
    );
    entity.addEventListener(
      'ObjectMouseOut',
      () => {
        // TODO mouse out
      },
      false,
    );

    group.add(entity);
  }

  /**
   * Init properties of ring wrapping community
   */
  initCommunityRingLayerProps(): void {
    if (
      (!this.communityAssetsLayer.children.length &&
        !this.communitySmallAssetsLayer.children.length) ||
      !this.configurationCenter
    ) {
      this.tbAssetPinMaxScale = 1000;
      this.tbSmallAssetPinMaxScale = 750;
      return;
    }

    const origin = [this.configurationCenter.lng, this.configurationCenter.lat, 0];
    const scenePos = window.tb.projectToWorld(origin); // 3D scene position from coordinate
    const maxDist =
      Math.sqrt(
        Math.max(
          ...[
            ...this.communityAssetsLayer.children,
            ...this.communitySmallAssetsLayer.children,
          ].map((child) => {
            const rx = Math.abs(scenePos.x - child.position.x);
            const ry = Math.abs(scenePos.y - child.position.y);
            return rx * rx + ry * ry;
          }),
        ),
      ) + 5; // Max distance from center to the farest asset. 5: delta factor
    const radius = maxDist * 1.1; // Padding of community ring

    const gradientStartColor = this.middleCol;
    let gradientEndColor = this.middleCol;
    if (this.simulationResults?.cumulativeNetEnergyFlow) {
      const netEnergyKey = Object.keys(this.simulationResults.cumulativeNetEnergyFlow)[0];
      gradientEndColor =
        this.simulationResults.cumulativeNetEnergyFlow[netEnergyKey] < 0
          ? this.positiveCol
          : this.negativeCol;
    }

    this.communityRingLayer = Object.assign(this.communityRingLayer, {
      origin,
      radius,
      gradientStartColor,
      gradientEndColor,
    });

    this.updateCommunityRingLayer(); // Add community ring
  }

  /**
   * Show/hide group
   * @param group target group
   * @param show flag for show or not
   */
  showGroup(group: Group, show: boolean): void {
    if (show) {
      if (window.tb.add) window.tb.add(group);
    } else {
      disposeObject(group);
      if (window.tb.remove) window.tb.remove(group);
    }
  }

  /**
   * Remove all children from given group
   * @param group target group
   */
  emptyGroup(group: Group): void {
    removeChildren(group);
  }

  /**
   * setter function for maxZoomGlobalViz
   * @param maxZoomGlobalViz number
   */
  setMaxZoomGlobalViz(maxZoomGlobalViz: number): void {
    this.maxZoomGlobalViz = maxZoomGlobalViz;
  }

  /**
   * setter function for isBuildMode
   * @param isBuildMode boolean
   */
  setIsBuildMode(isBuildMode: boolean): void {
    this.isBuildMode = isBuildMode;
  }

  /**
   * Listener of map viewport changed
   * @param viewport viewport props
   */
  viewportChanged(viewport: TViewport): void {
    if (!viewport || isEmpty(window.tb)) return;

    this.currentViewPort = viewport;
    const { zoom } = this.currentViewPort;

    if (!this.isBuildMode && zoom > 0 && zoom <= this.maxZoomGlobalViz) {
      this.showGroup(this.scatterPlotLayer, true);
      this.showGroup(this.pinLayer, false);
      this.showGroup(this.communityLayer, false);

      // Plot layer scale adjustment
      this.updateLayerScale({
        group: this.scatterPlotLayer,
        initialScale: this.tbPlotInitialScale,
      });
    } else if (!this.isBuildMode) {
      this.showGroup(this.scatterPlotLayer, false);
      this.showGroup(this.pinLayer, true);
      this.showGroup(this.communityLayer, false);

      // Pin layer scale adjustment
      this.updateLayerScale({
        group: this.pinLayer,
        initialScale: this.tbPinInitialScale,
        maxScale: this.tbPinMaxScale,
        minScale: this.tbPinMinScale,
      });
    } else {
      this.showGroup(this.scatterPlotLayer, false);
      this.showGroup(this.pinLayer, false);
      this.showGroup(this.communityLayer, true);

      // Community layer scale adjustment
      this.updateCommunityRingLayer();
      this.updateLayerScale({
        group: this.communityAssetsLayer,
        initialScale: this.tbAssetPinInitialScale,
        maxScale: this.tbAssetPinMaxScale,
        minScale: this.tbAssetPinMinScale,
      });
      this.updateLayerScale({
        group: this.communitySmallAssetsLayer,
        initialScale: this.tbSmallAssetPinInitialScale,
        maxScale: this.tbSmallAssetPinMaxScale,
        minScale: this.tbSmallAssetPinMinScale,
      });
      this.updateLayerScale({
        group: this.communityTempAssetsLayer,
        initialScale: this.tbAssetPinInitialScale,
        maxScale: this.tbAssetPinMaxScale,
        minScale: this.tbAssetPinMinScale,
      });
    }
  }

  /**
   * Update layer elements with new scale
   * @param group groups for each layers
   * @param initialScale initial scale for each layer
   * @param maxScale max scale for each layer
   * @param minScale min scale for each layer
   */
  updateLayerScale({
    group,
    initialScale,
    maxScale = 10000,
    minScale = 0,
    x = false,
    y = false,
    z = false,
  }: TUpdateLayerScaleInputProps): void {
    if (isEmpty(window.tb)) return;

    let newScale = (initialScale / this.map['transform']['scale']) * this.initialMapScale;

    if (newScale > maxScale) newScale = maxScale;
    else if (newScale < minScale) newScale = minScale;

    group.children.forEach((child) => {
      if (x) child.children[0].scale.x = newScale;
      if (y) child.children[0].scale.y = newScale;
      if (z) child.children[0].scale.z = newScale;
      if (!x && !y && !z) child.children[0].scale.set(newScale, newScale, newScale);
    });
  }

  /**
   * Update only community ring layer
   */
  updateCommunityRingLayer(): void {
    // If no community assets or tb not initialized, stop updating community ring.
    if (
      isEmpty(window.tb) ||
      (!this.communityAssetsLayer.children.length &&
        !this.communitySmallAssetsLayer.children.length)
    )
      return;

    this.emptyGroup(this.communityRingLayer);

    const { origin, gradientStartColor, gradientEndColor, radius } = this.communityRingLayer;

    // If essential properties are missing, stop
    if (!origin || !radius) return;

    const height = Math.min(radius, Math.max(2.5, 100000 / this.map['transform']['scale'])); // Set ring height by map scaling. But can't exceed ring radius
    const thickness = height / 10; // Set ring thickness always 1/10 of height
    const ringOutRadius = radius + thickness; // Outer ring radius
    this.tbAssetPinMaxScale = ringOutRadius / 1000; // Asset pin scale depends on ring radius

    const communityRing = generateTBRingBase({
      tb: window.tb,
      gradientStartColor,
      gradientEndColor,
      radius: ringOutRadius,
      height,
      thickness,
    });

    communityRing._communityAsset = {
      parentUuid: this.communityAsset?.uuid,
    };

    communityRing.setCoords(origin);

    this.communityRingLayer.add(communityRing);
  }

  /**
   * Add house pin temporarily
   * @param selectedLocation Clicked geolocation info
   */
  addTempHousePin(selectedLocation: Partial<TMapboxLocation> | null): void {
    this.emptyGroup(this.communityTempAssetsLayer);

    if (selectedLocation && selectedLocation?.lng && selectedLocation?.lat) {
      const origin = [selectedLocation.lng, selectedLocation.lat, randomOffsetVal(0)];

      this.addLayerEntities({
        group: this.communityTempAssetsLayer,
        origin,
        scale: this.tbAssetPinInitialScale,
        layerType: 3,
      });
      this.updateLayerScale({
        group: this.communityTempAssetsLayer,
        initialScale: this.tbAssetPinInitialScale,
        maxScale: this.tbAssetPinMaxScale,
        minScale: this.tbAssetPinMinScale,
      });
    }
  }

  /**
   * Show/hide 3D layers
   * @param buildMode flag for build mode
   */
  setBuildMode(buildMode: boolean): void {
    this.isBuildMode = buildMode;

    if (this.isBuildMode) {
      this.showGroup(this.scatterPlotLayer, false);
      this.showGroup(this.pinLayer, false);
      this.showGroup(this.communityLayer, true);
      this.viewportChanged(this.currentViewPort);
    } else {
      this.emptyGroup(this.communityRingLayer);
    }

    // this.map.triggerRepaint();
  }

  /**
   * Tick
   */
  tick = (): void => {
    // If diposed flag is true, dispose tb and realtime rendering
    if (this.disposed) {
      if (this.requestID) cancelAnimationFrame(this.requestID);

      if (Object.keys(window.tb).length) {
        window.tb.dispose();
        window.tb = {};
      }

      return;
    }

    // Time consumed for rendering current frame
    const deltaTime = this.clock.getDelta();

    // Pin material update
    this.pixelSortingOffsetY += deltaTime / 5;
    if (this.pixelSortingOffsetY > 1) this.pixelSortingOffsetY = 0;
    const downPixelSortingVector2 = new Vector2(0, -this.pixelSortingOffsetY);
    const upPixelSortingVector2 = new Vector2(0, this.pixelSortingOffsetY);

    this.positivePinTowerMaterial.uniforms.uvOffset.value = downPixelSortingVector2;
    this.negativePinTowerMaterial.uniforms.uvOffset.value = upPixelSortingVector2;
    this.positiveAssetPinTowerMaterial.uniforms.uvOffset.value = downPixelSortingVector2;
    this.negativeAssetPinTowerMaterial.uniforms.uvOffset.value = upPixelSortingVector2;

    // Repaint map
    this.map.triggerRepaint();
    // Stats update?
    this.stats?.tick(window.tb.renderer);

    this.requestID = requestAnimationFrame(this.tick);
  };

  /**
   * Dispose
   */
  dispose(): void {
    this.disposed = true;
  }

  getCommunityRingObject(): Object3D {
    return this.communityRingLayer.children[0];
  }
}

export { ThreeboxController };
