import React, { useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import {
  EOrientationParam,
  TOrientationParam,
} from 'src/components/CustomPV/components/Orientation/Orientation.types';
import { FormAssetsParams, TAssetsSaveProps } from 'src/components/FormAssetsParams';
import { TLibrary } from 'src/components/LibraryChooser';
import {
  DeviceTypes,
  LibraryOutput,
  Pv,
  UpdateLibraryMutation,
  useReadLibraryLazyQuery,
} from 'src/graphql';
import { useLibraryHelpers } from 'src/hooks/useLibraryHelpers';
import { selectAddNewLibrary } from 'src/redux/application/application.selectors';
import { selectAssetValuesForUuid } from 'src/redux/configuration/configuration.selectors';
import { EFormVariant } from 'src/typings/base-types';

import s from './DashboardAssetSettings.module.scss';

let azimuthValuesForCustomPV: TOrientationParam = {
  [EOrientationParam.AZIMUTH]: 0,
  [EOrientationParam.TILT]: 0,
};

// used in case of creating new custom PV
const azimuthValuesForCustomPVInitialState: TOrientationParam = azimuthValuesForCustomPV;

const setAzimuthValuesForCustomPV = (value: TOrientationParam) => {
  azimuthValuesForCustomPV = {
    ...azimuthValuesForCustomPV,
    ...value,
  };
};

export const DashboardAssetSettings: React.FC<{
  mode: 'basic' | 'advanced';
  mainParentData: LibraryOutput;
  newAssetData: TLibrary;
  onUpdate(data: UpdateLibraryMutation['updateLibrary']): void;
  selectedChildUuid?: string;
}> = ({ mainParentData, newAssetData, selectedChildUuid, mode, onUpdate }) => {
  const formHasErrorsRef = useRef(false);

  const assetValues = useSelector(selectAssetValuesForUuid(newAssetData.uuid));
  const addNewLibrary = useSelector(selectAddNewLibrary);

  const [isSaving, setIsSaving] = useState(false);
  const [newAssetConfig, setNewAssetConfig] = useState<TAssetsSaveProps>({
    assetUuid: '',
    values: {},
    assetType: undefined,
  });

  const { updateLibrary, createLibrary } = useLibraryHelpers();
  const [readLibrary] = useReadLibraryLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.readLibrary) {
        update(newAssetConfig, result.readLibrary);
      }
    },
  });

  const onUpdateCompleted = (data: UpdateLibraryMutation['updateLibrary']) => {
    onUpdate(data);
  };
  // const onSettingsSubmit = (data: TAssetsSaveProps) => update(data);
  const onSettingsSubmit = async (data: TAssetsSaveProps) => {
    if (addNewLibrary) {
      createNewLib(data);
    } else {
      update(data);
    }
  };

  const createNewLib = async (data) => {
    setNewAssetConfig(data);
    const newlibUuid = await createLibrary({
      name: data.values.name || '',
    });
    if (newlibUuid?.uuid) {
      readLibrary({
        variables: {
          uuid: newlibUuid.uuid,
        },
      });
    }
  };
  const update = async (data: TAssetsSaveProps, newLib?: LibraryOutput) => {
    if (isSaving) return;
    setIsSaving(true);
    const lib = await updateLibrary({
      library: newLib || mainParentData,
      tarrgetUuid: selectedChildUuid,
      newAssetData,
      data,
      firstAsset: newLib !== undefined,
    });

    if (lib) onUpdateCompleted(lib);
    setIsSaving(false);
  };

  if (newAssetData.isCustomPV) {
    let scenarioData: DeviceTypes;
    if (typeof mainParentData.scenarioData?.representation === 'string') {
      scenarioData = JSON.parse(mainParentData.scenarioData.representation) as Pv;
      setAzimuthValuesForCustomPV({
        azimuth: scenarioData.azimuth || 0,
        tilt: scenarioData.tilt || 0,
      });
    }
  }

  return (
    <div className={s.container}>
      <FormAssetsParams
        hasErrorsRef={formHasErrorsRef}
        id="dashboard-asset-settings"
        assetUuid={newAssetData.uuid}
        assetType={newAssetData.type}
        formVariant={EFormVariant[mode === 'basic' ? 'BasicLibrarySettings' : 'Advanced']}
        onSubmit={onSettingsSubmit}
        currentValues={assetValues}
        disableLocationField={true}
        isCustomPV={newAssetData.isCustomPV}
        azimuthValuesForCustomPV={azimuthValuesForCustomPVInitialState}
        setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
        isEdit={true}
        theme="dark"
      />
      <button type="submit" form="dashboard-asset-settings" className={s.saveSettings}>
        {isSaving ? <BaseIcon icon="spinner" size={20} /> : 'Save Asset'}
      </button>
    </div>
  );
};
