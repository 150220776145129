import { useEffect } from 'react';

import {
  addSingleNotification,
  deleteSingleNotification,
} from 'src/redux/notifications/notificaitons.slice';
import { TSingleNotification } from 'src/redux/notifications/notifications.types';
import { useAppDispatch } from 'src/redux/store';

export type TUseSingleNotificationArgs = {
  singleNotification: TSingleNotification;
  checkStatement?: boolean;
};

export const useSingleNotification = ({
  singleNotification,
  checkStatement = true,
}: TUseSingleNotificationArgs): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (checkStatement) {
      dispatch(addSingleNotification(singleNotification));
    }

    // delete on unmount
    return () => {
      dispatch(deleteSingleNotification());
    };
  }, [checkStatement, singleNotification, dispatch]);
};
