import { ReactNode } from 'react';

import { DataAggregationResolution } from 'src/graphql';

export type TEnergyBillInfoItem = {
  labelColor: string;
  label: string;
  percentage: number;
  cost: string;
};

export type TListEnergyBillInfo = Array<TEnergyBillInfoItem>;

export const EnergyBillSubtitles: Record<DataAggregationResolution, string> = {
  [DataAggregationResolution.Daily]: 'TODAY',
  [DataAggregationResolution.Weekly]: 'THIS_WEEK',
  [DataAggregationResolution.Monthly]: 'THIS_MONTH',
};

export type TEnergyBillComponentsProps = {
  list: TListEnergyBillInfo;
  total: string;
  info: ReactNode;
  title: string;
  subTitle?: string;
};
