import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { ChartSimulationProgress } from 'src/components/_charts/ChartSimulationProgress';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { selectReadOnly } from 'src/redux/configuration/configuration.selectors';

import s from './SimulationProgressModalSmall.module.scss';
import { TSimulationProgressModalSmallProps } from './SimulationProgressModalSmall.types';


export const SimulationProgressModalSmall: React.FC<TSimulationProgressModalSmallProps> = ({
  simulationStatusTitle,
  simulationProgress,
  runButtonState,
  runButtonOptions,
  stopButtonOptions,
  toggleExpanded,
  detailsData,
  graphStartDate,
  graphEndDate,
  showProgressPercentage,
}) => {
  const readOnly = useSelector(selectReadOnly);

  const runButtonTheme = useMemo(() => {
    switch (runButtonState) {
      case 'run':
      case 'resume':
      case 'rerun':
        return 'flat-green';
      case 'pause':
        return 'flat-blue';
      case 'processing':
        return 'flat-gray-light';
    }
  }, [runButtonState]);

  return (
    <div className={s.container}>
      <div className={s.percentages}>
        <span>{simulationStatusTitle}</span>
        {showProgressPercentage && <span>{simulationProgress?.percentageCompleted}%</span>}
      </div>
      <div className={s.graph}>
        <ChartSimulationProgress
          graphHeight={72}
          graphStartDate={graphStartDate}
          graphEndDate={graphEndDate}
        />
      </div>
      <div className={s.controls}>
        {!readOnly && (
          <>
            <BaseButtonSquare
              size="2"
              theme={runButtonTheme}
              icon={runButtonOptions.icon}
              svgSize="3"
              disabled={runButtonOptions.disabled}
              onClick={() => runButtonOptions.onClick && runButtonOptions.onClick()}
            />
            <BaseButtonSquare
              size="2"
              theme={stopButtonOptions.disabled ? 'flat-gray-light' : 'flat-red'}
              icon={stopButtonOptions.icon}
              svgSize="3"
              disabled={stopButtonOptions.disabled}
              onClick={() => stopButtonOptions.onClick && stopButtonOptions.onClick()}
            />
          </>
        )}
        <BaseButtonSquare
          size="2"
          theme="flat-gray-light"
          icon="expand"
          svgSize="3"
          onClick={() => toggleExpanded()}
        />
        <BaseButtonSquare size="2" theme="flat-gray-light" icon="close" svgSize="3" />
      </div>
      <div className={s.details}>
        {detailsData.map((item, index) => (
          <div className={s.detailsItem} key={index}>
            <div className={s.detailsItemIcon}>
              <BaseIcon icon={item.icon} size={15} />
            </div>
            <div className={s.detailsItemContent}>
              <span>{item.title}</span>
              <div>
                <span>{item.value}</span>
                <span>{item.unit}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
