import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { TAssetsInside } from 'src/components/ModalAssetsManager';
import { ICON_BY_ASSET_TYPE } from 'src/constants/application';
import {
  selectAssets,
  selectAssetsTreeRelations,
} from 'src/redux/configuration/configuration.selectors';
import { TAssetType } from 'src/typings/base-types';
import { TAsset } from 'src/typings/configuration.types';

// import all required modules and types


export type TUseAssetsInsideArgs = {
  hostAssetUuid?: TAsset['uuid'];
};

type TUseAssetsInsideReturn = {
  assetsInside: TAssetsInside;
};

export const useAssetsInside = ({
  hostAssetUuid,
}: TUseAssetsInsideArgs): TUseAssetsInsideReturn => {
  const assets = useSelector(selectAssets);
  const assetsTreeRelations = useSelector(selectAssetsTreeRelations);

  const assetsInside: TAssetsInside = useMemo(() => {
    const output: TAssetsInside = [];

    if (!hostAssetUuid) return output;

    const counter = {} as {
      [key in TAssetType]: number;
    };
    const order: TAssetType[] = [
      'Area',
      'PV',
      'Load',
      'Storage',
      'FiniteDieselGenerator',
      'HeatPump',
    ];
    const parentAssetChildrenUuids = assetsTreeRelations[hostAssetUuid];

    const countTheChildren = (childrenUuids: TAsset['uuid'][]) => {
      childrenUuids.forEach((uuid) => {
        const asset = assets[uuid];

        if (typeof counter[asset.type] === 'undefined') {
          counter[asset.type] = 1;
        } else {
          counter[asset.type] += 1;
        }

        if (assetsTreeRelations[asset.uuid].length) {
          countTheChildren(assetsTreeRelations[asset.uuid]);
        }
      });
    };

    countTheChildren(parentAssetChildrenUuids);

    order.forEach((assetType) => {
      if (assetType in counter) {
        output.push({
          type: assetType,
          icon: ICON_BY_ASSET_TYPE[assetType],
          amount: counter[assetType],
        });
      }
    });

    return output;
  }, [hostAssetUuid, assetsTreeRelations, assets]);

  return {
    assetsInside,
  };
};
