import React from 'react';

import classNames from 'classnames';

import s from './EnergyBadgeDot.module.scss';
import { TEnergyBadgeDotProps } from './EnergyBadgeDot.types';

export const EnergyBageDot: React.FC<TEnergyBadgeDotProps> = ({ backgroundClassname }) => (
  <div className={classNames(s.energyBadgeDot, backgroundClassname)}></div>
);
