import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseClose } from 'src/components/BaseClose';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { ContextMenu } from 'src/components/ContextMenu';
import { TCanaryAssetState } from 'src/components/MapSidebar/components/MapSidebarCanary';
import {
  TCanaryAssetsTree,
  TCanaryRegistryAssetsListProps,
} from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import { CanaryRegistryAssetTile } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryAssetTile';
import { TCanaryOrderedAssetTree } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryList.types';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useModal } from 'src/hooks/useModal';
import { selectIsLoggedIn, selectUsername } from 'src/redux/auth/auth.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectIsOperationalCommunity,
  selectMeasurementRequests,
} from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { get2dAssetIcon } from 'src/utils/assetIcons';

import s from './CanaryRegistryList.module.scss';

export const CanaryRegistryAssetsList: React.FC<TCanaryRegistryAssetsListProps> = ({
  assetsData,
  usersData,
  usersToAssetRelations,
  selectedItems,
  searchValue,
  onItemClick,
  getParentUuid,
  onAssetRemove,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { id: modalId } = useModal();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const userName = useSelector(selectUsername);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const measurementRequests = useSelector(selectMeasurementRequests);

  const [openedAssetConfigUuid, setOpenedAssetConfigUuid] = useState<string | null>(null);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const { isOwnerAdmin, isOwnerCM } = useIsAdminInspecting();

  const assetsTreeData = useMemo<TCanaryAssetsTree>(
    () =>
      assetsData.reduce<TCanaryAssetsTree>((acc, asset) => {
        const isSearched =
          searchValue.length > 0
            ? asset.name.toLowerCase().includes(searchValue.toLowerCase())
            : true;

        if (!isSearched) return acc;

        if (asset.name === 'Community') return acc;

        const parentUuid = getParentUuid(asset.uuid);
        const assetIcon = asset.name === 'Grid Market' ? 'market-list' : get2dAssetIcon(asset.type);

        const data = {
          uuid: asset.uuid,
          name: asset.name,
          icon: assetIcon,
          type: asset.type,
          owners: [],
          status: (usersToAssetRelations[userName] as unknown) as TCanaryAssetState,
          appliedUserName: undefined,
          appliedUserAvatar: undefined,
          parentUuid: parentUuid || null,
        };

        if (asset.type === 'Area' || asset.type === 'InfiniteBus') {
          acc[asset.uuid] = { data, children: [] };
        } else {
          if (parentUuid && acc[parentUuid]) {
            acc[parentUuid].children.push(data);
          } else {
            acc[asset.uuid] = { data, children: [] };
          }
        }

        return acc;
      }, {}),
    [assetsData, getParentUuid, searchValue, userName, usersToAssetRelations],
  );

  const orderedAssetTree = useMemo<TCanaryOrderedAssetTree>(() => {
    const assetsNamesAndKeys = Object.keys(assetsTreeData).map((item) => ({
      name: assetsTreeData[item].data.name,
      item: assetsTreeData[item],
    }));
    const orderedAssetNames = assetsNamesAndKeys.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );

    const staticItemNames = ['Community', 'Grid Market'];

    const staticItems = orderedAssetNames.filter((item) => staticItemNames.includes(item.name));

    const orderedList = [
      ...staticItems,
      ...orderedAssetNames
        .filter((item) => !staticItemNames.includes(item.name))
        .map((item, i) => ({ ...item, homeNumber: i })),
    ];

    return orderedList;
  }, [assetsTreeData]);

  const selectboxUsersList = useMemo(
    () =>
      usersData
        .filter((user) => !user.isAggregator && !user.isGridOperator)
        .map(({ name, uuid }) => ({ name, uuid })),
    [usersData],
  );

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  if (isLoggedIn && (isOwnerAdmin || isOwnerCM)) {
    dropDownItems.push({
      title: t('commands.REMOVE'),
      icon: 'custom-trash',
      onClick: () => {
        if (configUuid && openedAssetConfigUuid)
          onAssetRemove({ assetUuid: openedAssetConfigUuid });
        dispatch(closeModal(modalId));
      },
    });
  }

  const closeActiveModal = () => {
    setOpenedAssetConfigUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };

  const onMenuClick = (menuTriggerRef, data) => {
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedAssetConfigUuid(data.itemUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  const onExpandClick = (uuid: string) => {
    setExpandedItem(uuid);
  };

  return (
    <div className={s.assetsList}>
      {orderedAssetTree.map((assetTree, i) => {
        const asset = assetTree.item;

        const isCommunity = i === 0;
        const isSelected = selectedItems.includes(asset.data.uuid);
        const isExpanded = expandedItem === asset.data.uuid;

        const parentMeasurementStatus =
          measurementRequests.find((item) => item.areaUuid === asset.data.uuid)?.status || null;
        const childrenCount =
          asset.data.name !== 'Grid Market' ? asset.children.length : orderedAssetTree.length;

        return (
          <React.Fragment key={i}>
            <CanaryRegistryAssetTile
              asset={asset}
              data={asset.data}
              childrenCount={childrenCount}
              usersList={selectboxUsersList}
              selected={isSelected}
              onClick={isCommunity ? undefined : onItemClick}
              onMenuClick={
                dropDownItems.length > 0 && !isCommunity && !isOperationalCommunity
                  ? onMenuClick
                  : undefined
              }
              onExpandClick={isOperationalCommunity ? onExpandClick : undefined}
              measurementStatus={parentMeasurementStatus}
            />
            {isExpanded &&
              isOperationalCommunity &&
              asset.children.map((childAsset) => {
                const childMeasurementStatus =
                  measurementRequests.find((item) => item.areaUuid === childAsset.uuid)?.status ||
                  null;

                const selected = selectedItems.includes(childAsset.uuid);
                return (
                  <CanaryRegistryAssetTile
                    asset={asset}
                    key={childAsset.uuid}
                    data={childAsset}
                    isChildren
                    usersList={selectboxUsersList}
                    selected={selected}
                    onClick={onItemClick}
                    measurementStatus={childMeasurementStatus}
                  />
                );
              })}
          </React.Fragment>
        );
      })}
      <ContextMenu
        relativeElement={menuModalTriggerRef}
        modalId={modalId}
        position={{
          side: 'right',
          anchorPosition: 50,
        }}
        items={dropDownItems}
      />
      {errorMessage && (
        <div
          className={s.customToast}
          style={{
            top: menuModalTriggerRef.current?.getBoundingClientRect().top
              ? menuModalTriggerRef.current?.getBoundingClientRect().top - 45
              : 0,
          }}>
          {errorMessage} <BaseClose className={s.closeBtn} onClick={() => setErrorMessage(null)} />
        </div>
      )}
    </div>
  );
};
