import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectCommunityUser } from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';

import s from './Copyright.module.scss';
import { TCopyrightProps } from './Copyright.types';

export const Copyright: React.FC<TCopyrightProps> = ({ mode = 'default' }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //const userRole = useSelector(selectUserRole);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const communityUser = useSelector(selectCommunityUser);

  // Fixed::PH-1160-Admin dashboard is visible to not logged in user
  useEffect(() => {
    if (!isLoggedIn) {
    }
  }, [dispatch, isLoggedIn]);

  return (
    <div
      className={classNames(s.container, {
        [s.mobileRelative]: mode === 'mobileRelative',
      })}>
      <footer>
        <span>{t('ENERGY_COMMUNITY_MANAGED_BY', { name: communityUser })}</span>
      </footer>
    </div>
  );
};
