export function arrayToObject<T>(
  array: T[],
  keyProperty: keyof T,
  field?: keyof T,
): Record<string, T[keyof T] | T> {
  return array.reduce((acc, item) => {
    const key = String(item[keyProperty]);
    acc[key] = field ? (item[field] as T[keyof T]) : item;
    return acc;
  }, {} as Record<string, T[keyof T] | T>);
}

export function sumOfField<T>(data: T[], field: keyof T): number {
  return data.reduce((acc, obj) => {
    const value = obj[field];
    if (typeof value === 'number') {
      return acc + value;
    }
    return acc;
  }, 0);
}

export const areObjectsEqualByFields = <T>(
  obj1: T | null | undefined,
  obj2: T | null | undefined,
  fields?: Array<keyof T>,
): boolean => {
  if (!obj1 || !obj2) {
    return false;
  }

  if (fields && fields.length > 0) {
    for (const field of fields) {
      if (obj1[field] !== obj2[field]) {
        return false;
      }
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const keys1 = Object.keys((obj1 as unknown) as object);
    // eslint-disable-next-line @typescript-eslint/ban-types
    const keys2 = Object.keys((obj2 as unknown) as object);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
};

export const areArraysOfObjectsEqualByFields = <T>(
  array1: T[],
  array2: T[],
  fields?: Array<keyof T>,
): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2[i];

    if (!areObjectsEqualByFields(obj1, obj2, fields)) {
      return false;
    }
  }

  return true;
};
