import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TAsset } from 'src/typings/configuration.types';

export function getCommunityAsset({
  assets,
  rootAssetUuid,
  assetsTreeRelations,
  assetsValues,
}: Pick<TConfigurationState, 'assets' | 'rootAssetUuid' | 'assetsTreeRelations' | 'assetsValues'>):
  | TAsset
  | undefined {
  if (!rootAssetUuid) return;

  if (assetsValues[rootAssetUuid].name === 'Community') return assets[rootAssetUuid];

  const rootChildrenAssets = assetsTreeRelations[rootAssetUuid].map((uuid) => assets[uuid]);
  const communityAsset = rootChildrenAssets.find((asset) => asset.type === 'Area');

  return communityAsset;
}
