import React, { useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseInput } from 'src/components/BaseInput';
import { BaseSwitch } from 'src/components/BaseSwitch';
import { BaseTextarea } from 'src/components/BaseTextarea';
import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { AddToLibraryFieldContainer } from 'src/components/ModalAssetsManager/AddToLibrary/AddToLibraryFieldContainer';
import {
  TAddToLibraryFormState,
  TAddToLibraryProps,
  TAddToLibrarySubmitFormValidationResult,
} from 'src/components/ModalAssetsManager/AddToLibrary/AddToLibraryForm.types';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager/ModalAssetsManager.types';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';
import useSaveToLibrary from 'src/hooks/useSaveToLibrary';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  selectAssets,
  selectAssetsValues,
  selectSelectedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { EUserRoles, TOnChangePayload } from 'src/typings/base-types';

import s from '../ModalAssetsManager.module.scss';

export const AddToLibraryForm: React.FC<TAddToLibraryProps> = ({ setView }) => {
  const [formState, setFormState] = useState<TAddToLibraryFormState>({
    libraryDescription: '',
    libraryName: '',
    isPrivate: false,
    isDefaultLib: false,
  });
  const [error, setError] = useState<undefined | string>(undefined);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const assetsValues = useSelector(selectAssetsValues);
  const assets = useSelector(selectAssets);
  const { handleAddToLibraryForSaved } = useSaveToLibrary();
  const userRole = useSelector(selectUserRole);
  const isAdmin = userRole === EUserRoles.Admin;
  const dispatch = useAppDispatch();

  const setFormData = (e: TOnChangePayload<string | number | File | undefined, string>) =>
    setFormState((prev) => ({ ...prev, [e.name]: e.value }));

  const validateForm = (): TAddToLibrarySubmitFormValidationResult => {
    if (formState.libraryName.length == 0) {
      return { errormessage: 'Library Name can not be empty', success: false };
    }
    return { success: true };
  };

  const submitForm = () => {
    const validationResult = validateForm();
    if (!selectedAssetUuid) return;

    if (!validationResult.success) {
      setError(validationResult.errormessage);
      return;
    }

    handleAddToLibraryForSaved({
      ...formState,
      assetUuid: selectedAssetUuid,
      assetType: assets[selectedAssetUuid].type,
      values: assetsValues[selectedAssetUuid],
    }).then((r) => {
      if (r.errors) {
        let showMessage = 'Something went wrong';
        if (r.errors[0].message) {
          const parsedMessage = JSON.parse(r.errors[0].message);
          showMessage = Object.values(parsedMessage).join(' ');
        }
        dispatch(
          openToast({
            message: showMessage,
            type: 'error',
          }),
        );
        return;
      }
      dispatch(
        openToast({
          message: 'Library has been saved successfully',
          type: 'success',
        }),
      );
      setView(EModalAssetsManagerView.Summary);
    });
  };

  return (
    <>
      <div className={classnames(s.paddingLR, s.paddingT)}>
        <header className={s.header}>
          <h3 className={s.title}>Add To Library</h3>
          <button
            type="button"
            className={classnames(s.mla)}
            onClick={() => setView(EModalAssetsManagerView.Form)}>
            <BaseIcon icon="close" size={12} />
          </button>
        </header>
      </div>
      <CustomScrollbar className={classnames(s.paddingLR, s.formMaxHeight)}>
        <AddToLibraryFieldContainer>
          <BaseInput
            name="libraryName"
            label="Library Name"
            type="text"
            theme={'line-light'}
            value={formState.libraryName}
            error={error}
            autoComplete="off"
            onChange={setFormData}
          />
        </AddToLibraryFieldContainer>
        <AddToLibraryFieldContainer>
          <BaseTextarea
            name="libraryDescription"
            label="Library Description"
            theme={'line-light'}
            inputHeight="3"
            value={formState.libraryDescription}
            onChange={setFormData}
          />
        </AddToLibraryFieldContainer>
        <AddToLibraryFieldContainer>
          <BaseSwitch
            name="isPrivate"
            label="Is Private"
            options={[
              { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
              { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
            ]}
            theme="gray-custom"
            variant="horizontal-small"
            value={formState.isPrivate}
            onChange={setFormData}
          />
        </AddToLibraryFieldContainer>
        {isAdmin && (
          <AddToLibraryFieldContainer>
            <BaseSwitch
              name="isDefaultLib"
              label="GS_y Default Asset"
              options={[
                { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
                { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
              ]}
              theme="gray-custom"
              variant="horizontal-small"
              value={formState.isDefaultLib}
              onChange={setFormData}
            />
          </AddToLibraryFieldContainer>
        )}
        <FieldContainer className={classnames(s.inputField, s.paddingT, s.paddingB)}>
          <BaseButton type="button" onClick={submitForm} className={classnames(s.saveBtn)}>
            Save
          </BaseButton>
        </FieldContainer>
      </CustomScrollbar>
    </>
  );
};
