import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Chart, ChartDataset, ScatterDataPoint } from 'chart.js';
import { useSelector } from 'react-redux';
import { EChartName } from 'src/components/_charts/chartsData';
import { ChartSimulationProgressPlugin } from 'src/components/_charts/ChartSimulationProgress/ChartSimulationProgress.plugin';
import { useChartJS } from 'src/hooks/useChartJS';
import { selectRawCommunitySimulationResults } from 'src/redux/configuration/configuration.selectors';
import { UTCMoment } from 'src/utils/UTCMoment';

import s from './ChartSimulationProgress.module.scss';
import { TChartSimulationProgressProps } from './ChartSimulationProgress.types';




function getGradient(ctx, chartArea) {
  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(252, 19, 85, 1)');
    gradient.addColorStop(0.5, 'rgba(22, 65, 247, 1)');
    gradient.addColorStop(1, 'rgba(93, 226, 115, 1)');
  }

  return gradient;
}

const getBorderColor = (context) => {
  const chart = context.chart;
  // @ts-ignore
  const { ctx, chartArea } = chart;

  if (!chartArea) {
    // This case happens on initial chart load
    return null;
  }
  return getGradient(ctx, chartArea);
};

export const ChartSimulationProgress: React.FC<TChartSimulationProgressProps> = ({
  graphWidth,
  graphHeight,
  filter,
  graphStartDate,
  graphEndDate,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart>(null);
  const simulationResults = useSelector(selectRawCommunitySimulationResults);
  // const settingsData = useSelector(selectSettingsData);
  const marketSummary = useMemo(
    () => (simulationResults?.marketSummary && simulationResults?.marketSummary) || [],
    [simulationResults?.marketSummary],
  );

  const [chartWidth, setChartWidth] = useState(0);

  const finalTick = useMemo(() => {
    const ft = UTCMoment.fromBackend(graphEndDate).toDate();
    ft.setHours(0);
    ft.setMinutes(0);
    ft.setSeconds(0);
    return ft.getTime();
  }, [graphEndDate]);

  const initialTick = useMemo(() => {
    const ft = UTCMoment.fromBackend(graphStartDate).toDate();
    ft.setHours(1);
    ft.setMinutes(0);
    ft.setSeconds(0);
    return ft.getTime();
  }, [graphStartDate]);

  const parsedMarketSummary = useMemo(() => {
    const output = {
      tradedVolumeData: [] as ScatterDataPoint[],
      averageEnergyRateData: [] as ScatterDataPoint[],
    };

    return marketSummary.reduce((acc, item) => {
      const x = UTCMoment.fromBackend(item.timestamp).valueOf();

      acc.tradedVolumeData.push({ x, y: item.tradedVolume });
      acc.averageEnergyRateData.push({ x, y: item.averageEnergyRate || 0 });

      return acc;
    }, output);
  }, [marketSummary]);

  const datasets = useMemo(() => {
    const commonDatasetOptions: Partial<ChartDataset<'line'>> = {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderCapStyle: 'square',
      borderJoinStyle: 'miter',
      tension: 0.5,
    };

    return [
      {
        ...commonDatasetOptions,
        data: parsedMarketSummary.tradedVolumeData,
        borderColor: getBorderColor,
        parsing: false as const,
        hidden: filter && filter.includes(1),
      },
      {
        ...commonDatasetOptions,
        data: parsedMarketSummary.averageEnergyRateData,
        borderColor: getBorderColor,
        hidden: filter && filter.includes(0),
      },
    ];
  }, [parsedMarketSummary, filter]);

  const chartOptions = useMemo(
    () => ({
      maintainAspectRatio: false,
      aspectRatio: chartWidth / graphHeight,
      scales: {
        x: {
          max: finalTick,
          min: initialTick,
        },
      },
    }),
    [chartWidth, finalTick, initialTick, graphHeight],
  );

  const plugins = useMemo(() => [ChartSimulationProgressPlugin], []);

  const containerRef = useCallback((node) => {
    if (node) {
      setChartWidth(node.clientWidth);
    }
  }, []);

  useChartJS(
    EChartName.SimulationProgress,
    canvasRef,
    chartRef,
    {
      datasets,
    },
    {
      chartOptions: chartOptions,
      plugins: plugins,
    },
  );

  return (
    <div className={s.container} ref={containerRef}>
      <canvas
        ref={canvasRef}
        height={graphHeight}
        style={{ width: graphWidth ? graphWidth - 100 : chartWidth, height: graphHeight }}
      />
    </div>
  );
};
