//import { BaseIcon } from 'src/components/BaseIcon';
import React from 'react';

import classNames from 'classnames';
import { TCanaryRegistryUserTileProps } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';

import s from './CanaryRegistryList.module.scss';

export const CanaryRegistryUserTile: React.FC<TCanaryRegistryUserTileProps> = ({
  data,
  selected,
  //homesList,
  withoutSelectedUIEffect,
  onClick,
}) => (
  <div
    className={classNames(s.userTile, {
      [s.selected]: !withoutSelectedUIEffect && selected,
    })}
    onClick={() => onClick(data)}>
    {/* <ProfilePictureIcon
      avatarUrl={data.avatarUrl}
      name={data.name}
      className={{ nameInitials: s.avatar, picture: classNames(s.avatar, s.hasAvatar) }}
    /> */}
    <div className={s.userDataContainer}>
      <p className={s.username}>{data?.subtitle}</p>
      <p className={s.userEmail}>{data.name.split('@')[0]}</p>
    </div>
    <div className={s.actions}>
      {data?.isNewUser && <div className={s.newUserBlock}>⚡️ New</div>}
      {
        //<BaseIcon icon="more-horizontal" size={15} />
      }
    </div>
  </div>
);
