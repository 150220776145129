import React, { useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChartSelf, EChartSelfType } from 'src/components/_charts/ChartSelf';
import {
  EnergyBillComponents,
  EnergyBillSubtitles,
  TListEnergyBillInfo,
} from 'src/components/EnergyBillComponents';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { KeyResultsSCM } from 'src/components/KeyResultsSCM';
import { KeyResultProgress } from 'src/components/KeyResultsSCM/components/KeyResultProgress';
import { CustomResultHeader } from 'src/components/SimulationResultsPresentation/CustomResultHeader';
import { DownloadResultsButton } from 'src/components/SimulationResultsPresentation/CustomResultHeader/DownloadResultsButton';
import {
  TBillsDifference,
  TBillsFees,
  TSCMHomeKpi,
  TSingleHomeProps,
} from 'src/components/SimulationResultsPresentation/SingleHome/SingleHome.types';
import {
  EnergyBillInfoContent,
  MyEnergyProfileInfoText,
  SingleHomeNotification,
  SavingsBenchmarkInfoText,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/components/SimulationResultsPresentation/SingleHome/SingleHomeConstants';
import { FEE_MAPPINGS } from 'src/components/SimulationResultsPresentation/SingleHome/SingleHomeConstants';
import { EPredefinedModalIds } from 'src/constants/modals';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useAccumulatedResultsWithSubscription } from 'src/hooks/useAccumulatedResultsWithSubscription';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useSingleNotification } from 'src/hooks/useSingleNotification';
import {
  selectScreenMode,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import {
  selectAssetsValues,
  selectSelectedAssetUuid,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectDataResolution,
  selectHomeBillsDifferences,
  selectHomeKpi,
  selectIsOperationalCommunity,
  selectKpiDifference,
} from 'src/redux/scm/scm.selectors';
import {
  setHomeBillsDifferences,
  setHomeKpi,
  setIsAppLoading,
  setKpiDifference,
} from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { EScreenMode } from 'src/typings/configuration.types';
import { TKpi } from 'src/typings/simulation.types';
import { formatter } from 'src/utils/formatter';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';

import s from './SingleHome.module.scss';
import { createBillInfo, withCurrency } from './utils';

export const SingleHome: React.FC<TSingleHomeProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const homeBillsDifferences = useSelector(selectHomeBillsDifferences);
  const settingsData = useSelector(selectSettingsData);
  const homeKpi = useSelector(selectHomeKpi);
  const kpiDifference = useSelector(selectKpiDifference);
  const dataResolution = useSelector(selectDataResolution);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const assetsValues = useSelector(selectAssetsValues);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const screenMode = useSelector(selectScreenMode);

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { isOwnerCM } = useIsAdminInspecting();

  const {
    resultsData,
    resultsStartTime,
    resultsEndTime,
    billsDifferenceData,
    kpiDifferenceData,
    handleResolutionButton,
  } = useAccumulatedResults({
    initialRunQueries:
      //isOperationalCommunity
      //? []
      //:
      ['getAccumulatedResults', 'getAccumulatedBillsDifferences', 'getAccumulatedKpiDifferences'],
    mode: 'home',
  });

  useAccumulatedResultsWithSubscription({
    mode: 'home',
  });

  useSingleNotification({
    singleNotification: SingleHomeNotification,
    checkStatement: !isOperationalCommunity && isOwnerCM,
  });

  const currencySymbol = formatter.getCurrencySymbol(settingsData.currency);

  const listEnergyBillInfo = useMemo<TListEnergyBillInfo | null>(() => {
    if (!homeBillsDifferences) return null;
    const {
      spentToCommunity,
      gsyEnergyBillExclRevenueWithoutFees,
      gsyEnergyBillExclRevenue,
      importGridFees,
      fees,
    } = homeBillsDifferences;

    const spentToGrid = gsyEnergyBillExclRevenueWithoutFees - spentToCommunity;
    const spentToGridPercent = spentToGrid / gsyEnergyBillExclRevenue;
    const taxes =
      fees.marketplaceMonthlyFee +
      fees.gridFees +
      fees.contractedPowerMonthlyFee +
      fees.contractedPowerCargoMonthlyFee +
      fees.energyCargoFee +
      fees.gridFees +
      fees.marketplaceMonthlyFee +
      importGridFees;

    const billInfo = [
      createBillInfo(
        '#66EE66',
        t('bill_components.SPENT_TO_GRID'),
        spentToGridPercent,
        spentToGrid,
        currencySymbol,
      ),
      createBillInfo(
        '#FFB347',
        t('bill_components.SPENT_TO_COMMUNITY'),
        spentToCommunity / gsyEnergyBillExclRevenue,
        spentToCommunity,
        currencySymbol,
      ),
      createBillInfo(
        '#FC1355',
        t('bill_components.TAXES'),
        taxes / gsyEnergyBillExclRevenue,
        taxes,
        currencySymbol,
      ),
    ];

    FEE_MAPPINGS.forEach(({ key, color, labelKey }) => {
      if (fees.hasOwnProperty(key) && fees[key] > 0) {
        billInfo.push(
          createBillInfo(color, t(labelKey), fees[`${key}Percent`], fees[key], currencySymbol),
        );
      }
    });

    return billInfo;
  }, [homeBillsDifferences, currencySymbol, t]);

  usePositionBGM({
    modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR,
    top: screenMode === EScreenMode.Mobile ? 120 : isUserACommunityMember ? 73 : 120,
  });

  // unset the homebillsdifferences, homekpi, kpidifference when unmounting the component
  useEffect(() => {
    return () => {
      dispatch(setHomeBillsDifferences(undefined));
      dispatch(setHomeKpi(undefined));
      dispatch(setKpiDifference(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (billsDifferenceData) {
      const billsCamelCase = objectCamelCase<TBillsDifference>(JSON.parse(billsDifferenceData));
      billsCamelCase.fees = objectCamelCase<TBillsFees>(billsCamelCase.fees);
      dispatch(setHomeBillsDifferences(billsCamelCase));
    }
  }, [billsDifferenceData, dispatch]);

  useEffect(() => {
    if (resultsData?.kpi && resultsData?.billsEnergy)
      dispatch(
        setHomeKpi(
          pickKpiData({
            kpi: JSON.parse(resultsData?.kpi),
            billsEnergy: JSON.parse(resultsData?.billsEnergy),
          }),
        ),
      );
    if (!isOperationalCommunity && resultsData?.kpi) {
      dispatch(setIsAppLoading(false));
    }
  }, [resultsData, dispatch, isOperationalCommunity]);

  useEffect(() => {
    if (kpiDifferenceData)
      dispatch(setKpiDifference(objectCamelCase<TSCMHomeKpi>(JSON.parse(kpiDifferenceData))));
  }, [kpiDifferenceData, dispatch]);

  if (!sidebarExpanded) return null;

  return (
    <>
      <div className={s.rowWrapper}>
        <CustomResultHeader
          handleResolutionButton={handleResolutionButton}
          selectedResolution={dataResolution}
          isHomeNameActive={!isUserACommunityMember}
          isCommunityLabelActive={false}
          flag={isOperationalCommunity ? 'Operation' : 'Simulation'}
          homeName={selectedAssetUuid ? assetsValues[selectedAssetUuid]?.name : ''}
        />
        {resultsStartTime && resultsEndTime && !isUserACommunityMember && (
          <div className={s.downloadResultContainer}>
            <DownloadResultsButton startDate={resultsStartTime} endDate={resultsEndTime} />
          </div>
        )}
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          <KeyResultsSCM
            horizontal={true}
            mode={'Home'}
            currency={settingsData.currency}
            homeBillValue={homeBillsDifferences?.gsyEnergyBill}
            homeSavings={homeBillsDifferences?.savings}
            totalBenefit={homeBillsDifferences?.gsyTotalBenefit}
            title={`⚡ ${t('bill_components.ENERGY_BILL')}`}
          />
        </div>
        <div className={s.rightColumn}>
          <HomeEnergyInfo
            generatedValue={`${
              kpiDifference?.totalEnergyProducedKwh
                ? kpiDifference?.totalEnergyProducedKwh.toFixed(0)
                : 0
            } kWh`}
            //consumedValue={`${homeBillsDifferences?.homeBalanceKwh.toFixed(0)} kWh`}
            consumedValue={`${
              kpiDifference?.totalEnergyDemandedKwh
                ? kpiDifference?.totalEnergyDemandedKwh.toFixed(0)
                : 0
            } kWh`}
          />
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          {listEnergyBillInfo && (
            <EnergyBillComponents
              key={listEnergyBillInfo.map((item) => item.cost).join('')}
              list={listEnergyBillInfo}
              total={
                homeBillsDifferences?.gsyEnergyBillExclRevenue
                  ? withCurrency(currencySymbol, homeBillsDifferences.gsyEnergyBillExclRevenue)
                  : ''
              }
              info={<EnergyBillInfoContent />}
              title={`${t('bill_components.ENERGY_BILL_COMPONENTS')}`}
              subTitle={t(EnergyBillSubtitles[dataResolution]) || ''}
            />
          )}
        </div>
        <div className={classNames(s.rightColumn, s.selfandprogress)}>
          {kpiDifference && (
            <div className={s.selfSufficiencyWrapper}>
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(kpiDifference)}
                type={EChartSelfType.Sufficiency}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={t(SelfSufficiencyInfoText) || ''}
                isCommaSeparated={true}
              />
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(kpiDifference)}
                type={EChartSelfType.Consumption}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={t(SelfConsumptionInfoText) || ''}
                isCommaSeparated={true}
              />
            </div>
          )}
          {homeBillsDifferences && (
            <KeyResultProgress
              title={t('MY_ENERGY_SAVINGS_RANKING_IN_COMMUNITY')}
              info={t(SavingsBenchmarkInfoText) || ''}
              percentage={parseInt((homeBillsDifferences.energyBenchmark * 100).toFixed(0))}
              badges={[]}
              className={s.savingsRanking}
            />
          )}
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.rightColumn}>
          {homeKpi && (
            <EnergyProfileComponent
              title={t('MY_ENERGY_PROFILE')}
              info={t(MyEnergyProfileInfoText) || ''}
              mode={dataResolution}
              initialData={homeKpi}
              field="home"
            />
          )}
        </div>
        {/*energyFlowProps && !isUserACommunityMember && (
          <div className={s.leftColumn}>
            <EnergyFlow
              showSwitcher={false}
              title={t('MY_ENERGY_FLOWS') || ''}
              info={MyEnergyFlowsInfoText}
              wrapperClassNames={{
                className: s.energyFlowWrapper,
                headerClassName: s.energyFlowContent,
                contentClassName: s.energyFlowContent,
              }}
              {...energyFlowProps}
            />
          </div>
            )*/}
      </div>
    </>
  );
};
