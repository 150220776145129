import { TAuthState } from 'src/redux/auth/auth.slice';
import { RootState } from 'src/redux/store';
import { EUserRoles } from 'src/typings/base-types';

function getUserRole(state: RootState): EUserRoles | undefined {
  return state.auth.tokenAuth?.userRole as EUserRoles | undefined;
}

export const selectTokenAuth = (state: RootState): TAuthState['tokenAuth'] => state.auth.tokenAuth;

export const selectIsLoggedIn = (state: RootState): boolean => Boolean(state.auth.tokenAuth);

export const selectUsername = (state: RootState): string => state.auth.tokenAuth?.username || '';

export const selectUserRole = (state: RootState): EUserRoles | undefined =>
  state.auth.tokenAuth?.userRole ? (state.auth.tokenAuth?.userRole as EUserRoles) : undefined;

export const selectProfilePicture = (state: RootState): string =>
  state.auth.tokenAuth?.profilePicture || '';

/*
  Role checkers
*/
export const selectIsAdmin = (state: RootState): boolean => {
  const role = getUserRole(state);
  return role === EUserRoles.Admin;
};

export const selectIsAdminOrEO = (state: RootState): boolean => {
  const role = getUserRole(state);
  return role === EUserRoles.Admin || role === EUserRoles.ExchangeOperator;
};

export const selectIsAdminOrEOOrResearcher = (state: RootState): boolean => {
  const role = getUserRole(state);
  return (
    role === EUserRoles.Admin ||
    role === EUserRoles.ExchangeOperator ||
    role === EUserRoles.Researcher
  );
};

export const selectIsResearcherOrDSO = (state: RootState): boolean => {
  const role = getUserRole(state);
  return role === EUserRoles.Researcher || role === EUserRoles.DSO;
};

export const selectIsDSO = (state: RootState): boolean => {
  const role = getUserRole(state);
  return role === EUserRoles.DSO;
};

export const selectInitialCommunity = (state: RootState): string =>
  state.auth.initialCommunity || '';
