import React from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router';
import { AuthRegisterForms } from 'src/components/AuthRegisterForms';
import { BaseIcon } from 'src/components/BaseIcon';
import { setOnBoardingStep } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { ELsKey, ls } from 'src/utils/localStorage';

import s from './Login.module.scss';
import { TLoginProps } from './Login.types';

export const Login: React.FC<TLoginProps> = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const onSuccessRedirect = () => {
    const inviteLink = ls.get(ELsKey.INVITE_LINK);
    if (inviteLink) {
      dispatch(setOnBoardingStep(0)); // closes the modal map hero
      ls.remove(ELsKey.INVITE_LINK);
      history.push(inviteLink);
    }
    return history.push(routesConfig.scmMap());
  };

  return (
    <div className={classNames(s.container, s.containerBg)}>
      <BaseIcon
        icon="gs-logo-white"
        size={80}
        className={s.logo}
        onClick={() => history.push(routesConfig.home())}
      />
      <AuthRegisterForms onSuccess={() => onSuccessRedirect()} />
    </div>
  );
};
