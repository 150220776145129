import { useSelector } from 'react-redux';
import {
  ListMeasurementVerificationRequestsLazyQueryHookResult,
  ListMeasurementVerificationRequestsQuery,
  RequestMeasurementVerificationMutationHookResult,
  RevokeMeasurementVerificationMutationHookResult,
  SetMeasurementVerificationStatusMutationHookResult,
  useListMeasurementVerificationRequestsLazyQuery,
  useRequestMeasurementVerificationMutation,
  useRevokeMeasurementVerificationMutation,
  useSetMeasurementVerificationStatusMutation,
} from 'src/graphql';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import {
  addSingleNotification,
  deleteSingleNotification,
} from 'src/redux/notifications/notificaitons.slice';
import { ENotificationPlace } from 'src/redux/notifications/notifications.types';
import { TMeasurementRequest, setMeasurementRequests } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';

export type TUseMeasurementQueriesResponse = {
  listMeasurementVerificationRequests: ListMeasurementVerificationRequestsLazyQueryHookResult[0];
  listMeasurementVerificationRequestsData?: ListMeasurementVerificationRequestsQuery;
  requestMeasurementVerificationMutation: RequestMeasurementVerificationMutationHookResult[0];
  setMeasurementVerificationStatusMutation: SetMeasurementVerificationStatusMutationHookResult[0];
  revokeMeasurementVerificationMutation: RevokeMeasurementVerificationMutationHookResult[0];
};

export type TUseMeasurementQueriesArgs = {
  sendListRequestAfterMutation?: boolean;
  showNotificationAfterMutation?: boolean;
  listMeasurementVerificationRequestsOnCompleted?: (
    data: ListMeasurementVerificationRequestsQuery,
  ) => void;
};

type TUseMeasurementQueries = (args: TUseMeasurementQueriesArgs) => TUseMeasurementQueriesResponse;

export const useMeasurementQueries: TUseMeasurementQueries = ({
  sendListRequestAfterMutation = true,
  showNotificationAfterMutation = true,
  listMeasurementVerificationRequestsOnCompleted,
}) => {
  const configurationUuid = useSelector(selectActiveConfigurationUuid);

  const dispatch = useAppDispatch();

  const [
    listMeasurementVerificationRequests,
    { data: listMeasurementVerificationRequestsData },
  ] = useListMeasurementVerificationRequestsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (listMeasurementVerificationRequestsOnCompleted) {
        listMeasurementVerificationRequestsOnCompleted(data);
      }

      const tempList = data.listMeasurementVerificationRequests;

      if (!tempList) return;
      const tempData = tempList
        .filter((item) => item !== null && item !== undefined)
        .map((item) => ({
          areaUuid: item?.areaUuid,
          configUuid: item?.configUuid,
          status: item?.status,
        }));

      dispatch(setMeasurementRequests(tempData as TMeasurementRequest[]));
    },
  });

  const [setMeasurementVerificationStatusMutation] = useSetMeasurementVerificationStatusMutation({
    onCompleted(data) {
      if (data.setMeasurementVerificationStatus !== 'success') return;

      if (sendListRequestAfterMutation) {
        listMeasurementVerificationRequests({
          variables: {
            configUuid: configurationUuid!,
          },
        });
      }

      if (showNotificationAfterMutation) {
        dispatch(
          addSingleNotification({
            description: 'Your request to connect is being processed.',
            notificationPlace: ENotificationPlace.HEADER_REQUEST_SENT,
            characterIcon: '☝',
          }),
        );

        setTimeout(() => {
          dispatch(deleteSingleNotification());
        }, 5000);
      }
    },
  });

  const [requestMeasurementVerificationMutation] = useRequestMeasurementVerificationMutation({
    onCompleted(data) {
      if (data.requestMeasurementVerification !== 'success') return;

      if (sendListRequestAfterMutation) {
        listMeasurementVerificationRequests({
          variables: {
            configUuid: configurationUuid!,
          },
        });
      }

      if (showNotificationAfterMutation) {
        dispatch(
          addSingleNotification({
            description: 'Your request to connect is being processed.',
            notificationPlace: ENotificationPlace.HEADER_REQUEST_SENT,
            characterIcon: '☝',
          }),
        );

        setTimeout(() => {
          dispatch(deleteSingleNotification());
        }, 5000);
      }
    },
  });

  const [revokeMeasurementVerificationMutation] = useRevokeMeasurementVerificationMutation({
    onCompleted(data) {
      if (data.revokeMeasurementVerification !== 'success') return;

      if (sendListRequestAfterMutation) {
        listMeasurementVerificationRequests({
          variables: {
            configUuid: configurationUuid!,
          },
        });
      }

      if (showNotificationAfterMutation) {
        dispatch(
          openToast({
            type: 'success',
            message: 'Your request to connect has been revoked.',
          }),
        );
      }
    },
  });

  return {
    listMeasurementVerificationRequests,
    listMeasurementVerificationRequestsData,
    requestMeasurementVerificationMutation,
    setMeasurementVerificationStatusMutation,
    revokeMeasurementVerificationMutation,
  };
};
