import { useCallback, useContext, useMemo } from 'react';

import * as turf from '@turf/turf';
import { batch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ZOOM } from 'src/constants/application';
import WorldMapContext from 'src/contexts/WorldMapContext';
import {
  useLaunchSimulationMutation,
  usePauseSimulationMutation,
  useResumeSimulationMutation,
  useStopSimulationMutation,
} from 'src/graphql';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectAssetValuesForUuid,
  selectRootAsset,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  TConfigurationState,
  configurationReset,
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
} from 'src/redux/configuration/configuration.slice';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { TLngLatArray } from 'src/typings/base-types';
import { ELsKey, ls } from 'src/utils/localStorage';

type TUseConfiguration = {
  discardCurrentConfiguration: (options?: { zoomOut?: boolean }) => void;
  flyToActiveConfiguration: () => void;
  simulationLaunch: (uuid: string) => Promise<string | null>;
  simulationLaunchWithStoreUpdate: (uuid: string) => Promise<void>;
  simulationPause: (uuid: string) => void;
  simulationResume: (uuid: string) => void;
  simulationStop: (uuid: string) => void;
  zoomIntoConfiguration: ({ assetsValues }: TConfigurationState['assetsValues']) => void;
  actionButtonsHelper: {
    runButtonStatus:
      | 'play'
      | 'pause'
      | 'resume'
      | 'rerun'
      | 'pause-locked'
      | 'disabled'
      | undefined;
    stopButtonState: 'stop' | 'stop-locked' | undefined;
    runButtonAction: (
      configUuid: string | undefined,
      jobUuid: string | undefined,
    ) => Promise<string | null>;
    stopButtonAction: (jobUuid: string) => void;
  };
};

type TUseConfigurationOptions = {
  simulationStatus?: string;
};

/**
 *
 * @param options Override selectors values by options
 */
export function useConfigurationUtils(options?: TUseConfigurationOptions): TUseConfiguration {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { mapService } = useContext(WorldMapContext);
  const rootAsset = useSelector(selectRootAsset);
  const rootAssetValues = useSelector(selectAssetValuesForUuid(rootAsset?.uuid || ''));
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const simulationStatus = useSelector(selectSimulationStatus);
  const [launchSimulation] = useLaunchSimulationMutation();
  const [pauseSimulation] = usePauseSimulationMutation();
  const [stopSimulation] = useStopSimulationMutation();
  const [resumeSimulation] = useResumeSimulationMutation();
  const location = useAppLocation();
  const { pathname } = location;
  const isUserInvitationFlow = !isLoggedIn && pathname.includes('/singularity-map/results');
  const isSCMFlow = useSelector(selectSCMFlow);

  const flyToActiveConfiguration = useCallback(() => {
    if (rootAssetValues?.geoTagLocation) {
      mapService?.flyTo(
        {
          lng: rootAssetValues.geoTagLocation[0],
          lat: rootAssetValues.geoTagLocation[1],
        },
        { specificZoom: 14 },
      );
    }
  }, [mapService, rootAssetValues]);

  const discardCurrentConfiguration = useCallback(
    (options?: { zoomOut?: boolean }) => {
      batch(() => {
        dispatch(updateSelectedLocation(null));
        dispatch(configurationReset());
        dispatch(setActiveConfigurationJobUuid(undefined));
      });
      if (isUserInvitationFlow) {
        ls.set(ELsKey.INVITE_LINK, pathname);
      }
      history.push(isSCMFlow ? routesConfig.scmMap() : routesConfig.singularityMap());
      if (options?.zoomOut) {
        const viewport = mapService?.getViewport();
        if (viewport) {
          mapService?.flyTo(viewport, { specificZoom: ZOOM.QUIT_COMMUNITY, speed: 2.5 });
        }
      }
    },
    [dispatch, history, mapService, isUserInvitationFlow, pathname, isSCMFlow],
  );

  const simulationLaunch = useCallback(
    async (configurationUuid: string) => {
      const { data } = await launchSimulation({
        variables: {
          uuid: configurationUuid,
        },
      });
      if (data?.launchSimulation?.job) {
        return data?.launchSimulation?.job;
      }
      return null;
    },
    [launchSimulation],
  );

  const simulationResume = useCallback(
    (jobUuid: string) => {
      if (jobUuid) {
        resumeSimulation({
          variables: {
            jobId: jobUuid,
          },
        });
      }
    },
    [resumeSimulation],
  );

  const simulationPause = useCallback(
    (jobUuid: string) => {
      if (jobUuid) {
        pauseSimulation({
          variables: {
            jobId: jobUuid,
          },
        });
      }
    },
    [pauseSimulation],
  );

  const simulationStop = useCallback(
    (jobUuid: string) => {
      if (jobUuid) {
        stopSimulation({
          variables: {
            jobId: jobUuid,
          },
        });
      }
    },
    [stopSimulation],
  );

  const simulationLaunchWithStoreUpdate = useCallback(
    async (configurationUuid: string) => {
      const { data } = await launchSimulation({
        variables: {
          uuid: configurationUuid,
        },
      });

      if (data?.launchSimulation?.job) {
        dispatch(setActiveConfigurationUuid(activeConfigurationUuid));
        dispatch(setActiveConfigurationJobUuid(data?.launchSimulation?.job));
      }
    },
    [activeConfigurationUuid, dispatch, launchSimulation],
  );

  const zoomIntoConfiguration = useCallback(
    ({ assetsValues }: TConfigurationState['assetsValues']) => {
      const coordsArray = Object.values(assetsValues).reduce(
        (acc: TLngLatArray[], item: TConfigurationState['assetsValues']['uuid']) => {
          const { geoTagLocation } = item;
          if (!geoTagLocation?.length) return acc;
          acc.push(geoTagLocation);
          return acc;
        },
        [],
      );

      if (!coordsArray.length) return;

      if (coordsArray.length === 1) {
        coordsArray.push(coordsArray[0]);
      }

      const features = turf.points(coordsArray);
      const center = turf.center(features).geometry.coordinates;

      const maxDist =
        Math.sqrt(
          Math.max(
            ...coordsArray.map(([x, y]) => {
              const rx = Math.abs(center[0] - x);
              const ry = Math.abs(center[1] - y);
              return rx * rx + ry * ry;
            }),
          ),
        ) + 0.003;

      const bbox = [
        center[0] - maxDist,
        center[1] - maxDist,
        center[0] + maxDist,
        center[1] + maxDist,
      ];

      mapService?.flyTo(
        {
          bbox: bbox as [number, number, number, number],
        },
        {
          speed: 2.5,
        },
      );
    },
    [mapService],
  );

  const actionButtonsHelper = useMemo(() => {
    const allowStopStates = [
      'started',
      'running',
      'queued',
      'loading',
      'initializing',
      'paused',
      'disabled',
    ];
    let runButtonStatus: TUseConfiguration['actionButtonsHelper']['runButtonStatus'] | undefined =
      'pause-locked';
    let stopButtonState: TUseConfiguration['actionButtonsHelper']['stopButtonState'] | undefined =
      'stop-locked';

    switch (options?.simulationStatus || simulationStatus) {
      case 'paused':
        runButtonStatus = 'resume';
        stopButtonState = 'stop';
        break;
      case 'stopped':
      case 'error':
      case 'failed':
      case 'timed-out':
        runButtonStatus = 'rerun';
        stopButtonState = 'stop';
        break;
      case 'started':
      case 'running':
        runButtonStatus = 'disabled';
        stopButtonState = 'stop';
        break;
      case 'queued':
      case 'loading':
      case 'initializing':
        runButtonStatus = 'pause-locked';
        stopButtonState = 'stop';
        break;
      case 'stopping':
        runButtonStatus = 'pause-locked';
        stopButtonState = 'stop-locked';
        break;
      case 'finished':
        runButtonStatus = 'rerun';
        stopButtonState = 'stop-locked';
        break;
      default:
        runButtonStatus = undefined;
        stopButtonState = undefined;
        break;
    }

    return {
      runButtonStatus,
      stopButtonState,
      runButtonAction: async (
        configUuid: string | undefined,
        jobUuid: string | undefined,
      ): Promise<string | null> => {
        switch (runButtonStatus) {
          case 'play':
          case 'rerun':
            if (configUuid && activeConfigurationUuid === configUuid) {
              simulationLaunchWithStoreUpdate(configUuid);
            } else if (configUuid) {
              return await simulationLaunch(configUuid);
            }
            break;
          case 'pause':
            if (jobUuid) simulationPause(jobUuid);
            break;
          case 'resume':
            if (jobUuid) simulationResume(jobUuid);
            break;
        }
        return null;
      },
      stopButtonAction: (uuid: string) => {
        if (allowStopStates.includes(String(options?.simulationStatus || simulationStatus))) {
          simulationStop(uuid);
        }
      },
    };
  }, [
    activeConfigurationUuid,
    options?.simulationStatus,
    simulationLaunch,
    simulationLaunchWithStoreUpdate,
    simulationPause,
    simulationResume,
    simulationStatus,
    simulationStop,
  ]);

  return {
    discardCurrentConfiguration,
    flyToActiveConfiguration,
    simulationLaunch,
    simulationLaunchWithStoreUpdate,
    simulationPause,
    simulationResume,
    simulationStop,
    zoomIntoConfiguration,
    actionButtonsHelper,
  };
}
