import React from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { CommunityNotificationOutput } from 'src/graphql';
import useDownloadAPI from 'src/hooks/useDownloadAPI';

import s from './MessagingPortal.module.scss';


type FileAttachmentProps = {
  item: CommunityNotificationOutput;
};

export const FileAttachment: React.FC<FileAttachmentProps> = ({ item }) => {
  const uploadedFiles = item.uploadedFiles ?? [];
  const getUploadDetails =
    uploadedFiles && uploadedFiles.length > 0 ? uploadedFiles[0] : { url: '', name: '', uuid: '' };
  const resultsDownloadAPI = useDownloadAPI({
    type: 'file-attachment',
    url: getUploadDetails?.url,
    name: getUploadDetails?.name,
    uuid: getUploadDetails?.uuid,
  });

  const handleAttchmentClick = async () => {
    await resultsDownloadAPI.handleDownload();
  };

  return (
    <div className={s.attachment} onMouseUp={() => handleAttchmentClick()}>
      <div className={s.attachmentContainer}>
        <BaseIcon icon="attachment" size={22} /> <span> {getUploadDetails?.name} </span>
      </div>
    </div>
  );
};
