import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { AppRoute } from 'src/routes/AppRoute';
import { TAppRouteProps, routesConfig } from 'src/routes/routes.config';

export const PrivateRoute: React.FC<TAppRouteProps> = (props) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (isLoggedIn) return <AppRoute {...props} />;
  else return <Redirect to={routesConfig.login()} />;
};
