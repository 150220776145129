import React from 'react';

import classnames from 'classnames';
import { BaseLink } from 'src/components/BaseLink';

import s from './TabNav.module.scss';
import { TTabNavProps } from './TabNav.types';

export const TabNav: React.FC<TTabNavProps> = ({ className, nav, theme = '2' }) => {
  return (
    <nav className={classnames(s.tabsWrapper, className)}>
      {nav.map((item, index) => (
        <BaseLink
          key={index}
          className={s.tab}
          onClick={item.onClick}
          isActive={item.isActive}
          disabled={item.disabled}
          theme={theme}>
          {item.label}
        </BaseLink>
      ))}
    </nav>
  );
};
