import { TIconNames } from 'src/components/BaseIcon/IconNames.types';

export const get3dAssetIcon = (assetType: string): TIconNames => {
  switch (assetType) {
    case 'Area':
      return 'house-3d';
    case 'PV':
      return 'solar-3d';
    case 'Load':
      return 'plug-3d';
    case 'ScmStorage':
    case 'Storage':
      return 'battery-3d';
    case 'ScmHeatPump':
    case 'HeatPump':
      return 'heatpump-3d';
    case 'FiniteDieselGenerator':
    case 'MarketMaker':
    case 'InfiniteBus':
    case 'AreaOutput':
    case 'SmartMeter':
      return 'question-mark';
    default:
      return 'info';
  }
};

export const get2dAssetIcon = (assetType: string): TIconNames => {
  switch (assetType) {
    case 'Load':
      return 'plug';
    case 'ScmStorage':
    case 'Storage':
      return 'battery';
    case 'PV':
      return 'pv';
    case 'FiniteDieselGenerator':
      return 'house';
    case 'Area':
      return 'house';
    case 'MarketMaker':
      return 'house';
    case 'InfiniteBus':
      return 'house';
    case 'Home':
      return 'house';
    default:
      return 'question-mark';
  }
};
