
import { FetchResult } from '@apollo/client';
import { TLibrarySaveForm } from 'src/components/ModalAssetsManager/AddToLibrary/AddToLibraryForm.types';
import { CreateLibraryMutation, useCreateLibraryMutation } from 'src/graphql';
import useBuildSavingTreeBranch from 'src/hooks/useBuildSavingTreeBranch';
import { TLibrariesTags } from 'src/typings/base-types';

type TUseSaveToLibraryResult = {
  handleAddToLibraryForSaved: (
    payload: TLibrarySaveForm,
  ) => Promise<
    FetchResult<CreateLibraryMutation, Record<string, unknown>, Record<string, unknown>>
  >;
};

export default function useSaveToLibrary(): TUseSaveToLibraryResult {
  const { createAreaInputFromSaveProps } = useBuildSavingTreeBranch();
  const [createLibrary] = useCreateLibraryMutation({ errorPolicy: 'all' });

  const handleAddToLibraryForSaved = (
    payload: TLibrarySaveForm,
  ): Promise<
    FetchResult<CreateLibraryMutation, Record<string, unknown>, Record<string, unknown>>
  > => {
    const areaInput = createAreaInputFromSaveProps(payload);
    const tags: Array<TLibrariesTags> = [];
    if (payload.isDefaultLib) tags.push('DefaultLib');
    if (!payload.isPrivate) tags.push('Public');
    if (payload.assetType && ['PV', 'Storage', 'Load', 'Area'].includes(payload.assetType))
      tags.push(payload.assetType);

    return createLibrary({
      variables: {
        name: payload.libraryName,
        description: payload.libraryDescription,
        isPrivate: payload.isPrivate,
        scenarioData: {
          representation: areaInput,
        },
        tags: tags,
      },
    });
  };

  return {
    handleAddToLibraryForSaved,
  };
}
