import React from 'react';

import { TRegularNoResultsProps } from 'src/components/MapSidebar/components/MapSidebarResults/components/RegularNoResults/RegularNoResults.types';
import { TextBrick } from 'src/components/TextBrick';

import s from './RegularNoResults.module.scss';

export const RegularNoResults: React.FC<TRegularNoResultsProps> = ({ communityNotFound }) => (
  <div className={s.noResults}>
    <TextBrick>{communityNotFound ? 'Community not found' : 'No results'}</TextBrick>
  </div>
);
