import React from 'react';

import classnames from 'classnames';
import { motion } from 'framer-motion';

import s from './FadeAnimationHelper.module.scss';
import { TFadeAnimationHelperProps, TFadeAnimationVariant } from './FadeAnimationHelper.types';


export const FadeAnimationHelper: React.FC<TFadeAnimationHelperProps> = ({
  className,
  children,
  fadeIn = true,
  duration = 0.5,
  delay = 0,
  ease = 'linear',
}) => {
  const variant: TFadeAnimationVariant = {
    initial: { opacity: fadeIn ? 0 : 1, pointerEvents: fadeIn ? 'none' : 'auto' },
    animate: {
      opacity: fadeIn ? 1 : 0,
      pointerEvents: fadeIn ? 'auto' : 'none',
      transition: { delay, duration, ease },
    },
    exit: { opacity: 0, pointerEvents: 'none', transition: { duration } },
  };

  return (
    <motion.div
      className={classnames(s.container, className)}
      initial={variant.initial}
      animate={variant.animate}
      exit={variant.exit}>
      {children}
    </motion.div>
  );
};
