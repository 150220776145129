import React, { useMemo, useState } from 'react';

import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { DatePickerCustomHeader } from 'src/components/BaseDateRangePicker/DatePickerCustomHeader';
import { BaseIcon } from 'src/components/BaseIcon';
import { UTCMoment } from 'src/utils/UTCMoment';

import s from './BaseDateRangePicker.module.scss';
import { TBaseDateRangePickerProps } from './BaseDateRangePicker.types';


export const BaseDateRangePicker: React.FC<TBaseDateRangePickerProps> = ({
  className,
  theme,
  startValue,
  endValue,
  valueFormat,
  onChange,
  disabled,
  minDate,
  maxDate,
  placeholderText,
  startValueName,
  endValueName,
  tags,
}) => {
  const [isCalendarOpened, setIsCalendarOpened] = useState<false | 'start' | 'end'>(false);

  const selectedRangeDaysCount = useMemo(() => {
    if (startValue && endValue) {
      return UTCMoment.utc(endValue).diff(UTCMoment.utc(startValue), 'days');
    }
    return 0;
  }, [endValue, startValue]);

  const handleChange = (date: Date, isStartVal: boolean) => {
    const value = UTCMoment.utc(
      // https://github.com/Hacker0x01/react-datepicker/issues/1787#issuecomment-770313939
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    ).format(valueFormat);

    let endDate = !isStartVal ? value : endValue;
    const isRangeDaySelected = tags?.some((item) => item.value === selectedRangeDaysCount);

    if (isStartVal && isRangeDaySelected) {
      endDate = UTCMoment.utc(date).add(selectedRangeDaysCount, 'days').format(valueFormat);
    }

    onChange({
      startDate: isStartVal ? value : startValue,
      endDate,
    });
  };

  const handleBadgeClick = (value: number) => {
    const startDate = startValue || UTCMoment.utc().format(valueFormat);
    const endDate = UTCMoment.utc(startDate).add(value, 'days').format(valueFormat);
    onChange({
      startDate,
      endDate,
    });
  };

  return (
    <div
      className={classnames(className, {
        [s.container]: true,
        [s.themeDark]: theme === 'dark',
        [s.themeLight]: theme === 'light',
        [s.calendarOpened]: !!isCalendarOpened,
        [s.startCalendarActive]: isCalendarOpened === 'start',
        [s.endCalendarActive]: isCalendarOpened === 'end',
        themeLight: theme === 'light',
        themeDark: theme === 'dark',
      })}>
      <div className={s.pickers}>
        <input type="hidden" name={startValueName} />
        <input type="hidden" name={endValueName} />
        <BaseIcon className={s.calendarIcon} icon="calendar" />
        <BaseIcon className={s.calendarIcon} icon="calendar" />
        <DatePicker
          className={s.datepickerInput}
          selected={
            typeof startValue === 'string'
              ? UTCMoment.utc(startValue, valueFormat).toDate()
              : startValue
          }
          onChange={(d) => handleChange(d as Date, true)}
          dateFormat="dd.MM.yyyy"
          disabled={disabled}
          maxDate={maxDate || new Date()}
          placeholderText={placeholderText}
          onCalendarOpen={() => {
            setIsCalendarOpened('start');
          }}
          onCalendarClose={() => {
            setIsCalendarOpened(false);
          }}
          renderCustomHeader={DatePickerCustomHeader}
        />
        <DatePicker
          className={s.datepickerInput}
          selected={
            typeof endValue === 'string' ? UTCMoment.utc(endValue, valueFormat).toDate() : endValue
          }
          onChange={(d) => handleChange(d as Date, false)}
          dateFormat="dd.MM.yyyy"
          disabled={disabled}
          minDate={minDate}
          placeholderText={placeholderText}
          maxDate={new Date()}
          onCalendarOpen={() => {
            setIsCalendarOpened('end');
          }}
          onCalendarClose={() => {
            setIsCalendarOpened(false);
          }}
          renderCustomHeader={DatePickerCustomHeader}
        />
      </div>
      <div className={s.tags}>
        {tags?.map((item) => (
          <button
            key={item.value}
            disabled={disabled}
            type="button"
            onClick={() => handleBadgeClick(item.value)}
            className={classnames({
              [s.checked]: selectedRangeDaysCount === item.value,
            })}>
            {item.label}

            {item.badge && <div className={s.badge}>{item.badge}</div>}
          </button>
        ))}
      </div>
    </div>
  );
};
