import { TSimResultsStatus } from 'src/typings/base-types';
import { TMoment, UTCMoment } from 'src/utils/UTCMoment';


export type TCreateMonthObjectResult = {
  title: string;
  startUtc: number;
  endUtc: number;
  isCurrent?: boolean;
};

export const createMonthObjectsArray = (
  startDateUtc: TMoment,
  endDateUtc: TMoment,
  status?: TSimResultsStatus,
): TCreateMonthObjectResult[] => {
  const monthObjects: TCreateMonthObjectResult[] = [];

  const currentMonth = UTCMoment.utc(startDateUtc);
  const endMonth = UTCMoment.utc(endDateUtc);

  let counter = 0;
  while (currentMonth.isSameOrBefore(endMonth, 'month')) {
    const isStart = counter === 0;
    const isEnd = currentMonth.isSame(endMonth, 'month');
    const startUtc = isStart
      ? UTCMoment.utc(currentMonth)
      : UTCMoment.utc(currentMonth).startOf('month');
    const endUtc = isEnd
      ? UTCMoment.utc(endDateUtc).subtract(1, 'day').endOf('day')
      : UTCMoment.utc(currentMonth).endOf('month');

    const monthObject: TCreateMonthObjectResult = {
      title: `${startUtc.format('MMMM')} ${startUtc.format('YYYY')}`,
      startUtc: startUtc.toDate().getTime(),
      endUtc: endUtc.toDate().getTime(),
      isCurrent: status === 'running' && currentMonth.isSame(UTCMoment.utc(endMonth), 'month'),
    };

    monthObjects.push(monthObject);
    currentMonth.add(1, 'month');

    counter++;
  }

  return monthObjects;
};
