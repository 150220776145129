import React, { useMemo } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { TAssetCardProps } from 'src/components/MapSidebar/components/MapSidebarCanary/components/AssetsView/AssetsCard/AssetCard.types';
import { selectAssetValuesForUuid } from 'src/redux/configuration/configuration.selectors';

import s from './AssetsCard.module.scss';

export const AssetsCard: React.FC<TAssetCardProps> = ({
  data,
  assetIcon,
  onClick,
  childrenCount,
  isMoreButtonActive = true,
}) => {
  const assetValues = useSelector(selectAssetValuesForUuid(data.uuid!));

  const subtitle = useMemo(() => {
    const assetType = data.type;
    switch (assetType) {
      case 'Area':
        return childrenCount ? childrenCount + ' Assets' : '';
      case 'Storage':
        return 'Capacity ' + assetValues?.batteryCapacityKwh + ' kWh';
      case 'PV':
        return 'Capacity ' + assetValues?.capacityKw + ' kWh';
      case 'Load':
        return assetValues?.avgPowerW ? 'Avg power ' + assetValues.avgPowerW + 'W' : '';
      default:
    }
  }, [data, assetValues, childrenCount]);

  return (
    <div
      className={classNames(s.asset, s.gridItem, {
        [s.pointerNone]: !onClick,
      })}
      onClick={() => onClick?.(data.uuid!)}>
      {isMoreButtonActive && (
        <button className="moreMenu" type="button" title="More">
          <BaseIcon icon="more-horizontal" size={10} />
        </button>
      )}
      {false && (
        <div className={s.plugIcon}>
          <BaseIcon icon="plug" size={12} />
        </div>
      )}

      <div className="content">
        <div className={s.assetIcon}>
          <BaseIcon icon={assetIcon} size={100} />
        </div>
        <h6 className={s.title}>{data.name}</h6>
        {subtitle && <p>{subtitle}</p>}
      </div>
    </div>
  );
};
