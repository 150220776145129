import { CLOUD_COVERAGE_OPTIONS } from 'src/constants/application';
import { ConfigType } from 'src/graphql';
import { EFormVariant } from 'src/typings/base-types';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';

export type TPVArgs = {
  configType: ConfigType;
};

export const PV = ({ configType }: TPVArgs): TFieldsTemplateUnion[] => {
  const isDisabled = configType === ConfigType.CanaryNetwork;
  return [
    {
      name: 'name',
      type: 'text',
      label: 'labels.ASSET_NAME',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'labels.LOCATION',
      tooltipText: 'Parent market already has location',
      formView: EFormVariant.Express,
    },
    {
      name: 'capacityKw',
      type: 'number',
      label: 'common.CAPACITY',
      formView: EFormVariant.Advanced,
      unit: 'kWp',
      disabled: isDisabled,
    },
    {
      name: 'cloudCoverage',
      type: 'enum',
      label: 'common.PROFILE',
      options: CLOUD_COVERAGE_OPTIONS,
      formView: EFormVariant.Advanced,
      showTooltip: true,
      tooltipText: 'messages.GET_CORRECT_RESULTS',
      disabled: isDisabled,
    },
    {
      name: 'powerProfile',
      type: 'file',
      label: 'labels.OVERRIDE_PROFILE',
      formView: EFormVariant.Advanced,
      disabled: isDisabled,
    },
    {
      name: 'powerProfileUuid',
      formView: EFormVariant.Express,
      disabled: isDisabled,
    },
  ];
};
