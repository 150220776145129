import React from 'react';

import { FileUploader } from 'react-drag-drop-files';

import { TFileUploadDropProps } from './FileUploadDrop.types';

const fileTypes = ['XLS', 'XLSX', 'CSV'];

export const FileUploadDrop: React.FC<TFileUploadDropProps> = ({ children, handleChange }) => {
  const handleFileChange = (file) => {
    handleChange(file);
  };

  return (
    <>
      <FileUploader
        hoverTitle=" "
        onTypeError={(err) => alert(err)}
        multiple={true}
        handleChange={handleFileChange}
        name="file"
        types={fileTypes}>
        {children}
      </FileUploader>
    </>
  );
};
