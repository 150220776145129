import {
  BidOfferMatchAlgo,
  Currencies,
  ScmCoefficientAlgorithm,
  SpotMarketType,
} from 'src/graphql';
import { ESCMGroupSettingsName, TSettingsData } from 'src/utils/assetsFields/assetsFields.types';
import { UTCMoment, BACKEND_DATE_FORMATS } from 'src/utils/UTCMoment';

const today = UTCMoment.utc();
const tomorrow = UTCMoment.utc().add(7, 'day');

export const DEFAULT_SETTINGS_DATA: TSettingsData = {
  slotLengthMinutes: 15,
  spotMarketType: SpotMarketType.Coefficients,
  bidOfferMatchAlgo: BidOfferMatchAlgo.PayAsClear,
  marketCount: 1,
  endDate: tomorrow.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
  endDateTime: tomorrow.format(BACKEND_DATE_FORMATS.SETTINGS_DATA_END_DATE_TIME),
  slotLengthRealtimeSeconds: 0,
  tickLengthSeconds: 15,
  startDate: today.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
  currency: Currencies.Eur,
  scmCoefficientAlgorithm: ScmCoefficientAlgorithm.Static,
  scmGroupSettingsName: ESCMGroupSettingsName.DEFAULT,
  logo: '',
};
