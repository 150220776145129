import {
  TLibrary,
  TSelectedLibraryOptions,
} from 'src/components/LibraryChooser/LibraryChooser.types';
import { formatSubtitle } from 'src/components/LibraryChooser/LibraryChooserHelpers';
import { LibraryTypesGraphql } from 'src/graphql';
import { v4 } from 'uuid';

type customParameterKeys =
  | 'isCustomPV'
  | 'isCustomHouse'
  | 'isCustomLoad'
  | 'isCustomHeatPump'
  | 'isCustomStorage';

const getCustomParameters = (key: customParameterKeys, value: boolean) => {
  const params = {
    isCustomHouse: true,
    isCustomPV: false,
    isCustomLoad: false,
    isCustomHeatPump: false,
    isCustomStorage: false,
  };

  params[key] = value;

  return params;
};

const customHouseUuid = v4();
export const customHouse: TLibrary = {
  libraryUuid: customHouseUuid,
  uuid: customHouseUuid,
  icon: 'house-3d',
  title: 'Custom Home',
  subtitle: formatSubtitle(0, 0),
  type: 'Area',
  key: customHouseUuid,
  ...getCustomParameters('isCustomHouse', true),
  description: 'A home with no assets attached - configure and add custom assets',
  disabled: true,
};

const customPVUuid = v4();
export const customPV: TLibrary = {
  libraryUuid: customPVUuid,
  uuid: customPVUuid,
  icon: 'solar-3d',
  title: 'Custom PV',
  subtitle: formatSubtitle(0, 1),
  type: 'PV',
  key: customPVUuid,
  ...getCustomParameters('isCustomPV', true),
  description: `Add Custom PV \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
  disabled: false,
};

const customLoadUuid = v4();
export const customLoad: TLibrary = {
  libraryUuid: customLoadUuid,
  uuid: customLoadUuid,
  icon: 'plug-3d',
  title: 'Custom Load',
  subtitle: formatSubtitle(0, 1),
  type: 'Load',
  key: customLoadUuid,
  ...getCustomParameters('isCustomLoad', true),
  description: `Add Custom Load \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
  disabled: false,
};

const customHeatPumpUuid = v4();
export const customHeatPump: TLibrary = {
  libraryUuid: customHeatPumpUuid,
  uuid: customHeatPumpUuid,
  icon: 'heatpump-3d',
  title: 'Custom Heat Pump',
  subtitle: formatSubtitle(0, 1),
  type: 'ScmHeatPump',
  key: customHeatPumpUuid,
  ...getCustomParameters('isCustomHeatPump', true),
  description: `Add Custom Heat Pump \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
  disabled: true,
};

const customScmStorageUuid = v4();
export const customScmStorage: TLibrary = {
  libraryUuid: customScmStorageUuid,
  uuid: customScmStorageUuid,
  icon: 'battery-3d',
  title: 'Custom Battery',
  subtitle: formatSubtitle(0, 1),
  type: 'ScmStorage',
  key: customScmStorageUuid,
  ...getCustomParameters('isCustomStorage', true),
  description: `Add Custom Battery \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
  disabled: true,
};

export const allCustomAssets = [
  customHouse,
  customPV,
  customLoad,
  customHeatPump,
  customScmStorage,
];

export const selectLibraryOptions: TSelectedLibraryOptions = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'GS_y Library',
    value: LibraryTypesGraphql.GsyLibrary,
  },
  {
    label: 'Public Library',
    value: LibraryTypesGraphql.PublicLibrary,
  },
  {
    label: 'My Library',
    value: LibraryTypesGraphql.MyLibrary,
  },
];
