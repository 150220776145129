import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TFieldValue } from 'src/typings/base-types';
import {
  TAllFieldNames,
  TAssetFieldTemplatesArgs,
  TFieldsUnionWithValue,
} from 'src/utils/assetsFields/assetsFields.types';
import { baseFieldTemplates } from 'src/utils/assetsFields/fieldTemplates';
import { fieldValues } from 'src/utils/assetsFields/fieldValues';

/*
 *
 * This function returns all asset's templates with values injected
 *
 */
export function getAllFieldTemplatesWithValuesForAsset({
  type,
  configType,
  values,
  configurationCharacteristic,
}: TAssetFieldTemplatesArgs): TFieldsUnionWithValue[] {
  const fieldTemplates = baseFieldTemplates({
    type,
    configType,
    configurationCharacteristic,
  });

  const fieldsTemplatesWithValues: TFieldsUnionWithValue[] = fieldTemplates
    .filter((field) => !field.EXCLUDE)
    .map(
      (field) =>
        ({
          ...field,
          value: !!values
            ? Object.keys(values).includes(field.name)
              ? values![field.name]
              : field.defaultValue
            : field.defaultValue,
        } as TFieldsUnionWithValue),
    );

  return fieldsTemplatesWithValues;
}

/*
 *
 * This function returns single asset's templates with values injected
 *
 */
export function getSingleFieldTemplateWithValueForAsset({
  value,
  disabled,
  ...args
}: TAssetFieldTemplatesArgs & {
  name: TAllFieldNames;
  disabled?: boolean;
  value?: TFieldValue;
}): TFieldsUnionWithValue | undefined {
  const defaultValues = fieldValues.assetsFields({
    type: args.type,
    settingsData: args.settingsData,
    configurationCharacteristic: args.configurationCharacteristic,
  });

  const fieldTemplate = baseFieldTemplates(args).find((f) => f.name === args.name);
  const finalValue = typeof value === 'undefined' ? defaultValues![args.name] : value;

  if (!fieldTemplate) return;

  // @ts-ignore
  return {
    ...fieldTemplate,
    disabled,
    value: finalValue,
  };
}

type TAssetValues = TConfigurationState['assetsValues']['assetType'];
export function getAssetValues(fields: TFieldsUnionWithValue[]): TAssetValues {
  return fields.reduce((acc, item) => {
    // @ts-ignore
    acc[item.name] = item.value;
    return acc;
  }, {} as TAssetValues);
}
