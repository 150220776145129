import { TOrientationParam } from 'src/components/CustomPV/components/Orientation/Orientation.types';
import { TFieldsUnionWithValue } from 'src/utils/assetsFields/assetsFields.types';

export type TCustomPVProps = {
  className?: string;
  capacityFields: TFieldsUnionWithValue[];
  tradingFields: TFieldsUnionWithValue[];
  azimuthValuesForCustomPV?: TOrientationParam;
  setAzimuthValuesForCustomPV?: (values: TOrientationParam) => void;
  showOrientationSection?: boolean;
  theme?: 'light' | 'dark';
};

export enum EAccordionType {
  capacityAndProfile = 'navigation.CAPACITY_AND_PROFILE',
  orientation = 'navigation.ORIENTATION',
  tradingStrategy = 'Trading Strategy',
}
