// eslint-disable-next-line no-restricted-imports
import { get, remove, set } from 'local-storage'; // This is the only place where this import is allowed.
import { TAuthState } from 'src/redux/auth/auth.slice';

import { TMapState } from '../redux/map/map.slice';

export enum ELsKey {
  TOKEN_AUTH = 'TOKEN_AUTH',
  SIMULATIONS = 'SIMULATIONS',
  WILLI_COMMUNITY = 'WILLI_COMMUNITY',
  INVITE_LINK = 'INVITE_LINK',
  APP_FLOW = 'APP_FLOW',
  SCM_STEP = 'SCM_STEP',
  INITIAL_COMMUNITY = 'INITIAL_COMMUNITY',
  LANGUAGE = 'LANGUAGE',
}

// prettier-ignore
type TData<T> = 
  T extends ELsKey.TOKEN_AUTH ? TAuthState['tokenAuth'] :
  T extends ELsKey.SIMULATIONS ? TMapState['globalSimulationLocations'] :
  T extends ELsKey.INVITE_LINK ? string :
  T extends ELsKey.APP_FLOW ? string :
  T extends ELsKey.SCM_STEP ? string :
  T extends ELsKey.LANGUAGE ? string :
  null;

export const ls = {
  set<T extends ELsKey>(key: T, value: TData<T>): void {
    set(key, value);
  },
  get<T extends ELsKey>(key: T): TData<T> | null {
    return get(key);
  },
  remove<T extends ELsKey>(key: T): void {
    return remove(key);
  },
};
