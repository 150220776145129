import React, { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Route, useLocation, Redirect } from 'react-router-dom';
import { ModalAuth } from 'src/components/ModalAuth';
import { ModalNetworkCanary } from 'src/components/ModalNetworkCanary';
import { AppLocationContextProvider, TAppLocationContextValue } from 'src/hooks/useAppLocation';
import { TAppRouteProps, routesConfig } from 'src/routes/routes.config';

export const AppRoute: React.FC<TAppRouteProps> = (props) => {
  const location = useLocation();

  const value: TAppLocationContextValue = useMemo(() => {
    return {
      ...location,
      routeName: props.routeName,
    };
  }, [location, props.routeName]);

  if (location.pathname === '/' || location.pathname === '') {
    return <Redirect to={routesConfig.scmMap()} />;
  }

  return (
    <AppLocationContextProvider value={value}>
      <Route {...props} />

      {/* ModalAuth */}
      <ModalAuth />

      {/* ModalNetworkCanary */}
      <ModalNetworkCanary />
    </AppLocationContextProvider>
  );
};
