import React from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { Maybe, UserRequestsStatus } from 'src/graphql';


import s from './MeasurementNotificationList.module.scss';

export const MeasurementNotificationPhrase: React.FC<{
  childrenPhrase: string;
  assetName?: string;
  userName?: string;
}> = ({ childrenPhrase, assetName, userName }) => (
  <>
    <h3>
      <span>{userName}</span>
    </h3>
    <p>
      Requested connection for <span>{childrenPhrase}</span> for <span>{assetName}</span>
    </p>
  </>
);

export const MeasurementAcceptedPhrase: React.FC<{
  childrenPhrase?: string;
  assetName?: string;
}> = ({ childrenPhrase, assetName }) => (
  <>
    <h3>Asset Connected</h3>
    <p>
      <span>{childrenPhrase}</span> in <span>{assetName}</span> is now connected.
    </p>
  </>
);

export const MeasurementRejectedPhrase: React.FC<{
  childrenPhrase?: string;
  assetName?: string;
}> = ({ childrenPhrase, assetName }) => (
  <>
    <h3>Connection Rejected</h3>
    <p>
      <span>{childrenPhrase}</span> in <span>{assetName}</span> connection request was rejected.
    </p>
  </>
);

export const MeasurementStatusIcon: React.FC<{
  status?: Maybe<UserRequestsStatus>;
}> = ({ status }) => {
  switch (status) {
    case UserRequestsStatus.Executed:
      return (
        <span className={s.executedIcon}>
          <BaseIcon icon="wifi-white" size={20} />
        </span>
      );
    case UserRequestsStatus.Declined:
      return (
        <span className={s.rejectedIcon}>
          <BaseIcon icon="close" svgProps={{ fill: '#000' }} size={14} />
        </span>
      );
    default:
      return null;
  }
};
