import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TFilterItem, AssetsListWithSearch } from 'src/components/AssetsListWithSearch';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { TLibrary } from 'src/components/LibraryChooser';
import {
  allCustomAssets,
  customHouse,
} from 'src/components/LibraryChooser/LibraryChooserConstants';
import { AssetCreateNewTabs } from 'src/components/MapSidebar/components/MapSidebarCanary/components/AssetCreateNew/AssetCreateNewTabs';
import { useAssetCreateTabs } from 'src/components/MapSidebar/components/MapSidebarCanary/components/AssetCreateNew/useAssetCreateTabs';
import { AssetSettings } from 'src/components/MapSidebar/components/MapSidebarCanary/components/AssetSettings';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { setSidebarMode } from 'src/redux/application/application.slice';
import { setHideSidebarHeader } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { TLibrariesTags } from 'src/typings/base-types';
import { get3dAssetIcon } from 'src/utils/assetIcons';

import s from './AssetCreateNew.module.scss';
import { TAssetCreateNewProps } from './AssetCreateNew.types';

export const AssetCreateNew: React.FC<TAssetCreateNewProps> = ({
  data,
  selectedAsset,
  onUpdate,
  onAddNewAsset,
  onAddNewAssetUnderUuid,
  setSelectedAsset,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [activeFilter, setActiveFilter] = useState<TLibrariesTags[]>(LIBRARY_FILTERS_MAPPING.All);
  const [selectedNewAsset, setSelectedNewAsset] = useState<TLibrary>();

  useEffect(() => {
    dispatch(setHideSidebarHeader(true));
    return () => {
      dispatch(setHideSidebarHeader(false));
    };
  }, [dispatch]);

  const subtitle = useMemo(() => {
    switch (selectedNewAsset?.type) {
      case 'Load':
        return `${t('common.CONSUMPTION')}: -- kWh`;
      case 'Storage':
        return `${t('common.CAPACITY')}: -- kWh`;
      default:
        return '';
    }
  }, [selectedNewAsset, t]);

  const onAssetSelect = (data: TLibrary) => {
    setSelectedNewAsset(data);
  };

  const onFilterChange = (filter: TFilterItem) => setActiveFilter(filter.value);
  const onCancelClick = () => dispatch(setSidebarMode('assetPreview'));

  const onAssetCancelClick = () => {
    setSelectedNewAsset(undefined);
  };

  const { onTabSelect, activeSettingsTab } = useAssetCreateTabs({
    data: selectedNewAsset,
  });

  const onTreeUpdate = () => onUpdate();

  const filteredAssets = useMemo(() => {
    if ([LIBRARY_FILTERS_MAPPING.All, LIBRARY_FILTERS_MAPPING.Custom].includes(activeFilter)) {
      return allCustomAssets;
    } else if (activeFilter === LIBRARY_FILTERS_MAPPING.Area) {
      return [customHouse];
    }
    return allCustomAssets.filter((asset) => asset.type.includes(activeFilter[0]));
  }, [activeFilter]);

  return (
    <div className={s.container}>
      <header className={s.header}>
        <p>{t('commands.ADD_ASSET')}</p>
        <BaseButton theme="grey3" onClick={onCancelClick} className={s.cancelButton}>
          <BaseIcon icon="close" className={s.svgIcon} />
        </BaseButton>
      </header>
      <div className={s.content}>
        {!selectedNewAsset && (
          <>
            <div className={s.typeSelector}>
              <h5>{t('tooltips.WHICH_ASSET')}</h5>
              <p>{t('tooltips.ADD_ASSET_INFO')}</p>
            </div>
            <AssetsListWithSearch
              disableDescription={true}
              disableAssetDescription={false}
              assets={filteredAssets}
              isAllButtonRequiredInFilter={false}
              searchQuery={''}
              onSearchQueryChange={() => null}
              onAssetChoose={onAssetSelect}
              loading={false}
              error={undefined}
              activeFilter={activeFilter}
              onFilterChange={onFilterChange}
              theme="light"
              listViewProp="3grid"
              searchPosition="top"
              showCustomAssetFilter={true}
            />
          </>
        )}
        {selectedNewAsset && (
          <div className={s.createAsset}>
            <div className={s.hero}>
              <BaseButton
                theme="grey2"
                onClick={onAssetCancelClick}
                className={classNames(s.cancelButton, s.assetCancel)}>
                <BaseIcon icon="close" className={s.svgIcon} />
              </BaseButton>
              <div className={s.icon}>
                <BaseIcon icon={get3dAssetIcon(selectedNewAsset.type)} />
              </div>
              <h5>{selectedNewAsset.title}</h5>
              <p>{subtitle}</p>
            </div>
            <nav className={s.navigation}>
              <AssetCreateNewTabs
                onTabSelect={onTabSelect}
                activeSettingsTab={activeSettingsTab}
                mode={selectedNewAsset.type}
              />
            </nav>
            <AssetSettings
              onAddNewAsset={onAddNewAsset}
              onAddNewAssetUnderUuid={onAddNewAssetUnderUuid}
              mainParentData={data}
              selectedChildUuid={selectedAsset}
              newAssetData={selectedNewAsset}
              mode={activeSettingsTab}
              onUpdate={onTreeUpdate}
              onSaveAction="add"
              setSelectedAsset={setSelectedAsset}
              backToPreview={() => {
                onCancelClick();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
