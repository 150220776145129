import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseDropdown } from 'src/components/BaseDropdown';
import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { BaseInput } from 'src/components/BaseInput';
import { useModal } from 'src/hooks/useModal';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';

import s from './BaseSelect.module.scss';
import { TBaseSelectProps } from './BaseSelect.types';




export const BaseSelect: React.FC<TBaseSelectProps> = function BaseSelect({
  className,
  name,
  value,
  label,
  options,
  disabled,
  error,
  onChange,
  theme,
  showScrollbar,
  scrollbarClassName,
  inputHeight,
  defaultLabel,
  showTooltip = false,
  tooltipText = '',
}) {
  const { id: modalId } = useModal();
  const selectedLabel = options ? (options.find((o) => o.value === value) || {}).label : '';
  const dropdownIsOpen = useSelector(selectIsModalOpened(modalId));
  const dispatch = useAppDispatch();

  const handleSelect = (newValue: TBaseSelectProps['value'], event) => {
    // value === newValue - this condition is required to make siblingFieldsReactions work properly.
    if (disabled || value === newValue) return;
    onChange?.({ name, value: newValue }, event);
  };

  return (
    <div
      className={classnames(className, s.container, {
        [s.disabled]: disabled,
        [s.opened]: dropdownIsOpen,
      })}>
      <BaseInput
        className={s.input}
        type="text"
        value={selectedLabel !== undefined ? String(selectedLabel) : defaultLabel}
        theme={theme}
        name={name}
        iconRight="arrow"
        iconRightSize={8}
        label={label}
        inputHeight={inputHeight}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) return;
          if (dropdownIsOpen) return;
          dispatch(openModal(modalId));
        }}
        showValidationIcon={false}
        showTooltip={showTooltip}
        tooltipText={tooltipText}
      />

      <BaseDropdown
        options={options}
        onSelect={handleSelect}
        modalId={modalId}
        position="bottom"
        showScrollbar={showScrollbar}
        scrollbarClassName={scrollbarClassName}
      />

      <BaseErrorMessage>{error}</BaseErrorMessage>
    </div>
  );
};
