import React, { useContext, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useAvailableUserRolesLazyQuery } from 'src/graphql';
import { setAvailableUserRoles } from 'src/redux/application/application.slice';
import { selectIsLoggedIn, selectUserRole, selectUsername } from 'src/redux/auth/auth.selectors';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { getFrontendUserRoleName } from 'src/utils/getFrontendUserRoleName';

import { useAppLocation } from '../../hooks/useAppLocation';
import { routesConfig } from '../../routes/routes.config';
import { CanaryNetworkServiceContext } from '../CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';


type TPageEffectsContainer = {
  children: React.ReactNode;
};

const PageEffectsContainer: React.FC<TPageEffectsContainer> = ({ children }) => {
  const dispatch = useAppDispatch();
  const isSCMFlow = useSelector(selectSCMFlow);

  const CanaryNetworkContext = useContext(CanaryNetworkServiceContext);
  const userName = useSelector(selectUsername);
  const userRole = useSelector(selectUserRole);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useAppLocation();

  const [fetchAvailableUserRoles] = useAvailableUserRolesLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ availableUserRoles }) => {
      const roles = availableUserRoles!.userRoles!.map((item) => ({
        roleName: getFrontendUserRoleName(item!.roleName!),
        value: item!.value!,
      }));

      dispatch(setAvailableUserRoles(roles || []));
    },
  });

  useEffect(() => {
    if (!isLoggedIn) return;
    fetchAvailableUserRoles();
  }, [fetchAvailableUserRoles, isLoggedIn]);

  useEffect(() => {
    if (userName && !isSCMFlow) {
      CanaryNetworkContext.fetcAllUserToAdminRequests(userName);
    }
  }, [CanaryNetworkContext, userName, isSCMFlow]);

  useEffect(() => {
    if (
      !!matchPath(location.pathname, {
        path: routesConfig.singularityMap(),
      }) &&
      !isSCMFlow
    ) {
      CanaryNetworkContext.fetchGlobalListUserToAdminRequests();
    }
  }, [CanaryNetworkContext, location.pathname, userRole, isSCMFlow]);

  return <>{children}</>;
};
export default PageEffectsContainer;
