/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import classnames from 'classnames';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseDateRangePicker } from 'src/components/BaseDateRangePicker';
import { BaseFileUpload } from 'src/components/BaseFileUpload';
import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import { BaseSelect } from 'src/components/BaseSelect';
import { BaseTextarea, TBaseTextareaProps } from 'src/components/BaseTextarea';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { GridMarketModalHeader } from 'src/components/MapSidebar/components/GridMarket/GridMarketModalHeader';
import mapSidebarStyles from 'src/components/MapSidebar/components/MapSidebarResults/MapSidebarResults.module.scss';
import {
  ConfigType,
  ListCollaborationsDocument,
  ListCollaborationsQuery,
  SettingsDataFieldsFragment,
} from 'src/graphql';
import { isLibrary } from 'src/mocks/configurationSettings';
import { selectIsAdmin } from 'src/redux/auth/auth.selectors';
import {
  selectCommunityAssetSettings,
  selectConfigType,
  selectConfiguration,
  selectIsCommunityCreatedInDB,
  selectReadOnly,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import { EFormVariant, TFieldValue } from 'src/typings/base-types';
import {
  TCommunitySettingsFields,
  TFieldsUnionWithValue,
  TSettingsData,
} from 'src/utils/assetsFields/assetsFields.types';
import { getAssetValues } from 'src/utils/assetsFields/fieldTemplatesWithValues';
import { findField } from 'src/utils/fieldUtils';
import { validateFields } from 'src/utils/fieldValidation';
import { UTCMoment } from 'src/utils/UTCMoment';
import { _DeepNonNullableObject } from 'utility-types/dist/mapped-types';

import { fieldTemplates, validators } from './formFields';
import s from './FormSettingsData.module.scss';
import {
  TFieldsVisibility,
  TFormSettingsDataProps,
  TSettingsSaveProps,
} from './FormSettingsData.types';

function getVisibleFields({
  fields,
  fieldsVisibility,
  formVariant,
}: {
  fields: ReturnType<typeof fieldTemplates>;
  fieldsVisibility: TFieldsVisibility;
  formVariant: TFormSettingsDataProps['formVariant'];
  isAdmin: boolean;
}) {
  if (!fields) return fields;

  return fields.reduce((acc: ReturnType<typeof fieldTemplates>, field) => {
    const conditionA =
      formVariant === EFormVariant.Express ? field.formView === EFormVariant.Express : true;
    const conditionB = field.name in fieldsVisibility ? fieldsVisibility[field.name] : true;

    if (conditionA && conditionB) {
      acc.push(field);
    }

    return acc;
  }, []);
}

type THandleChangeArgs = {
  name: keyof TSettingsData | 'locationVisible' | 'name';
  value: TFieldValue;
};

export const FormSettingsData: React.FC<TFormSettingsDataProps> = ({
  formVariant = EFormVariant.Advanced,
  onSubmit,
  id,
  className,
  isStartEndDateDisabled = false,
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();

  const isAdmin = useSelector(selectIsAdmin);
  const settingsData = useSelector(selectSettingsData) as _DeepNonNullableObject<
    Omit<SettingsDataFieldsFragment, '__typename'>
  >;

  const {
    gridFeeConstant,
    importCapacityKva,
    exportCapacityKva,
    coefficientPercentage,
    baselinePeakEnergyImportKwh,
    baselinePeakEnergyExportKwh,
  } = useSelector(selectCommunityAssetSettings) || {};
  const configuration = useSelector(selectConfiguration);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const readOnly = useSelector(selectReadOnly);
  const configType = useSelector(selectConfigType);

  const isCanaryNetwork = configType === ConfigType.CanaryNetwork;

  const [allFields, setAllFields] = useState(
    fieldTemplates({
      values: {
        ...settingsData,
        name: configuration.name,
        description: configuration.description,
        timezone: configuration.timezone,
        locationVisible: configuration.locationVisible,
        gridFeeEnabled: !!gridFeeConstant,
        gridFeeConstant,
        transformerCapacityEnabled: Boolean(importCapacityKva || exportCapacityKva), // setting as default true as data unavailble from BE
        importCapacityKva,
        exportCapacityKva,
        coefficientPercentage,
        baselinePeakEnergyEnabled: Boolean(
          baselinePeakEnergyImportKwh || baselinePeakEnergyExportKwh,
        ),
        baselinePeakEnergyImportKwh,
        baselinePeakEnergyExportKwh,
      },
      configurationCharacteristic: configuration,
    }),
  );

  const [allFieldsStored, setAllFieldsStored] = useState<TFieldsUnionWithValue[] | undefined>(
    undefined,
  );

  const [errors, setErrors] = useState<TFormFieldsGeneratorProps['errors']>(null);
  const settingsDataMemo = useMemo(() => settingsData, [settingsData]);
  const configurationMemo = useMemo(() => configuration, [configuration]);

  const gridFeeConstantMemo = useMemo(() => gridFeeConstant, [gridFeeConstant]);
  const combineValues = useMemo(
    () =>
      fieldTemplates({
        values: {
          ...settingsDataMemo,
          name: configurationMemo.name,
          description: configurationMemo.description,
          timezone: configurationMemo.timezone,
          locationVisible: configurationMemo.locationVisible,
          gridFeeEnabled: !!gridFeeConstantMemo,
          gridFeeConstant: gridFeeConstantMemo,
          transformerCapacityEnabled: Boolean(importCapacityKva || exportCapacityKva), // setting as default true as data unavailble from BE
          importCapacityKva,
          exportCapacityKva,
          coefficientPercentage,
          baselinePeakEnergyEnabled: Boolean(
            baselinePeakEnergyImportKwh || baselinePeakEnergyExportKwh,
          ),
          baselinePeakEnergyImportKwh,
          baselinePeakEnergyExportKwh,
        },
        configurationCharacteristic: configurationMemo,
      }),
    [
      settingsDataMemo,
      configurationMemo,
      gridFeeConstantMemo,
      importCapacityKva,
      exportCapacityKva,
      coefficientPercentage,
      baselinePeakEnergyImportKwh,
      baselinePeakEnergyExportKwh,
    ],
  );

  useEffect(() => {
    if (allFieldsStored && JSON.stringify(combineValues) !== JSON.stringify(allFieldsStored)) {
      setAllFields(combineValues);
      setAllFieldsStored(combineValues);
    }
  }, [combineValues, allFieldsStored, setAllFields, setAllFieldsStored]);

  useEffect(() => {
    if (!allFieldsStored) {
      setAllFieldsStored(allFields);
    }
  }, [setAllFieldsStored, allFields, allFieldsStored]);

  // If a field is not present in the object it will be visible by default.
  const fieldsVisibility: TFieldsVisibility = {
    currency: !isLibrary,
    startEndDate: !isCanaryNetwork && !isLibrary,
    tickLengthSeconds: !isLibrary,
    slotLengthRealtimeSeconds: !isCanaryNetwork && !isLibrary,
    gridFeeEnabled: !isLibrary,
    gridFeeConstant: Boolean(!isLibrary && findField(allFields, 'gridFeeEnabled')?.value),
    locationVisible: !isCanaryNetwork,
    timezone: isCanaryNetwork,
    importCapacityKva: Boolean(findField(allFields, 'transformerCapacityEnabled')?.value),
    exportCapacityKva: Boolean(findField(allFields, 'transformerCapacityEnabled')?.value),
    coefficientPercentage: false,
    baselinePeakEnergyImportKwh: Boolean(findField(allFields, 'baselinePeakEnergyEnabled')?.value),
    baselinePeakEnergyExportKwh: Boolean(findField(allFields, 'baselinePeakEnergyEnabled')?.value),
    bidOfferMatchAlgo: !isLibrary,
  };

  const visibleFields = getVisibleFields({
    fields: allFields,
    fieldsVisibility,
    formVariant,
    isAdmin,
  });

  const getUsedProjectNames = useCallback(async (): Promise<string[]> => {
    const {
      data,
    }: ApolloQueryResult<ListCollaborationsQuery> = await client.query<ListCollaborationsQuery>({
      query: ListCollaborationsDocument,
      fetchPolicy: 'cache-first',
    });

    const projects = data?.listCollaborations?.configurations || [];

    return projects
      .map((item) => item?.name)
      .filter((item): item is string => typeof item === 'string');
  }, [client]);

  const validateFieldsWrapper = useCallback(
    (fields: TFieldsUnionWithValue[]) => {
      return getUsedProjectNames().then((result) => {
        const output = validateFields({
          validators: validators({
            usedProjectNames: result,
            currentProjectName: isCommunityCreatedInDB ? configuration.name : '',
          }),
          fields,
        });
        setErrors(output.errors);

        return output;
      });
    },
    [setErrors, configuration.name, getUsedProjectNames, isCommunityCreatedInDB],
  );

  const handleChange = useCallback(
    ({ name, value }: THandleChangeArgs) => {
      if (!allFields) return;

      // We use immer for immutability, cloneDeep is too expensive
      const newFields = produce([...allFields], (draftState) => {
        const fieldToUpdate = draftState.find((f) => f.name === name);

        if (fieldToUpdate) {
          fieldToUpdate.value = value;

          // Update sibling fields
          if (fieldToUpdate.name === 'startEndDate') {
            const startEndDate = draftState.find((f) => f.name === 'startEndDate');

            if (startEndDate) {
              startEndDate.EXCLUDE = isCanaryNetwork;
            }
          }

          if (
            fieldToUpdate.name === 'logo' &&
            typeof value === 'object' &&
            'fileName' in fieldToUpdate
          ) {
            if (value && 'name' in value) {
              fieldToUpdate.fileName = value.name;
            }
          }
        }
      });

      validateFieldsWrapper(newFields);
      setAllFields(newFields);
    },
    [allFields, validateFieldsWrapper, isCanaryNetwork],
  );

  const renderedFields = useMemo(() => {
    switch (formVariant) {
      case EFormVariant.Advanced:
        return visibleFields?.filter(
          (f) => f.formView === EFormVariant.Advanced || f.formView === EFormVariant.Express,
        );

      default:
        return visibleFields?.filter((f) => f.formView === formVariant);
    }
  }, [visibleFields, formVariant]);

  const handleSubmit = async () => {
    const { errors } = await validateFieldsWrapper(renderedFields);
    if (errors) return;

    const values = getAssetValues(
      allFields.filter((f) => (f.name in fieldsVisibility ? fieldsVisibility[f.name] : true)),
    ) as TCommunitySettingsFields & TSettingsSaveProps['communityAssetSettings'];

    if (values.startEndDate) {
      values.startDate = values.startEndDate.startDate as string;
      values.endDate = values.startEndDate.endDate as string;
    }

    const {
      name,
      description,
      locationVisible,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      startEndDate,
      gridFeeConstant,
      ...newSettingsData
    } = values;

    const timezoneValue = timezoneField?.value || '';

    onSubmit({
      name,
      description,
      locationVisible,
      timezone: timezoneValue,
      settingsData: newSettingsData,
      communityAssetSettings: {
        gridFeeConstant: gridFeeConstant,
      },
    });
  };

  const containerRef = useRef<HTMLFormElement>(null);
  const nameField = useMemo(() => renderedFields.find((x) => x.name === 'name'), [renderedFields]);
  const logoField = useMemo(() => {
    const field = renderedFields.find((x) => x.name === 'logo');
    return {
      ...field,
      fileName:
        typeof field?.value === 'object' && 'name' in field.value!
          ? field.value.name
          : settingsDataMemo?.logo?.split('/').pop(),
    };
  }, [renderedFields, settingsDataMemo]);

  const descriptionField = useMemo(() => renderedFields.find((x) => x.name === 'description'), [
    renderedFields,
  ]);
  const startEndDateField: any = useMemo(
    () => renderedFields.find((x) => x.name === 'startEndDate'),
    [renderedFields],
  );
  const currencyField: any = useMemo(() => renderedFields.find((x) => x.name === 'currency'), [
    renderedFields,
  ]);
  const languageField: any = useMemo(() => allFields.find((x) => x.name === 'language'), [
    allFields,
  ]);
  const timezoneField: any = useMemo(() => allFields.find((x) => x.name === 'timezone'), [
    allFields,
  ]);

  return (
    <form
      className={classnames(s.container, className)}
      onSubmit={(e) => {
        e.preventDefault();

        if (readOnly) return;
        handleSubmit();
      }}
      id={id}
      ref={containerRef}>
      <GridMarketModalHeader formTitle={t('labels.COMMUNITY_NAME')} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseInput
          name={nameField?.name || ''}
          type={(nameField?.type || 'text') as TBaseInputProps['type']}
          label={nameField?.label}
          theme={'filled-gray'}
          value={(nameField?.value || '') as TBaseInputProps['value']}
          onChange={(val) => handleChange(val as THandleChangeArgs)}
          autoComplete="off"
          error={errors?.[nameField?.name || 0]}
        />
      </FieldContainer>
      {configType === ConfigType.CanaryNetwork && (
        <>
          <GridMarketModalHeader formTitle={t('labels.LOGO')} />
          <FieldContainer>
            <BaseFileUpload
              accept=".png, .jpg, .jpeg"
              name="file"
              value={logoField.fileName || ''}
              onChange={(val) =>
                handleChange(({ name: 'logo', value: val.value } as unknown) as THandleChangeArgs)
              }
              theme="filled-gray"
              onBlur={() => null}
              allowDownload={true}
            />
          </FieldContainer>
        </>
      )}
      <GridMarketModalHeader formTitle={t('labels.NOTES')} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseTextarea
          name={descriptionField?.name || ''}
          theme={'filled-gray'}
          inputHeight="10"
          value={(descriptionField?.value || '') as TBaseTextareaProps['value']}
          onChange={(val) => handleChange(val as THandleChangeArgs)}
        />
      </FieldContainer>
      {!isStartEndDateDisabled && (
        <>
          <GridMarketModalHeader formTitle={t('labels.SIMULATION_LENGTH')} />
          <FieldContainer className={s.datePickerContainer}>
            <BaseDateRangePicker
              theme={'light'}
              startValue={startEndDateField?.value.startDate}
              endValue={startEndDateField?.value.endDate}
              valueFormat={startEndDateField?.valueFormat}
              minDate={UTCMoment.utc(startEndDateField?.value.startDate).add(1, 'day').toDate()}
              maxDate={UTCMoment.utc(startEndDateField?.value.endDate).subtract(1, 'day').toDate()}
              onChange={({ startDate, endDate }) => {
                handleChange({
                  name: startEndDateField?.name,
                  value: { startDate: startDate, endDate: endDate },
                });
              }}
              tags={[
                {
                  value: 7,
                  label: `1 ${t('common.WEEK')}`,
                  badge: `${t('common.RECOMMENDED')}`,
                },
                {
                  value: 30,
                  label: `1 ${t('common.MONTH')}`,
                },
              ]}
            />
          </FieldContainer>
        </>
      )}
      <GridMarketModalHeader formTitle={t('labels.SIMULATION_CURRENCY')} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseSelect
          name={currencyField?.name}
          label={currencyField?.label}
          value={currencyField?.value}
          options={currencyField?.options}
          theme={'filled-gray'}
          onChange={(val: any) => handleChange(val)}
        />
      </FieldContainer>
      <GridMarketModalHeader formTitle={t('labels.COMMUNITY_TIME_ZONE')} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseSelect
          name={timezoneField?.name}
          label={timezoneField?.label}
          value={timezoneField?.value}
          options={timezoneField?.options}
          theme={'filled-gray'}
          onChange={(val: any) => handleChange(val)}
        />
      </FieldContainer>
      <GridMarketModalHeader formTitle={t('labels.LANGUAGE')} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseSelect
          name={languageField?.name}
          label={languageField?.label}
          value={languageField?.value}
          options={languageField?.options}
          theme={'filled-gray'}
          onChange={(val: any) => handleChange(val)}
        />
      </FieldContainer>
      <div className={mapSidebarStyles.formButtonsWrapper}>
        <BaseButton
          type="submit"
          className={mapSidebarStyles.formButton}
          form={id}
          disabled={readOnly}>
          {t('commands.SAVE')}
        </BaseButton>
      </div>
    </form>
  );
};
