import React from 'react';

import { ReactDatePickerProps } from 'react-datepicker';
import { BaseIcon } from 'src/components/BaseIcon';

import s from './BaseDateRangePicker.module.scss';

export const DatePickerCustomHeader: ReactDatePickerProps['renderCustomHeader'] = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div className={s.customHeaderContainer}>
    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      <BaseIcon icon="arrow-left" size={10} />
    </button>
    <span className="react-datepicker__current-month">
      {date.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      })}
    </span>
    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      <BaseIcon icon="arrow-left" size={10} style={{ transform: 'rotate(180deg)' }} />
    </button>
  </div>
);
