import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseInput } from 'src/components/BaseInput';
import { BaseSwitch } from 'src/components/BaseSwitch';
import { ContextMenu } from 'src/components/ContextMenu';
import { EDataGridDisplayType } from 'src/components/DataGrid';

import s from './DataGrid.module.scss';
import { TDataGridProps } from './DataGrid.types';

export const DataGrid = function <T>({
  activeFilter,
  allItemsCount,
  className,
  data,
  filters,
  headerTitle,
  itemsPerPage,
  pageNumber,
  searchValue,
  tableColumns,
  tagsFilters,
  selectedTagsFilters,
  contextMenu,
  dataFetch,
  gridItemRenderFn,
  onFilterChange,
  setPageNumber,
  setSearchValue,
  tableItemRenderFn,
  setSelectedTagsFilters,
  sortHandler,
}: TDataGridProps<T>): JSX.Element {
  const [displayType, setDisplayType] = useState<EDataGridDisplayType>(EDataGridDisplayType.Grid);

  const prevPage = () => setPageNumber(Math.max(0, pageNumber - 1));
  const nextPage = () => data?.length === itemsPerPage && setPageNumber(pageNumber + 1);

  const updateGridData = useCallback(() => {
    dataFetch();
  }, [dataFetch]);

  const getGridData = useCallback(() => {
    if (displayType !== EDataGridDisplayType.Grid) return;
    return data.map((item) => gridItemRenderFn(item, s.gridItem));
  }, [data, displayType, gridItemRenderFn]);

  const getTableData = useCallback(() => {
    if (displayType !== EDataGridDisplayType.List) return;

    return (
      <table className={classNames(s.table)}>
        <thead>
          <tr>
            {tableColumns.map((item, i) => (
              <th key={i}>
                {item.name}
                {item?.sort && (
                  <BaseIcon
                    className={classNames(s.sortIcon, s[item.sort])}
                    icon="chevron-down"
                    size={10}
                    onClick={() => {
                      const { name, sort } = item;
                      sortHandler?.(name, sort as string);
                    }}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{data.map((item) => tableItemRenderFn(item, s.tableDataRow))}</tbody>
      </table>
    );
  }, [data, displayType, tableColumns, tableItemRenderFn, sortHandler]);

  useEffect(() => {
    updateGridData();
  }, [updateGridData]);

  return (
    <div className={classNames(s.container, className)}>
      <div className={s.main}>
        <header>
          <h2>{headerTitle}</h2>
          <BaseInput
            type="text"
            name="search"
            theme="filled-gray"
            placeholder="Search creator"
            iconLeft="search"
            style={{ width: 360 }}
            value={searchValue}
            onChange={({ value }) => {
              setPageNumber(0);
              setSearchValue(`${value}`);
            }}
          />
          <nav>
            {filters.map((item, i) => (
              <button
                key={i}
                type="button"
                className={classNames({
                  [s.active]: activeFilter === item.value,
                })}
                onClick={() => onFilterChange(item.value)}>
                {item.name}
              </button>
            ))}
          </nav>
          <div className={s.actions}>
            <div className={s.tags}>
              {tagsFilters?.map((item) => (
                <BaseButtonSquare
                  key={item.id}
                  icon={item.icon}
                  theme={selectedTagsFilters?.includes(item.id) ? 'flat-dark' : 'flat-gray'}
                  onClick={() => {
                    if (!selectedTagsFilters || !setSelectedTagsFilters) {
                      throw new Error(
                        'If you want use tagsFilters you need to include selectedTagsFilters and setSelectedTagsFilters',
                      );
                    }
                    if (selectedTagsFilters.includes(item.id)) {
                      setSelectedTagsFilters(selectedTagsFilters.filter((tag) => tag !== item.id));
                    } else {
                      setSelectedTagsFilters([...selectedTagsFilters, item.id]);
                    }
                  }}
                />
              ))}
            </div>
            <BaseSwitch
              theme="gray"
              variant="horizontal-big"
              name="gridDisplay"
              value={displayType}
              onChange={({ value }) => {
                setDisplayType(value as EDataGridDisplayType);
              }}
              options={[
                {
                  value: EDataGridDisplayType.Grid,
                  icon: 'grid',
                  iconSize: 12,
                },
                {
                  value: EDataGridDisplayType.List,
                  icon: 'list',
                  iconSize: 12,
                },
              ]}
            />
          </div>
        </header>
        <div className={s.dataGrid}>
          {!data && 'Loading...'}
          {data && displayType === EDataGridDisplayType.Grid && getGridData()}
          {data && displayType === EDataGridDisplayType.List && getTableData()}
          {contextMenu?.isOpen && (
            <ContextMenu
              relativeElement={contextMenu.ref}
              modalId={contextMenu.id}
              position={{
                side: 'bottom',
                anchorPosition: 50,
              }}
              items={contextMenu.items}
            />
          )}
        </div>
        <footer>
          <span>
            {pageNumber + 1} {allItemsCount && `of ${Math.ceil(allItemsCount / itemsPerPage)}`}
          </span>
          <button title="Prev Page" type="button" onClick={prevPage}>
            <BaseIcon icon="arrow" size={12} />
          </button>
          <button title="Next Page" type="button" onClick={nextPage}>
            <BaseIcon icon="arrow" size={12} />
          </button>
        </footer>
      </div>
    </div>
  );
};
