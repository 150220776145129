import { TAssetsSaveProps } from 'src/components/FormAssetsParams';
import { TLibrary } from 'src/components/LibraryChooser';
import {
  CreateLibraryMutation,
  DeleteLibraryMutation,
  DuplicateLibraryMutation,
  LibraryOutput,
  UpdateLibraryMutation,
  useCreateLibraryMutation,
  useDeleteLibraryMutation,
  useDuplicateLibraryMutation,
  useUpdateLibraryMutation,
} from 'src/graphql';
import { TConfigurationCharacteristic } from 'src/typings/configuration.types';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { TAreaInput, prepareAreaInput } from 'src/utils/mutationsGates';
import { v4 } from 'uuid';

export type TLibraryHelpers = {
  createLibrary(data: { name: string }): Promise<CreateLibraryMutation['createLibrary'] | null>;
  updateLibrary(data: {
    library: LibraryOutput;
    newAssetData: TLibrary;
    data: TAssetsSaveProps;
    tarrgetUuid?: string;
    firstAsset?: boolean;
  }): Promise<UpdateLibraryMutation['updateLibrary'] | null>;
  duplicateLibrary(data: {
    uuid: string;
    isPrivate?: boolean;
  }): Promise<DuplicateLibraryMutation['duplicateLibrary'] | null>;
  deleteLibrary(data: { uuid: string }): Promise<DeleteLibraryMutation['deleteLibrary'] | null>;
};

export function useLibraryHelpers(): TLibraryHelpers {
  const [createLibraryMutation] = useCreateLibraryMutation();
  const [updateLibraryMutation] = useUpdateLibraryMutation();
  const [duplicateLibraryMutation] = useDuplicateLibraryMutation();
  const [deleteLibraryMutation] = useDeleteLibraryMutation();

  const createLibrary = async ({ name }) => {
    try {
      const createdLib = await createLibraryMutation({
        variables: {
          name,
        },
      });

      return createdLib.data?.createLibrary || null;
    } catch (err) {
      return null;
    }
  };

  const updateLibrary: TLibraryHelpers['updateLibrary'] = async ({
    library,
    tarrgetUuid,
    newAssetData,
    data,
    firstAsset,
  }) => {
    try {
      const { values } = data;

      if (!library.uuid) return;

      const connectorAssetUuid = tarrgetUuid || library.uuid;

      const destructuredTree = destructureConfigTree(
        library.scenarioData?.representation?.serialized,
        {
          assignNewUuids: false,
          parentUuid: connectorAssetUuid,
          assignGeoTagLocation: values.geoTagLocation,
          considerUsedAssetsNames: undefined,
        },
      );

      const newUuid = v4();

      const savingTreeBranch = {
        ...destructuredTree,
      };

      let areaInput;
      if (!firstAsset) {
        if (!destructuredTree.rootAssetUuid) return;

        if (newAssetData.scenarioData?.representation?.serialized) {
          const destructuredRepresentation = destructureConfigTree(
            newAssetData.scenarioData?.representation?.serialized,
            {
              assignNewUuids: true,
              parentUuid: tarrgetUuid || destructuredTree.rootAssetUuid,
              assignGeoTagLocation: undefined,
              considerUsedAssetsNames: undefined,
              noMarketMarker: true,
            },
          );

          savingTreeBranch.assets = {
            ...savingTreeBranch.assets,
            ...destructuredRepresentation.assets,
          };
          savingTreeBranch.assetsTreeRelations = {
            ...savingTreeBranch.assetsTreeRelations,
            ...destructuredRepresentation.assetsTreeRelations,
          };
          savingTreeBranch.assetsValues = {
            ...savingTreeBranch.assetsValues,
            ...destructuredRepresentation.assetsValues,
          };

          savingTreeBranch.assetsTreeRelations[tarrgetUuid || destructuredTree.rootAssetUuid!].push(
            destructuredRepresentation.rootAssetUuid!,
          );

          savingTreeBranch.assetsValues[destructuredRepresentation.rootAssetUuid!] = values;
        } else {
          savingTreeBranch.assets[newUuid] = {
            parentUuid: destructuredTree.rootAssetUuid,
            type: newAssetData.type,
            uuid: newUuid,
            isCustomPV: newAssetData.isCustomPV,
            // isCustomHouse: newAssetData.isCustomHouse,
          };
          savingTreeBranch.assetsTreeRelations[newUuid] = [];
          savingTreeBranch.assetsTreeRelations[destructuredTree.rootAssetUuid].push(newUuid);
          savingTreeBranch.assetsValues[newUuid] = values;
        }

        areaInput = prepareAreaInput({
          ...savingTreeBranch,
          settingsData: undefined,
          configurationCharacteristic: {} as TConfigurationCharacteristic,
        });
      }

      const result = await updateLibraryMutation({
        variables: {
          uuid: library.uuid,
          scenarioData: {
            representation: firstAsset
              ? {
                  ...(values as TAreaInput),

                  fitToLimit: false,
                  type: data.assetType,
                }
              : areaInput,
          },
        },
      });

      return result.data?.updateLibrary || null;
    } catch (err) {
      return null;
    }
  };

  const duplicateLibrary = async ({ uuid, isPrivate }) => {
    try {
      const duplicatedLib = await duplicateLibraryMutation({
        variables: {
          uuid,
          isPrivate,
        },
      });

      return duplicatedLib.data?.duplicateLibrary || null;
    } catch (err) {
      return null;
    }
  };

  const deleteLibrary = async ({ uuid }) => {
    try {
      const result = await deleteLibraryMutation({
        variables: {
          uuid,
        },
      });
      return result.data?.deleteLibrary || null;
    } catch (error) {
      return null;
    }
  };

  return {
    createLibrary,
    updateLibrary,
    duplicateLibrary,
    deleteLibrary,
  };
}
