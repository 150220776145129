import React from 'react';

import { useSelector } from 'react-redux';
import { EModalAssetsManagerView, ModalAssetsManager } from 'src/components/ModalAssetsManager';
import { selectModalCommunitySummaryView } from 'src/redux/application/application.selectors';
import {
  initialApplicationState,
  setModalCommunitySummaryView,
} from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';

import { TModalCommunitySummaryProps } from './ModalCommunitySummary.types';


export const ModalCommunitySummary: React.FC<TModalCommunitySummaryProps> = ({ ...rest }) => {
  const dispatch = useAppDispatch();
  const modalCommunitySummaryView = useSelector(selectModalCommunitySummaryView);
  return (
    <ModalAssetsManager
      summaryIcon="market"
      view={modalCommunitySummaryView}
      viewOnDestroy={initialApplicationState.modalCommunitySummaryView}
      disallowReturnToSummary={() =>
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.SaveOrSettings))
      }
      onViewChange={(v) => {
        dispatch(setModalCommunitySummaryView(v));
      }}
      {...rest}
    />
  );
};
