import React, { useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import { uploadedFilesCache } from 'src/cache/uploadedFiles';
import { BaseButton } from 'src/components/BaseButton';
import { TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import { BaseFileUpload } from 'src/components/BaseFileUpload';
import { BaseInput } from 'src/components/BaseInput';
import { BaseTextarea } from 'src/components/BaseTextarea';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { GridMarketModalHeader } from 'src/components/MapSidebar/components/GridMarket/GridMarketModalHeader';
import { useSendCommunityNotificationMutation } from 'src/graphql';
import {
  selectActiveConfigurationUuid,
  selectReadOnly,
} from 'src/redux/configuration/configuration.selectors';
import { selectCommunityMembers } from 'src/redux/scm/scm.selectors';
import { TValidationObject, rules, validateFields } from 'src/utils/fieldValidation';

import s from './MessagingPortalForm.module.scss';
import { TFormMessagingPortalProps } from './MessagingPortalForm.types';

type FormData = {
  selectedMemberNames: { value: string; label: string }[];
  title: string;
  message: string;
  file?: File;
};

export const MessagingPortalForm: React.FC<TFormMessagingPortalProps> = ({
  onFormSubmit,
  id,
  className,
}) => {
  const { t } = useTranslation();

  const readOnly = useSelector(selectReadOnly);
  const [isError, setIsError] = useState(true);
  const FILESIZE = 5;
  const scmMembers = useSelector(selectCommunityMembers);
  const [SendCommunityNotificationMutation] = useSendCommunityNotificationMutation();
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const animatedComponents = makeAnimated();
  const scmMembersData = useMemo(() => {
    return scmMembers.map((member) => ({
      value: member.name,
      label: member.email,
    }));
  }, [scmMembers]);
  const initFormData: FormData = {
    selectedMemberNames: [],
    title: '',
    message: '',
  };

  const sendCommunityNotification = async (configUuid) => {
    const selectedMembers = formData.selectedMemberNames.map((option) => option.value);

    SendCommunityNotificationMutation({
      variables: {
        configUuid: configUuid,
        message: formData.message,
        selectedMemberNames: selectedMembers,
        title: formData.title,
        uploadedFiles: formData.file
          ? [{ file: uploadedFilesCache.get(formData.file), name: formData.file.name }]
          : [],
      },
    }).then(() => {
      onFormSubmit(true);
    });
  };

  const handleChange = ({ value, name }) => {
    if (typeof value !== 'string' && value.name === undefined) return;

    setFormData((curr) => ({
      ...curr,
      [name]: value,
    }));
  };

  const validators = () => ({
    title: [...((n) => [rules.required(n)])('Title')],
    message: [...((n) => [rules.required(n)])('Message')],
    file: [...((n) => [rules.maxFileSize(n, FILESIZE)])('File')],
  });

  const [formData, setFormData] = useState(initFormData);
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  const colourStyles: StylesConfig = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: '#efeff1',
      border: state.hasValue ? 'none' : 'none',
      borderColor: state.isFocused ? '#6e6' : state.hasValue ? 'tranparent' : 'tranparent',
      borderRadius: '4px',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? '#efeff1'
          : isFocused
          ? '#efeff1'
          : undefined,
        color: isDisabled ? '#6e6' : isSelected ? '#6e6' : undefined,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'white',
      };
    },
  };

  const containerRef = useRef<HTMLFormElement>(null);

  const handleChangeSelect = (selectedOption) => {
    setFormData((curr) => ({
      ...curr,
      ['selectedMemberNames']: selectedOption,
    }));
  };

  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name, id } = e.target;
    const targetName = name || id;
    const { errors } = validateFields({
      validators: {
        [targetName]: validationsArr,
      },
      formData,
    });
    if (errors) {
      setIsError(true);
      if (errors[targetName]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [targetName]: errors[targetName],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[targetName];
      if (isEmpty(newState)) {
        setIsError(false);
      }
      setValidationErrors(newState);
    }
  }

  const handleSubmit = async () => {
    const { errors } = validateFields({
      validators: validators(),
      formData,
    });

    await setValidationErrors((prevState) => ({
      ...prevState,
      ...(errors || {}),
    }));
    if (!isEmpty(validationErrors) || !isEmpty(errors)) {
      setIsError(true);
      return;
    }
    sendCommunityNotification(configUuid);
    setFormData(initFormData);
    setIsError(true);
  };

  return (
    <form
      className={classnames(s.container, className)}
      onSubmit={(e) => {
        e.preventDefault();

        if (readOnly) return;
        handleSubmit();
      }}
      id={id}
      ref={containerRef}>
      <>
        <GridMarketModalHeader formTitle={t('labels.MESSAGE_TO')} />
        <FieldContainer className={classnames(s.inputField)}>
          <Select
            inputId="selectedMemberNames"
            isClearable={false}
            closeMenuOnSelect={false}
            value={formData.selectedMemberNames}
            components={animatedComponents}
            isMulti
            onChange={(val) => handleChangeSelect(val)}
            options={scmMembersData}
            styles={colourStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: '#6e6',
              },
            })}
          />
        </FieldContainer>
      </>
      <GridMarketModalHeader formTitle={t('labels.TITLE')} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseInput
          name={'title'}
          type={'text'}
          theme={'filled-gray'}
          value={formData.title}
          onChange={(val) => handleChange(val)}
          autoComplete="off"
          error={validationErrors.title}
          onBlur={(e) => handleFieldBlur(e, validators().title)}
        />
      </FieldContainer>
      <GridMarketModalHeader formTitle={t('labels.MESSAGE')} />
      <FieldContainer className={classnames(s.inputField)}>
        <BaseTextarea
          name={'message'}
          theme={'filled-gray'}
          inputHeight="10"
          value={formData.message}
          onChange={(val) => handleChange(val)}
          autoComplete="off"
          error={validationErrors.message}
          onBlur={(e) => handleFieldBlur(e, validators().message)}
        />
      </FieldContainer>
      <GridMarketModalHeader formTitle={'Add attachment'} />
      <FieldContainer>
        <BaseFileUpload
          accept=".xlsx, .pdf, .png, .jpg, .csv"
          name="file"
          value={formData.file || ''}
          onChange={(val) => handleChange(val)}
          theme="filled-gray"
          error={validationErrors.file}
          onBlur={(e) => handleFieldBlur(e, validators().file)}
          allowDownload={true}
        />
      </FieldContainer>
      <div className={s.messagingPortalBtn}>
        <BaseButton className={s.termsBtn} type="submit" disabled={isError || readOnly}>
          Send Message
        </BaseButton>
      </div>
    </form>
  );
};
