import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { TSimulationResultData } from 'src/hooks/useMapLoadAfterBeResponse';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { mergeSimulationResults } from 'src/utils/mergeSimulationResults';

export type TGetAppendDataForSimulationResponse = {
  data: TSimulationResultData;
  stateKey: 'simulationResults' | 'communitySimulationResults';
};

export const getAppendDataForSimulation = (
  state: WritableDraft<TConfigurationState>,
  action: PayloadAction<
    NonNullable<
      TConfigurationState['simulationResults'] | TConfigurationState['communitySimulationResults']
    > & { isCommunitySimulationResult?: boolean }
  >,
): TGetAppendDataForSimulationResponse => {
  const { payload: next } = action;

  const stateKey = next.isCommunitySimulationResult
    ? 'communitySimulationResults'
    : 'simulationResults';

  const prev = state[stateKey];

  return {
    data: {
      assetUuid: next.assetUuid,
      assetsInfo: next.assetsInfo,
      bidsOffersTrades: mergeSimulationResults.bidsOffersTrades(
        prev?.bidsOffersTrades,
        next.bidsOffersTrades,
      ),
      cumulativeGridTrades: next.cumulativeGridTrades,
      cumulativeNetEnergyFlow: next.cumulativeNetEnergyFlow,
      cumulativeBills: next.cumulativeBills,
      currentMarket: next.currentMarket,
      tradeProfileAreaThroughput: mergeSimulationResults.tradeProfileAreaThroughput(
        prev?.tradeProfileAreaThroughput,
        next.tradeProfileAreaThroughput,
      ),
      kpi: next.kpi,
      marketSummary: mergeSimulationResults.marketSummary(prev?.marketSummary, next.marketSummary),
      bills: next.bills,
      priceEnergyDay: mergeSimulationResults.priceEnergyDay(
        prev?.priceEnergyDay,
        next.priceEnergyDay,
      ),
      progressInfo: next.progressInfo,
      status: next.status,
      deviceStatistics: mergeSimulationResults.deviceStatistics(
        prev?.deviceStatistics,
        next.deviceStatistics,
      ),
      latestSavingsKpi: next.latestSavingsKpi,
      savingsKpiProfile: mergeSimulationResults.savingsKpiProfile(
        prev?.savingsKpiProfile,
        next.savingsKpiProfile,
      ),
    },
    stateKey,
  };
};
