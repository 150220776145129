import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import vars from 'src/assets/styles/utils/vars.module.scss';
import { BaseBanner } from 'src/components/BaseBanner';
import { BaseSearch } from 'src/components/BaseSearch';
import { LoadingWrapper } from 'src/components/LoadingWrapper';
import { LocationSearch } from 'src/components/LocationSearch';
import {
  EListFilter,
  MapSidebarList,
} from 'src/components/MapSidebar/components/MapSidebarCommunitiesList';
import { useListCommunityInfoQuery } from 'src/graphql';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { EAppMode } from 'src/utils/appMode';

import s from './MyCommunities.module.scss';
import { TMyCommunitiesProps } from './MyCommunities.types';

export const MyCommunities: React.FC<TMyCommunitiesProps> = () => {
  const { loading } = useListCommunityInfoQuery();
  const [searchQuery, setSearchQuery] = useState('');
  const appMode = useSelector(selectAppMode);

  const handleGoClicked = () => {
    // handling goes here TODO remove console.log later
  };

  return (
    <LoadingWrapper loading={loading}>
      {() => (
        <div className={s.container}>
          {appMode === EAppMode.Mobile ? (
            <BaseSearch
              name="communitiesSearch"
              theme="filled-gray"
              value={searchQuery}
              onChange={({ value }) => setSearchQuery(value)}
              label="Search by name"
              iconRight="search"
              iconRightColor={vars['color-black']}
            />
          ) : (
            <LocationSearch
              dropdownClassName={s.locationSearch}
              name="location"
              theme="filled-gray"
              flyAfterSelect
            />
          )}
          <BaseBanner
            iconStart={'atom'}
            textHeading="Bring your simulations closer to reality"
            text="Connects assets, use realtime data and trade"
            cta={{ label: 'Go', handler: handleGoClicked }}
          />
          <MapSidebarList
            mobileListClassName={s.list}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            activeFilter={EListFilter.Operational}
            setActiveFilter={() => null}
          />
        </div>
      )}
    </LoadingWrapper>
  );
};
