import React from 'react';

import { BaseClose } from 'src/components/BaseClose';
import { FRONTEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';


import s from './EventsTooltip.module.scss';
import { EEventStatusType, TEventsTooltipProps } from './EventsTooltip.types';

export const EventsTooltip: React.FC<TEventsTooltipProps> = (props) => {
  const renderContent = (props) => {
    const { isActive, dateTime, onClose, data } = props;
    const isMultiple = data.length > 1;
    const firstItem = data[0];
    const date = UTCMoment.utc(dateTime).format('DD');
    const monthName = UTCMoment.utc(dateTime).format('MMM');
    const dayName = UTCMoment.utc(dateTime).format('ddd');
    const activeTime = UTCMoment.utc(dateTime).format(
      FRONTEND_DATE_FORMATS.TOOLTIP_TIMELINE_PRECISE,
    );
    if (isActive) {
      return (
        <div className={s.active}>
          <BaseClose
            id={'tooltip-close'}
            className={s.closeIcon}
            onClick={() => {
              onClose?.();
            }}
          />
          <div className={s.date}>
            <span className={s.capital}>{dayName}</span> - {monthName} {date}
          </div>
          <div className={s.time}>{activeTime}</div>
          {data.map((item) => (
            <div key={item.title} className={s.titleStatusContainer}>
              <span>{item.title}</span>
              {' - '}
              <span className={s[item.status]}>{EEventStatusType[item.status]}</span>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className={s.inActive}>
          <div className={s.date}>
            {dayName} {activeTime}
          </div>
          {isMultiple ? (
            <div className={s.date}>{String(data.length).padStart(2, '0')} Events</div>
          ) : (
            <>
              <span>{firstItem.title}</span>
              {' - '}
              <span className={s[firstItem.status]}>{EEventStatusType[firstItem.status]}</span>
            </>
          )}
        </div>
      );
    }
  };

  return (
    <div className={s.container}>
      <div className={s.content}>{renderContent(props)}</div>
    </div>
  );
};
