import React from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { TInfoAreaProps } from 'src/components/HomeEnergyInfo/HomeEnergyInfo.types';

import s from './HomeEnergyInfo.module.scss';

export const InfoArea: React.FC<TInfoAreaProps> = ({ label, value, icon }) => {
  return (
    <div className={s.infoAreaWrapper}>
      <div className={s.iconWrapper}>
        <BaseIcon className={s.mainIcon} icon={icon} />
      </div>
      <div className={s.textArea}>
        <span>{label}</span>
        <h5>{value}</h5>
      </div>
    </div>
  );
};
