import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TContextMenuProps } from 'src/components/ContextMenu/ContextMenu.types';

export type TDataGridProps<T> = {
  className?: string;
  activeFilter: string | undefined;
  allItemsCount?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  filters: { name: string; value: string | undefined }[];
  headerTitle: string;
  itemsPerPage: number;
  pageNumber: number;
  searchValue: string;
  tableColumns: { name: string; sort: string | null }[];
  tagsFilters?: { id: string; name: string; icon: TIconNames; value: string | string[] }[];
  selectedTagsFilters?: string[];
  contextMenu?: {
    isOpen: boolean;
    ref: React.RefObject<HTMLElement | null>;
    items: TContextMenuProps['items'];
    id;
  };
  dataFetch(): void;
  gridItemRenderFn(data: T, className: string): JSX.Element;
  onFilterChange(val: string | undefined): void;
  setPageNumber(val: number): void;
  setSearchValue(val: string): void;
  tableItemRenderFn(data: T, className: string): JSX.Element;
  setSelectedTagsFilters?(val: string[]): void;
  sortHandler?(col: string, val: string): void;
};

export enum EDataGridDisplayType {
  Grid = 'GRID',
  List = 'LIST',
}
