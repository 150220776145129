// TODO: REFACTOR: Remove this file and use 'src/constants/colorPalette.ts'

import vars from 'src/assets/styles/utils/vars.module.scss';
import { ESavingType } from 'src/components/ChartSavings/ChartSavings.types';

export const ChartRoundedDoughnutColors = {
  resultColor: {
    sc: ['#FC1355', '#1641F7', '#5DE273'],
    ss: ['#FC1355', '#1A48ED'],
  },
  labelColor: '#34363f',
  emptyLineColor: '#D1CFCF',
};

export const ChartPriceColors = {
  averageLine: '#9B51E0',
  minLine: '#1641F7',
  maxLine: '#FC1355',
  gridFeeLine: '#66EE66',
  gridFeeBackgroundGradient: ['rgba(102, 238, 102, 0.37)', 'rgba(102, 238, 102, 0)'],
};

export const ChartDayProfileColors = {
  volumeLine: '#FC1355',
  tradesLine: '#1641F7',
  pricesLine: '#66EE66',
  gridFeeLine: '#80B0F8',
};

export const ChartEnergyBillsAndNetTradedConfig = {
  fontSize: 11,
  textColor: '#FBF9F9',
  fillGradientColors: ['#66EE66', '#1641F7', '#FC1355'],
  colors: {
    green: '#66EE66',
    red: '#FC1355',
    blue: '#1641F7',
  },
};

export const ChartBidsAndOffersColors = {
  tradesColor: '#FC1355',
  bidsColor: '#1641F7',
  offersColor: '#66EE66',
  housesColors: ['#1641F7', '#66EE66', '#FC1355', '#80B0F8', '#9B51E0'],
};

export const ChartSavingsColors = {
  [ESavingType.Earning]: vars['color-ace-blue'],
  [ESavingType.Neutral]: vars['color-metal-grey'],
  [ESavingType.Saving]: vars['color-oh-so-green'],
  [ESavingType.Losses]: vars['color-soft-singularity-red'],
};
