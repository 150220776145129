import React, { RefObject, useEffect, useRef, useState } from 'react';


import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { ModalProfilePictureCropper } from 'src/components/ModalProfilePictureCropper';
import { ProfilePictureIcon } from 'src/components/UserAvatar';
import { EPredefinedModalIds } from 'src/constants/modals';
import { selectTokenAuth } from 'src/redux/auth/auth.selectors';
import { setTokenAuth } from 'src/redux/auth/auth.slice';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { v4 } from 'uuid';

import { useUpdateUserMutation } from '../../graphql';
import s from './ProfilePicture.module.scss';
import { TProfilePictureProps } from './ProfilePicture.types';

export const ProfilePicture: React.FC<TProfilePictureProps> = ({
  changePicture,
  setForceRenderKey,
}: TProfilePictureProps) => {
  const inputFile = useRef() as RefObject<HTMLInputElement>;
  const [image, setImage] = useState<File>();
  const [picture, setPicture] = useState<File>();
  const [updateUserMutation, { loading }] = useUpdateUserMutation();
  const userInfo = useSelector(selectTokenAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      setImage(undefined);
      setPicture(undefined);
    };
  }, []);

  const handleImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file;
    if (e && (e.target as HTMLInputElement).files && (e.target as HTMLInputElement).files?.length) {
      file = (e.target as HTMLInputElement).files;
      if (file[0]) {
        setImage(file[0]);
        dispatch(openModal(EPredefinedModalIds.MODAL_PROFILE_PICTURE_CROPPER));
      }
    }
  };

  async function handleSubmit(file) {
    if (userInfo?.username) {
      try {
        const { data } = await updateUserMutation({
          variables: {
            profilePicture: file,
          },
        });
        dispatch(
          setTokenAuth({
            ...userInfo,
            profilePicture: data?.updateUser?.profilePicture,
          }),
        );
      } catch (err) {}
    } else {
      changePicture?.({ name: 'picture', value: file });
    }
    setPicture(file);
    dispatch(closeModal(EPredefinedModalIds.MODAL_PROFILE_PICTURE_CROPPER));
    if (setForceRenderKey) setForceRenderKey(v4());
  }

  const showPicture = () => {
    return (
      <div className={s.profileIcon}>
        <input
          type="file"
          onChange={handleImageSelected}
          //be able to select same file tow times in a row
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
            // (e.target as HTMLInputElement).files = null;
          }}
          ref={inputFile}
          className={s.input}
          id="picture-input"
          accept="image/*"
        />
        <label htmlFor="picture-input">
          {
            //if new picture is selected
            picture ? (
              <img src={URL.createObjectURL(picture)} className={s.picture} />
            ) : //if user is logedin and has already picture
            userInfo ? (
              <ProfilePictureIcon
                avatarUrl={userInfo.profilePicture}
                name={userInfo.name}
                username={userInfo.username}
                className={{ picture: s.picture, nameInitials: s.nameInitials }}
              />
            ) : (
              //if we are on FormSignup, show person icon
              <BaseIcon className={s.icon} icon="person" size={50} />
            )
          }
        </label>

        {
          // if a picture is selected
          picture || userInfo?.profilePicture ? (
            <BaseIcon
              className={classNames(s.additionalIcon, s.closeIcon)}
              icon="close"
              size={15}
              onClick={() => handleSubmit(null)}
            />
          ) : // user is logined
          userInfo ? (
            <BaseIcon icon="plus" size={15} className={classNames(s.additionalIcon, s.plusIcon)} />
          ) : (
            //on signUp form
            <BaseIcon
              icon="plus"
              size={15}
              className={classNames(s.additionalIcon, s.plusIcon, s.plusPersonIcon)}
            />
          )
        }
      </div>
    );
  };
  return (
    <>
      {userInfo?.username ? (
        showPicture()
      ) : (
        <div className={s.profilePicture}>
          {showPicture()}
          <div className={s.signUpSection}>
            <p>
              Profile picture <span className={s.notMandatory}>(not mandatory)</span>
            </p>
            <br />
            <BaseButton
              icon="plus-circle"
              size="small"
              theme="quaternary"
              onClick={() => {
                inputFile?.current?.click();
              }}>
              Upload photo
            </BaseButton>
          </div>
        </div>
      )}
      {image && (
        <ModalProfilePictureCropper
          originalFile={image}
          isSubmiting={loading}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};
