import React from 'react';

import classnames from 'classnames';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';

import s from './FieldContainer.module.scss';
import { TFieldContainerProps } from './FieldContainer.types';

export const FieldContainer: React.FC<TFieldContainerProps> = ({
  children,
  inlineAlign,
  visibility = 'visible' as const,
  className,
  showTooltip,
  tooltipText,
}) => {
  const Field = (
    <div
      className={classnames(s.container, className, {
        [s.inline]: inlineAlign,
        [s[`inline-${inlineAlign}`]]: inlineAlign,
        [`--visibility` as string]: visibility,
      })}>
      {children}
    </div>
  );

  if (showTooltip && tooltipText) {
    return (
      <BaseTooltipTrigger tooltipChildren={tooltipText} position="top" offset={0}>
        {Field}
      </BaseTooltipTrigger>
    );
  }

  return Field;
};
