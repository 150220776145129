import React from 'react';

import { Line, LineChart } from 'recharts';
import { ChartBrushWrapper } from 'src/components/ChartBrushWrapper';
import { TPriceGraphBrushProps } from 'src/components/ChartEnergyProfile/ChartEnergyProfile.types';

export const ChartEnergyProfileBrush: React.FC<TPriceGraphBrushProps> = ({
  startIndex,
  endIndex,
  data,
  setGraphIndexes,
}) => (
  <ChartBrushWrapper
    data={data}
    setGraphIndexes={setGraphIndexes}
    startIndex={startIndex}
    endIndex={endIndex}>
    <LineChart
      width={500}
      height={20}
      data={data}
      margin={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}>
      <Line
        type="linear"
        isAnimationActive={false}
        dataKey="consumption"
        stroke="#A0A0AC"
        dot={false}
      />
      <Line
        type="linear"
        isAnimationActive={false}
        dataKey="generation"
        stroke="#A0A0AC"
        dot={false}
      />
      <Line type="linear" isAnimationActive={false} dataKey="sold" stroke="#A0A0AC" dot={false} />
      {/*        <Line type="monotone" xAxisId={1} dataKey="onemonth" stroke="#A0A0AC" dot={false} />*/}
    </LineChart>
  </ChartBrushWrapper>
);
