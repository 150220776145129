import { TAssetType } from 'src/typings/base-types';
import { TUsedNames } from 'src/typings/configuration.types';
import { TAllFieldNames } from 'src/utils/assetsFields/assetsFields.types';
import { TFieldsUnionWithValue, TUpdateFields } from 'src/utils/assetsFields/assetsFields.types';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { TFieldValuesByName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { findField } from 'src/utils/fieldUtils';
import { rules, TValidators, TValidationObject } from 'src/utils/fieldValidation';
/*
    Validation rules for all types of nodes.
    If given field is absent then it's always valid.
    Validation rules are objects with the following structure:
    {
      r: - validation function
      m: - error message
    }
  */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const nameValidator = (name: string, usedAssetsNames: TUsedNames, currentValueName?: string) => [
  rules.required(name),
  rules.noForwardSlash(name),
  rules.isNameUnique(usedAssetsNames, currentValueName || ''),
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buyingRateValidator = (name: string, fields: TFieldsUnionWithValue[]) => [
  rules.required(name),
  rules.float(name),
  rules.range(name, 0, 10000),
  {
    r: ({ newValue }) => {
      const energyRateField = findField(fields, 'energyRate');
      const energyRateProfileField = findField(fields, 'energyRateProfile');
      if (
        !energyRateField ||
        energyRateProfileField ||
        !newValue ||
        typeof energyRateField.value !== 'number'
      ) {
        return true;
      }
      return newValue <= energyRateField.value;
    },
    m: `${name} has to be lower than or equal to selling rate.`,
  } as TValidationObject<TFieldValuesByName<'InfiniteBus'>['energyBuyRate']>,
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rangeValidator = (name: string, min: number, max: number) => [
  ...((n) => [rules.required(n), rules.float(n), rules.range(n, min, max)])(name),
];

type TPayload = Pick<TUpdateFields, 'fields'> & {
  usedAssetsNames: TUsedNames;
  currentValues?: TValuesByFieldName;
};

export const assetTypeToValidator: {
  [assetType in Extract<
    TAssetType,
    'Area' | 'Load' | 'PV' | 'ScmStorage' | 'ScmHeatPump' | 'InfiniteBus'
  >]: (payload: TPayload) => TValidators<TAllFieldNames>;
} = {
  Area: ({ usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    geoTagLocation: [rules.isGeoTagSet('Location')],
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  Load: ({ usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    geoTagLocation: [rules.isGeoTagSet('Location')],
    dailyLoadProfile: [rules.required('Profile')],
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  PV: ({ usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    capacityKw: rangeValidator('Capacity', 0, 100000000),
    geoTagLocation: [rules.isGeoTagSet('Location')],
    powerProfile: [rules.required('Profile')],
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ScmStorage: ({ usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    geoTagLocation: [rules.isGeoTagSet('Location')],
    prosumptionKwhProfile: [rules.required('Profile')],
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ScmHeatPump: ({ usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    geoTagLocation: [rules.isGeoTagSet('Location')],
    consumptionKwhProfile: [rules.required('Profile')],
  }),
  InfiniteBus: ({ fields, usedAssetsNames, currentValues }) => ({
    name: nameValidator('Name', usedAssetsNames, currentValues?.name),
    energyRate: rangeValidator('Market maker rate', 0, 10000),
    geoTagLocation: [rules.isGeoTagSet('Location')],
    energyBuyRate: [
      ...((n) => [
        rules.required(n),
        rules.float(n),
        rules.range(n, 0, 10000),
        {
          r: ({ newValue }) => {
            const energyRateField = findField(fields, 'energyRate');
            const energyRateProfileField = findField(fields, 'energyRateProfile');
            if (
              !energyRateField ||
              energyRateProfileField ||
              !newValue ||
              typeof energyRateField.value !== 'number'
            ) {
              return true;
            }
            return newValue <= energyRateField.value;
          },
          m: `${n} has to be lower than or equal to selling rate.`,
        } as TValidationObject<TFieldValuesByName<'InfiniteBus'>['energyBuyRate']>,
      ])('Buying rate'),
    ],
  }),
};
