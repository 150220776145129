import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectScreenMode } from 'src/redux/application/application.selectors';
import { EScreenMode } from 'src/typings/configuration.types';

import { EModalSize } from '../../constants/modals';
import { BaseGenericModal } from '../BaseGenericModal';
import s from './BaseOverflowMenu.module.scss';
import { TBaseOverflowMenuProps } from './BaseOverflowMenu.types';

export const BaseOverflowMenu: React.FC<TBaseOverflowMenuProps> = ({
  children,
  relativeElement,
  modalId,
  position,
  size = EModalSize.S115,
  className,
  singleActionModal = true,
  hideMobile = false,
}) => {
  const screenMode = useSelector(selectScreenMode);

  if (screenMode === EScreenMode.Mobile && hideMobile) return null;

  return (
    <BaseGenericModal
      modalId={modalId}
      size={size}
      relativeElement={relativeElement}
      position={position}
      theme="dark"
      anchorArrow
      singleActionModal={singleActionModal}
      className={className}>
      <div className={classnames(s.menuContainer, 'gradient-dark')}>{children}</div>
    </BaseGenericModal>
  );
};
