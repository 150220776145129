import React from 'react';

import classNames from 'classnames';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';

import s from '../ModalAssetsManager.module.scss';

type AddToLibraryFieldContainerProps = {
  children?: React.ReactNode;
};
export const AddToLibraryFieldContainer: React.FC<AddToLibraryFieldContainerProps> = ({
  children,
}) => <FieldContainer className={classNames(s.inputField, s.paddingT)}>{children}</FieldContainer>;
