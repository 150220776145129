import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectTokenAuth, selectUserRole } from 'src/redux/auth/auth.selectors';
import { selectCommunityUser } from 'src/redux/configuration/configuration.selectors';
import { EUserRoles } from 'src/typings/base-types';

export type TUseIsAdminInspectingResult = {
  isAdminInspecting: boolean;
  isOwnerAdmin: boolean;
  isOwnerCM: boolean;
};

export const useIsAdminInspecting = (): TUseIsAdminInspectingResult => {
  const userRole = useSelector(selectUserRole);
  const communityUser = useSelector(selectCommunityUser);
  const userInfo = useSelector(selectTokenAuth);

  const isAdminInspecting = useMemo(
    () =>
      userRole && userRole === EUserRoles.Admin && communityUser !== userInfo?.email ? true : false,
    [userRole, communityUser, userInfo?.email],
  );

  const isOwnerAdmin = useMemo(
    () => !!(userRole && userRole === EUserRoles.Admin && communityUser === userInfo?.email),
    [userRole, communityUser, userInfo?.email],
  );

  const isOwnerCM = useMemo(
    () =>
      userRole && userRole === EUserRoles.ExchangeOperator && communityUser === userInfo?.email
        ? true
        : false,
    [userRole, communityUser, userInfo?.email],
  );

  return { isAdminInspecting, isOwnerAdmin, isOwnerCM };
};
