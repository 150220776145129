import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { DailyResultsIndicatorSCM } from 'src/components/MapSidebar/components/MapSidebarResults/components/DailyResultsIndicatorSCM';
import { MonthlyResultsIndicatorSCM } from 'src/components/MapSidebar/components/MapSidebarResults/components/MonthlyResultsIndicatorSCM';
import { TCustomResultHeaderProps } from 'src/components/SimulationResultsPresentation/CustomResultHeader/CustomResultHeader.types';
import { HeaderSimulationFlag } from 'src/components/SimulationResultsPresentation/CustomResultHeader/HeaderSimulationFlag';
import { HomeCommunityTab } from 'src/components/SimulationResultsPresentation/CustomResultHeader/HomeCommunityTab';
import { DataAggregationResolution } from 'src/graphql';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import { selectAssetsValues } from 'src/redux/configuration/configuration.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';

import s from './CustomResultHeader.module.scss';

export const CustomResultHeader: React.FC<TCustomResultHeaderProps> = ({
  handleResolutionButton,
  selectedResolution,
  isHomeNameActive,
  isCommunityLabelActive,
  flag = 'Operation',
  homeName,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { zoomIntoConfiguration } = useConfigurationUtils();
  const assetsValues = useSelector(selectAssetsValues);
  const { isUserACommunityMember } = useIsUserACommunityMember();

  const changeTheScmSidebarTab = () => {
    dispatch(setSidebarExpanded(true));
    dispatch(setSelectedAssetUuid(undefined));
    zoomIntoConfiguration({ assetsValues });
  };

  return (
    <div className={s.customResultHeaderWrapper}>
      {isHomeNameActive && (
        <div className={s.homeName} onClick={changeTheScmSidebarTab}>
          <div className={s.iconContainer}>
            <div className={s.borderIcon} />
            <BaseIcon icon="arrow-left-full" size={15} />
          </div>
          <span>{homeName}</span>
        </div>
      )}
      {isCommunityLabelActive && (
        <div className={s.communityName}>
          <span>{t('COMMUNITY_RESULTS')}</span>
        </div>
      )}
      {!isHomeNameActive && !isCommunityLabelActive && <HomeCommunityTab />}
      <div className={s.topRightArea}>
        {selectedResolution === DataAggregationResolution.Monthly && <MonthlyResultsIndicatorSCM />}
        {selectedResolution === DataAggregationResolution.Daily && <DailyResultsIndicatorSCM />}
        <div className={s.dailyMonthlyContainer}>
          <button
            type="button"
            className={classNames(s.text, {
              [s.selected]: selectedResolution === DataAggregationResolution.Monthly,
            })}
            onClick={() => handleResolutionButton(DataAggregationResolution.Monthly)}>
            {t('MONTHLY')}
          </button>
          <button
            type="button"
            className={classNames(s.text, {
              [s.selected]: selectedResolution === DataAggregationResolution.Daily,
            })}
            onClick={() => handleResolutionButton(DataAggregationResolution.Daily)}>
            {t('DAILY')}
          </button>
        </div>
        {flag === 'Operation' && !isUserACommunityMember && (
          <div className={s.liveResultsContainer}>
            <span className={s.text}>{t('OPERATION_RESULTS')}</span>
          </div>
        )}
        {flag === 'Simulation' && (
          <HeaderSimulationFlag
            className={s.simulationResults}
            isIconActive={true}
            title={t('SIMULATION_RESULTS')}
          />
        )}
      </div>
    </div>
  );
};
