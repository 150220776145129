import React from 'react';

import { useTranslation } from 'react-i18next';

import s from './MobileInfoArea.module.scss';

export const MobileInfoArea: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={s.profileMobile} onClick={onClick}>
      <div className={s.profileIconArea}>
        <span>{'☝'}</span>
      </div>
      <div className={s.profileMobileContent}>
        <p>
          {t('ENERGY_SETUP_OF_YOUR_HOME')}
          <br />
          {t('NOTIFY_US_CHANGES')}
        </p>
        <p className={s.greenText}>{t('CLICK_ON_THE_RESULTS_INFO')}</p>
      </div>
    </div>
  );
};
