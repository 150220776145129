import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { MapSidebarCommunitiesHeader } from 'src/components/MapSidebar/components/MapSidebarCommunitiesList/MapSidebarCommunitiesHeader';
import { MapSidebarList } from 'src/components/MapSidebar/components/MapSidebarCommunitiesList/MapSidebarCommunitiesList';
import {
  EListFilter,
  TMapSidebarCommunitiesListProps,
} from 'src/components/MapSidebar/components/MapSidebarCommunitiesList/MapSidebarCommunitiesList.types';
import { selectActiveCommunityListTab } from 'src/redux/scm/scm.selectors';
import { setActiveCommunityListTab } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';

import s from './MapSidebarCommunitiesList.module.scss';

export const MapSidebarCommunitiesListWrapper: React.FC<TMapSidebarCommunitiesListProps> = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const activeCommunitiesListTab = useSelector(selectActiveCommunityListTab);
  const dispatch = useAppDispatch();

  const setActiveCommunityTab = useCallback<(tab: EListFilter) => void>(
    (tab) => {
      dispatch(setActiveCommunityListTab(tab));
    },
    [dispatch],
  );

  return (
    <div className={s.container}>
      <MapSidebarCommunitiesHeader />
      <MapSidebarList
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        activeFilter={activeCommunitiesListTab}
        setActiveFilter={setActiveCommunityTab}
      />
    </div>
  );
};
