import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TToast, TToastState } from 'src/redux/toast/toast.types';
import { v4 } from 'uuid';

const initialState: TToastState = {
  toastList: [],
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast(state, action: PayloadAction<Omit<TToast, 'uuid'>>) {
      state.toastList.push({ uuid: v4(), ...action.payload });
    },
    closeToast(state, action: PayloadAction<TToast['uuid']>) {
      const index = state.toastList.findIndex((t) => t.uuid === action.payload);
      state.toastList.splice(index, 1);
    },
  },
});

// Actions
export const { openToast, closeToast } = toastSlice.actions;

// Reducer
export const toastReducer = toastSlice.reducer;
