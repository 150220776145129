import React, { useRef } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseDropdown, TBaseDropdownProps } from 'src/components/BaseDropdown';
import { BaseInput } from 'src/components/BaseInput';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';

import s from './BaseSearch.module.scss';
import { TBaseSearchProps } from './BaseSearch.types';

export const BaseSearch: React.FC<TBaseSearchProps> = function BaseSearch({
  className,
  dropdownClassName,
  dropdownPosition,
  name,
  value,
  onChange,
  onDropdownItemSelect,
  theme = 'filled-gray',
  label = 'Search',
  iconRight,
  iconRightSize,
  iconRightColor,
  iconRightOnClick,
  iconLeft,
  iconLeftSize,
  iconLeftColor,
  options = [],
  withDropdown,
  inputHeight,
  autoComplete = 'off',
  elevateLabel = false,
  disabled,
  dropdownId = '',
  error,
}: TBaseSearchProps) {
  const dispatch = useAppDispatch();
  const dropdownIsOpen = useSelector(selectIsModalOpened(dropdownId));
  const rootElRef = useRef<HTMLDivElement>(null);

  const handleSelect: TBaseDropdownProps['onSelect'] = (value) => {
    if (typeof value === 'string') {
      onDropdownItemSelect?.({ name, value });
    }
  };

  return (
    <div className={classnames(s.searchWrapper, className)} ref={rootElRef}>
      <BaseInput
        theme={theme}
        value={value}
        type="text"
        name={name}
        iconRight={iconRight}
        iconLeft={iconLeft}
        label={label}
        onChange={onChange}
        inputHeight={inputHeight}
        iconLeftColor={iconLeftColor}
        iconRightSize={iconRightSize}
        iconLeftSize={iconLeftSize}
        iconRightColor={iconRightColor}
        iconRightOnClick={iconRightOnClick}
        autoComplete={autoComplete}
        error={error}
        onClick={
          dropdownIsOpen
            ? () => dispatch(closeModal(dropdownId))
            : () => dispatch(openModal(dropdownId))
        }
        elevateLabel={elevateLabel}
        disabled={disabled}
        showValidationIcon={false}
      />
      {withDropdown && dropdownId && (
        <BaseDropdown
          options={options}
          onSelect={handleSelect}
          modalId={dropdownId}
          className={dropdownClassName}
          position={dropdownPosition}
        />
      )}
    </div>
  );
};
