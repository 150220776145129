import { useMemo, useState } from 'react';

import { ELsKey, ls } from 'src/utils/localStorage';

type TAppFlow = {
  appFlow: string | undefined | null;
  isSCMFlow: boolean;
  isPhoenixFlow: boolean;
  updateAppFlow(flow: string): void;
  resetAppFlow(): void;
  resetSCMSteps(): void;
};

export enum APP_FLOW {
  SCM = 'scm',
  PHOENIX = 'phoenix',
}

export const useAppFlow = (): TAppFlow => {
  const [appFlow, setAppFlow] = useState(ls.get(ELsKey.APP_FLOW));
  const isSCMFlow = useMemo(() => appFlow === APP_FLOW.SCM, [appFlow]);
  const isPhoenixFlow = useMemo(() => appFlow === APP_FLOW.PHOENIX || appFlow === null, [appFlow]);

  const updateAppFlow = (flow: string): void => {
    setAppFlow(flow);
    ls.set(ELsKey.APP_FLOW, flow);
  };

  const resetAppFlow = (): void => {
    ls.remove(ELsKey.APP_FLOW);
  };

  const resetSCMSteps = (): void => {
    ls.set(ELsKey.SCM_STEP, '0');
  };

  return { appFlow, isSCMFlow, isPhoenixFlow, updateAppFlow, resetAppFlow, resetSCMSteps };
};
