import React from 'react';

import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseSelect } from 'src/components/BaseSelect';
import { UserSimpleOutput, useSetUserRoleMutation } from 'src/graphql';
import { selectAvailableUserRoles } from 'src/redux/application/application.selectors';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { getFrontendUserRoleName } from 'src/utils/getFrontendUserRoleName';
import { FRONTEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

export const DashboardUsersTableItem: React.FC<{
  data: UserSimpleOutput;
  className: string;
  updateGridData(): void;
  onClick(): void;
}> = ({ data, className, updateGridData, onClick }) => {
  const userRoles = useSelector(selectAvailableUserRoles);
  const dispatch = useAppDispatch();

  const [setUserRole] = useSetUserRoleMutation({
    onCompleted: () => {
      dispatch(
        openToast({
          message: 'User Role Updated',
          type: 'success',
        }),
      );
    },
    onError: (err) => {
      const msg = JSON.parse(err.message).set_user_role;
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });

  const getUserRoleNameId = (name: string) => {
    return userRoles.find((item) => item.roleName === getFrontendUserRoleName(name))?.value;
  };

  const updateUserRole = ({ value }) => {
    if (data.email && data.username) {
      setUserRole({
        variables: {
          username: data.username,
          email: data.email,
          role: value,
        },
      });
      updateGridData();
    }
  };

  return (
    <tr className={className}>
      <td onClick={onClick}>
        <div className="details">
          {data.profilePicture ? (
            <div
              className="avatar"
              style={{ backgroundImage: `url(${data.profilePicture})` }}></div>
          ) : (
            <div className="avatar isLetter">{data.email?.slice(0, 1)}</div>
          )}
          <div className="userDetails">
            <h6>{data.email?.split('@')[0]}</h6>
            <p>{data.email}</p>
          </div>
        </div>
      </td>
      <td>
        <BaseSelect
          className="roleSelector"
          theme="filled-white"
          name="userRole"
          value={getUserRoleNameId(data.userRole as string)}
          options={userRoles.map((item) => ({ label: item.roleName, value: item.value }))}
          onChange={updateUserRole}
        />
      </td>
      <td>
        {UTCMoment.fromBackend(data?.joinedDate).format(FRONTEND_DATE_FORMATS.USER_CREATION_DATE)}
      </td>
      <td>
        {data.requestStatus === 'RECEIVED' ? (
          <div className="buildCanary">⚡ Build Canary</div>
        ) : (
          <div>{data.requestStatus?.split('_').join(' ')}</div>
        )}
      </td>
      <td onClick={onClick}>
        <div className="assets">
          <div>
            <div>
              <BaseIcon icon="simulation" size={11} />
              <span>{data.numberOfSimulations}</span>
            </div>
            <span>Simulation</span>
          </div>
          <div>
            <div>
              <BaseIcon icon="canary" size={11} />
              <span>{data.numberOfConfiguredAgents}</span>
            </div>
            <span>Canary Network</span>
          </div>
          <div>
            <div>
              <BaseIcon icon="book" size={11} />
              <span>{data.numberOfLibraries}</span>
            </div>
            <span>Library</span>
          </div>
        </div>
      </td>
      <td>
        <button type="button">
          <BaseIcon icon="more-horizontal" size={12} />
        </button>
      </td>
    </tr>
  );
};
