import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { useAppDispatch } from 'src/redux/store';

import s from './InformationBox.module.scss';
import { TInformationBoxProps } from './InformationBox.types';

export const InformationBox: React.FC<TInformationBoxProps> = ({ onClickAction }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  // Fixed::PH-1160-Admin dashboard is visible to not logged in user
  useEffect(() => {
    if (!isLoggedIn) {
    }
  }, [dispatch, isLoggedIn]);

  return (
    <div className={s.container}>
      <div className={s.InfoContainer}>
        <div>☝️</div>
        <div>
          <p>{t('ENERGY_SETUP_OF_YOUR_HOME')}</p>
          <p>{t('NOTIFY_US_CHANGES')}</p>
          <h3>
            <a onClick={onClickAction}>{t('CLICK_ON_THE_RESULTS_INFO')}</a>
          </h3>
        </div>
      </div>
    </div>
  );
};
