import React, { useEffect } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseClose } from 'src/components/BaseClose';
import { useAppDispatch } from 'src/redux/store';
import { selectToastList } from 'src/redux/toast/toast.selectors';
import { closeToast } from 'src/redux/toast/toast.slice';

import s from './Toast.module.scss';
import { TToastProps } from './Toast.types';



const AUTO_CLOSE_TIMEOUT = 5000;

export const Toast: React.FC<TToastProps> = React.memo(function ({
  children,
  type,
  uuid,
}: TToastProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(closeToast(uuid));
    }, AUTO_CLOSE_TIMEOUT);

    return () => {
      clearTimeout(id);
    };
  }, [dispatch, uuid]);

  return (
    <div className={classnames(s.singleToast, s[`type-${type}`])}>
      <div dangerouslySetInnerHTML={{ __html: children }} />

      <BaseClose className={s.closeBtn} onClick={() => dispatch(closeToast(uuid))} />
    </div>
  );
});

export const ToastList: React.FC = React.memo(function () {
  const toastList = useSelector(selectToastList);

  if (!toastList.length) return null;
  return (
    <ul className={s.list}>
      {toastList.map((toast) => (
        <li className={s.listItem} key={toast.uuid}>
          <Toast type={toast.type} uuid={toast.uuid}>
            {toast.message}
          </Toast>
        </li>
      ))}
    </ul>
  );
});
