import React from 'react';

import { Login } from '../Login';
import s from './DesktopLogin.module.scss';
import { TLoginProps } from './DesktopLogin.types';

export const DesktopLogin: React.FC<TLoginProps> = () => {
  return (
    <div className={s.container}>
      <div className={s.loginContainer}>
        <Login />
      </div>
    </div>
  );
};
