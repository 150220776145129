import React, { useRef } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseClose } from 'src/components/BaseClose';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';

import { FormAddUser, useSignUpFormData } from './components/FormAddUser';
import s from './ModalAddUser.module.scss';


export const ModalAddUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const { formData, handleFieldChange } = useSignUpFormData();

  const isModalDeployOpened = useSelector(selectIsModalOpened(EPredefinedModalIds.MODAL_ADD_USER));

  const closeAddUserModal = () => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_ADD_USER));
  };

  if (!isModalDeployOpened) return null;

  return (
    <>
      <div className={s.background} onClick={closeAddUserModal} />
      <BaseGenericModal
        size={EModalSize.XL550}
        modalId={EPredefinedModalIds.MODAL_ADD_USER}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalAddUser)}>
          <h2 className={s.modalTitle}>Add New User</h2>
          <BaseClose className={s.closeBtn} onClick={closeAddUserModal} />
          <FormAddUser
            className={s.formSignup}
            onSuccess={closeAddUserModal}
            {...{ formData, handleFieldChange }}
          />
        </div>
      </BaseGenericModal>
    </>
  );
};
