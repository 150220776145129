import gridPath from 'src/assets/textures/pv_grid.png';
import gridDirPath from 'src/assets/textures/pv_grid_dir_big_2.png';
import solarPanGridPath from 'src/assets/textures/solar_pan_grid.jpg';
import trianglePath from 'src/assets/textures/triangle.png';
import { TOrientationParam } from 'src/components/CustomPV/components/Orientation';
import { TCustomPVProps } from 'src/components/CustomPV/CustomPV.types';
import {
  AmbientLight,
  DirectionalLight,
  ExtrudeGeometry,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera,
  PlaneGeometry,
  RepeatWrapping,
  Scene,
  Shape,
  Texture,
  Vector2,
  WebGLRenderer,
} from 'src/three';
import { loadTexture, removeChildren, toRad } from 'src/utils/canvasUtils';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const HOUSE_WIDTH = 1;
const HOUSE_HEIGHT = 0.5;
const SOLAR_PAN_WIDTH = HOUSE_WIDTH;
const HALF_HOUSE_WIDTH = HOUSE_WIDTH / 2;

export class OrientationAdvancedController {
  private container: HTMLDivElement; // HTML div for containing 3d context
  private width: number; // Container width
  private height: number; // Container height
  private aspect: number; // Container aspect (w/h)
  private pixelRatio: number; // Device pixel ratio
  private renderer: WebGLRenderer; // Webgl renderer
  private scene: Scene; // Scene
  private camera: PerspectiveCamera; // Camera
  private controls: OrbitControls; // Camera controls
  private houseModel: Object3D; // Object3D for containing house meshes
  private gridDirTexture: Texture; // Grid direction texture
  private triangleTexture: Texture; // Triangle indicator texture
  private solarPanGridTexture: Texture; // Solar pan texture
  private triangleMesh: Mesh | null = null; // Triangle mesh
  private solarPanMat: MeshBasicMaterial | null = null; // Solar pan material
  private houseMat: MeshStandardMaterial | null = null; // House material
  private houseExtrudeSettings = {
    steps: 2,
    depth: HOUSE_WIDTH,
    bevelEnabled: false,
  }; // House extrude setting
  private orientationParam: TOrientationParam; // Orientation props (azimuth & tilt)
  private disposed: boolean; // Flag for indicating disposal state
  private requestID: number | undefined; // id of current tick
  private theme: TCustomPVProps['theme'];
  private dirPathType: 'big' | 'normal';

  constructor(
    container: HTMLDivElement,
    orientationParam: TOrientationParam,
    theme?: TCustomPVProps['theme'],
    dirPathType?: 'big' | 'normal',
  ) {
    this.container = container;
    this.orientationParam = orientationParam;
    this.width = container.offsetWidth;
    this.height = container.offsetHeight;
    this.aspect = this.width / this.height;
    this.pixelRatio = window.devicePixelRatio;
    this.houseModel = new Object3D();
    this.disposed = false;
    this.requestID = undefined;
    this.theme = theme ? theme : 'dark';
    this.dirPathType = dirPathType ? dirPathType : 'normal';
    // Init
    this.init();
  }

  /**
   * Initialize
   */
  async init(): Promise<void> {
    this.rendererSetup();
    this.sceneSetup();
    this.cameraSetup();
    this.lightSetup();
    await this.meshSetup();
    this.eventListenerSetup();

    this.tick();
  }

  /**
   * Setup webgl renderer
   */
  rendererSetup(): void {
    this.renderer = new WebGLRenderer({
      antialias: true,
      powerPreference: 'high-performance',
      alpha: true,
    });

    this.renderer.setPixelRatio(this.pixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(this.theme === 'dark' ? '#34363F' : '#efeff1');

    this.container.appendChild(this.renderer.domElement);
  }

  /**
   * Setup scene
   */
  sceneSetup(): void {
    this.scene = new Scene();
  }

  /**
   * Setup camera
   */
  cameraSetup(): void {
    this.camera = new PerspectiveCamera(25, this.aspect, 0.1, 100);

    this.camera.position.set(-5.5, 5.5, 5.5);
    this.camera.lookAt(0, 0, 0);

    this.controls = new OrbitControls(this.camera, this.container);
    this.controls.enablePan = false;
    this.controls.enableZoom = false;
    this.controls.maxPolarAngle = (Math.PI * 4) / 9;
  }

  /**
   * Setup lights
   */
  lightSetup(): void {
    const light = new AmbientLight(0x404040, 2); // soft white light
    this.scene.add(light);
    const directionalLight1 = new DirectionalLight(0xffffff, 0.5);
    directionalLight1.position.set(0, 1, 1);
    this.scene.add(directionalLight1);
    const directionalLight2 = new DirectionalLight(0xffffff, 0.3);
    directionalLight2.position.set(0.5, 1, 0.5);
    this.scene.add(directionalLight2);
  }

  /**
   * Setup mesh
   */
  async meshSetup(): Promise<void> {
    // Load textures
    try {
      [this.gridDirTexture, this.triangleTexture, this.solarPanGridTexture] = await Promise.all([
        loadTexture(this.dirPathType === 'big' ? gridDirPath : gridPath),
        loadTexture(trianglePath),
        loadTexture(solarPanGridPath),
      ]);
      this.solarPanGridTexture.wrapS = RepeatWrapping;
      this.solarPanGridTexture.wrapT = RepeatWrapping;
      this.solarPanGridTexture.repeat.set(4, 4);
    } catch (error) {
      console.error(error);
    }

    // Add meshes
    this.addHouse();
    this.addGrid();
    this.updateAzimuth();

    this.scene.add(this.houseModel);
  }

  /**
   * Add house mesh
   */
  addHouse(): void {
    removeChildren(this.houseModel);

    // Construct shape
    let { tilt } = this.orientationParam;
    const points = [
      new Vector2(-HALF_HOUSE_WIDTH, HOUSE_HEIGHT),
      new Vector2(-HALF_HOUSE_WIDTH, 0),
      new Vector2(HALF_HOUSE_WIDTH, 0),
      new Vector2(HALF_HOUSE_WIDTH, HOUSE_HEIGHT),
    ];
    if (tilt >= 0 && tilt <= 45) {
      points.push(new Vector2(0, Math.tan(toRad(tilt)) * HALF_HOUSE_WIDTH + HOUSE_HEIGHT));
    } else if (tilt > 45 && tilt < 90) {
      points.push(
        new Vector2(HOUSE_HEIGHT / Math.tan(toRad(tilt)) - HALF_HOUSE_WIDTH, HOUSE_HEIGHT * 2),
      );
    } else {
      tilt = 90;
      points.push(new Vector2(-HALF_HOUSE_WIDTH, HOUSE_HEIGHT * 2));
    }
    const shape = new Shape(points);

    // House
    const houseGeo = new ExtrudeGeometry(shape, this.houseExtrudeSettings);
    if (!this.houseMat) {
      this.houseMat = new MeshStandardMaterial({
        color: 0x666666,
        transparent: true,
        opacity: 0.7,
      });
    }
    const houseMesh = new Mesh(houseGeo, this.houseMat);
    houseMesh.position.z -= HALF_HOUSE_WIDTH;
    houseMesh.renderOrder = 2;

    // Solar pan
    const solarPanModel = new Object3D();
    const solarPanHeight = points[0].distanceTo(points[4]);
    const solarPanGeo = new PlaneGeometry(solarPanHeight, SOLAR_PAN_WIDTH);
    if (!this.solarPanMat) {
      this.solarPanMat = new MeshBasicMaterial({
        map: this.solarPanGridTexture,
      });
    }
    const solarPanMesh = new Mesh(solarPanGeo, this.solarPanMat);
    solarPanMesh.position.set(solarPanHeight / 2, 0.001, 0);
    solarPanMesh.rotateX(-Math.PI / 2);
    solarPanModel.add(solarPanMesh);
    solarPanModel.position.set(-HALF_HOUSE_WIDTH, HOUSE_HEIGHT, 0);
    solarPanModel.rotateZ(toRad(tilt));
    solarPanModel.renderOrder = 3;

    // Azimuth indicator
    if (!this.triangleMesh) {
      const triangleGeo = new PlaneGeometry(0.3, 0.3);
      const triangleMat = new MeshBasicMaterial({
        map: this.triangleTexture,
        transparent: true,
        depthWrite: false,
        depthTest: false,
      });
      this.triangleMesh = new Mesh(triangleGeo, triangleMat);
      this.triangleMesh.position.set(-0.7, 0, 0);
      this.triangleMesh.rotateX(-Math.PI / 2);
      this.triangleMesh.rotateZ(-Math.PI / 2);
      this.triangleMesh.renderOrder = 2;
    }

    const subContainer = new Object3D();
    subContainer.add(houseMesh);
    subContainer.add(solarPanModel);

    this.houseModel.add(this.triangleMesh);
    this.houseModel.add(subContainer);
  }

  /**
   * Update azimuth
   */
  updateAzimuth(): void {
    this.houseModel.rotation.y = -toRad(this.orientationParam.azimuth);
  }

  /**
   * Add grid pan
   */
  addGrid(): void {
    const gridGeo = new PlaneGeometry(4, 4);
    const gridMat = new MeshBasicMaterial({
      map: this.gridDirTexture,
      transparent: true,
      opacity: 0.5,
    });
    const gridMesh = new Mesh(gridGeo, gridMat);
    gridMesh.rotateX(-Math.PI / 2);
    gridMesh.rotateZ(Math.PI / 2);
    gridMesh.renderOrder = 1;

    this.scene.add(gridMesh);
  }

  /**
   * Setup event listeners
   */
  eventListenerSetup(): void {
    window.addEventListener('resize', this.onWindowResize, false);
  }

  /**
   * Dispose event listeners
   */
  eventListenerDispose(): void {
    window.removeEventListener('resize', this.onWindowResize, false);
  }

  /**
   * Resize event listener
   */
  onWindowResize = (): void => {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.aspect = this.width / this.height;

    this.renderer.setSize(this.width, this.height);

    this.camera.aspect = this.aspect;
    this.camera.updateProjectionMatrix();
  };

  /**
   * Tick
   */
  tick = (): void => {
    if (this.disposed) {
      if (this.requestID) window.cancelAnimationFrame(this.requestID);

      return;
    }

    this.controls.update();
    this.render();

    this.requestID = window.requestAnimationFrame(this.tick);
  };

  /**
   * Render
   */
  render(): void {
    this.renderer.render(this.scene, this.camera);
  }

  /**
   * Update
   */
  update(orientationParam: TOrientationParam): void {
    const { azimuth, tilt } = orientationParam;
    const { azimuth: prevAzimuth, tilt: prevTilt } = this.orientationParam;

    this.orientationParam = orientationParam;

    if (tilt !== prevTilt) {
      this.addHouse();
    }

    if (azimuth !== prevAzimuth) {
      this.updateAzimuth();
    }
  }

  /**
   * Dispose
   */
  dispose(): void {
    this.disposed = true;
    this.eventListenerDispose();
  }
}
