import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { KeyResultInfoArea } from 'src/components/KeyResultsSCM/components/KeyResultInfoArea';
import { useGetDetails } from 'src/components/KeyResultsSCM/KeyResultsSCMhelpers';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { formatter } from 'src/utils/formatter';

import s from './KeyResultsSCM.module.scss';
import { EViewType, TChartSavingsProps } from './KeyResultsSCM.types';

export const KeyResultsSCM: React.FC<TChartSavingsProps> = ({
  mode,
  currency,
  //kpi,
  homeBillValue,
  //homeBillInfo,
  homeSavings,
  //homeSavingsInfo,
  totalBenefit,
  communitySavings,
  communitySelfSufficiency,
  title = '⚡ Key Results',
  showToggle = false,
  horizontal = false,
}) => {
  const { scmGroupSettingsName } = useSelector(selectSettingsData);

  const currencySymbol = formatter.getCurrencySymbol(currency);
  function normalizePercentage(value = 0) {
    return value.toFixed(2).replace('.', ',');
  }

  const { getDetails } = useGetDetails();

  //  if (mode === 'Home' && isUserACommunityMember) {
  //    return null;
  //  }

  const isVergy = useMemo(() => scmGroupSettingsName === 'VERGY', [scmGroupSettingsName]);

  return (
    <div className={s.container}>
      <div className={s.header}>
        {title.length > 0 && <div className={s.title}>{title}</div>}
        {showToggle && (
          <button type="button" className={s.toggle}>
            <span>Monthly</span>
          </button>
        )}
      </div>
      {mode === 'Community' ? (
        <div>
          <KeyResultInfoArea
            symbol={currencySymbol}
            symbolPosition="left"
            value={normalizePercentage(communitySavings)}
            resultInfo={getDetails(EViewType.CommunitySavings)}
            useInfoHelper={false}
          />
          <KeyResultInfoArea
            symbol="%"
            symbolPosition="right"
            value={normalizePercentage(communitySelfSufficiency)}
            resultInfo={getDetails(EViewType.CommunitySelfSufficiency)}
            useInfoHelper={false}
          />
        </div>
      ) : (
        <div className={horizontal ? s.horizontalWrapper : undefined}>
          <KeyResultInfoArea
            className={horizontal ? s.horizontalBlock : undefined}
            symbol={currencySymbol}
            symbolPosition="left"
            value={normalizePercentage(homeBillValue)}
            resultInfo={getDetails(EViewType.EnergyBill)}
          />
          <KeyResultInfoArea
            className={horizontal ? s.horizontalBlock : undefined}
            symbol={currencySymbol}
            symbolPosition="left"
            value={normalizePercentage(homeSavings)}
            resultInfo={getDetails(EViewType.Savings)}
          />
          {isVergy && (
            <KeyResultInfoArea
              className={horizontal ? s.horizontalBlock : undefined}
              symbol={currencySymbol}
              symbolPosition="left"
              value={normalizePercentage(totalBenefit)}
              resultInfo={getDetails(EViewType.TotalBenefit)}
            />
          )}
          {/*<KeyResultInfoArea
            symbol="%"
            symbolPosition="right"
            value={normalizePercentage(kpi?.self_sufficiency)}
            resultInfo={getDetails(EViewType.SelfSufficiency)}
      />*/}
        </div>
      )}
      {/*<KeyResultProgress
        title="Energy Benchmark"
        percentage={40}
        badges={[
          {
            type: 'House',
            count: 3,
            onClick: () => {
              console.log('asda');
            },
          },
        ]}
      />*/}
    </div>
  );
};
