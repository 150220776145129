import React, { useContext, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { TableBillRow } from 'src/components/_charts/TableBills/TableBillRow';
import {
  TResponseBill,
  TTableBillsProps,
  TTableBillsState,
} from 'src/components/_charts/TableBills/TableBills.types';
import { TableBillsHeader } from 'src/components/_charts/TableBills/TableBillsHeader';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { selectDataResolution, selectSCMBillTableData } from 'src/redux/scm/scm.selectors';
import { setIsAppLoading, setSCMBillTableData } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { KeysToSnakeCase } from 'src/typings/helpers';
import { objectCamelCase } from 'src/utils/objectCamelCase';

import s from './TableBills.module.scss';

export const TableBills: React.FC<TTableBillsProps> = ({ onBillClick }) => {
  const dispatch = useAppDispatch();

  const { mapService } = useContext(WorldMapContext);

  const { flyToAsset, assetFlyConfig } = useFlyTo({ mapService });

  const dataResolution = useSelector(selectDataResolution);
  const bills = useSelector(selectSCMBillTableData);

  const dataResolutionRef = useRef(dataResolution);

  const {
    queries: { getAccumulatedBillsDifferencesCommunity },
    billsDifferencesCommunityData,
    calledInfo: { billsDifferencesCommunityResponseCalled },
  } = useAccumulatedResults({
    initialRunQueries: [],
    mode: 'community',
  });

  useEffect(() => {
    if (!billsDifferencesCommunityResponseCalled || dataResolutionRef.current !== dataResolution) {
      dataResolutionRef.current = dataResolution;
      getAccumulatedBillsDifferencesCommunity();
    }
  }, [
    billsDifferencesCommunityResponseCalled,
    dataResolution,
    getAccumulatedBillsDifferencesCommunity,
  ]);

  //create a useEffect to dispatch the setCommunityKpiDifference
  useEffect(() => {
    if (billsDifferencesCommunityData) {
      const responseObj: Record<
        string,
        { name: string; bills: KeysToSnakeCase<TResponseBill> }
      > = JSON.parse(billsDifferencesCommunityData);

      const tempBills: TTableBillsState['bills'] = [];

      for (const key in responseObj) {
        const partialBill = objectCamelCase<TResponseBill>(responseObj[key].bills);
        tempBills.push({
          ...partialBill,
          assetName: responseObj[key].name,
          uuid: key,
        });
      }

      dispatch(setSCMBillTableData(tempBills));
    }
  }, [billsDifferencesCommunityData, dispatch]);

  const handleBillClick = (uuid: string) => {
    dispatch(setIsAppLoading(true));
    onBillClick(uuid);
    flyToAsset(uuid, assetFlyConfig);
    setTimeout(() => {
      dispatch(setIsAppLoading(false));
    }, 300);
  };

  return (
    <div className={s.scrollableTable}>
      <table className={s.tableBills}>
        <TableBillsHeader />
        <tbody>
          {bills.map((bill, index) => (
            <TableBillRow bill={bill} key={index} onBillClick={handleBillClick} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
