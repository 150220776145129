import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { FormFieldsGenerator, TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import {
  ConfigType,
  ListCommunityInfoDocument,
  ListCommunityInfoQuery,
  SettingsDataFieldsFragment,
  SpotMarketType,
} from 'src/graphql';
import { isLibrary } from 'src/mocks/configurationSettings';
import { selectIsAdmin } from 'src/redux/auth/auth.selectors';
import {
  selectAssetsValues,
  selectCommunityAssetSettings,
  selectConfigType,
  selectConfiguration,
  selectIsCommunityCreatedInDB,
  selectReadOnly,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import { EFormVariant, TFieldValue } from 'src/typings/base-types';
import {
  TCommunitySettingsFields,
  TFieldsUnionWithValue,
  TSettingsData,
} from 'src/utils/assetsFields/assetsFields.types';
import { getAssetValues } from 'src/utils/assetsFields/fieldTemplatesWithValues';
import { findField } from 'src/utils/fieldUtils';
import { validateFields } from 'src/utils/fieldValidation';
import { _DeepNonNullableObject } from 'utility-types/dist/mapped-types';

import { fieldTemplates, relatedFormFields, validators } from './formFields';
import {
  TFieldsVisibility,
  TFormSettingsDataProps,
  TGetVisibleFieldsArgs,
  TSettingsSaveProps,
} from './FormSettingsData.types';

function getVisibleFields({
  fields,
  fieldsVisibility,
  formVariant,
  // isAdmin,
  visibleFieldsInfoFromProp,
  visibleFieldsOrderFromProp,
}: TGetVisibleFieldsArgs) {
  if (!fields) return fields;
  const fieldsUnion = fields.filter((field) => {
    if (visibleFieldsInfoFromProp) {
      return field.name in fieldsVisibility ? fieldsVisibility[field.name] : false;
    }
    const conditionA =
      formVariant === EFormVariant.Express ? field.formView === EFormVariant.Express : true;
    const conditionB = field.name in fieldsVisibility ? fieldsVisibility[field.name] : true;

    return conditionA && conditionB;
  });

  if (visibleFieldsOrderFromProp) {
    const orderedResult: Array<TFieldsUnionWithValue> = visibleFieldsOrderFromProp
      .map((item) => {
        const fieldIndex = fieldsUnion.findIndex((field) => field.name === item);
        if (fieldIndex > -1) {
          return fieldsUnion[fieldIndex];
        }
        return undefined;
      })
      .filter(
        (item: TFieldsUnionWithValue | undefined): item is TFieldsUnionWithValue =>
          item !== undefined,
      );
    return orderedResult;
  }
  return fieldsUnion;
}

export const FormSettingsData: React.FC<TFormSettingsDataProps> = ({
  formVariant = EFormVariant.Advanced,
  //hasErrorsRef,
  onSubmit,
  theme = 'light',
  visibleFieldsInfo,
  visibleFieldsOrder,
  ...formFieldsGeneratorProps
}) => {
  const client = useApolloClient();

  const isAdmin = useSelector(selectIsAdmin);
  const settingsData = useSelector(selectSettingsData) as _DeepNonNullableObject<
    Omit<SettingsDataFieldsFragment, '__typename'>
  >;
  const {
    gridFeeConstant,
    importCapacityKva,
    exportCapacityKva,
    coefficientPercentage,
    baselinePeakEnergyImportKwh,
    baselinePeakEnergyExportKwh,
  } = useSelector(selectCommunityAssetSettings) || {};
  const configuration = useSelector(selectConfiguration);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const assetValues = useSelector(selectAssetsValues);
  const readOnly = useSelector(selectReadOnly);
  const configType = useSelector(selectConfigType);

  const isCanaryNetwork = configType === ConfigType.CanaryNetwork;
  const isSpotMarketLengthDisabled = Object.values(assetValues).some((asset) => {
    return (
      !!asset.powerProfile ||
      asset.isCustomPV ||
      !!asset.dailyLoadProfile ||
      !!asset.consumptionKwhProfile
    );
  });

  const [allFields, setAllFields] = useState(
    fieldTemplates({
      values: {
        ...settingsData,
        name: configuration.name,
        description: configuration.description,
        timezone: configuration.timezone,
        locationVisible: configuration.locationVisible,
        gridFeeEnabled: !!gridFeeConstant,
        gridFeeConstant,
        transformerCapacityEnabled: Boolean(importCapacityKva || exportCapacityKva), // setting as default true as data unavailble from BE
        importCapacityKva,
        exportCapacityKva,
        coefficientPercentage,
        baselinePeakEnergyEnabled: Boolean(
          baselinePeakEnergyImportKwh || baselinePeakEnergyExportKwh,
        ),
        baselinePeakEnergyImportKwh,
        baselinePeakEnergyExportKwh,
      },
      configurationCharacteristic: configuration,
    }),
  );

  const [allFieldsStored, setAllFieldsStored] = useState<TFieldsUnionWithValue[] | undefined>(
    undefined,
  );

  const [errors, setErrors] = useState<TFormFieldsGeneratorProps['errors']>(null);
  const settingsDataMemo = useMemo(() => settingsData, [settingsData]);
  const configurationMemo = useMemo(() => configuration, [configuration]);
  const gridFeeConstantMemo = useMemo(() => gridFeeConstant, [gridFeeConstant]);

  const combineValues = useMemo(
    () =>
      fieldTemplates({
        values: {
          ...settingsDataMemo,
          name: configurationMemo.name,
          description: configurationMemo.description,
          timezone: configurationMemo.timezone,
          locationVisible: configurationMemo.locationVisible,
          gridFeeEnabled: !!gridFeeConstantMemo,
          gridFeeConstant: gridFeeConstantMemo,
          transformerCapacityEnabled: Boolean(importCapacityKva || exportCapacityKva), // setting as default true as data unavailble from BE
          importCapacityKva,
          exportCapacityKva,
          coefficientPercentage,
          baselinePeakEnergyEnabled: Boolean(
            baselinePeakEnergyImportKwh || baselinePeakEnergyExportKwh,
          ),
          baselinePeakEnergyImportKwh,
          baselinePeakEnergyExportKwh,
        },
        configurationCharacteristic: configurationMemo,
      }),
    [
      settingsDataMemo,
      configurationMemo,
      gridFeeConstantMemo,
      importCapacityKva,
      exportCapacityKva,
      coefficientPercentage,
      baselinePeakEnergyImportKwh,
      baselinePeakEnergyExportKwh,
    ],
  );

  useEffect(() => {
    if (allFieldsStored && JSON.stringify(combineValues) !== JSON.stringify(allFieldsStored)) {
      setAllFields(combineValues);
      setAllFieldsStored(combineValues);
    }
  }, [combineValues, allFieldsStored, setAllFields, setAllFieldsStored]);

  useEffect(() => {
    if (!allFieldsStored) {
      setAllFieldsStored(allFields);
    }
  }, [setAllFieldsStored, allFields, allFieldsStored]);

  // If a field is not present in the object it will be visible by default, if it is not from the visibleFieldsInfo prop
  const fieldsVisibility: TFieldsVisibility = visibleFieldsInfo
    ? {
        ...visibleFieldsInfo,
        gridFeeConstant:
          visibleFieldsInfo.gridFeeConstant &&
          Boolean(!isLibrary && findField(allFields, 'gridFeeEnabled')?.value),
      }
    : {
        currency: !isLibrary,
        startEndDate: !isCanaryNetwork && !isLibrary,
        tickLengthSeconds: !isLibrary,
        slotLengthRealtimeSeconds: !isCanaryNetwork && !isLibrary,
        gridFeeEnabled: !isLibrary,
        gridFeeConstant: Boolean(!isLibrary && findField(allFields, 'gridFeeEnabled')?.value),
        locationVisible: !isCanaryNetwork,
        timezone: isCanaryNetwork,
        importCapacityKva: Boolean(findField(allFields, 'transformerCapacityEnabled')?.value),
        exportCapacityKva: Boolean(findField(allFields, 'transformerCapacityEnabled')?.value),
        coefficientPercentage: false,
        baselinePeakEnergyImportKwh: Boolean(
          findField(allFields, 'baselinePeakEnergyEnabled')?.value,
        ),
        baselinePeakEnergyExportKwh: Boolean(
          findField(allFields, 'baselinePeakEnergyEnabled')?.value,
        ),
        bidOfferMatchAlgo:
          findField(allFields, 'spotMarketType')?.value === SpotMarketType.TwoSided,
      };

  const visibleFields = getVisibleFields({
    fields: allFields,
    fieldsVisibility,
    formVariant,
    isAdmin,
    visibleFieldsInfoFromProp: visibleFieldsInfo,
    visibleFieldsOrderFromProp: visibleFieldsOrder,
  });

  const getUsedProjectNames = useCallback((): string[] => {
    const data: ListCommunityInfoQuery | null = client.readQuery({
      query: ListCommunityInfoDocument,
    });
    const projects = data?.listCommunityInfo?.projects || [];
    const output: string[] = [];

    projects.forEach((project) => {
      const name = project?.name;

      if (name) {
        output.push(name);
      }
    });

    return output;
  }, [client]);

  const validateFieldsWrapper = useCallback(
    (fields: TFieldsUnionWithValue[]) => {
      const output = validateFields({
        validators: validators({
          usedProjectNames: getUsedProjectNames(),
          currentProjectName: isCommunityCreatedInDB ? configuration.name : '',
        }),
        fields,
      });

      setErrors(output.errors);
      return output;
    },
    [setErrors, configuration.name, getUsedProjectNames, isCommunityCreatedInDB],
  );

  const handleChange = useCallback(
    ({
      name,
      value,
    }: {
      name: keyof TSettingsData | 'locationVisible' | 'name';
      value: TFieldValue;
    }) => {
      if (!allFields) return;

      const allFieldsNew = [...allFields];
      /*if (
        // fieldToUpdate?.name === 'transformerCapacityEnabled' ||
        fieldToUpdate?.name === 'baselinePeakEnergyEnabled'
      ) {
        allFieldsNew = updateFields({
          type: 'Area',
          fields: allFields,
          updatedField: { name: name as TAllFieldNames, value },
          ...payload,
        });
      }*/

      // We use immer for immutability, cloneDeep is too expensive
      const newFields = produce(allFieldsNew, (draftState) => {
        const fieldToUpdate = draftState.find((f) => f.name === name);

        if (fieldToUpdate) {
          fieldToUpdate.value = value;

          // Update sibling fields
          switch (fieldToUpdate.name) {
            case 'startEndDate':
              const startEndDate = draftState.find((f) => f.name === 'startEndDate');

              if (startEndDate) {
                startEndDate.EXCLUDE = isCanaryNetwork;
              }
              break;
            case 'gridFeeEnabled':
              const gridFeeConstantField = draftState.find((f) => f.name === 'gridFeeConstant');

              if (gridFeeConstantField) {
                gridFeeConstantField.value = 0;
                gridFeeConstantField.EXCLUDE = false;
              }

              break;
            case 'baselinePeakEnergyEnabled':
            case 'transformerCapacityEnabled':
              const fieldEnabled = draftState.find((f) => f.name === fieldToUpdate.name)?.value;
              const relatedFieldsItem = relatedFormFields.find(
                (item) => item.fieldName === fieldToUpdate.name,
              )?.relatedFields;

              if (relatedFieldsItem) {
                const importExport = relatedFieldsItem.map((subitem) =>
                  draftState.find((f) => f.name === subitem),
                );
                importExport.forEach((item) => {
                  if (item) {
                    item.EXCLUDE = !fieldEnabled;
                    item.value = 0;
                  }
                });
              }
              break;
            default:
              break;
          }
        }
      });
      validateFieldsWrapper(newFields);
      setAllFields(newFields);
    },
    [allFields, validateFieldsWrapper, isCanaryNetwork],
  );

  const renderedFields = useMemo(() => {
    switch (formVariant) {
      case EFormVariant.Advanced:
        visibleFields?.map((f) => {
          if (f.name === 'slotLengthMinutes' && !f.disabled) {
            f.disabled = isSpotMarketLengthDisabled;
          }
        });
        return visibleFields?.filter(
          (f) => f.formView === EFormVariant.Advanced || f.formView === EFormVariant.Express,
        );

      default:
        return visibleFields?.filter((f) => f.formView === formVariant);
    }
  }, [visibleFields, formVariant, isSpotMarketLengthDisabled]);

  return (
    <FormFieldsGenerator
      fields={renderedFields}
      errors={errors}
      onChange={handleChange}
      onSubmit={() => {
        const { errors } = validateFieldsWrapper(renderedFields);
        if (errors) return;

        const values = getAssetValues(
          allFields.filter((f) => (f.name in fieldsVisibility ? fieldsVisibility[f.name] : true)),
        ) as TCommunitySettingsFields & TSettingsSaveProps['communityAssetSettings'];

        if (values.startEndDate) {
          values.startDate = values.startEndDate.startDate as string;
          values.endDate = values.startEndDate.endDate as string;
        }

        const {
          name,
          description,
          locationVisible,
          timezone,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          startEndDate,
          gridFeeConstant,
          ...newSettingsData
        } = values;

        onSubmit({
          name,
          description,
          locationVisible,
          timezone: timezone ?? '',
          settingsData: newSettingsData,
          communityAssetSettings: {
            gridFeeConstant: gridFeeConstant,
          },
        });
      }}
      focusField="name"
      theme={theme}
      readOnly={readOnly}
      {...formFieldsGeneratorProps}
    />
  );
};
