import { EMenuColor } from 'src/components/Header/Header.types';

import s from './Header.module.scss';

export const NAV_LINKS = [
  {
    route: 'home',
    title: 'Home',
  },
  {
    route: 'singularityMap',
    title: 'Singularity Map',
  },
  {
    route: 'scm',
    title: 'Community Builder',
  },
  {
    route: 'company',
    title: 'Company',
  },
  {
    route: 'resources',
    title: 'Resources',
  },
];

export const selectBgBlueLandingPage = {
  [EMenuColor.AlternativeOne]: s.blurAlt_1,
  [EMenuColor.AlternativeTwo]: s.blurAlt_2,
};

export const selectColorMenuLandingPage = {
  [EMenuColor.AlternativeOne]: s.menuColorLandingPageAlt_1,
  [EMenuColor.AlternativeTwo]: s.menuColorLandingPageAlt_2,
};
