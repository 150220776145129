import React from 'react';

import classnames from 'classnames';

import s from './BaseList.module.scss';
import { TBaseListProps } from './BaseList.types';

export const BaseList: React.FC<TBaseListProps> = ({ children, className, width = 'auto' }) => (
  <ul
    className={classnames(className, s.list)}
    style={{
      ['--width' as string]: width,
    }}>
    {Array.isArray(children) &&
      children.map((child) => {
        if (!child) return null;

        return (
          <li className={s.item} key={child.key}>
            {child}
          </li>
        );
      })}
  </ul>
);
