import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import s from './AssetCreateNew.module.scss';
import { TAssetCreateNewTabs } from './AssetCreateNew.types';

export const AssetCreateNewTabs: React.FC<TAssetCreateNewTabs> = ({
  activeSettingsTab,
  onTabSelect,
  mode,
}) => {
  const { t } = useTranslation();

  switch (mode) {
    case 'Storage':
      return (
        <button type="button" className={classNames(s.active)}>
          {t('navigation.BATTERY_SETTINGS')}
        </button>
      );
    case 'Load':
      return (
        <button type="button" className={classNames(s.active)}>
          {t('navigation.LOAD_SETTINGS')}
        </button>
      );
    default:
      return (
        <>
          <button
            type="button"
            className={classNames({
              [s.active]: activeSettingsTab === 'basic',
            })}
            onClick={() => onTabSelect('basic')}>
            {t('navigation.BASIC_SETTINGS')}
          </button>
          <button
            type="button"
            className={classNames({
              [s.active]: activeSettingsTab === 'advanced',
            })}
            onClick={() => onTabSelect('advanced')}>
            {t('navigation.ADVANCED_SETTINGS')}
          </button>
        </>
      );
  }
};
