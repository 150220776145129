import React, { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { DashboardAssets } from 'src/components/AdminDashboards/DashboardAssets';
import { DashboardUser } from 'src/components/AdminDashboards/DashboardUser';
import { DashboardUsers } from 'src/components/AdminDashboards/DashboardUsers';
import { selectActiveDashboard } from 'src/redux/application/application.selectors';
import { EDashboard } from 'src/typings/base-types';

import s from './AdminDashboards.module.scss';
import { TAdminDashboardsProps } from './AdminDashboards.types';

export const AdminDashboards: React.FC<TAdminDashboardsProps> = () => {
  const activeDashboard = useSelector(selectActiveDashboard);
  const [selectedItem, setSelectedItem] = useState<string>();
  const [selectedAssetItem, setSelectedAssetItem] = useState<string>();

  const getContainerWidth = useMemo(() => {
    if (activeDashboard === EDashboard.Users && selectedItem) return 400;
    return undefined;
  }, [activeDashboard, selectedItem]);

  if (!activeDashboard) return null;

  return (
    <div className={s.container} style={{ width: getContainerWidth }}>
      {activeDashboard === EDashboard.Libraries && (
        <DashboardAssets
          selectedItem={selectedAssetItem}
          onAssetSelect={(uuid) => setSelectedAssetItem(uuid)}
        />
      )}
      {!selectedItem && activeDashboard === EDashboard.Users && (
        <DashboardUsers onUserSelect={(uuid) => setSelectedItem(uuid)} />
      )}
      {selectedItem && activeDashboard === EDashboard.Users && (
        <DashboardUser
          userName={selectedItem}
          onBackToDashboard={() => setSelectedItem(undefined)}
        />
      )}
    </div>
  );
};
