import { LngLatBoundsLike } from 'mapbox-gl';
import { TAssetsInside } from 'src/components/ModalAssetsManager';
import { BidOfferMatchAlgo, ScmCoefficientAlgorithm, SpotMarketType } from 'src/graphql';
import { TAssetType, TLibrariesTags } from 'src/typings/base-types';
import { TEnumFieldTemplate } from 'src/utils/assetsFields/assetsFields.types';
import { EHeatPumpSourceType } from 'src/utils/assetsFields/valuesByFieldName.types';
import { mergedTimezones } from 'src/utils/mergedTimezones';

export const GRID_MARKET_ASSET_TYPES: Readonly<TAssetType[]> = ['MarketMaker', 'InfiniteBus'];

export const ICON_BY_ASSET_TYPE: Readonly<
  { [key in Exclude<TAssetType, 'InfiniteBus' | 'MarketMaker'>]: TAssetsInside[0]['icon'] }
> = {
  Area: 'house',
  FiniteDieselGenerator: 'factory',
  PV: 'pv',
  Load: 'plug',
  Storage: 'battery',
  ScmStorage: 'battery',
  HeatPump: 'heatpump',
  ScmHeatPump: 'heatpump',
};

export const CLOUD_COVERAGE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  { value: 4, label: 'labels.UPLOAD_PROFILE' },
  { value: 5, label: 'labels.LOCAL_GENERATION_PROFILE' },
];

export const SPOT_MARKET_TYPE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  { value: SpotMarketType.OneSided, label: 'One-sided' },
  { value: SpotMarketType.TwoSided, label: 'Two-sided' },
];

export const BID_OFFER_MATCH_ALGO_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  { value: BidOfferMatchAlgo.PayAsBid, label: 'Pay-as-Bid' },
  { value: BidOfferMatchAlgo.PayAsClear, label: 'Pay-as-Clear' },
  { value: BidOfferMatchAlgo.External, label: 'External matching' },
];

export const SCM_TRADING_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  { value: BidOfferMatchAlgo.PayAsClear, label: 'Community trading' },
  { value: BidOfferMatchAlgo.PayAsBid, label: 'Pay-as-Bid' },
];

export const HEATPUMP_PUMP_SOURCE_TYPE: Readonly<TEnumFieldTemplate['options']> = [
  { value: EHeatPumpSourceType.Air, label: 'Air-To-Water' },
  { value: EHeatPumpSourceType.Ground, label: 'Water-To-Water' },
];

export const CURRENCY_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  { value: 'USD', label: '🇺🇸 US Dollar' },
  { value: 'EUR', label: '🇪🇺 Euro' },
  { value: 'JPY', label: '🇯🇵 Japanese Yen' },
  { value: 'GBP', label: '🇬🇧 Pound Sterling' },
  { value: 'AUD', label: '🇦🇺 Australian Dollar' },
  { value: 'CAD', label: '🇨🇦 Canadian Dollar' },
  { value: 'CHF', label: '🇨🇭 Swiss Franc' },
  { value: 'CNY', label: '🇨🇳 Chinese Renminbi' },
];

export const ZOOM = {
  ADD_ASSET: 4,
  QUIT_COMMUNITY: 6,
};
export const ADD_ASSET_LAT_ADJUST = 0; //-0.003;

export const DEFAULT_COMMUNITY_NAME = 'Phoenix Community';

export const GRID_MARKET_MODE = 'GRID_MARKET_MODE';

export const LIBRARY_FILTERS_MAPPING = {
  All: ['DefaultLib'] as TLibrariesTags[],
  Custom: ['CustomAsset', 'DefaultLib'] as TLibrariesTags[],
  Area: ['Home', 'DefaultLib'] as TLibrariesTags[],
  PV: ['PV', 'DefaultLib'] as TLibrariesTags[],
  Load: ['Load', 'DefaultLib'] as TLibrariesTags[],
  Storage: ['Storage', 'DefaultLib'] as TLibrariesTags[],
  HeatPump: ['HeatPump', 'DefaultLib'] as TLibrariesTags[],
};

export const NET_ENERGY_KEY = 'Net energy';

export const SWITCHER_ICON_SIZES = {
  tick: 16,
  close: 10,
};

export const TIME_RESOLUTION_HRS = 0.25;

export const START_LOCATION_GERMANY: LngLatBoundsLike = [
  [-123.749, 28.613], // Northeast
  [-61.1718, 48.69096], // Southwest
];
export const START_LOCATION: LngLatBoundsLike = [
  [-70, 70],
  [70, -70],
];
export const SIDEBAR_TRANSITION_TIME = 300; // ms

export const MAX_ENERGY_RATE_CHANGE_PER_UPDATE = 1000;

export const PASSWORD_MIN_LENGTH = 8;

export const TIMEZONE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = mergedTimezones;
export const LANGUAGE_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  {
    label: 'English (Default)',
    value: 1,
  },
  {
    label: 'German',
    value: 2,
  },
  {
    label: 'Spanish',
    value: 3,
  },
];

export const SHARING_COEFFICIENT_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  {
    label: 'labels.FIXED_COEFFICIENTS',
    value: ScmCoefficientAlgorithm.Static,
  },
  // {
  //   label: 'Variable Coefficient',
  //   value: ScmCoefficientAlgorithm.Dynamic,
  // },
];

export const GRID_MARKET_OPTIONS: Readonly<TEnumFieldTemplate['options']> = [
  {
    label: 'General Grid Market',
    value: 1,
  },
  {
    label: 'Variable Grid Market',
    value: 2,
  },
  {
    label: 'Positive Grid Market',
    value: 3,
  },
];

export const MOBILE_WIDTH = 768;
