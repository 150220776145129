import React from 'react';

import classNames from 'classnames';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { BaseButton } from 'src/components/BaseButton';
import { TSimulationRunButtonProps } from 'src/components/Header/Header.types';
import { selectSingleNotificationByNotificationPlace } from 'src/redux/notifications/notifications.selectors';
import { ENotificationPlace } from 'src/redux/notifications/notifications.types';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';

import s from './Header.module.scss';


export const SimulationRunButton: React.FC<TSimulationRunButtonProps> = ({
  runButtonState,
  runButtonOptions,
  runPauseBtnRef,
  additionalAction,
}) => {
  const headerLaunchNotification = useSelector(
    selectSingleNotificationByNotificationPlace(ENotificationPlace.HEADER_LAUNCH_BUTTON),
  );

  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);

  return (
    <>
      <div
        id="header-launch-element"
        data-tooltip-html={ReactDOMServer.renderToString(
          <div className={s.tooltipContent}>
            <p>{headerLaunchNotification?.description}</p>
          </div>,
        )}>
        <BaseButton
          className={classNames(s.runPauseBtn, s.ml5)}
          // theme={runButtonState === 'pause' ? 'secondary' : undefined}
          theme={
            isOperationalCommunity
              ? undefined
              : runButtonState === 'pause'
              ? 'grey'
              : runButtonState === 'rerun'
              ? 'quaternary'
              : undefined
          }
          onClick={() => {
            if (runButtonOptions.onClick) {
              runButtonOptions.onClick();
            }
            additionalAction?.();
          }}
          icon={runButtonOptions.icon}
          disabled={runButtonOptions.disabled}
          propRef={(r) => {
            if (r) {
              // @ts-ignore
              runPauseBtnRef.current = r;
            }
          }}>
          {runButtonOptions.title}
        </BaseButton>
      </div>
      <Tooltip
        anchorId="header-launch-element"
        isOpen={headerLaunchNotification !== null}
        place="bottom"
      />
    </>
  );
};
