import React from 'react';

import { BaseIcon } from '../BaseIcon';
import { BaseTooltipTrigger } from '../BaseTooltip';
import s from './InfoHelper.module.scss';
import { TInfoHelperProps } from './InfoHelper.types';

export const InfoHelper: React.FC<TInfoHelperProps> = React.memo(function InfoHelper({
  className,
  info,
  size = 12,
  isPlainText = true,
  position = 'right',
}) {
  const getInfo = () => {
    if (isPlainText) {
      const tmpInfo = info as string;
      const lines = tmpInfo.split('\n');
      const content = lines?.map((line) => <p key={line}>{line}</p>);
      return <div className={s.displayBlock}>{content}</div>;
    }
    return <div className={s.displayBlock}>{info}</div>;
  };

  return (
    <BaseTooltipTrigger
      triggerClassName={className}
      tooltipClassName={s.helperTooltip}
      position={position}
      tooltipChildren={info ? getInfo() : ''}
      wordWrap={true}>
      <BaseIcon icon="info-thick" className={s.icon} size={size} />
    </BaseTooltipTrigger>
  );
});
