import { TSimulationProgressModalDetailsData } from 'src/components/SimulationProgressModal/SimulationProgressModal';
import { TuseSimulationButtons } from 'src/hooks/useSimulationButtons';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';

export type TTimelineEventChartProps = {
  className?: string;
  style?: React.CSSProperties;

  graphWidth: number;
  isSidebarOpened: boolean;
  simulationStatusTitle: string;
  simulationProgress: TConfigurationState['simulationProgress'];
  runButtonState: TuseSimulationButtons['runButtonState'];
  runButtonOptions: TuseSimulationButtons['runButtonOptions'];
  stopButtonOptions: TuseSimulationButtons['stopButtonOptions'];
  detailsData: TSimulationProgressModalDetailsData;
  filter?: number[];
  durationFilter?: string;
  graphStartDate?: string;
  graphEndDate?: string;
};

export enum EEventType {
  added = 'create_area',
  edited = 'update_area',
  removed = 'delete_area',
}
