import React, { useCallback, useMemo, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { TCanaryRegistryAssetTileProps } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import { UserRequestsStatus } from 'src/graphql';
import { useMeasurementQueries } from 'src/hooks/useMeasurementQueries';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectIsOperationalCommunity, selectCommunityMembers } from 'src/redux/scm/scm.selectors';
import { EUserRoles } from 'src/typings/base-types';

import s from './CanaryRegistryList.module.scss';

export const CanaryRegistryAssetTile: React.FC<TCanaryRegistryAssetTileProps> = ({
  asset,
  data,
  onClick,
  selected,
  childrenCount,
  isChildren,
  onMenuClick,
  onExpandClick,
  measurementStatus,
}) => {
  const { t } = useTranslation();

  const userRole = useSelector(selectUserRole);
  const configurationUuid = useSelector(selectActiveConfigurationUuid);
  const scmMembers = useSelector(selectCommunityMembers);
  const modalRefObject = useRef<HTMLDivElement>(null);
  const isOperational = useSelector(selectIsOperationalCommunity);
  const isAdmin = userRole === EUserRoles.Admin;
  const showWifiIcon = useMemo(() => isOperational && !measurementStatus, [
    isOperational,
    measurementStatus,
  ]);

  const showWifiLoadIcon = useMemo(
    () =>
      isOperational &&
      measurementStatus &&
      measurementStatus === UserRequestsStatus.Received &&
      !isAdmin,
    [isOperational, measurementStatus, isAdmin],
  );

  const showAcceptOrRejectFlow = useMemo(
    () =>
      isOperational &&
      measurementStatus &&
      measurementStatus === UserRequestsStatus.Received &&
      isAdmin,
    [isOperational, measurementStatus, isAdmin],
  );

  const showExcecutedIcon = useMemo(
    () => isOperational && measurementStatus && measurementStatus === UserRequestsStatus.Executed,
    [isOperational, measurementStatus],
  );

  const showRejectedIcon = useMemo(
    () => isOperational && measurementStatus && measurementStatus === UserRequestsStatus.Declined,
    [isOperational, measurementStatus],
  );

  const {
    setMeasurementVerificationStatusMutation,
    requestMeasurementVerificationMutation,
    revokeMeasurementVerificationMutation,
  } = useMeasurementQueries({
    sendListRequestAfterMutation: true,
    showNotificationAfterMutation: true,
  });

  const sendRequestMeasurementVerification = useCallback<
    (args: { uuid: string; configurationUuid?: string }) => void
  >(
    ({ uuid, configurationUuid }) => {
      if (!configurationUuid) return;

      requestMeasurementVerificationMutation({
        variables: {
          configUuid: configurationUuid,
          areaUuids: [uuid],
        },
      });
    },
    [requestMeasurementVerificationMutation],
  );

  const setMeasurementApprovalVerificationStatus = useCallback<
    (args: { uuid: string; configurationUuid?: string }) => void
  >(
    ({ uuid, configurationUuid }) => {
      if (!configurationUuid) return;

      setMeasurementVerificationStatusMutation({
        variables: {
          configUuid: configurationUuid,
          acceptedAreaUuids: [uuid],
          rejectedAreaUuids: [],
        },
      });
    },
    [setMeasurementVerificationStatusMutation],
  );

  const revokeMeasurementRequest = useCallback<
    (args: { uuid: string; configurationUuid?: string }) => void
  >(
    ({ uuid, configurationUuid }) => {
      if (!configurationUuid) return;
      revokeMeasurementVerificationMutation({
        variables: {
          configUuid: configurationUuid,
          areaUuids: [uuid],
        },
      });
    },
    [revokeMeasurementVerificationMutation],
  );

  const setMeasurementRejectedVerificationStatus = useCallback<
    (args: { uuid: string; configurationUuid?: string }) => void
  >(
    ({ uuid, configurationUuid }) => {
      if (!configurationUuid) return;

      setMeasurementVerificationStatusMutation({
        variables: {
          configUuid: configurationUuid,
          acceptedAreaUuids: [],
          rejectedAreaUuids: [uuid],
        },
      });
    },
    [setMeasurementVerificationStatusMutation],
  );

  const onTileClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onClick?.(asset);
  };

  const currentItem = useMemo(() => scmMembers.find((item) => item.uuid === data?.uuid), [
    data?.uuid,
    scmMembers,
  ]);

  const getTileName = useCallback(
    (name: string) => (name === 'Grid Market' ? 'Community' : name),
    [],
  );

  return (
    <div
      className={classNames(s.assetTile, {
        [s.isChildren]: isChildren,
        [s.selected]: selected,
      })}>
      <div
        className={classNames(s.leftContainer, {
          [s.clickable]: !!onClick,
        })}
        onClick={(event) => {
          if (currentItem?.name === 'Grid Market') return;

          if (onExpandClick && data.uuid) {
            onExpandClick(data.uuid);
            return;
          }

          onTileClick(event);
        }}>
        <span>
          <BaseIcon className={s.icon} icon={data.icon} size={12} />
        </span>

        <div className={s.nameContainer}>
          <p className={s.name}>{getTileName(data.name)}</p>
        </div>
      </div>

      {!!childrenCount && (
        <div className={s.childAssetsCount}>
          <span>
            {childrenCount} {t('common.ASSETS')}
          </span>
          <BaseIcon icon="eye" size={12} />
        </div>
      )}
      {showWifiIcon && (
        <div
          className={classNames(s.wifiCircle, s.withCirclePadding, s.withCircleClickable)}
          onClick={() =>
            sendRequestMeasurementVerification({
              uuid: data.uuid,
              configurationUuid,
            })
          }>
          <BaseIcon icon="wifi-notconnected" size={12} />
        </div>
      )}
      {showWifiLoadIcon && (
        <div
          className={classNames(
            s.wifiCircle,
            s.withCirclePadding,
            s.withCircleClickable,
            s.withHoverDelete,
          )}
          onClick={() => {
            revokeMeasurementRequest({
              uuid: data.uuid,
              configurationUuid,
            });
          }}>
          <div className={s.wifiLoadFluLayer} />
          <div className={s.wifiLoadIcon} />
          <BaseIcon icon="wifi-notconnected" className={s.wifiNotConnected} size={12} />
          <div className={classNames(s.rejectedIcon)}>
            <BaseIcon icon="close" size={8} />
          </div>
        </div>
      )}
      {showExcecutedIcon && (
        <div
          className={classNames(
            s.wifiCircle,
            s.wifiExecuted,
            s.withCircleClickable,
            s.withHoverDelete,
          )}
          onClick={() => {
            revokeMeasurementRequest({
              uuid: data.uuid,
              configurationUuid,
            });
          }}>
          <BaseIcon className={s.iconWifi} icon="wifi-white" size={12} />
          <div className={classNames(s.rejectedIcon)}>
            <BaseIcon icon="close" size={8} />
          </div>
        </div>
      )}
      {showRejectedIcon && (
        <div
          className={classNames(s.wifiCircle, s.rejectedWrapper, s.withCircleClickable)}
          onClick={() => {
            revokeMeasurementRequest({
              uuid: data.uuid,
              configurationUuid,
            });
          }}>
          <div className={classNames(s.rejectedIcon)}>
            <BaseIcon icon="close" size={8} />
          </div>
        </div>
      )}
      {showAcceptOrRejectFlow && (
        <div className={s.acceptOrRejectContainer}>
          <button
            type="button"
            title="Accept"
            onClick={() => {
              setMeasurementApprovalVerificationStatus({ uuid: data.uuid, configurationUuid });
            }}>
            <BaseIcon icon="check-mark" size={12} />
          </button>
          <button
            type="button"
            title="Reject"
            onClick={() =>
              setMeasurementRejectedVerificationStatus({ uuid: data.uuid, configurationUuid })
            }>
            <BaseIcon icon="close" size={8} />
          </button>
        </div>
      )}
      {onMenuClick && (
        <div
          className={s.menuIcon}
          ref={modalRefObject}
          onClick={() => {
            if (data && data.uuid) {
              onMenuClick(modalRefObject.current, { itemUuid: data.uuid });
            }
          }}>
          <BaseIcon icon="more-horizontal" size={15} />
        </div>
      )}
    </div>
  );
};
