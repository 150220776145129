import React, { useState } from 'react';


import classnames from 'classnames';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseList } from 'src/components/BaseList';
import { closeModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';

import { CustomScrollbar } from '../CustomScrollbar';
import s from './BaseDropdown.module.scss';
import { TBaseDropdownOption, TBaseDropdownProps } from './BaseDropdown.types';

export const BaseDropdown: React.FC<TBaseDropdownProps> = ({
  options,
  defaultItem,
  onSelect,
  modalId,
  position = 'top',
  className,
  showScrollbar,
  scrollbarClassName,
}: TBaseDropdownProps) => {
  const [activeItem, setActiveItem] = useState(() => {
    return options?.find((item) => item.label === defaultItem?.label);
  });

  const dispatch = useAppDispatch();

  const isItemActive = ({ value }: TBaseDropdownOption) => activeItem?.value === value;

  const onItemClick = (item: TBaseDropdownOption, event: React.MouseEvent) => {
    dispatch(closeModal(modalId));
    setActiveItem(item);
    onSelect?.(item.value, event);
  };

  return (
    <BaseGenericModal
      size="auto"
      modalId={modalId}
      singleActionModal
      inline={true}
      className={classnames(s.container, s[position], className)}>
      {showScrollbar ? (
        <CustomScrollbar className={scrollbarClassName}>
          <BaseList
            width="100%"
            className={s.list}
            style={{ overflow: showScrollbar ? 'hidden' : '' }}>
            {options?.map((item, index) => (
              <div
                key={index}
                className={classnames(s.item, { [s.itemActive]: isItemActive(item) })}
                onClick={(e) => {
                  onItemClick(item, e);
                }}>
                <button type="button">{item.label}</button>
              </div>
            ))}
          </BaseList>
        </CustomScrollbar>
      ) : (
        <BaseList width="100%" className={s.list}>
          {options?.map((item, index) => (
            <div
              key={index}
              className={classnames(s.item, { [s.itemActive]: isItemActive(item) })}
              onClick={(e) => {
                onItemClick(item, e);
              }}>
              <button type="button">{item.label}</button>
            </div>
          ))}
        </BaseList>
      )}
    </BaseGenericModal>
  );
};
