import { CommunityConfigurationOutput, CommunityProjectOutput, Maybe } from 'src/graphql';
import { TLngLat } from 'src/typings/base-types';

type TProject = {
  configurations?: Maybe<
    Array<Maybe<Pick<CommunityConfigurationOutput, 'latitude' | 'longitude'>>>
  >;
} & Pick<CommunityProjectOutput, 'latitude' | 'longitude'>;

export function getConfigurationLocation(project: TProject): TLngLat | undefined {
  const { configurations, latitude, longitude } = project;

  if (configurations?.length) {
    const lat = configurations[0]?.latitude;
    const lng = configurations[0]?.longitude;
    if (lng && lat) return { lng, lat };
  }

  if (longitude && latitude) {
    return { lng: longitude, lat: latitude };
  }

  return undefined;
}
