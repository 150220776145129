import React from 'react';

import classnames from 'classnames';
import { TTooltipSavingsProps } from 'src/components/_charts/TooltipFactory/tooltips/TooltipSavings/TooltipSavings.types';
import { BaseIcon } from 'src/components/BaseIcon';
import { ESavingType } from 'src/components/ChartSavings';
import { ChartSavingsColors } from 'src/constants/chart';

import s from './TooltipSavings.module.scss';

function getSign(type: ESavingType) {
  let sign = '';

  switch (type) {
    case ESavingType.Earning:
      sign = '+';
      break;
    case ESavingType.Neutral:
      break;
    case ESavingType.Saving:
      sign = '+';
      break;
    case ESavingType.Losses:
      sign = '-';
      break;
  }

  return sign;
}

function CashDisplay({
  value,
  type,
  currencySymbol,
}: {
  value: number;
  type: ESavingType;
  currencySymbol: string;
}) {
  return (
    <div className={s.cashDisplay}>
      <div className={s.savingLine} style={{ background: ChartSavingsColors[type] }} />
      {getSign(type)}
      {value?.toFixed(2)}
      {currencySymbol}
    </div>
  );
}

export const TooltipSavings: React.FC<TTooltipSavingsProps> = ({ context }) => {
  const { tooltip } = context;
  // @ts-ignore
  const { savingTypes, currencySymbol } = tooltip.customData as TCustomData;
  const dataIndex = tooltip.dataPoints[0].dataIndex;
  const type = savingTypes[dataIndex];
  const savingDisplayType = type === ESavingType.Earning ? ESavingType.Saving : type;
  const rawDataPoint = tooltip.dataPoints.find((datapoint) => datapoint.dataset.label === type)
    ?.raw as {
    saving_percentage: number;
    profit: number;
    saving: number;
  };
  // const cashValue = Math.abs(rawDataPoint.y as number);
  const percentageValue = Math.abs(rawDataPoint.saving_percentage as number);
  const profitValue = Math.abs(rawDataPoint.profit as number);
  const savingValue = Math.abs(rawDataPoint.saving as number);

  return (
    <div className={s.container}>
      <div>
        <h4 className={s.title}>Savings</h4>
        <div className={s.savingsRow}>
          <CashDisplay
            type={savingDisplayType}
            value={savingValue}
            currencySymbol={currencySymbol}
          />
          {type !== ESavingType.Neutral && type !== ESavingType.Earning && (
            <div
              className={s.percentageDisplay}
              style={{ color: ChartSavingsColors[savingDisplayType] }}>
              <BaseIcon
                icon="triangle-2"
                size={5}
                className={classnames(s.triangle, {
                  [s.triangleLoss]: type === ESavingType.Losses,
                })}
              />
              {getSign(type)}
              {percentageValue}%
            </div>
          )}
        </div>
      </div>

      <div>
        <h4 className={s.title}>Profit</h4>

        {type === ESavingType.Earning ? (
          <CashDisplay type={type} value={profitValue} currencySymbol={currencySymbol} />
        ) : (
          <div className={s.noProfit} />
        )}
      </div>

      <div className={s.tick} />
    </div>
  );
};
