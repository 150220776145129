import React, { useRef } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BaseHamburger } from 'src/components/BaseHamburger';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { BaseMobileModal } from 'src/components/BaseMobileModal';
import s from 'src/components/Header/Header.module.scss';
import { LanguageSelect } from 'src/components/Header/LanguageSelect/LanguageSelect';
import { TLoggedMobileComponentProps } from 'src/components/Header/LoggedMobileComponent/LoggedMobileComponent.types';
import { FormInviteUser } from 'src/components/MapSidebar/components/FormInviteUser';
import { ModalBurgerNavigation } from 'src/components/ModalBurgerNavigation';
import { UserAvatar } from 'src/components/UserAvatar';
import { EDomIds } from 'src/constants/domSelectors';
import { useModal } from 'src/hooks/useModal';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { toggleModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';

export enum EHeaderMode {
  Default = 'DEFAULT',
  Community = 'COMMUNITY',
}

export const LoggedMobileComponent = ({
  title,
  handleHamburgerClick,
  handleLogoRedirection,
  memoizedNotifications,
}: TLoggedMobileComponentProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { id: burgerMenuModalId } = useModal();
  const { id: notificationsModalUuid } = useModal();

  const isBurgerMenuOpened = useSelector(selectIsModalOpened(burgerMenuModalId));

  const notificationsBellButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <header
        id={EDomIds.HEADER}
        className={classnames(s.container, s.itemsCenter, s.mobileHeader)}>
        <nav className={classnames(s.nav_con, s.itemsCenter)}>
          <BaseHamburger
            isOpened={isBurgerMenuOpened as boolean}
            onClick={handleHamburgerClick}
            className={s.hamburger}
          />
          <ModalBurgerNavigation modalId={burgerMenuModalId} />
          <BaseLink
            isNavLink={true}
            onClick={handleLogoRedirection}
            to={routesConfig.scm()}
            className={s.logoLink}
            activeStyle={false}>
            <BaseIcon icon="gs-logo" />
          </BaseLink>
        </nav>

        <nav className={classnames(s.nav_con, s.mla, s.itemsCenter)}>
          <LanguageSelect />
          <button
            className={s.notificationsButton}
            type="button"
            title="Notificatons"
            ref={notificationsBellButtonRef}
            onClick={() => {
              dispatch(toggleModal(notificationsModalUuid));
            }}>
            <BaseIcon icon="bell" size={22} />
            {memoizedNotifications && memoizedNotifications.length !== 0 && (
              <div className={s.notificationsBubble}>
                <span className={s.notificationsBubbleText}>{memoizedNotifications.length}</span>
              </div>
            )}
          </button>
          <BaseMobileModal modalId={notificationsModalUuid}>
            <FormInviteUser />
          </BaseMobileModal>
        </nav>
        <UserAvatar />
      </header>
      <div className={s.mobileTitleWrapper}>
        <span className={s.title}>{title}</span>
      </div>
    </>
  );
};
