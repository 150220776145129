import React from 'react';

import classnames from 'classnames';
import { BaseButton } from 'src/components/BaseButton';

import s from '../ModalAssetsManager.module.scss';

type GridMarketSubmitButtonProps = {
  children: React.ReactNode;
  formId: React.MutableRefObject<string>;
};

export const GridMarketSubmitButton: React.FC<GridMarketSubmitButtonProps> = ({
  children = 'Save',
  formId,
}) => {
  return (
    <div className={classnames(s.paddingLR, s.paddingB)}>
      <BaseButton type="submit" className={s.saveBtn} form={formId.current}>
        {children}
      </BaseButton>
    </div>
  );
};
