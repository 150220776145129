import produce from 'immer';
import { TFieldValue } from 'src/typings/base-types';
import {
  TUpdateFields as TUpdateFieldsArgs,
  TupdateSiblingFieldsArgs,
} from 'src/utils/assetsFields/assetsFields.types';
import { siblingFieldsReactions } from 'src/utils/assetsFields/siblingFieldsReactions';

function updateSiblingFields({
  fields,
  updatedFieldName,
  newValue,
  prevValue,
  type,
  settingsData,
  isLibrary,
  configType,
  configurationCharacteristic,
}: TupdateSiblingFieldsArgs): TupdateSiblingFieldsArgs['fields'] {
  let output = fields;

  const updateSiblingField = siblingFieldsReactions[type]
    ? (siblingFieldsReactions[type] || {})[updatedFieldName]
    : null;

  if (updateSiblingField) {
    output = updateSiblingField({
      fields,
      settingsData,
      newValue,
      prevValue,
      isLibrary,
      configType,
      type,
      configurationCharacteristic,
    });
  }

  return output;
}

export function updateFields({
  type,
  settingsData,
  isLibrary,
  configType,
  fields,
  updatedField,
  configurationCharacteristic,
}: TUpdateFieldsArgs): TUpdateFieldsArgs['fields'] {
  let output = fields;
  let prevValue: TFieldValue;

  // We use immer for immutability, cloneDeep is too expensive
  output = produce(output, (draftState) => {
    const fieldToUpdate = draftState.find((f) => f.name === updatedField.name);

    if (fieldToUpdate) {
      prevValue = fieldToUpdate.value;
      fieldToUpdate.value = updatedField.value;
    }
  });

  output = updateSiblingFields({
    fields: output,
    updatedFieldName: updatedField.name,
    newValue: updatedField.value,
    prevValue,
    type,
    settingsData,
    isLibrary,
    configType,
    configurationCharacteristic,
  });

  return output;
}
