import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import ReactDOM from 'react-dom';
import {
  TBaseTooltipContainerProps,
  TBaseTooltipProps,
  TBaseTooltipTriggerProps,
} from 'src/components/BaseTooltip';


import s from './BaseTooltip.module.scss';

const positionMap = {
  right: 'left',
  bottom: 'top',
  left: 'left',
  top: 'top',
};

const alignMap: {
  [key: string]: Array<keyof Omit<DOMRect, 'toJSON'>>;
} = {
  right: ['top', 'height', 'y'],
  bottom: ['left', 'width', 'x'],
  left: ['top', 'height', 'y'],
  top: ['left', 'width', 'x'],
};

const tickPositionMap = {
  right: 'left' as const,
  bottom: 'top' as const,
  left: 'right' as const,
  top: 'bottom' as const,
};

const BaseTooltip: FunctionComponent<TBaseTooltipProps> = function BaseTooltip({
  rootElRef,
  children,
  className: className,
  theme = '1',
  tickPosition = 'bottom',
  disabled,
  onClick,
  wordWrap = false,
}) {
  if (!children) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const RootTag: any = onClick ? 'button' : 'div';

  return (
    <RootTag
      className={classnames(className, s.tooltip, s[`theme-${theme}`], {
        [s.wordWrap]: wordWrap === true,
      })}
      ref={rootElRef}
      onClick={onClick}
      type="button"
      disabled={disabled}
      style={{ ['--pointer-events' as string]: onClick ? 'auto' : 'none' }}>
      {typeof children === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: children }}></div>
      ) : (
        children
      )}
      <div
        className={s.tick}
        style={{
          [tickPosition]: '-2px',
          [alignMap[tickPosition][0]]: '50%',
          transform: `translate${alignMap[tickPosition][2]}(-50%) rotate(-45deg)`,
        }}
      />
    </RootTag>
  );
};

const BaseTooltipContainer: React.FC<TBaseTooltipContainerProps> = function BaseTooltipContainer({
  tooltipChildren,
  relatedDOMRect,
  position,
  offset,
  tooltipClassName,
  ...rest
}: TBaseTooltipContainerProps) {
  const rootElRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!rootElRef.current) return;

    const tooltipWidth = rootElRef.current.getBoundingClientRect().width;
    const tooltipHeight = rootElRef.current.getBoundingClientRect().height;
    let relativeOffset;

    switch (position) {
      case 'left':
        relativeOffset = tooltipWidth + offset;
        break;
      case 'top':
        relativeOffset = tooltipHeight + offset;
        break;
      case 'right':
      case 'bottom':
      default:
        relativeOffset = -offset;
    }

    const newStyle = {
      [positionMap[position]]: relatedDOMRect[position] - relativeOffset + 'px',
      [alignMap[position][0]]:
        Number(relatedDOMRect[alignMap[position][0]]) +
        Number(relatedDOMRect[alignMap[position][1]]) / 2 +
        'px',
      transform: `translate${alignMap[position][2]}(-50%)`,
    };
    Object.assign(rootElRef.current.style, newStyle);

    if (position === 'bottom') {
      if (!rootElRef.current) return;
      const { right } = rootElRef.current.getBoundingClientRect();
      const diff = window.innerWidth - right - 20;
      if (diff < 0) {
        rootElRef.current.style.transform = `translate${alignMap[position][2]}(calc(-50% + ${diff}px))`;
      }
    }
  }, [offset, position, relatedDOMRect]);

  return (
    <BaseTooltip
      className={classnames(s.tooltipFixedPos, tooltipClassName)}
      rootElRef={rootElRef}
      tickPosition={tickPositionMap[position]}
      {...rest}>
      {tooltipChildren}
    </BaseTooltip>
  );
};
const BaseTooltipTrigger: React.FC<TBaseTooltipTriggerProps> = function BaseTooltipTrigger({
  triggerClassName = '',
  children,
  position = 'bottom',
  offset = 10,
  disabled = false,
  ...rest
}: TBaseTooltipTriggerProps) {
  const portalEl = document.getElementById('app-root');
  const [isOpened, setOpened] = useState(false);
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpened(false);
  }, [disabled]);

  useEffect(() => () => setOpened(false), []);

  let portal;
  if (portalEl && elRef.current) {
    const { top, right, bottom, left, width, height, x, y } = elRef.current.getBoundingClientRect();
    const data = {
      position,
      offset,
      relatedDOMRect: { top, right, bottom, left, width, height, x, y },
    };
    const renderedEl = !isOpened ? null : <BaseTooltipContainer {...data} {...rest} />;

    portal = ReactDOM.createPortal(renderedEl, portalEl);
  }

  return (
    <>
      <div
        className={classnames(s.trigger, triggerClassName)}
        onMouseEnter={() => {
          if (disabled) return;
          setOpened(true);
        }}
        onMouseLeave={() => setOpened(false)}
        ref={elRef}>
        {children}
      </div>

      {portal}
    </>
  );
};
export { BaseTooltip, BaseTooltipContainer, BaseTooltipTrigger };
