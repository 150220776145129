import React, { useCallback, useEffect, useState } from 'react';

import { DashboardUsersGridItem } from 'src/components/AdminDashboards/DashboardUsers/DashboardUsersGridItem';
import { DashboardUsersTableItem } from 'src/components/AdminDashboards/DashboardUsers/DashboardUsersTableItem';
import { DataGrid } from 'src/components/DataGrid';
import {
  UserRoleFilterField,
  UserSimpleOutput,
  useListAdminUserDetailsLazyQuery,
} from 'src/graphql';
import {
  sortByCreateDate,
  sortByProjects,
  sortByString,
} from 'src/utils/adminDashboards/dataGridUtils';

import s from './DashboardUsers.module.scss';
import { TDashboardUsersProps } from './DashboardUsers.types';

const ITEMS_PER_PAGE = 21;
const TABLE_COLUMNS = [
  {
    name: 'Account',
    sort: 'asc',
  },
  {
    name: 'Role',
    sort: 'asc',
  },
  {
    name: 'Creation',
    sort: 'asc',
  },
  {
    name: 'Status',
    sort: 'asc',
  },
  {
    name: 'Projects',
    sort: 'asc',
  },
  {
    name: '',
    sort: null,
  },
];

const scmFilters = [
  { name: 'All', value: UserRoleFilterField.None },
  {
    name: 'GSy Users',
    value: UserRoleFilterField.GsyUser,
  },
  {
    name: 'Exchange Operators',
    value: UserRoleFilterField.ExchangeOperator,
  },
];

export const DashboardUsers: React.FC<TDashboardUsersProps> = ({ onUserSelect }) => {
  const [tableColumns, setTableColumns] = useState(TABLE_COLUMNS);
  const [activeFilter, setActiveFilter] = useState<UserRoleFilterField>(UserRoleFilterField.None);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [listAdminUserDetails, { data }] = useListAdminUserDetailsLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [users, setUsers] = useState<Array<UserSimpleOutput>>();

  useEffect(() => {
    const res = data?.listAdminUserDetails?.userList?.map((item) => ({ ...item }));
    setUsers(res as Array<UserSimpleOutput>);
  }, [data]);

  // const users = data?.listAdminUserDetails?.userList?.map((item) => ({ ...item }));
  const totalUsers = data?.listAdminUserDetails?.totalNumberOfUsers || undefined;

  const dataFetch = useCallback(() => {
    listAdminUserDetails({
      variables: {
        filterRole: activeFilter,
        search: searchValue,
        skip: pageNumber * ITEMS_PER_PAGE,
        first: ITEMS_PER_PAGE,
      },
    });
  }, [activeFilter, listAdminUserDetails, pageNumber, searchValue]);

  const handleFilterChange = (val) => {
    setActiveFilter(val);
    setPageNumber(0);
  };

  const sortFilterHandler = (col, val) => {
    let sortValue = val;
    const cols = tableColumns.map((x) => {
      if (x.name === col) {
        if (x.sort === 'asc') {
          x.sort = 'desc';
        } else {
          x.sort = 'asc';
        }
        sortValue = x.sort;
      }
      return x;
    });
    setTableColumns([...cols]);
    let tempData;
    switch (col) {
      case 'Creation':
        tempData = sortByCreateDate(users, sortValue);
        break;
      case 'Projects':
        tempData = sortByProjects(users, sortValue);
        break;
      case 'Account':
        tempData = sortByString(users, sortValue, 'email');
        break;
      case 'Role':
        tempData = sortByString(users, sortValue, 'userRole');
        break;
      case 'Status':
        tempData = sortByString(users, sortValue, 'requestStatus');
        break;
    }
    setUsers(tempData);
  };

  return (
    <div className={s.container}>
      <DataGrid<UserSimpleOutput>
        headerTitle="Users"
        filters={scmFilters}
        tableColumns={tableColumns}
        activeFilter={activeFilter}
        allItemsCount={totalUsers}
        data={users}
        dataFetch={dataFetch}
        itemsPerPage={ITEMS_PER_PAGE}
        onFilterChange={(val: UserRoleFilterField) => handleFilterChange(val)}
        pageNumber={pageNumber}
        searchValue={searchValue}
        setPageNumber={(val) => setPageNumber(val)}
        setSearchValue={(val) => setSearchValue(val)}
        gridItemRenderFn={(data, className) => (
          <DashboardUsersGridItem
            className={className}
            key={data.id}
            data={data}
            updateGridData={dataFetch}
            onClick={() => {
              if (onUserSelect && data.email) {
                onUserSelect(data.email);
              }
            }}
          />
        )}
        tableItemRenderFn={(data, classname) => (
          <DashboardUsersTableItem
            className={classname}
            key={data.id}
            data={data}
            updateGridData={dataFetch}
            onClick={() => {
              if (onUserSelect && data.email) {
                onUserSelect(data.email);
              }
            }}
          />
        )}
        sortHandler={(col, value) => sortFilterHandler(col, value)}
      />
    </div>
  );
};
