// Use this context for all things which are not serializable (so does not fit in Redux)

import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { TModalAlertProps } from 'src/components/ModalAlert';
import { EPredefinedModalIds } from 'src/constants/modals';
import { ConfigType } from 'src/graphql';
import {
  selectCommunityName,
  selectConfigType,
  selectSimulationResults,
} from 'src/redux/configuration/configuration.selectors';
import { setHasUserAllowedResultsLoss } from 'src/redux/configuration/configuration.slice';
import { openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';

type TApplicationContextValue = {
  triggerDownloadAlert(message?: string): Promise<unknown>;
  triggerResultsLossAlert(args?: { type: 'add-asset' }): Promise<unknown>;
  triggerCommunityDeleteAlert(): Promise<unknown>;
  alertContinueResolver: React.MutableRefObject<(() => void) | undefined>;
  alertProps: Pick<TModalAlertProps, 'text' | 'showActions'>;
};

const initValue: TApplicationContextValue = {
  triggerDownloadAlert: () => new Promise((resolve) => resolve(true)),
  triggerResultsLossAlert: () => new Promise((resolve) => resolve(true)),
  triggerCommunityDeleteAlert: () => new Promise((resolve) => resolve(true)),
  alertContinueResolver: {
    current: undefined,
  },
  alertProps: {
    text: '',
  },
};

export const ApplicationContext = React.createContext<TApplicationContextValue>(initValue);

export const ApplicationContextProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const simulationResults = useSelector(selectSimulationResults);
  const [alertProps, setAlertProps] = useState<TApplicationContextValue['alertProps']>({
    text: '',
    showActions: true,
  });
  const communityName = useSelector(selectCommunityName);
  const configType = useSelector(selectConfigType);
  const isCN = configType === ConfigType.CanaryNetwork;

  const triggerResultsLossAlert = useCallback(
    ({ type } = {}) => {
      return new Promise((resolve) => {
        if (simulationResults && !isCN) {
          let text = '';

          switch (type) {
            case 'add-asset':
              text =
                'Attention: If you want to add more assets to your simulation you will lose the current results. Are you sure you want to continue?';
              break;

            default:
              text = 'Warning: If you make this change your current results will be deleted.';
              break;
          }

          setAlertProps({
            text,
          });
          dispatch(openModal(EPredefinedModalIds.MODAL_ALERT));
          initValue.alertContinueResolver.current = () => {
            resolve(true);
            dispatch(setHasUserAllowedResultsLoss(true));
          };
        } else {
          resolve(true);
        }
      });
    },
    [dispatch, simulationResults, isCN],
  );

  const triggerCommunityDeleteAlert = useCallback(() => {
    return new Promise((resolve) => {
      setAlertProps({ text: `Are you sure you want to delete ${communityName}?` });
      dispatch(openModal(EPredefinedModalIds.MODAL_ALERT));
      initValue.alertContinueResolver.current = () => resolve(true);
    });
  }, [communityName, dispatch]);

  const triggerDownloadAlert = useCallback(
    (message) => {
      return new Promise((resolve) => {
        setAlertProps({ text: message, showActions: false });
        dispatch(openModal(EPredefinedModalIds.MODAL_ALERT));
        initValue.alertContinueResolver.current = () => resolve(true);
      });
    },
    [dispatch],
  );

  return (
    <ApplicationContext.Provider
      value={{
        ...initValue,
        triggerDownloadAlert,
        triggerResultsLossAlert,
        triggerCommunityDeleteAlert,
        alertProps,
      }}>
      {children}
    </ApplicationContext.Provider>
  );
};
