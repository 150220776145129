import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { BaseSearch } from 'src/components/BaseSearch';
import { ContextMenu } from 'src/components/ContextMenu';
import {
  EOrientationParam,
  TOrientationParam,
} from 'src/components/CustomPV/components/Orientation/Orientation.types';
import { FormAssetsParams, TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useRemoveConfigurationAreaMutation } from 'src/graphql';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useModal } from 'src/hooks/useModal';
import {
  selectActiveConfigurationUuid,
  selectAssetValuesForUuid,
  selectReadOnly,
} from 'src/redux/configuration/configuration.selectors';
import {
  removeAssetByUuidWithDelete,
  setIsModalSummaryWithList,
} from 'src/redux/configuration/configuration.slice';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { selectCommunityMembers } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { EFormVariant, TAssetType } from 'src/typings/base-types';
import { get2dAssetIcon, get3dAssetIcon } from 'src/utils/assetIcons';
import { intervalListener } from 'src/utils/intervalListener';
import { nonNullable } from 'src/utils/ts-utils';
import { v4 } from 'uuid';

import s from './UserAssetPreview.module.scss';
import {
  EUserAssetPreviewTabs,
  TAssetListItemProps,
  TAssetsListData,
  TUserAssetPreviewProps,
} from './UserAssetPreview.types';

const NAVIGATION: Array<EUserAssetPreviewTabs> = [
  EUserAssetPreviewTabs.Assets,
  EUserAssetPreviewTabs.Advanced,
];

let azimuthValuesForCustomPV: TOrientationParam = {
  [EOrientationParam.AZIMUTH]: 0,
  [EOrientationParam.TILT]: 0,
};

// used in case of creating new custom PV
const azimuthValuesForCustomPVInitialState: TOrientationParam = azimuthValuesForCustomPV;

const setAzimuthValuesForCustomPV = (value: TOrientationParam) => {
  azimuthValuesForCustomPV = {
    ...azimuthValuesForCustomPV,
    ...value,
  };
};

const AssetListItem: React.FC<TAssetListItemProps> = (props) => {
  const modalRefObject = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames(s.assetListItem, {
        [s.isSelected]: props.isSelected,
        [s.isSelectable]: props.isSelectable,
      })}
      style={props.style}>
      <BaseIcon icon={props.icon} size={16} />
      <p onClick={props.onClick}>{props.title}</p>
      {props.menuActive && (
        <div
          ref={modalRefObject}
          onClick={() => {
            if (props.uuid) {
              props.onMenuClick(modalRefObject.current, { itemUuid: props.uuid });
            }
          }}>
          <BaseIcon icon="more-horizontal" size={16} />
        </div>
      )}
    </div>
  );
};

export const UserAssetPreview: React.FC<TUserAssetPreviewProps> = ({
  selectedItem,
  selectedAsset,
  handleSetMode,
  setSelectedAsset,
  backToDashboard,
  onRemove,
  menuActive = true,
  onAssetValuesSavePromise,
  isCreateAssetView,
  setAssetsList,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { children } = selectedItem;
  const { id: modalId } = useModal();
  const [data, setData] = useState(selectedItem.data);
  const formId = useRef(v4());
  const [openedAssetConfigUuid, setOpenedAssetConfigUuid] = useState<string | null>(null);
  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const { isOwnerAdmin, isOwnerCM } = useIsAdminInspecting();

  const [removeConfigurationArea] = useRemoveConfigurationAreaMutation({
    onCompleted: (data) => {
      if (data.removeConfigurationArea && selectedItem.data.uuid) {
        dispatch(openToast({ message: 'Asset deleted successfully', type: 'success' }));
        if (onRemove) onRemove();
        dispatch(removeAssetByUuidWithDelete(selectedItem.data.uuid));
      }
    },
    onError: (error) => {
      const message = JSON.parse(error.message);

      dispatch(openToast({ message: message.remove_configuration_area[0], type: 'error' }));
    },
  });

  const isStandaloneAsset = data?.tags?.includes('DefaultLib');

  const scmMembers = useSelector(selectCommunityMembers);
  const assetValues = useSelector(selectAssetValuesForUuid(data.uuid!));
  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);

  const { mapService } = useContext(WorldMapContext);

  const formHasErrorsRef = useRef(false);

  // Create a state for displayButtons
  const [displayButtons, setDisplayButtons] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<EUserAssetPreviewTabs>(EUserAssetPreviewTabs.Basic);
  const [searchValue, setSearchValue] = useState<string>('');

  // TODO::We will integrate the results from this API
  //const [libraryData, setLibraryData] = useState(data);

  const tabsNavigation = NAVIGATION.filter((item) => {
    if (isStandaloneAsset) {
      return item !== EUserAssetPreviewTabs.Assets;
    }
    if (isCreateAssetView) {
      return true;
    }
    return item !== EUserAssetPreviewTabs.Advanced;
  });

  // Create a useEffect to set the displayButtons state
  // to false when the ActiveTab is not Assets
  useEffect(() => {
    setDisplayButtons(activeTab === EUserAssetPreviewTabs.Assets);
  }, [activeTab]);

  const scenarioData = useMemo(
    () =>
      data?.scenarioData ? JSON.parse(data?.scenarioData?.representation?.serialized || '') : {},
    [data?.scenarioData],
  );

  const getAsset = (uuid: string) => {
    return scenarioData.children.find((item) => item.uuid === uuid);
  };

  const onAddAssetClick = (item) => {
    handleSetMode('addNewAsset');
    setAssetsList?.(item);
  };

  const selectListItem = (value) => {
    setSelectedAsset(selectedAsset === value ? undefined : value);
  };

  const onSearchChange = ({ value }) => {
    setSearchValue(value);
  };

  //create a useEffect to update the isModalSummaryWithList state
  //if isCreateAssetView is false
  //when mounted as true
  //and unmounted as false
  useEffect(() => {
    if (!isCreateAssetView) {
      dispatch(setIsModalSummaryWithList(true));
    }
    return () => {
      dispatch(setIsModalSummaryWithList(false));
    };
  }, [dispatch, isCreateAssetView]);

  useEffect(() => {
    // PH-1276-Fixed::No auto-zoom in on houses while changing their features
    const assetCordinates = {
      lng: assetValues?.geoTagLocation?.[0],
      lat: assetValues?.geoTagLocation?.[1],
    };
    mapService?.flyTo(assetCordinates, { specificZoom: 18 });
    intervalListener(
      () => mapService?.set3DView(true),
      () => {
        const isBusy =
          mapService?.map.isEasing() || mapService?.map.isMoving() || mapService?.map.isRotating();

        return isBusy !== undefined ? isBusy : true;
      },
    );
  }, [assetValues?.geoTagLocation, mapService]);

  const closeActiveModal = () => {
    setOpenedAssetConfigUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };

  const onMenuClick = (menuTriggerRef, data) => {
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedAssetConfigUuid(data.itemUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  const renderAssetsList = (items: TAssetsListData[], level = 0) => {
    return items.map(({ children, ...item }, i) => (
      <React.Fragment key={i}>
        {(searchValue ? item.title.toLowerCase().includes(searchValue) : true) && (
          <AssetListItem
            key={i}
            {...item}
            style={{ marginLeft: `${20 * level}px` }}
            isSelected={selectedAsset === item.uuid}
            onClick={() => children && !searchValue.length && selectListItem(item.uuid)}
            menuActive={menuActive}
            onMenuClick={onMenuClick}
          />
        )}
        {children &&
          (searchValue.length || selectedAsset === item.uuid) &&
          renderAssetsList(children, level + 1)}
      </React.Fragment>
    ));
  };

  const assetsList = useMemo<TAssetsListData[]>(() => {
    if (!children) return [];

    const createDataItem = (item): TAssetsListData => ({
      uuid: item.uuid,
      title: `${item.name}`,
      icon: get2dAssetIcon(item.type),
      isSelected: false,
      isSelectable: !!item?.children,
      children: item.children ? createList(item.children) : undefined,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createList = (items: any[]) =>
      items.reduce<TAssetsListData[]>((acc, item) => {
        acc.push(createDataItem(item));
        return acc;
      }, []);

    return children.length ? createList(children) : [];
  }, [children]);

  useEffect(() => {
    if (isStandaloneAsset) setActiveTab(EUserAssetPreviewTabs.Basic);

    setActiveTab(EUserAssetPreviewTabs.Assets);
  }, [isStandaloneAsset]);

  const readOnly = useSelector(selectReadOnly);
  const selectedAssetData = selectedAsset && getAsset(selectedAsset);
  const isCustomPV = Boolean(nonNullable(scenarioData.azimuth) && nonNullable(scenarioData.tilt));

  const DashboardHeader = ({ onClickAction = backToDashboard }) => {
    if (onClickAction) {
      return (
        <header className={s.header}>
          <h3 onClick={() => handleSetMode('assetPreview')}>{`${data?.name}'s ${t(
            'common.HOME',
          )}`}</h3>
          <div>
            <BaseButtonSquare
              className={s.theXButton}
              icon="close"
              theme="flat-gray-light"
              onClick={onClickAction}
            />
          </div>
        </header>
      );
    }
    return null;
  };

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  dropDownItems.push({
    title: t('commands.EDIT'),
    icon: 'pencil-edit',
    onClick: () => {
      if (openedAssetConfigUuid) {
        handleSetMode('editAsset');
        setSelectedAsset(openedAssetConfigUuid);
      }
    },
  });

  const handleAssetValuesSave: TFormAssetsParamsProps['onSubmit'] = async (payload) => {
    onAssetValuesSavePromise?.({
      ...payload,
      values: { ...payload.values, coefficientPercentage: assetValues?.coefficientPercentage },
    }).then((r) => {
      if (r) {
        dispatch(
          openToast({
            type: 'success',
            message: 'Asset Updated',
          }),
        );

        setData({ ...data, ...payload.values });
      }
    });
    dashboardOnClick();
  };

  const currentMember = useMemo(() => scmMembers.find((item) => item.name === data?.name), [
    data?.name,
    scmMembers,
  ]);

  const dashboardOnClick = () => {
    return activeTab !== EUserAssetPreviewTabs.Assets
      ? () => setActiveTab(EUserAssetPreviewTabs.Assets)
      : backToDashboard;
  };

  return (
    <div className={s.container}>
      {!isCreateAssetView && <DashboardHeader onClickAction={dashboardOnClick()} />}
      {data && (
        <div className={s.wrapper}>
          <div className={s.hero}>
            <header>
              <div className={s.userPill}>
                <span>{selectedItem.data.name}</span>
              </div>
              {displayButtons && !isCreateAssetView && (
                <div className={s.buttonContainer}>
                  {(isOwnerAdmin || isOwnerCM) && (
                    <BaseButtonSquare
                      onClick={() => {
                        if (!activeConfigurationUuid || !selectedItem.data.uuid) return;

                        removeConfigurationArea({
                          variables: {
                            configurationUuid: activeConfigurationUuid,
                            areaUuid: selectedItem.data.uuid,
                          },
                        });
                      }}
                      className={s.button}
                      icon="trash"
                      size="2"
                    />
                  )}
                  <BaseButtonSquare
                    onClick={() => {
                      setActiveTab(EUserAssetPreviewTabs.Basic);
                    }}
                    className={s.button}
                    icon="pencil-edit"
                    size="2"
                  />
                </div>
              )}
            </header>
            <div className={s.icon}>
              <BaseIcon icon={get3dAssetIcon(data?.libraryType || data?.type || 'canary')} />
            </div>
            {isCreateAssetView && <p>{data.name}</p>}
            {currentMember && <div className={s.address}>{currentMember.address}</div>}
            <p>
              {t('common.ASSETS')}: {assetsList?.length || 0}
            </p>
          </div>
          <nav className={s.navigation}>
            {tabsNavigation.map((item, i) => (
              <button
                key={i}
                type="button"
                className={classNames({
                  [s.active]: activeTab === item,
                })}
                onClick={() => {
                  setActiveTab(item);
                }}>
                {t(item)}
              </button>
            ))}
          </nav>
          {activeTab === EUserAssetPreviewTabs.Assets && (
            <div className={s.assetsList}>
              <div className={s.content}>
                {children?.length ? (
                  <>
                    <BaseSearch
                      className={s.search}
                      name="search"
                      iconRight="search"
                      label={`${t('commands.SEARCH')}`}
                      value={searchValue}
                      onChange={onSearchChange}
                    />
                    {renderAssetsList(assetsList)}
                  </>
                ) : (
                  <p style={{ textAlign: 'center', opacity: 0.6 }}>
                    <strong>{data?.name}</strong> {t('messages.NO_CHILD_ASSETS')}
                  </p>
                )}
                {/* PH-1265-Hide add asset button */}
                {!readOnly && (
                  <button type="button" className={s.addAssetButton} onClick={onAddAssetClick}>
                    <BaseIcon icon="plus" size={9} /> {t('commands.ADD_ASSET')}
                    {selectedAssetData && <span>({selectedAssetData.name})</span>}
                  </button>
                )}
              </div>
            </div>
          )}
          {activeTab !== EUserAssetPreviewTabs.Assets && (
            <div className={classNames(s.settings, s.overflowYAuto)}>
              <FormAssetsParams
                hasErrorsRef={formHasErrorsRef}
                id={formId.current}
                assetUuid={data.uuid!}
                assetType={data.type as TAssetType}
                formVariant={
                  activeTab === EUserAssetPreviewTabs.Basic
                    ? EFormVariant.Express
                    : EFormVariant.OnlyAdvanced
                }
                onSubmit={(data) => handleAssetValuesSave(data)}
                currentValues={{ ...assetValues, ...currentMember }}
                disableLocationField={readOnly}
                isCustomPV={isCustomPV}
                azimuthValuesForCustomPV={azimuthValuesForCustomPVInitialState}
                setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
                isEdit={true}
                theme="dark"
              />
              <div className={classNames(s.saveBtnContainer, s.cancelButtonContainer)}>
                <BaseButton
                  type="button"
                  onClick={() => {
                    setActiveTab(EUserAssetPreviewTabs.Assets);
                  }}
                  theme="grey">
                  {t('commands.CANCEL')}
                </BaseButton>
              </div>
              <div className={s.saveBtnContainer}>
                <BaseButton form={formId.current} type="submit" disabled={readOnly}>
                  {t('commands.SAVE')}
                </BaseButton>
              </div>
            </div>
          )}
          <ContextMenu
            relativeElement={menuModalTriggerRef}
            modalId={modalId}
            position={{
              side: 'right',
              anchorPosition: 50,
            }}
            items={dropDownItems}
          />
        </div>
      )}
    </div>
  );
};
