import React from 'react';

import s from './BaseHamburger.module.scss';
import { TBaseHamburgerProps } from './BaseHamburger.types';

export const BaseHamburger: React.FC<TBaseHamburgerProps> = ({ isOpened, className, onClick }) => {
  return (
    <button
      className={`${s.button} ${isOpened ? s.opened : ''} ${className}`}
      type="button"
      onClick={onClick}>
      <div className={s.line} />
      <div className={s.line} />
      <div className={s.line} />
    </button>
  );
};
