import React, { useEffect, useRef, useState } from 'react';

import WorldGlobeParticlesLogos from 'src/assets/images/world-globe-particles-logos.png';
import WorldGlobeParticles from 'src/assets/images/world-globe-particles.png';
import { BaseButton } from 'src/components/BaseButton';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';

import s from './ModalEmbedJoin.module.scss';
import { TModalEmbedJoinProps } from './ModalEmbedJoin.types';

export const ModalEmbedJoin: React.FC<TModalEmbedJoinProps> = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const { modalsEmbedShow } = useConfigurationFlowState();
  const [isClose, setIsClose] = useState(false);

  const handleCloseClick = () => {
    setIsClose(true);
  };

  useEffect(() => {
    if (modalsEmbedShow) {
      dispatch(openModal(EPredefinedModalIds.MODAL_EMBED_JOIN));
    } else {
      dispatch(closeModal(EPredefinedModalIds.MODAL_EMBED_JOIN));
    }
  }, [dispatch, modalsEmbedShow]);

  return (
    <div className={s.container}>
      <BaseGenericModal
        inline={true}
        size={EModalSize.M255}
        modalId={EPredefinedModalIds.MODAL_EMBED_JOIN}
        className={s.modal}
        modalRef={modalRef}>
        {!isClose && (
          <div className={s.contentContainer}>
            <button type="button" className={s.closeButton} onClick={handleCloseClick}>
              <BaseIcon icon="close" size={9} />
            </button>
            <BaseIcon icon="gs-logo-white" className={s.logo} />
            <h3 className={s.heading}>Join the decentralized energy movement</h3>
            <p className={s.text}>
              <strong>3213</strong>People already built their decentralized energy communities.
            </p>
            <div className={s.globeParticlesContainer}>
              <img src={WorldGlobeParticles} className={s.globeParticles} />
              <img src={WorldGlobeParticlesLogos} className={s.globeParticlesLogos} />
            </div>
          </div>
        )}
        <BaseButton
          theme="primary"
          size="normal"
          className={s.buildButton}
          onClick={() => (window.location.pathname = routesConfig.scmMapCreate())}>
          Build your community
        </BaseButton>
      </BaseGenericModal>
    </div>
  );
};
