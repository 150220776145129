import {
  MarketSummary,
  ProgressInfo,
  ReadSimulationResultsBidTradeQuery,
  ReadSimulationResultsQuery,
} from 'src/graphql';
import { TSimResultsStatus } from 'src/typings/base-types';
import {
  TAssetsInfo,
  TBidsOffersTradesItem,
  TKpi,
  TBills,
  TBillsItem,
  TPriceEnergyDay,
  TPriceEnergyDayItem,
} from 'src/typings/simulation.types';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

type TBillsResult = {
  [Property in keyof TBills]: TBillsItem;
};

type TPriceEnergyDayResult = {
  [Property in keyof TPriceEnergyDay]: TPriceEnergyDayItem[];
};

type TBidsOffersTradesResult = {
  bids: TBidsOffersTradesItem[];
  offers: TBidsOffersTradesItem[];
  trades: TBidsOffersTradesItem[];
};

type TCumulativeGridTradesResult = {
  areaName: string;
  bars: {
    energy: number;
    targetArea: string;
  }[];
}[];

type TCumulativeBillsResult = {
  name: string;
  total: number;
  earned: number;
  penalties: number;
  spentTotal: number;
  penaltyEnergy: number;
};

type TCumulativeNetEnergyFlowResult = { [key: string]: number };

type TEnergyTradeProfileAreaThroughputResult = {
  areaThroughput: {
    [time: string]: {
      import: { peak_energy_net_kWh: number; peak_energy_trade_kWh: number };
      export: { peak_energy_net_kWh: number; peak_energy_trade_kWh: number };
      net_energy_flow: { peak_energy_kWh: number };
    };
  } | null;
  tradeProfile: {
    [key in 'bought_energy' | 'sold_energy']: {
      [area: string]: {
        [area in 'accumulated' | string]: {
          [date: string]: number;
        };
      };
    };
  };
};

type TDeviceStatisticsResult = {
  [key in
    | 'trade_price_eur'
    | 'trade_energy_kWh'
    | 'pv_production_kWh'
    | 'max_trade_price_eur'
    | 'min_trade_price_eur'
    | 'max_trade_energy_kWh'
    | 'min_trade_energy_kWh'
    | 'max_pv_production_kWh'
    | 'min_pv_production_kWh'
    | 'bought_trade_energy_kWh'
    | 'min_bought_trade_energy_kWh'
    | 'max_bought_trade_energy_kWh'
    | 'sold_trade_energy_kWh'
    | 'min_sold_trade_energy_kWh'
    | 'max_sold_trade_energy_kWh'
    | 'load_profile_kWh'
    | 'min_load_profile_kWh'
    | 'max_load_profile_kWh'
    | 'production_kWh'
    | 'min_production_kWh'
    | 'max_production_kWh'
    | 'soc_history_%'
    | 'min_soc_history_%'
    | 'max_soc_history_%'
    | string]?: {
    [date: string]: number | string | null;
  };
};

type TSavingsKpiProfileResult = {
  [date: string]: {
    base_case_cost: number | null;
    gsy_e_cost: number | null;
    saving_absolute: number | null;
    saving_percentage: number | null;
  };
};

type TLatestSavingsKpiResult = TSavingsKpiProfileResult['date'];

function parseValue<T>(value: unknown): T | undefined {
  try {
    return value && value !== '{}' ? (JSON.parse(value as string) as T) : undefined;
  } catch (e) {}
  return value as T | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function parseSimulationResults(
  results: ReadSimulationResultsQuery['simulationResults'] &
    ReadSimulationResultsBidTradeQuery['simulationResults'],
) {
  const marketSummary = parseValue<MarketSummary[]>(results?.marketSummary);
  const status = parseValue<TSimResultsStatus>(results?.status);
  const assetsInfo = parseValue<TAssetsInfo>(results?.assetsInfo);
  const progressInfo = parseValue<ProgressInfo>(results?.progressInfo);
  const kpi = parseValue<TKpi>(results?.kpi);
  const priceEnergyDay = parseValue<TPriceEnergyDayResult>(results?.priceEnergyDay);
  const bidsOffersTrades = parseValue<TBidsOffersTradesResult>(results?.bidsOffersTrades);
  const bills = parseValue<TBillsResult>(
    results?.bills,
  );
  const cumulativeBills = parseValue<TCumulativeBillsResult>(results?.cumulativeBills);
  const cumulativeGridTrades = parseValue<TCumulativeGridTradesResult>(
    results?.cumulativeGridTrades,
  );
  const cumulativeNetEnergyFlow = parseValue<TCumulativeNetEnergyFlowResult>(
    results?.cumulativeNetEnergyFlow,
  );
  const tradeProfileAreaThroughput = parseValue<TEnergyTradeProfileAreaThroughputResult>(
    results?.tradeProfileAreaThroughput,
  );
  const currentMarket = results?.currentMarket
    ? UTCMoment.fromBackend(results?.currentMarket, BACKEND_DATE_FORMATS.CURRENT_MARKET).unix()
    : undefined;
  const deviceStatistics = parseValue<TDeviceStatisticsResult>(results?.deviceStatistics);
  const savingsKpiProfile = parseValue<TSavingsKpiProfileResult>(results?.savingsKpiProfile);
  const latestSavingsKpi = parseValue<TLatestSavingsKpiResult>(results?.latestSavingsKpi);

  return {
    status,
    assetsInfo,
    kpi,
    marketSummary,
    bills,
    priceEnergyDay,
    cumulativeGridTrades,
    cumulativeNetEnergyFlow,
    cumulativeBills,
    tradeProfileAreaThroughput,
    currentMarket,
    bidsOffersTrades: bidsOffersTrades && {
      bids: bidsOffersTrades.bids || [],
      offers: bidsOffersTrades.offers || [],
      trades: bidsOffersTrades.trades || [],
    },
    progressInfo: progressInfo && {
      percentageCompleted: progressInfo.percentageCompleted || 0,
      etaSeconds: progressInfo.etaSeconds || 0,
      elapsedTimeSeconds: progressInfo.elapsedTimeSeconds || 0,
    },
    deviceStatistics,
    savingsKpiProfile,
    latestSavingsKpi,
  };
}
