import React, { useContext, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { AssetsViewUserTile } from 'src/components/MapSidebar/components/MapSidebarCanary/components/AssetsView/AssetsViewUserTile';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { selectSelectedMember } from 'src/redux/application/application.selectors';
import { setSelectedMember } from 'src/redux/application/application.slice';
import { selectReadOnly } from 'src/redux/configuration/configuration.selectors';
import { selectAssetsValues } from 'src/redux/configuration/configuration.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { selectIsAppLoading } from 'src/redux/scm/scm.selectors';
import { selectCommunityMembers } from 'src/redux/scm/scm.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { get3dAssetIcon } from 'src/utils/assetIcons';

import { AssetsCard } from './AssetsCard';
import s from './AssetsView.module.scss';
import { TAssetsViewProps } from './AssetsView.types';

export const AssetsView: React.FC<TAssetsViewProps> = ({
  assetsList,
  onAssetClick,
  onAddAssetClick,
  toggleAssetView,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { mapService } = useContext(WorldMapContext);

  const { children, data } = assetsList;
  const { zoomIntoConfiguration } = useConfigurationUtils();
  const { isUserACommunityMember, isAdmin } = useIsUserACommunityMember();

  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const communityMembers = useSelector(selectCommunityMembers);
  const readOnly = useSelector(selectReadOnly);
  const isAppLoading = useSelector(selectIsAppLoading);
  const selectedMember = useSelector(selectSelectedMember);
  const assetsValues = useSelector(selectAssetsValues);

  const currentMember = useMemo(
    () => communityMembers.find((item) => item.uuid === selectedMember),
    [communityMembers, selectedMember],
  );

  const showAllMembersButton = selectedMember !== null;

  useEffect(() => {
    if (isUserACommunityMember && mapService) {
      let isBusy = true;
      const customListener = setInterval(() => {
        isBusy =
          mapService.map.isEasing() || mapService.map.isMoving() || mapService.map.isRotating();
        if (isBusy === false) {
          clearInterval(customListener);
          mapService.set3DView(true);
        }
      }, 1000);
    }
  }, [isUserACommunityMember, mapService]);

  const handleAssetClick = (data) => {
    onAssetClick?.(assetsList);
    dispatch(setSelectedAssetUuid(data.uuid));
  };

  const onClickAllMembers = () => {
    dispatch(setSelectedMember(null));
    dispatch(setSelectedAssetUuid(undefined));
    zoomIntoConfiguration({ assetsValues });
    toggleAssetView!();
  };

  const isNewMemberForm = selectedMember === 'NEW_MEMBER';

  return (
    <>
      {showAllMembersButton && (
        <div className={s.header} onClick={onClickAllMembers}>
          <BaseIcon size={14} icon="arrow-left-full" className={s.iconBack} />
          {t('navigation.ALL_MEMBERS')}
        </div>
      )}

      {!showAllMembersButton && (
        <div className={s.topButtonRight}>
          <BaseButtonSquare
            size="2"
            svgSize="3"
            icon="close"
            theme="flat-gray-dark"
            onClick={toggleAssetView}
          />
        </div>
      )}

      <div className={s.container}>
        {isNewMemberForm ? (
          <AssetsViewUserTile
            uuid={`${selectedMember}`}
            name={''}
            avatarUrl={undefined}
            // @ts-ignore
            email={undefined}
            address={undefined}
            zipCode={undefined}
            isAdmin={isAdmin}
            isNewUser={selectedMember === null || false}
            toggleAssetView={toggleAssetView}
          />
        ) : (
          currentMember && (
            <div key={currentMember.uuid}>
              <AssetsViewUserTile
                uuid={currentMember.uuid}
                name={currentMember.name}
                email={currentMember.email ?? currentMember.name}
                address={currentMember?.address}
                zipCode={currentMember?.zipCode}
                toggleAssetView={toggleAssetView}
              />
            </div>
          )
        )}
        {!isNewMemberForm && data && (
          <div className={s.assetsContainer}>
            <AssetsCard
              isUserACommunityMember={isUserACommunityMember}
              key={data.uuid}
              data={data}
              assetIcon={get3dAssetIcon(data.type)}
              childrenCount={children.length}
              onClick={() => handleAssetClick(data)}
              isMoreButtonActive={false}
            />
          </div>
        )}
        {children && children.length ? (
          <div className={s.assetsContainer}>
            {[...children].map((asset) => {
              return (
                <AssetsCard
                  isUserACommunityMember={isUserACommunityMember}
                  key={asset.uuid}
                  data={asset}
                  assetIcon={get3dAssetIcon(asset.type)}
                  isMoreButtonActive={false}
                />
              );
            })}
          </div>
        ) : (
          !isNewMemberForm && (
            <div className={s.noAssetsContainer}>
              <div className={s.noAssetPill}>{t('messages.NO_ASSETS')}</div>
            </div>
          )
        )}
        {!readOnly && showAllMembersButton && !isUserACommunityMember && !isNewMemberForm && (
          <BaseButton
            isLoading={isAppLoading}
            disabled={isOperationalCommunity}
            onClick={onAddAssetClick}
            theme="grey2"
            className={s.addAssetButton}
            icon="plus-circle">
            {t('commands.ADD_ASSET')}
          </BaseButton>
        )}
      </div>
    </>
  );
};
