import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PASSWORD_MIN_LENGTH } from 'src/constants/application';

import { useUpdateUserMutation } from '../../graphql';
import { useGraphQLMessage } from '../../hooks/useGraphQLMessage';
import { TValidationObject, rules, validateFields } from '../../utils/fieldValidation';
import { BaseButton } from '../BaseButton';
import { BaseErrorMessage, TBaseErrorMessageProps } from '../BaseErrorMessage';
import { BaseInput, TBaseInputProps } from '../BaseInput';
import { BaseLink } from '../BaseLink';
import { LoadingWrapper } from '../LoadingWrapper';
import s from './FormEditPassword.module.scss';
import { TFormEditPasswordProps } from './FormEditPassword.types';

const initFormData = {
  oldPassword: '',
  newPassword: '',
  repeatedNewPassword: '',
};

export const FormEditPassword: React.FC<TFormEditPasswordProps> = () => {
  const { t } = useTranslation();

  const [updateUserMutation, { loading, error }] = useUpdateUserMutation();
  const graphqlMessage = useGraphQLMessage({ error });
  const [formData, setFormData] = useState(initFormData);
  const [passwordSuccessfulChange, setPasswordSuccessfulChange] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  const validators = () => ({
    password: [...((n) => [rules.required(n)])('Password')],
    passwordNew: [
      ...((n) => [rules.required(n), rules.minLength(n, PASSWORD_MIN_LENGTH)])('Password'),
    ],
    passwordRepeated: [
      ...((n) => [
        rules.required(n),
        rules.minLength(n, PASSWORD_MIN_LENGTH),
        rules.passwordMatch(formData.newPassword),
      ])('Password'),
    ],
  });

  const handleFieldChange: TBaseInputProps['onChange'] = ({ value, name }) => {
    setFormData((curr) => ({
      ...curr,
      [name]: value,
    }));
  };

  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { errors } = validateFields({
      validators: validators(),
      formData,
    });

    setValidationErrors(errors || {});

    // todo - verify check
    if (!errors) {
      return;
    }

    try {
      await updateUserMutation({
        variables: {
          password: formData.newPassword,
          passwordRepeat: formData.repeatedNewPassword,
        },
      });
      setPasswordSuccessfulChange(true);
    } catch (err) {
      setPasswordSuccessfulChange(false);
    }
  }

  return (
    <form onSubmit={handleSubmit} className={s.formWrapper}>
      <div className={s.formWrapperTop}>
        <BaseLink className={s.editPassword} isActive={true} theme="2">
          {t('navigation.EDIT_PASSWORD')}
        </BaseLink>
        <BaseInput
          type="password"
          name="oldPassword"
          value={formData.oldPassword}
          onChange={handleFieldChange}
          label={`${t('labels.OLD_PASSWORD')}`}
          theme="line-light"
          disabled={loading}
          error={validationErrors.oldPassword}
          onBlur={(e) => handleFieldBlur(e, validators().password)}
        />
      </div>
      <div className={s.divider}></div>
      <div className={s.formWrapperBottom}>
        <BaseInput
          type="password"
          name="newPassword"
          value={formData.newPassword}
          onChange={handleFieldChange}
          label={`${t('labels.NEW_PASSWORD')}`}
          theme="line-light"
          disabled={loading}
          className={s.inputEdit}
          error={validationErrors.newPassword}
          onBlur={(e) => handleFieldBlur(e, validators().passwordNew)}
        />
        <BaseInput
          type="password"
          name="repeatedNewPassword"
          value={formData.repeatedNewPassword}
          onChange={handleFieldChange}
          label={`${t('labels.REPEAT_NEW_PASSWORD')}`}
          theme="line-light"
          disabled={loading}
          className={s.inputEdit}
          error={validationErrors.repeatedNewPassword}
          onBlur={(e) => handleFieldBlur(e, validators().passwordRepeated)}
        />
        {graphqlMessage && (
          <BaseErrorMessage className={s.queryMessage}>{graphqlMessage}</BaseErrorMessage>
        )}
        {passwordSuccessfulChange ? (
          <div className={s.successMessage}>{t('messages.PASSWORD_CHANGED')}</div>
        ) : (
          <LoadingWrapper className={s.loader} loading={loading}>
            {() => (
              <BaseButton type="submit" className={s.buttonLogin}>
                {t('commands.SAVE')}
              </BaseButton>
            )}
          </LoadingWrapper>
        )}
      </div>
    </form>
  );
};
