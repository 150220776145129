import React, { useEffect } from 'react';

import { useHistory } from 'react-router';
import { ModalChangePassword } from 'src/components/ModalChangePassword';
import { EPredefinedModalIds } from 'src/constants/modals';
import { TResetPasswordProps } from 'src/pages/ResetPassword';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { TemplateDefault } from 'src/templates/TemplateDefault';

export const ResetPassword: React.FC<TResetPasswordProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(openModal(EPredefinedModalIds.MODAL_CHANGE_PASSWORD));

    return () => {
      dispatch(closeModal(EPredefinedModalIds.MODAL_CHANGE_PASSWORD));
    };
  }, [dispatch]);

  return (
    <TemplateDefault>
      <ModalChangePassword
        resetId={match.params.resetId}
        resetKey={match.params.resetKey}
        onClose={() => {
          history.push(routesConfig.singularityMap());
        }}
      />
    </TemplateDefault>
  );
};
