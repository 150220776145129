import React from 'react';

import vars from 'src/assets/styles/utils/vars.module.scss';
import { uploadedFilesCache } from 'src/cache/uploadedFiles';
import { BaseInput } from 'src/components/BaseInput';
import useDownloadAPI from 'src/hooks/useDownloadAPI';
import { isLibrary } from 'src/mocks/configurationSettings';
import { TFileIdentifiers } from 'src/typings/base-types';
import { isJson } from 'src/utils/isJson';

import { TBaseFileUploadProps } from './BaseFileUpload.types';

export const BaseFileUpload: React.FC<TBaseFileUploadProps> = ({
  className,
  value,
  name,
  label,
  accept = '.csv',
  disabled,
  theme,
  onChange,
  onBlur,
  error,
  showTooltip,
  tooltipText,
  allowDownload,
  fileUuid,
  configUuid,
  unit,
}: TBaseFileUploadProps) => {
  let filename = !value || typeof value === 'string' ? value || '' : value.name;
  let filenamestring;

  if (value && isJson(value)) {
    filenamestring = JSON.parse(filename);
    filename = filenamestring.filename || filenamestring.name || filename;
  }
  const { handleDownload, error: downloadError, loading: downloadLoading } = useDownloadAPI({
    type: 'uploaded-file',
    filename,
    fileUuid,
    isLibrary,
    configUuid,
  });
  return (
    <BaseInput
      type="file"
      filename={filename}
      onChange={({ name, value }) => {
        let newValue: TFileIdentifiers | '' = '';

        if (value instanceof File) {
          newValue = {
            name: value.name,
            lastModified: value.lastModified,
            size: value.size,
          };

          // We store files in separated object because Redux doesn't support unserializable data like "File"
          uploadedFilesCache.set(value);
        }

        onChange?.({ name, value: newValue });
      }}
      onBlur={(e) => {
        onBlur?.(e);
      }}
      className={className}
      name={name}
      showTooltip={showTooltip}
      label={label}
      accept={accept}
      disabled={disabled}
      theme={theme}
      tooltipText={tooltipText}
      error={error || downloadError}
      forceTooltipForFileName={true}
      forceTopLabel={true}
      fileWithUnitType={!!unit}
      unit={unit}
      {...(allowDownload &&
        configUuid &&
        (fileUuid || filename) && {
          iconRight: downloadLoading ? 'spinner' : 'download',
          iconRightSize: 16,
          iconRightColor: vars['color-oh-so-green'],
          iconRightOnClick: handleDownload,
        })}
    />
  );
};
