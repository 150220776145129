import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { EPredefinedModalIds } from 'src/constants/modals';

export type TModalContent = {
  title?: string;
  subTitle?: string;
  icon: TIconNames;
};

export type TModalsState = {
  activeModals: string[];
  modalContent: TModalContent;
  positionBGM: TPositionBGM[];
};

// BGM : BaseGenericModal
export type TPositionBGM = {
  modalId: EPredefinedModalIds;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

const initialState: TModalsState = {
  activeModals: [],
  modalContent: {
    icon: 'file',
  },
  positionBGM: [],
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<string>) {
      return {
        ...state,
        activeModals: state.activeModals.includes(action.payload)
          ? state.activeModals
          : [...state.activeModals, action.payload],
      };
    },
    closeModal(state, action: PayloadAction<string>) {
      return {
        ...state,
        activeModals: state.activeModals.filter((item) => item !== action.payload),
      };
    },
    toggleModal(state, action: PayloadAction<string>) {
      return {
        ...state,
        activeModals: state.activeModals.includes(action.payload)
          ? state.activeModals.filter((item) => item !== action.payload)
          : [...state.activeModals, action.payload],
      };
    },
    updateModalContent(state, action) {
      return {
        ...state,
        modalContent: action.payload,
      };
    },
    resetModalContent(state) {
      return {
        ...state,
        modalContent: initialState.modalContent,
      };
    },
    setPositionBGM(state, action: PayloadAction<TPositionBGM>) {
      return {
        ...state,
        positionBGM: [
          ...state.positionBGM.filter((item) => item.modalId !== action.payload.modalId),
          action.payload,
        ],
      };
    },
    deletePositionBGM(state, action: PayloadAction<string>) {
      return {
        ...state,
        positionBGM: state.positionBGM.filter((item) => item.modalId !== action.payload),
      };
    },
  },
});

// Actions
export const {
  openModal,
  closeModal,
  toggleModal,
  updateModalContent,
  resetModalContent,
  setPositionBGM,
  deletePositionBGM,
} = modalsSlice.actions;

// Reducer
export const modalsReducer = modalsSlice.reducer;
