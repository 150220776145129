import { useEffect } from 'react';

import { TPositionBGM, deletePositionBGM, setPositionBGM } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';

export type TUsePositionBGMArgs = TPositionBGM;

export const usePositionBGM = ({
  modalId,
  top,
  left,
  right,
  bottom,
}: TUsePositionBGMArgs): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPositionBGM({ modalId, top, left, right, bottom }));
    return () => dispatch(deletePositionBGM(modalId));
  }, [dispatch, modalId, top, left, right, bottom]);
};
