export const toPercentage = (value: number): number => {
  return Math.floor(value * 100);
};

export const withCurrency = (currencySymbol: string, value: number): string => {
  return `${currencySymbol}${value > 10 ? Math.round(value) : value.toFixed(2).replace('.', ',')}`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createBillInfo = (
  labelColor: string,
  label: string,
  percentage: number,
  cost: number,
  currencySymbol: string,
) => {
  return {
    labelColor,
    label,
    percentage: toPercentage(percentage),
    cost: withCurrency(currencySymbol, cost),
  };
};
