
import React from 'react';

import classnames from 'classnames';

import s from './LoadingWrapper.module.scss';
import { TLoadingProps, TLoadingWrapperProps } from './LoadingWrapper.types';

export const Loader = ({ className, style, onClick }: TLoadingProps): JSX.Element => (
  <div className={classnames(className, s.container)} style={style} onClick={onClick}>
    <svg className={s.spinner} viewBox="0 0 50 50">
      <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
    </svg>
  </div>
);

export const LoadingWrapper: React.FC<TLoadingWrapperProps> = ({
  className,
  style,
  loading,
  children,
  onClick,
}) => {
  if (loading) return <Loader className={className} style={style} onClick={onClick} />;
  return children();
};
