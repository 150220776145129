import { useCallback, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { EPredefinedModalIds } from 'src/constants/modals';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useGetConfigurationToUse } from 'src/hooks/useGetConfigurationToUse';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  selectActiveConfigurationUuid,
  selectConfigurationCenter,
} from 'src/redux/configuration/configuration.selectors';
import {
  setActiveConfigurationUuid,
  setIsCNLaunching,
} from 'src/redux/configuration/configuration.slice';
import { closeModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { EUserRoles } from 'src/typings/base-types';

export type LaunchToCanaryArgs = {
  openedConfigurationUuid?: string;
};

export type TUseLaunchToCanaryResponse = {
  launchToCanary: (payload?: LaunchToCanaryArgs) => void;
};

export function useLaunchToCanary(): TUseLaunchToCanaryResponse {
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const userRole = useSelector(selectUserRole);
  const configurationCenter = useSelector(selectConfigurationCenter);
  const configurationUtils = useConfigurationUtils();

  const dispatch = useAppDispatch();
  const CanaryNetworkService = useContext(CanaryNetworkServiceContext);
  const { getTheNameOfLaunchConfiguration } = useGetConfigurationToUse();
  const currentTimezone = useMemo(() => {
    if (configurationCenter && configurationCenter.lng) {
      const possibleTimezone = `GMT${configurationCenter.lng > 0 ? '+' : ''}${Math.round(
        (configurationCenter.lng * 24) / 360,
      )}`;
      return possibleTimezone || 'Europe/Berlin';
    }
    return 'Europe/Berlin';
  }, [configurationCenter]);

  const launchToCanary = useCallback<(payload?: LaunchToCanaryArgs) => void>(
    async (payload) => {
      dispatch(setIsCNLaunching(true));
      const configurationUuidForToUse = payload?.openedConfigurationUuid || activeConfigurationUuid;

      if (!configurationUuidForToUse) return;

      const cName = getTheNameOfLaunchConfiguration({ configurationUuidForToUse });

      // This part has been deleted because the logic is not true.
      // It has to compare with the canary network list
      // and "CanaryNetworkService" does it
      // const cnNames = communitiesList.map((item) => item!.name!) || [];
      // const name = createCNName(cName, cnNames);

      const response = await CanaryNetworkService.launchToCanary(configurationUuidForToUse, {
        timezone: currentTimezone,
        name: cName,
      });

      if (response) {
        if (userRole === EUserRoles.Admin) {
          configurationUtils.discardCurrentConfiguration();
          setTimeout(() => {
            dispatch(setActiveConfigurationUuid(response));
          }, 0);
        } else {
          dispatch(closeModal(EPredefinedModalIds.MODAL_CANARY_REQUEST));
          dispatch(
            openToast({
              message: 'An agent will get in touch with you shortly.',
              type: 'success',
            }),
          );
          //CanaryNetworkService.fetchGlobalListUserToAdminRequests();
        }
      }
      dispatch(setIsCNLaunching(false));
    },
    [
      currentTimezone,
      activeConfigurationUuid,
      configurationUtils,
      userRole,
      dispatch,
      CanaryNetworkService,
      getTheNameOfLaunchConfiguration,
    ],
  );

  return { launchToCanary };
}
