import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { ESCMGroupSettingsName } from 'src/utils/assetsFields/assetsFields.types';

import s from './TableBills.module.scss';

export const TableBillsHeader: React.FC = () => {
  const { t } = useTranslation();

  const { scmGroupSettingsName } = useSelector(selectSettingsData);

  /**
   * For instance this below would load the configurations including logo, color and etc
   * according to scmGroupSettingsName
   */

  const isVergy = useMemo(() => scmGroupSettingsName === ESCMGroupSettingsName.VERGY, [
    scmGroupSettingsName,
  ]);
  return (
    <thead>
      <tr className={s.darkTableHeader}>
        <th style={{ width: '20%' }}></th>
        <th className={s.rightBorder} colSpan={3}>
          {t('labels.BOUGHT_FROM_GRID')}
        </th>
        <th className={s.rightBorder} colSpan={2}>
          {t('labels.SOLD_TO_GRID')}
        </th>
        {isVergy ? (
          <> </>
        ) : (
          <>
            <th className={s.rightBorder} colSpan={2}>
              {t('labels.BOUGHT_FROM_COMMUNITY')}
            </th>
            <th className={s.rightBorder} colSpan={2}>
              {t('labels.SOLD_TO_COMMUNITY')}
            </th>
          </>
        )}
        <th colSpan={2}>{t('labels.TOTAL_BALANCE')}</th>
      </tr>
      <tr className={s.columnTitlesHeader}>
        <th className={s.textLeft}>{t('common.ASSET')}</th>
        <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
        <th colSpan={2}>{t('common.PAID')} (€)</th>
        <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
        <th>{t('common.REVENUE')} (€)</th>
        {isVergy ? (
          <></>
        ) : (
          <>
            <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
            <th>{t('common.PAID')} (€)</th>
            <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
            <th>{t('common.REVENUE')} (€)</th>
          </>
        )}

        <th className={s.greyBackground}>{t('common.ENERGY')} (kWh)</th>
        <th>{t('common.TOTAL')} (€)</th>
      </tr>
      <tr className={s.informationsHeader}>
        <th></th>
        <th></th>
        <th className={s.informationCell}>{t('labels.TRADE_COST')} (€)</th>
        <th className={s.informationCell}>{t('labels.GRID_FEES')} (€)</th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
};
