import React from 'react';

import classnames from 'classnames';

import s from './BaseErrorMessage.module.scss';
import { TBaseErrorMessageProps } from './BaseErrorMessage.types';

export const BaseErrorMessage: React.FC<TBaseErrorMessageProps> = ({
  className,
  width,
  children,
}) => {
  if (!children) return null;

  return (
    <div
      className={classnames(s.container, className)}
      style={{ ['--width' as string]: width }}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};
