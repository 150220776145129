import { ConfigType } from 'src/graphql';
import { EFormVariant } from 'src/typings/base-types';
import { TFieldsTemplateUnion } from 'src/utils/assetsFields/assetsFields.types';

export type THeatPumpArgs = {
  configType: ConfigType;
};

export const HeatPump = ({ configType }: THeatPumpArgs): TFieldsTemplateUnion[] => {
  const isDisabled = configType === ConfigType.CanaryNetwork;
  return [
    {
      name: 'name',
      type: 'text',
      label: 'Asset Name',
      formView: EFormVariant.Express,
    },
    {
      name: 'geoTagLocation',
      type: 'location',
      label: 'Location',
      tooltipText: 'Parent market already has location',
      formView: EFormVariant.Express,
    },
    {
      name: 'consumptionKwhProfile',
      type: 'file',
      label: 'Upload Profile',
      tooltipText: 'Please upload heat pump consumption profile here. ',
      formView: EFormVariant.Advanced,
      disabled: isDisabled,
    },
  ];
};
