import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AuthRegisterForms } from 'src/components/AuthRegisterForms';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';

import s from './ModalAuth.module.scss';
import { TModalAuthProps } from './ModalAuth.types';

export const ModalAuth: React.FC<TModalAuthProps> = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { routeName } = useAppLocation();

  const isLoginModalOpened = useSelector(selectIsModalOpened(EPredefinedModalIds.MODAL_AUTH_LOGIN));
  const isRegisterModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_AUTH_REGISTER),
  );

  const modalId = useMemo(() => {
    if (isLoginModalOpened) {
      return EPredefinedModalIds.MODAL_AUTH_LOGIN;
    }

    if (isRegisterModalOpened) {
      return EPredefinedModalIds.MODAL_AUTH_REGISTER;
    }

    return '';
  }, [isLoginModalOpened, isRegisterModalOpened]);

  const [showLoginBlock, setShowLoginBlock] = useState<boolean>(isLoginModalOpened);

  useLayoutEffect(() => {
    setShowLoginBlock(modalId === EPredefinedModalIds.MODAL_AUTH_LOGIN);
  }, [modalId]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(closeModal(modalId));
    });
    return unlisten;
  }, [history, dispatch, modalId]);

  function closeAuthModal() {
    dispatch(closeModal(modalId));

    if (routeName === routesConfig.accountActivated()) {
      history.push(routesConfig.singularityMap());
    }
  }

  if (!isLoginModalOpened && !isRegisterModalOpened) return null;

  return (
    <>
      <div className={s.background} />
      <BaseGenericModal
        className={s.modal}
        modalId={modalId}
        size={showLoginBlock ? EModalSize.XL465 : EModalSize.XL500}
        modalRef={modalRef}>
        <AuthRegisterForms
          showCloseButton
          onCloseButtonClick={() => {
            closeAuthModal();
          }}
          onSuccess={() => {
            closeAuthModal();
          }}
        />
      </BaseGenericModal>
    </>
  );
};
