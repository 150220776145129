import { ApolloCache, FetchResult } from '@apollo/client';
import produce from 'immer';
import {
  CreateConfigurationMutation,
  DeleteConfigurationMutation,
  DeleteProjectMutation,
  ListCanaryNetworksDocument,
  ListCanaryNetworksQuery,
  ListCanaryNetworksStatusesQueryVariables,
  ListCollaborationsDocument,
  ListCollaborationsQuery,
  ListCollaborationsStatusesQueryVariables,
  ListCommunityInfoDocument,
  ListCommunityInfoQuery,
  ListCommunityInfoQueryVariables,
  UpdateConfigurationMutation,
} from 'src/graphql';

export const cacheUpdate = {
  createConfiguration(
    cache: ApolloCache<CreateConfigurationMutation>,
    {
      data,
    }: FetchResult<CreateConfigurationMutation, Record<string, unknown>, Record<string, unknown>>,
    {
      latitude,
      longitude,
      locationVisible,
    }: { latitude: number | undefined; longitude: number | undefined; locationVisible: boolean },
  ): void {
    const createdConfiguration = data?.createConfiguration;

    if (!createdConfiguration) return;

    const cachedData = cache.readQuery<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>({
      query: ListCommunityInfoDocument,
    });

    if (!cachedData) return;

    const newData = produce(cachedData, (draftState) => {
      const projects = draftState?.listCommunityInfo?.projects;

      if (!projects) return;

      projects.push({
        name: createdConfiguration.name,
        uuid: createdConfiguration.project?.uuid,
        user: createdConfiguration.user,
        timestamp: createdConfiguration.timestamp,
        private: createdConfiguration.private,
        latitude,
        longitude,
        locationVisible,
        configurations: [
          {
            name: createdConfiguration.name,
            uuid: createdConfiguration.uuid,
            configType: createdConfiguration.configType,
            globalCumulativeNetEnergyFlow: null,
            latitude: null,
            longitude: null,
          },
        ],
      });
    });

    cache.writeQuery<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>({
      query: ListCommunityInfoDocument,
      data: newData,
    });
  },

  updateConfiguration(
    cache: ApolloCache<UpdateConfigurationMutation>,
    {
      data,
    }: FetchResult<UpdateConfigurationMutation, Record<string, unknown>, Record<string, unknown>>,
    updatedData: {
      projectUuid: string;
      name: string;
      latitude: number | undefined;
      longitude: number | undefined;
      locationVisible: boolean;
    },
  ): void {
    const updatedConfiguration = data?.updateConfiguration;

    if (!updatedConfiguration) return;

    const cachedData = cache.readQuery<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>({
      query: ListCommunityInfoDocument,
    });

    if (!cachedData) return;

    const newData = produce(cachedData, (draftState) => {
      const project = draftState?.listCommunityInfo?.projects?.find(
        (p) => p?.uuid === updatedData.projectUuid,
      );

      if (project) {
        project.name = updatedData.name;
        project.latitude = updatedData.latitude;
        project.longitude = updatedData.longitude;
        project.locationVisible = updatedData.locationVisible;

        const [configuration] = project.configurations || [];

        if (configuration) {
          configuration.name = updatedConfiguration.name;
          configuration.latitude = null;
          configuration.longitude = null;
        }
      }
    });

    cache.writeQuery<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>({
      query: ListCommunityInfoDocument,
      data: newData,
    });
  },

  deleteProject(
    cache: ApolloCache<DeleteProjectMutation>,
    { deletedProjectUuid }: { deletedProjectUuid: string },
  ): void {
    const cachedData = cache.readQuery<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>({
      query: ListCommunityInfoDocument,
    });

    if (!cachedData) return;

    const newData = produce(cachedData, (draftState) => {
      const projects = draftState?.listCommunityInfo?.projects;

      if (!projects) return;

      const index = projects.findIndex((p) => p?.uuid === deletedProjectUuid);

      if (index > -1) {
        projects.splice(index, 1);
      }
    });

    cache.writeQuery<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>({
      query: ListCommunityInfoDocument,
      data: newData,
    });
  },
  deleteConfiguration(
    cache: ApolloCache<DeleteConfigurationMutation>,
    { deletedProjectUuid }: { deletedProjectUuid: string },
  ): void {
    const cachedData = cache.readQuery<
      ListCanaryNetworksQuery,
      ListCanaryNetworksStatusesQueryVariables
    >({
      query: ListCanaryNetworksDocument,
    });
    if (!cachedData) return;

    const newData = produce(cachedData, (draftState) => {
      const projects = draftState?.listCanaryNetworks?.configurations;
      if (!projects) return;
      const index = projects.findIndex((p) => p?.uuid === deletedProjectUuid);
      if (index > -1) {
        projects.splice(index, 1);
      }
    });

    cache.writeQuery<ListCanaryNetworksQuery, ListCanaryNetworksStatusesQueryVariables>({
      query: ListCanaryNetworksDocument,
      data: newData,
    });
  },

  deleteCollaboration(
    cache: ApolloCache<DeleteConfigurationMutation>,
    { deletedProjectUuid }: { deletedProjectUuid: string },
  ): void {
    const cachedData = cache.readQuery<
      ListCollaborationsQuery,
      ListCollaborationsStatusesQueryVariables
    >({
      query: ListCollaborationsDocument,
    });

    if (!cachedData) return;

    const newData = produce(cachedData, (draftState) => {
      const projects = draftState?.listCollaborations?.configurations;
      if (!projects) return;
      const index = projects.findIndex((p) => p?.uuid === deletedProjectUuid);
      if (index > -1) {
        projects.splice(index, 1);
      }
    });

    cache.writeQuery<ListCollaborationsQuery, ListCollaborationsStatusesQueryVariables>({
      query: ListCollaborationsDocument,
      data: newData,
    });
  },
};
