import React, { useMemo } from 'react';

import classnames from 'classnames';
import { LocationDescriptorObject } from 'history';
import { Link, NavLink } from 'react-router-dom';
import { NAV_LINKS } from 'src/components/Header/constants';

import s from './BaseButtonOrLink.module.scss';
import { TBaseButtonOrLinkProps } from './BaseButtonOrLink.types';

export const BaseButtonOrLink: React.FC<TBaseButtonOrLinkProps> = ({
  className,
  type = 'button',
  disabled = false,
  children,
  isNavLink,
  to,
  onClick,
  form,
  target,
  propRef,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let Component: any = 'button';
  const rest = {};

  const isHeaderNavLink = useMemo(
    () => [...NAV_LINKS].findIndex((x) => x.title === children) >= 0,
    [children],
  );

  if (to) {
    let isExternalLink = false;

    if (typeof to === 'string') {
      isExternalLink = to.includes('://') || to.includes('mailto');
    } else if ((to as LocationDescriptorObject).pathname) {
      isExternalLink = (to as LocationDescriptorObject).pathname!.includes('://');
    }

    if (isExternalLink) {
      Component = 'a';
      Object.assign(rest, {
        href: to,
        target,
        ...(target === '_blank' && { rel: 'noopener noreferrer' }),
      });
    } else {
      Component = isNavLink ? NavLink : Link;
      Object.assign(rest, {
        to,
      });
    }
  }

  return (
    <Component
      className={classnames(className, {
        // Fixed PH-1296 -this will disable the link if the route is currently active
        [s.disabledLink]: isNavLink && isHeaderNavLink,
      })}
      onClick={onClick}
      type={type}
      disabled={disabled}
      form={form}
      ref={(ref) => propRef && propRef(ref)}
      {...rest}>
      {children}
    </Component>
  );
};
