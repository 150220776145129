import React, { useMemo } from 'react';

import { TMeasurementNotificationListItemProps } from 'src/components/MeasurementNotificationList/MeasurementNotificationList.types';
import {
  MeasurementAcceptedPhrase,
  MeasurementRejectedPhrase,
  MeasurementStatusIcon,
} from 'src/components/MeasurementNotificationList/MeasurementNotificationListHelperComponents';
import { UserRequestsStatus } from 'src/graphql';

import s from './MeasurementNotificationList.module.scss';

export const MeasurementResultListItem: React.FC<TMeasurementNotificationListItemProps> = ({
  notification,
}) => {
  const childrenPhrase = useMemo(() => notification.children?.map((item) => item.name).join(', '), [
    notification,
  ]);

  if (notification.status === UserRequestsStatus.Received) return null;

  return (
    <li>
      <div className={s.icon}>
        <MeasurementStatusIcon status={notification.status} />
      </div>
      <div className={s.content}>
        {notification.status === UserRequestsStatus.Executed && (
          <MeasurementAcceptedPhrase
            assetName={notification.asset.name}
            childrenPhrase={childrenPhrase}
          />
        )}
        {notification.status === UserRequestsStatus.Declined && (
          <MeasurementRejectedPhrase
            assetName={notification.asset.name}
            childrenPhrase={childrenPhrase}
          />
        )}
      </div>
    </li>
  );
};
