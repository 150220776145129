import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { UserRequestResponse, useRespondToSimulationToCnRequestMutation } from 'src/graphql';
import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import {
  deleteAllGlobalNotifications,
  deleteGlobalNotification,
} from 'src/redux/notifications/notificaitons.slice';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { TNotificationsModalListProps } from 'src/typings/base-types';

import s from './NotificationsModalList.module.scss';

export const NotificationsModalList: React.FC<TNotificationsModalListProps> = ({
  items,
  onChange,
  className,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [respondToSimulationToCnRequest] = useRespondToSimulationToCnRequestMutation();
  const [loadingItems, setLoadingItems] = useState<string[]>([]);

  const acceptSingleItem = async (uuid: string) => {
    setLoadingItems((loadingItems) =>
      loadingItems.indexOf(uuid) === -1 ? [...loadingItems, uuid] : [...loadingItems],
    );
    try {
      const { data } = await respondToSimulationToCnRequest({
        variables: {
          action: UserRequestResponse.Execute,
          requestId: uuid,
        },
      });
      if (!data?.respondToSimulationToCnRequest?.configuration?.uuid) throw new Error();
      dispatch(
        openToast({
          message: 'Request accepted',
          type: 'success',
        }),
      );
      dispatch(setActiveConfigurationUuid(data.respondToSimulationToCnRequest.configuration.uuid));
    } catch (err) {
      dispatch(
        openToast({
          message: 'Something went wrong',
          type: 'error',
        }),
      );
    }
    setLoadingItems(loadingItems.filter((item) => item !== uuid));
    dispatch(deleteGlobalNotification(uuid));
    onChange();
  };

  const rejectSingleItem = async (uuid: string) => {
    setLoadingItems((loadingItems) =>
      loadingItems.indexOf(uuid) === -1 ? [...loadingItems, uuid] : [...loadingItems],
    );
    try {
      await respondToSimulationToCnRequest({
        variables: {
          action: UserRequestResponse.Reject,
          requestId: uuid,
        },
      });
      dispatch(
        openToast({
          message: 'Request rejected',
          type: 'success',
        }),
      );
    } catch (err) {
      dispatch(
        openToast({
          message: 'Something went wrong',
          type: 'error',
        }),
      );
    }
    setLoadingItems(loadingItems.filter((item) => item !== uuid));
    dispatch(deleteGlobalNotification(uuid));
    onChange();
  };

  const acceptAll = async () => {
    await Promise.all(
      items.map(async ({ uuid }) => {
        setLoadingItems((loadingItems) =>
          loadingItems.indexOf(uuid) === -1 ? [...loadingItems, uuid] : [...loadingItems],
        );
        try {
          await respondToSimulationToCnRequest({
            variables: {
              action: UserRequestResponse.Execute,
              requestId: uuid,
            },
          });
        } catch (err) {
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
        }
        setLoadingItems(loadingItems.filter((item) => item !== uuid));
      }),
    );
    dispatch(
      openToast({
        message: 'All request accepted',
        type: 'success',
      }),
    );
    dispatch(deleteAllGlobalNotifications());
    onChange();
  };

  const rejectAll = async () => {
    await Promise.all(
      items.map(async ({ uuid }) => {
        setLoadingItems((loadingItems) =>
          loadingItems.indexOf(uuid) === -1 ? [...loadingItems, uuid] : [...loadingItems],
        );
        try {
          await respondToSimulationToCnRequest({
            variables: {
              action: UserRequestResponse.Reject,
              requestId: uuid,
            },
          });
        } catch (err) {
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
        }
        setLoadingItems(loadingItems.filter((item) => item !== uuid));
      }),
    );
    dispatch(
      openToast({
        message: 'All request rejected',
        type: 'success',
      }),
    );
    dispatch(deleteAllGlobalNotifications());
    onChange();
  };

  return (
    <div className={classNames(s.container, className)}>
      <ul>
        {items.map((item) => (
          <li key={item.uuid}>
            {loadingItems.includes(item.uuid) ? (
              <div className={s.loader}>
                <svg className={s.spinner} viewBox="0 0 32 32">
                  <circle
                    className={s.path}
                    cx="16"
                    cy="16"
                    r="12"
                    fill="none"
                    strokeWidth="3"></circle>
                </svg>
              </div>
            ) : (
              <div
                className={s.avatar}
                style={{ backgroundImage: `url(${item.profileThumbnail})` }}></div>
            )}
            <div className={s.content}>
              <p>{item.name}</p>
              <p>
                {item.description}{' '}
                {item.descriptionAction && (
                  <button type="button" onClick={item.descriptionAction.action}>
                    {item.descriptionAction.title}
                  </button>
                )}
              </p>
            </div>
            <button type="button" title="Accept" onClick={() => acceptSingleItem(item.uuid)}>
              <BaseIcon icon="check-mark" size={12} />
            </button>
            <button type="button" title="Reject" onClick={() => rejectSingleItem(item.uuid)}>
              <BaseIcon icon="close" size={8} />
            </button>
          </li>
        ))}
      </ul>
      {!items.length && <p>{t('messages.NO_NOTIFICATIONS')}</p>}
      {items.length && (
        <div className={s.commonActions}>
          <BaseButton theme="grey-border" size="small" onClick={rejectAll}>
            Reject All
          </BaseButton>
          <BaseButton theme="primary" size="small" onClick={acceptAll}>
            Accept All
          </BaseButton>
        </div>
      )}
    </div>
  );
};
