import React from 'react';

import classnames from 'classnames';
import { BaseIcon } from 'src/components/BaseIcon';

import { BaseButtonOrLink } from '../BaseButtonOrLink';
import s from './BaseButton.module.scss';
import { TBaseButtonProps } from './BaseButton.types';

export const BaseButton: React.FC<TBaseButtonProps> = function BaseButton({
  className = '',
  children,
  theme = 'primary',
  size = 'normal',
  icon,
  disabled,
  isLoading,
  preserveStyleWhenDisabled = false,
  ...rest
}) {
  return (
    <BaseButtonOrLink
      className={classnames(className, s.button, s[`theme-${theme}`], s[`size-${size}`], {
        'loading-shimmer': isLoading,
        [s.preserveStyleWhenDisabled]: preserveStyleWhenDisabled,
      })}
      disabled={disabled || isLoading}
      {...rest}>
      {icon && <BaseIcon icon={icon} className={s.icon} />}
      {children}
    </BaseButtonOrLink>
  );
};
