import React from 'react';

import classNames from 'classnames';
import { BaseIcon } from 'src/components/BaseIcon';
import { THeaderSimulationFlagProps } from 'src/components/SimulationResultsPresentation/CustomResultHeader/CustomResultHeader.types';

import s from './CustomResultHeader.module.scss';

export const HeaderSimulationFlag: React.FC<THeaderSimulationFlagProps> = ({
  className,
  isIconActive,
  title,
}) => (
  <div className={classNames(s.liveResultsContainer, className)}>
    <span className={s.text}>{title}</span>
    {isIconActive && <BaseIcon icon="info-antialias" size={12} />}
  </div>
);
