import React, { RefObject, useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { BaseButton } from 'src/components/BaseButton';
import { BaseErrorMessage, TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import { afterAuth } from 'src/constants/refetchQueries';
import { useTokenAuthMutation } from 'src/graphql';
import { setLanguage } from 'src/redux/application/application.slice';
import { setTokenAuth } from 'src/redux/auth/auth.slice';
import { useAppDispatch } from 'src/redux/store';
import { EUserRoles } from 'src/typings/base-types';
import { TValidationObject, rules, validateFields } from 'src/utils/fieldValidation';
import { ELsKey, ls } from 'src/utils/localStorage';

import { useGraphQLMessage } from '../../hooks/useGraphQLMessage';
import s from './FormLogin.module.scss';
import { TFormLoginProps } from './FormLogin.types';

const initFormData = {
  email: '',
  password: '',
};

const validators = () => ({
  email: [...((n) => [rules.required(n), rules.email(n)])('Email')],
  password: [...((n) => [rules.required(n)])('Password')],
});

export const FormLoginSCM: React.FC<TFormLoginProps> = ({ onSuccess, handleForgotPassword }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const [tokenAuthMutation, { loading, error }] = useTokenAuthMutation();
  const graphqlMessage = useGraphQLMessage({ error });
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});
  const recaptchaRef = useRef() as RefObject<ReCAPTCHA>;
  const [failedSignIn, setFailedSignIn] = useState(0);

  const [formData, setFormData] = useState(initFormData);

  const handleFieldChange: TBaseInputProps['onChange'] = ({ value, name }) => {
    setFormData((curr) => ({
      ...curr,
      [name]: value,
    }));
  };

  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { errors } = validateFields({
      validators: validators(),
      formData,
    });

    setValidationErrors(errors || {});

    if (errors) {
      return;
    }

    try {
      const { data } = await tokenAuthMutation({
        variables: {
          username: formData.email,
          password: formData.password,
        },
        refetchQueries: ({ data }) => {
          ls.set(ELsKey.TOKEN_AUTH, data?.tokenAuth);

          return afterAuth;
        },
      });
      const recaptchaToken = recaptchaRef.current?.getValue();

      const checkData = failedSignIn >= 3 ? !!(data && recaptchaToken) : !!data;

      if (checkData) {
        const token = data?.tokenAuth;
        const lsLanguage = ls.get(ELsKey.LANGUAGE);
        if (
          token &&
          token.userRole &&
          [EUserRoles.Admin, EUserRoles.ExchangeOperator].includes(token.userRole as EUserRoles) &&
          lsLanguage === null
        ) {
          ls.set(ELsKey.LANGUAGE, 'en');
          i18n.changeLanguage('en');
          dispatch(setLanguage('en'));
        }
        dispatch(setTokenAuth(token));

        onSuccess?.(token);
      }
    } catch (err) {
      setFailedSignIn((prev) => prev + 1);
    }
  }

  return (
    <form onSubmit={handleSubmit} className={s.formLoginSCM}>
      <div className={s.fieldsContainer}>
        <BaseInput
          type="email"
          name="email"
          value={formData.email}
          onChange={handleFieldChange}
          label={t('EMAIL') || undefined}
          theme="line-light"
          className={s.inputEmail}
          error={validationErrors.email}
          disabled={loading}
          onBlur={(e) => handleFieldBlur(e, validators().email)}
        />
        <BaseInput
          type="password"
          name="password"
          value={formData.password}
          onChange={handleFieldChange}
          label={t('PASSWORD') || undefined}
          theme="line-light"
          className={s.inputPassword}
          error={validationErrors.password}
          disabled={loading}
          onBlur={(e) => handleFieldBlur(e, validators().password)}
        />
      </div>

      {graphqlMessage && (
        <BaseErrorMessage className={s.queryMessage}>{graphqlMessage}</BaseErrorMessage>
      )}
      {failedSignIn >= 3 && (
        <div className={s.recaptchaContainer}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
          />
        </div>
      )}
      <div onClick={handleForgotPassword} className={s.forgotPassword}>
        {t('CHANGE_PASSWORD')}
      </div>
      <BaseButton type="submit" className={s.buttonLogin} isLoading={loading}>
        {t('GET_STARTED')}
      </BaseButton>
      {/* <div className={s.contactText}>
        <p>{t('CONTACT_TO_SIGN_UP', { email: scmcommunityManager })}</p>
      </div> */}
    </form>
  );
};
