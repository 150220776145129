import React from 'react';

import { SingleNotificationWrapper } from 'src/components/SingleNotifications/SingleNotificationWrapper';
import { deleteSingleNotification } from 'src/redux/notifications/notificaitons.slice';
import { TSingleNotification } from 'src/redux/notifications/notifications.types';
import { useAppDispatch } from 'src/redux/store';

import s from './RunSimulationNotification.module.scss';


export type TRunSimulationNotificationProps = {
  singleNotification: TSingleNotification;
  runPauseBtnRef: React.RefObject<HTMLElement>;
};

export const RunSimulationNotification: React.FC<TRunSimulationNotificationProps> = ({
  singleNotification,
}) => {
  const dispatch = useAppDispatch();

  return (
    <SingleNotificationWrapper
      className={s.triangleWrapper}
      onClick={() => {
        dispatch(deleteSingleNotification());
      }}>
      <div className={s.singleNotificationContainer}>
        {singleNotification.characterIcon && (
          <span className={s.singleCharacterContainer}>{singleNotification.characterIcon}</span>
        )}
        {singleNotification.description && <span>{singleNotification.description}</span>}
      </div>
    </SingleNotificationWrapper>
  );
};
