import { unionBy } from 'lodash';
import { TSimulationResultData } from 'src/hooks/useConfigurationEffects';
import { NN } from 'src/typings/helpers';

function areaThroughput<T extends Record<string, unknown> | null>(prev: T, next: T): T {
  return { ...prev, ...next };
}

function tradeProfile(
  prev: NN<TSimulationResultData['tradeProfileAreaThroughput']>['tradeProfile'],
  next: NN<TSimulationResultData['tradeProfileAreaThroughput']>['tradeProfile'],
): NN<TSimulationResultData['tradeProfileAreaThroughput']>['tradeProfile'] {
  type TETPValues =
    | NN<
        TSimulationResultData['tradeProfileAreaThroughput']
      >['tradeProfile']['bought_energy']
    | NN<
        TSimulationResultData['tradeProfileAreaThroughput']
      >['tradeProfile']['sold_energy'];
  function appendETPValues(prev: TETPValues, next: TETPValues) {
    const data = JSON.parse(JSON.stringify(prev));

    const nextEntries = Object.entries(next);
    for (let n = 0; n < nextEntries.length; n++) {
      const [agent, trades] = nextEntries[n];

      if (!data[agent]) {
        data[agent] = {};
      }

      const tradesEntries = Object.entries(trades);
      for (let t = 0; t < tradesEntries.length; t++) {
        const [tradePartner, tradeDetails] = tradesEntries[t];
        if (!data[agent][tradePartner]) {
          data[agent][tradePartner] = {};
        }

        Object.entries(tradeDetails).forEach(([timestamp, value]) => {
          data[agent][tradePartner][timestamp] = value;
        });
      }
    }

    return data;
  }

  if (!prev.sold_energy) {
    prev.sold_energy = {};
  }
  if (!prev.bought_energy) {
    prev.bought_energy = {};
  }

  return {
    sold_energy: Object.keys(next.sold_energy || {}).length
      ? appendETPValues(prev.sold_energy, next.sold_energy)
      : prev.sold_energy,
    bought_energy: Object.keys(next.bought_energy || {}).length
      ? appendETPValues(prev.bought_energy, next.bought_energy)
      : prev.bought_energy,
  };
}

export const mergeSimulationResults = {
  areaThroughput,

  tradeProfile,

  tradeProfileAreaThroughput(
    prev: TSimulationResultData['tradeProfileAreaThroughput'] | undefined,
    next: TSimulationResultData['tradeProfileAreaThroughput'] | undefined,
  ): TSimulationResultData['tradeProfileAreaThroughput'] | undefined {
    if (!prev) return next;
    if (!next) return prev;

    return {
      areaThroughput: areaThroughput(prev.areaThroughput, next.areaThroughput),
      tradeProfile: tradeProfile(prev.tradeProfile, next.tradeProfile),
    };
  },

  marketSummary<T extends Array<{ timestamp: number } | undefined | null> | undefined | null>(
    prev: T,
    next: T,
  ): T {
    return unionBy(prev, next, 'timestamp') as T;
  },

  priceEnergyDay<T extends { 'price-energy-day': Array<{ time: string }> } | undefined>(
    prev: T,
    next: T,
  ): T {
    if (!prev) return next;
    if (!next) return prev;

    return {
      ...prev,
      ...next,
      'price-energy-day': unionBy(prev['price-energy-day'], next['price-energy-day'], 'time'),
    };
  },

  bidsOffersTrades(
    prev: TSimulationResultData['bidsOffersTrades'] | undefined,
    next: TSimulationResultData['bidsOffersTrades'] | undefined,
  ): TSimulationResultData['bidsOffersTrades'] | undefined {
    if (!prev) return next;
    if (!next) return prev;

    return {
      bids: unionBy(prev.bids, next.bids, 'id'),
      offers: unionBy(prev.offers, next.offers, 'id'),
      trades: unionBy(prev.trades, next.trades, 'id'),
    };
  },

  deviceStatistics(
    prev: TSimulationResultData['deviceStatistics'] | undefined,
    next: TSimulationResultData['deviceStatistics'] | undefined,
  ): TSimulationResultData['deviceStatistics'] | undefined {
    if (!prev) return next;
    if (!next) return prev;

    const res = {} as NonNullable<TSimulationResultData['deviceStatistics']>;

    const prevEntries = Object.entries(prev);

    for (let i = 0; i < prevEntries.length; i++) {
      const [agent, trades] = prevEntries[i];
      res[agent] = { ...trades, ...next[agent] };
    }

    return res;
  },

  savingsKpiProfile(
    prev: TSimulationResultData['savingsKpiProfile'] | undefined,
    next: TSimulationResultData['savingsKpiProfile'] | undefined,
  ): TSimulationResultData['savingsKpiProfile'] | undefined {
    if (!prev) return next;
    if (!next) return prev;

    const res = prev;

    const prevEntries = Object.entries(prev);

    for (let i = 0; i < prevEntries.length; i++) {
      const [date, trades] = prevEntries[i];
      res[date] = trades;
    }

    return res;
  },
};
