import React from 'react';

import { useTranslation } from 'react-i18next';
import { THomeEnergyInfoProps } from 'src/components/HomeEnergyInfo/HomeEnergyInfo.types';
import { InfoArea } from 'src/components/HomeEnergyInfo/InfoArea';

import s from './HomeEnergyInfo.module.scss';


export const HomeEnergyInfo: React.FC<THomeEnergyInfoProps> = ({
  generatedValue,
  consumedValue,
}) => {
  const { t: translate } = useTranslation();

  return (
    <div className={s.mainWrapper}>
      <InfoArea icon={'solar-3d'} value={generatedValue} label={translate('ENERGY_GENERATED')} />
      <InfoArea icon={'plug-3d'} value={consumedValue} label={translate('ENERGY_CONSUMED')} />
    </div>
  );
};
