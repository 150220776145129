import React, { useLayoutEffect } from 'react';

import { EPredefinedModalIds } from 'src/constants/modals';
import { openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { TemplateDefault } from 'src/templates/TemplateDefault';

export const AccountActivated: React.FC = () => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
  }, [dispatch]);

  return <TemplateDefault />;
};
