import React, { useCallback, useEffect } from 'react';


import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { TabNav } from 'src/components/TabNav';
import { selectActiveGridMarketTab } from 'src/redux/application/application.selectors';
import {
  setActiveGridMarketForm,
  setActiveGridMarketTab,
} from 'src/redux/application/application.slice';
import {
  selectRootAssetUuid,
  selectSelectedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { EFormVariant, EGridMarketTab } from 'src/typings/base-types';

import s from '../ModalAssetsManager.module.scss';

type GridMarketTabsProps = {
  assetKeyWithTabs: [
    { tabName: EGridMarketTab.Agent; key: string },
    { tabName: EGridMarketTab.Market; key: string },
  ];
  gridAgentAssetUuid: string;
  gridMarketAssetUuid: string;
};

export const GridMarketTabs: React.FC<GridMarketTabsProps> = ({ assetKeyWithTabs }) => {
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const rootAssetUuid = useSelector(selectRootAssetUuid);
  const selectedTab = useSelector(selectActiveGridMarketTab);
  const dispatch = useAppDispatch();

  const setTabAndForm = useCallback<(tabName: EGridMarketTab, formName: EFormVariant) => void>(
    (tabName, formName) => {
      dispatch(setActiveGridMarketTab(tabName));
      dispatch(setActiveGridMarketForm(formName));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!selectedAssetUuid) return;

    if (selectedAssetUuid === rootAssetUuid) {
      setTabAndForm(EGridMarketTab.Market, EFormVariant.GridMarket);
      return;
    }
    setTabAndForm(EGridMarketTab.Agent, EFormVariant.GridAgent);
  }, [selectedAssetUuid, rootAssetUuid, setTabAndForm]);

  const setActiveTab = (tabName: EGridMarketTab, formName: EFormVariant) => {
    setTabAndForm(tabName, formName);
    dispatch(setSelectedAssetUuid(assetKeyWithTabs.find((item) => item.tabName === tabName)?.key));
  };

  return (
    <div className={classnames(s.paddingLR, s.paddingT)}>
      <TabNav
        nav={[
          {
            label: 'Grid Market',
            onClick: () => setActiveTab(EGridMarketTab.Market, EFormVariant.GridMarket),
            isActive: selectedTab === EGridMarketTab.Market,
          },
          {
            label: 'Grid Agent',
            onClick: () => setActiveTab(EGridMarketTab.Agent, EFormVariant.GridAgent),
            isActive: selectedTab === EGridMarketTab.Agent,
          },
        ]}
      />
    </div>
  );
};
