import React from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { NAV_LINKS } from 'src/components/Header/constants';
import s from 'src/components/Header/Header.module.scss';
import { EPredefinedModalIds } from 'src/constants/modals';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { selectTutorialStep } from 'src/redux/application/application.selectors';
import { openModal } from 'src/redux/modals/modals.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';

export const NotLoggedComponent = (): JSX.Element => {
  const location = useAppLocation();
  const dispatch = useAppDispatch();

  const tutorialStep = useSelector(selectTutorialStep);
  const { t } = useTranslation();

  return (
    <header
      className={classnames(s.notLoggedContainer, s.itemsCenter)}
      style={{
        zIndex: tutorialStep === 5 ? 99999 : 100,
        pointerEvents: tutorialStep === 5 ? 'none' : 'auto',
      }}>
      <nav className={classnames(s.nav_con, s.itemsCenter)}>
        <BaseLink
          isNavLink={true}
          to={routesConfig.scm()}
          className={classnames(s.logoLink, s.logoLinkNotLogged)}
          activeStyle={false}>
          <BaseIcon icon="gs-logo" />
        </BaseLink>

        {NAV_LINKS.map((link) => {
          return (
            <BaseLink
              key={link.route}
              isNavLink={location?.routeName === link.route}
              to={routesConfig[link.route]()}
              className={classnames(s.pageNavLink, 'click-area')}>
              {link.title}
            </BaseLink>
          );
        })}
      </nav>

      <nav className={classnames(s.nav_con, s.itemsCenter, s.mla)}>
        <button
          type="button"
          className={s.loginLink}
          onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN))}>
          {t('LOGIN')}
        </button>
      </nav>
    </header>
  );
};
