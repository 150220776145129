import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  ENotificationPlace,
  TSingleNotification,
} from 'src/redux/notifications/notifications.types';

export const EnergyBillInfoContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {t('bill_components.ENERGY_BILL_TOTAL_COST_DIVIDED')} <br />
      <br />
      <b>{t('bill_components.SPENT_TO_GRID')}:</b> {t('bill_components.SPENT_TO_GRID_INFO')}
      <br />
      <br />
      <b>{t('bill_components.SPENT_TO_COMMUNITY')}:</b>{' '}
      {t('bill_components.SPENT_TO_COMMUNITY_INFO')}
      <br />
      <br />
      <b>{t('bill_components.TAXES')}:</b> {t('bill_components.TAXES_INFO')}
      <br />
      <br />
      <b>{t('bill_components.FIXED_MONTHLY_FEE')}:</b> {t('bill_components.FIXED_MONTHLY_FEE_INFO')}
      <br />
      <br />
      <b>{t('bill_components.ASSISTANCE_MONTHLY_FEE')}:</b>{' '}
      {t('bill_components.ASSISTANCE_MONTHLY_FEE_INFO')}
    </>
  );
};

export const SelfSufficiencyInfoText = 'SELF_SUFFICIENCY_INFO_TEXT';

export const SelfConsumptionInfoText = 'SELF_CONSUMPTION_INFO_TEXT';

export const SavingsBenchmarkInfoText = 'SAVINGS_BENCHMARK_INFO_TEXT';

export const MyEnergyFlowsInfoText = 'MY_ENERGY_FLOWS_INFO_TEXT';

export const MyEnergyProfileInfoText = 'MY_ENERGY_PROFILE_INFO_TEXT';

export const SingleHomeNotification: TSingleNotification = {
  notificationPlace: ENotificationPlace.HEADER_RUN_SIMULATION,
  characterIcon: '☝',
  description:
    'You can also rerun the same simulation or go back to change member or market settings and run a new simulation to optimise configuration ahead of moving to operation.',
};

export const FEE_MAPPINGS = [
  {
    key: 'assistanceMonthlyFee',
    color: '#FC1355',
    labelKey: 'bill_components.ASSISTANCE_MONTHLY_FEE',
  },
  {
    key: 'serviceMonthlyFee',
    color: '#80B0F8',
    labelKey: 'bill_components.SERVICE_MONTHLY_FEE',
  },
];
