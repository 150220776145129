import { uploadedFilesCache } from 'src/cache/uploadedFiles';
import { SettingsInput } from 'src/graphql';
import { TSettingsData } from 'src/utils/assetsFields/assetsFields.types';

export function prepareSettingsInput(settingsData: TSettingsData): SettingsInput {
  const {
    slotLengthMinutes,
    tickLengthSeconds,
    marketCount,
    slotLengthRealtimeSeconds,
    scmGroupSettingsName,
    startDate,
    endDate,
    spotMarketType,
    bidOfferMatchAlgo,
    currency,
  } = settingsData;

  const settingsInput: SettingsInput = {
    slotLengthMinutes,
    tickLengthSeconds,
    marketCount,
    slotLengthRealtimeSeconds,
    scmGroupSettingsName,
    startDate,
    endDate,
    spotMarketType,
    bidOfferMatchAlgo,
    currency,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logo: (settingsData as any)?.logo
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        uploadedFilesCache.get((settingsData as any)?.logo)
      : null,
  };

  return settingsInput;
}
