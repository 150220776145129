import { CommunityProperties, Maybe } from 'src/graphql';
import { TAssetType } from 'src/typings/base-types';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';

export type TAsset = {
  parentUuid: string | undefined;
  type: TAssetType;
  uuid: string;
  isCustomPV?: boolean;
  coefficientPercentage?: number;
};

export type TAssetArea = Omit<TAsset, 'type'> & { type: 'Area' };
export type TAssetFiniteDieselGenerator = Omit<TAsset, 'type'> & { type: 'FiniteDieselGenerator' };
export type TAssetLoad = Omit<TAsset, 'type'> & { type: 'Load' };
export type TAssetPV = Omit<TAsset, 'type'> & { type: 'PV' };
export type TAssetStorage = Omit<TAsset, 'type'> & { type: 'Storage' };
export type TAssetMarketMaker = Omit<TAsset, 'type'> & { type: 'MarketMaker' };
export type TAssetInfiniteBus = Omit<TAsset, 'type'> & { type: 'InfiniteBus' };

export type TAssetsTreeRelations = {
  [key in TAsset['uuid']]: Array<TAsset['uuid']>;
};

export type TConfigTree = {
  type: TAssetType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uuid: TAsset['uuid'] | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: TConfigTree[] | any;
};

export type TUsedNames = string[];

export type TConfigurationCharacteristic = {
  name: string;
  user: string;
  description: string;
  projectUuid: string;
  gridMakerHasUploadedProfile: boolean;
  marketMakerRate: number | null;
  locationVisible: boolean;
  transformerCapacityEnabled: boolean;
  baselinePeakEnergyEnabled: boolean;
  timezone: string;
  timestamp: Maybe<string>;
  communityProperties: Maybe<CommunityProperties>;
};

export type TTreeBranch = Pick<
  ReturnType<typeof destructureConfigTree>,
  'assets' | 'assetsTreeRelations' | 'assetsValues'
> & {
  rootAssetUuid: TAsset['uuid'];
};

export enum EScreenMode {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export enum EScreenOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}
