import React, { useContext, useEffect } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import gif1 from 'src/assets/images/Onboarding/Onboarding1.gif';
import gif2 from 'src/assets/images/Onboarding/Onboarding2.gif';
import gif3 from 'src/assets/images/Onboarding/Onboarding3.gif';
import { BaseClose } from 'src/components/BaseClose';
import { BaseIcon } from 'src/components/BaseIcon';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import { START_LOCATION } from 'src/constants/application';
import {
  selectOnboardingStep,
  selectTutorialStep,
} from 'src/redux/application/application.selectors';
import {
  setGuideStep,
  setModalAssetManagerActiveView,
  setOnBoardingStep,
  setTutorialStep,
} from 'src/redux/application/application.slice';
import { setModalMapHeroDismissed } from 'src/redux/application/application.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { routesConfig } from 'src/routes/routes.config';

import { EPredefinedModalIds } from '../../constants/modals';
import WorldMapContext from '../../contexts/WorldMapContext';
import { useConfigurationFlowState } from '../../hooks/useConfigurationFlowState';
import { selectCommunitiesCount } from '../../redux/communities/communities.selectors';
import { closeModal, openModal } from '../../redux/modals/modals.slice';
import { useAppDispatch } from '../../redux/store';
import { BaseButton } from '../BaseButton';
import { BaseGenericModal } from '../BaseGenericModal';
import { LoadingWrapper } from '../LoadingWrapper';
import s from './ModalMapHero.module.scss';
import { TModalMapHeroProps } from './ModalMapHero.types';

// TODO: REFACTORING!!!
export const ModalMapHero: React.FC<TModalMapHeroProps> = () => {
  const history = useHistory();

  const communitiesCount = useSelector(selectCommunitiesCount);
  const dispatch = useAppDispatch();
  const { modalMapHeroShow } = useConfigurationFlowState();
  const { mapService } = useContext(WorldMapContext);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const tutorialStep = useSelector(selectTutorialStep);

  setTutorialStep(1);

  function onExploreClick() {
    dispatch(setGuideStep(1));
    dispatch(openModal(EPredefinedModalIds.MODAL_ONBOARDING));

    dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.AddCommunity));
    dispatch(setOnBoardingStep(0));
    history.push(routesConfig.scmMapCreate());
    mapService?.fitBounds(START_LOCATION);
  }

  useEffect(() => {
    if (modalMapHeroShow) {
      dispatch(openModal(EPredefinedModalIds.MODAL_MAP_HERO));
    } else {
      dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_HERO));
    }
  }, [dispatch, modalMapHeroShow]);

  const activeTutorialSteps = isLoggedIn ? [1, 2, 3, 4] : [1, 2, 3, 4, 5];

  const WalkMeThroughStepModal = (value) => (
    <div className={s.WalkMeThroughModel} style={value.data.position}>
      <BaseClose
        className={s.closeBtn}
        onClick={() => {
          dispatch(setTutorialStep(0));
        }}
      />
      <BaseIcon
        icon="side-arrow"
        size={18}
        className={s.sideArrow}
        style={value.data.icon_position}
      />
      <div className={s.WalkMeThroughModelText}>
        <div className={s.WalkMeThroughModelTitle}>{value.data.title}</div>
        <div className={s.WalkMeThroughModelDescription}>{value.data.description}</div>
      </div>
      <div className={s.paginationCon}>
        <div className={s.pagination}>
          {tutorialStep != 1 ? (
            <BaseButton
              className={s.prevStepButton}
              onClick={() => {
                dispatch(setTutorialStep(tutorialStep - 1));
              }}>
              <BaseIcon icon="arrow-left-full" size={13} />
              {/* Prev */}
            </BaseButton>
          ) : null}

          <div className={s.stepsContainer}>
            {activeTutorialSteps.map((d) => (
              <BaseButton
                className={classnames(s.stepButton, {
                  [s.activeStep]: d === tutorialStep,
                })}
                onClick={() => {
                  dispatch(setTutorialStep(d));
                }}
                key={d}>
                {d}
              </BaseButton>
            ))}
          </div>
          {tutorialStep == activeTutorialSteps[activeTutorialSteps.length - 1] ? (
            <BaseButton
              disabled={false}
              className={s.nextStepButton}
              onClick={() => {
                dispatch(setTutorialStep(0));
              }}>
              Finish
              <BaseIcon className={s.nextIcon} icon="arrow-left-full" size={13} />
            </BaseButton>
          ) : (
            <BaseButton
              disabled={false}
              className={s.nextStepButton}
              onClick={() => {
                dispatch(setTutorialStep(tutorialStep + 1));
              }}>
              Next
              <BaseIcon className={s.nextIcon} icon="arrow-left-full" size={13} />
            </BaseButton>
          )}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (tutorialStep == 1) {
      dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
    } else {
      dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
    }
  }, [dispatch, tutorialStep]);

  const renderWalkMeThrough = (value) => {
    switch (value) {
      case 1:
        const data1 = {
          title: 'Simulations worldwide',
          description: 'See the number of energy community simulations worldwide',
          position: { top: '183px', left: '415px' },
          icon_position: { top: '13px', left: '-14px' },
        };

        return <WalkMeThroughStepModal data={data1} />;
      case 2:
        const data2 = {
          title: 'Search Bar',
          description: 'Search for your location on the map',
          position: { top: '108px', left: '415px' },
          icon_position: { top: '13px', left: '-14px' },
        };

        return <WalkMeThroughStepModal data={data2} />;

      case 3:
        const data3 = {
          title: 'Zoom',
          description: 'Zoom in and out of the map',
          position: { top: '200px', right: '74px' },
          icon_position: { top: '13px', right: '-14px', transform: 'rotate(180deg)' },
        };

        return <WalkMeThroughStepModal data={data3} />;

      case 4:
        const data4 = {
          title: '3D map',
          description: 'View the map in 3D',
          position: { top: '345px', right: '74px' },
          icon_position: { top: '13px', right: '-14px', transform: 'rotate(180deg)' },
        };

        return <WalkMeThroughStepModal data={data4} />;

      case 5:
        const data5 = {
          title: 'Login & Register',
          description: 'Create an account to run your simulation and save your progress',
          position: { top: '96px', right: '273px' },
          icon_position: { top: '-14px', right: '36px', transform: 'rotate(90deg)' },
        };

        return <WalkMeThroughStepModal data={data5} />;
      default:
        return null;
    }
  };

  return (
    <>
      <BaseGenericModal
        size="auto"
        modalId={EPredefinedModalIds.MODAL_MAP_HERO}
        className={s.mapHero}>
        <LoadingWrapper loading={!communitiesCount}>
          {() => (
            <>
              <h1 className={s.mapHeroTitle}>
                <span className={s.mapHeroTitleNumber}>{communitiesCount}_</span>
                <br />
                Simulations worldwide
              </h1>
              <div className={s.mapHeroButtonsWrapper}>
                <BaseButton
                  className={s.mapHeroButton}
                  theme="dark-transparent"
                  onClick={() => dispatch(setTutorialStep(1))}>
                  Walk Me Through
                </BaseButton>
                <BaseButton className={s.mapHeroButton} onClick={onExploreClick}>
                  Start Building
                </BaseButton>
              </div>
            </>
          )}
        </LoadingWrapper>
      </BaseGenericModal>
      <div>{renderWalkMeThrough(tutorialStep)}</div>
      <div>
        <Step />
      </div>
    </>
  );
};

const Step = () => {
  const dispatch = useAppDispatch();
  const onboardingStep = useSelector(selectOnboardingStep);

  const content = [
    {
      gif: gif1,
      buttonText: 'Next',
      title: 'Welcome to the Singularity Map',
      description: (
        <>
          We created this platform to empower people to start their energy journey and unlock
          <span className={s.descriptionHighlight}> degrees of freedom </span>
          previously unimagined.
          <br />
          <br />
          We connect you to the resources and provide you with the tools needed to build your own
          <span className={s.descriptionHighlight}> virtual energy community</span>.
          <br />
          <br />
          Here’s what you need to know.
        </>
      ),
    },
    {
      gif: gif2,
      buttonText: 'Next',
      title: 'What does your future look like?',
      description: (
        <>
          The energy sector needs to <span className={s.descriptionHighlight}> decarbonise </span>{' '}
          rapidly.
          <br />
          <br />
          As this transition takes place, citizens should{' '}
          <span className={s.descriptionHighlight}> become more empowered</span> and have their
          relationship with energy strengthened. This is where{' '}
          <span className={s.descriptionHighlight}> energy communities </span>come in.
        </>
      ),
    },
    {
      gif: gif3,
      buttonText: "Let's go! 🚀",
      title: 'What is the Singularity Map?',
      description: (
        <>
          This is a simulation tool where you can populate your community with homes, consumption
          profiles, solar panels and batteries. These digital assets are represented by trading
          agents, acting on your behalf, according to your preferences. Optimise your future energy
          scenario by configuring the details digitally before bringing your energy community to
          life.
        </>
      ),
    },
  ];

  let activeStep = onboardingStep - 1;
  if (activeStep < 0) {
    activeStep = 0;
  } else if (activeStep > 2) {
    activeStep = 2;
  }
  const activeContent = content[activeStep];
  if (![1, 2, 3].includes(onboardingStep)) {
    return null;
  }
  return (
    <>
      <div className={s.background} />
      <div className={s.welcomeModal}>
        <BaseClose
          className={s.closeBtn}
          onClick={() => {
            dispatch(setModalMapHeroDismissed(true));
            dispatch(setOnBoardingStep(4));
          }}
        />
        <div className={classnames(s.wmLeft, 'col-md-6')}>
          <img src={activeContent.gif} />
        </div>

        <div className={classnames(s.wmRight, 'col-md-6')}>
          <div className={classnames(s.wmControl)}>
            <p style={{ display: 'flex', alignItems: 'center' }}>INTRO</p>
            <div className={classnames(s.wmNextOrPrev)}>
              {onboardingStep > 1 && (
                <div className={s.wmNextPrevBtn}>
                  <BaseIcon
                    icon="arrow-modern-prev"
                    size={11}
                    onClick={() => {
                      dispatch(setOnBoardingStep(onboardingStep - 1));
                    }}
                  />
                </div>
              )}

              <p className={s.wmNumOf}> {onboardingStep} of 3 </p>

              {onboardingStep < 3 && (
                <div className={s.wmNextPrevBtn}>
                  <BaseIcon
                    icon="arrow-modern-next"
                    size={11}
                    style={{ paddingLeft: '2px' }}
                    onClick={() => {
                      dispatch(setOnBoardingStep(onboardingStep + 1));
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <p className={s.wmTitle}>{activeContent.title}</p>
          <p className={s.wmDescription}>{activeContent.description}</p>
          <BaseButton
            className={classnames(s.goNext)}
            onClick={() => {
              dispatch(setOnBoardingStep(onboardingStep + 1));
            }}>
            {activeContent.buttonText}
          </BaseButton>
        </div>
      </div>
    </>
  );
};
