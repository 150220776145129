import { ChartOptions, TooltipOptions } from 'chart.js';
import {
  commonChartOptions,
  commonInteraction,
} from 'src/components/_charts/chartsData/commonOptions';
import { TooltipFactory } from 'src/components/_charts/TooltipFactory/TooltipFactory';
import { TooltipSavings } from 'src/components/_charts/TooltipFactory/tooltips/TooltipSavings/TooltipSavings';

const scaleX = {
  stacked: true,
  ticks: {
    color: '#a0a0ac',
    font: { size: 8 },
    padding: 15,
  },
  grid: {
    display: false,
    drawBorder: false,
    drawTicks: false,
  },
};

const scaleY = {
  stacked: true,
  ticks: {
    display: false,
  },
  grid: {
    display: false,
    drawBorder: false,
    drawTicks: false,
  },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tooltip: Partial<TooltipOptions<any>> = {
  enabled: false,
  external: (context) =>
    TooltipFactory({ context, component: TooltipSavings, position: 'over-bar' }),
};

export const SavingsConfig: ChartOptions = {
  ...commonChartOptions,
  layout: {
    padding: {
      top: 5,
      bottom: -10,
    },
  },
  parsing: undefined,
  interaction: commonInteraction,
  scales: {
    x: { ...scaleX },
    y: {
      ...scaleY,
    },
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 1,
    },
  },
  plugins: {
    tooltip,
  },
};
