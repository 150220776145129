import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { GlobalSimulationResultsData, useSimulationGlobalResultsLazyQuery } from 'src/graphql';
import { selectActiveConfigurationJobUuid } from 'src/redux/configuration/configuration.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';

type TUseLaunchDateTime = {
  launchDatetime: GlobalSimulationResultsData['launchDatetime'];
};

export const useLaunchDateTime = (): TUseLaunchDateTime => {
  const [
    getSimulationGlobalResults,
    { data: globalResultData },
  ] = useSimulationGlobalResultsLazyQuery();

  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);

  useEffect(() => {
    if (isOperationalCommunity && activeConfigurationJobUuid) {
      getSimulationGlobalResults({
        variables: {
          jobId: activeConfigurationJobUuid,
        },
      });
    }
  }, [isOperationalCommunity, getSimulationGlobalResults, activeConfigurationJobUuid]);

  return { launchDatetime: globalResultData?.simulationGlobalResults?.launchDatetime };
};
