import React, { useLayoutEffect, useRef, useState } from 'react';

import { Brush, ComposedChart, ResponsiveContainer } from 'recharts';
import { BaseIcon } from 'src/components/BaseIcon';
import { TChartBrushWrapperProps } from 'src/components/ChartBrushWrapper/ChartBrushWrapper.types';

//import { IconTraveller } from 'src/assets/Icons';
import s from './ChartBrushWrapper.module.scss';

export const ChartBrushWrapper: React.FC<TChartBrushWrapperProps> = ({
  startIndex = 0,
  endIndex = 11,
  data,
  setGraphIndexes,
  children,
}) => {
  const brushMeasureRef = useRef<HTMLElement | null>(null);
  const [brushBorder, setBrushBorder] = useState<number>(0);

  useLayoutEffect(() => {
    function handleResize() {
      if (!brushMeasureRef.current) return;

      const { width } = brushMeasureRef.current.getBoundingClientRect();
      setBrushBorder(width);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [brushMeasureRef]);

  return (
    <>
      <div ref={(e) => (brushMeasureRef.current = e)} className={s.modalChartBrushMeasure}></div>
      <div className={s.modalChartBrushBorder} style={{ width: brushBorder, height: '36px' }}></div>
      <div className={s.brushContainer}>
        <div className={s.brushContent}>
          <ResponsiveContainer width="100%" height={29} className={s.modalResponsiveBrushContainer}>
            <ComposedChart
              width={500}
              height={30}
              data={data}
              margin={{
                top: 0,
                right: 5,
                left: 20,
                bottom: 0,
              }}>
              <Brush
                style={{
                  position: 'absolute',
                }}
                dataKey="name"
                height={25}
                stroke="#fff"
                strokeOpacity={1}
                strokeWidth={1}
                fill="#fff"
                travellerWidth={5}
                startIndex={startIndex}
                endIndex={endIndex}
                alwaysShowText={false}
                onChange={(args) => {
                  setGraphIndexes(args);
                }}
                tickFormatter={() => ''}
                traveller={(args) => {
                  return (
                    <g>
                      <BaseIcon
                        icon={'traveller'}
                        svgProps={{ x: args.x - 10, y: args.y - 2, height: 31, width: 19 }}
                      />
                    </g>
                  );
                }}>
                {children}
              </Brush>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};
