import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { BaseSelect } from 'src/components/BaseSelect';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { InfoHelper } from 'src/components/InfoHelper';
import { SHARING_COEFFICIENT_OPTIONS } from 'src/constants/application';
import { ScmCoefficientAlgorithm, SettingsDataFieldsFragment } from 'src/graphql';
import { useUpdateMemberCoefficientsMutation } from 'src/graphql';
import { useMemberEvents } from 'src/hooks/useMemberEvents';
import { selectReadOnly } from 'src/redux/configuration/configuration.selectors';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectCommunityMembers } from 'src/redux/scm/scm.selectors';
import { setCommunityMembers } from 'src/redux/scm/scm.slice';
import { arrayToObject, sumOfField } from 'src/utils/general';
import { areArraysOfObjectsEqualByFields } from 'src/utils/general';
import { dispatchSuccessToast, dispatchErrorToast } from 'src/utils/toasts';
import { _DeepNonNullableObject } from 'utility-types/dist/mapped-types';

import { BaseButton } from '../../../BaseButton';
import { CoefficientAssetTile } from './components/CoefficientAssetTile';
import s from './SharingCoefficient.module.scss';

export const SharingCoefficient: React.FC = () => {
  const { t } = useTranslation();

  const configUuid = useSelector(selectActiveConfigurationUuid);
  const readOnly = useSelector(selectReadOnly);
  const communityMembers = useSelector(selectCommunityMembers);
  const settingsData = useSelector(selectSettingsData) as _DeepNonNullableObject<
    Omit<SettingsDataFieldsFragment, '__typename'>
  >;

  const [newCommunityMembers, setNewCommunityMembersCoefficients] = useState(communityMembers);
  const [originalCommunityMembers, setOriginalCommunityMembers] = useState(communityMembers);
  const [coefficientsType, setCoefficientsType] = useState(
    settingsData.scmCoefficientAlgorithm || ScmCoefficientAlgorithm.Static,
  );

  const { handleReadConfiguration } = useMemberEvents({});
  const [updateMemberCoefficients] = useUpdateMemberCoefficientsMutation({
    errorPolicy: 'all',
    onCompleted: () => {
      setCommunityMembers(newCommunityMembers);
      setOriginalCommunityMembers(newCommunityMembers);
      dispatchSuccessToast();
      handleReadConfiguration({
        variables: {
          uuid: configUuid!,
        },
      });
    },
    onError: (err) => dispatchErrorToast(err),
  });

  const containerRef = useRef<HTMLFormElement>(null);

  const onValueChange = (uuid, value) => {
    if (typeof value === 'number') {
      const updatedCommunityMembers = newCommunityMembers.map((item) =>
        item.uuid === uuid ? { ...item, coefficientPercentage: value } : item,
      );
      setNewCommunityMembersCoefficients(updatedCommunityMembers);
    }
  };

  const containerProps = {
    showTooltip: false,
    tooltipText: '',
    key: 'sharingCoefficient',
  };

  const handleSelectChange = ({ value }) => {
    setCoefficientsType(value);
  };

  const handleSubmit = () => {
    const memberCoefficientMapping = arrayToObject(
      newCommunityMembers,
      'uuid',
      'coefficientPercentage',
    );
    updateMemberCoefficients({
      variables: {
        configUuid: configUuid || '',
        memberCoefficientMapping: JSON.stringify(memberCoefficientMapping),
      },
    });
  };
  const isValidForm = sumOfField(newCommunityMembers, 'coefficientPercentage') === 1;

  const canSave =
    !areArraysOfObjectsEqualByFields(newCommunityMembers, originalCommunityMembers, [
      'coefficientPercentage',
    ]) && isValidForm;

  return (
    <div className={s.container}>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (readOnly) return;
          handleSubmit();
        }}
        id={'sharingcoefficient'}
        ref={containerRef}>
        <div className={s.headingText}>
          {t('labels.ENERGY_SHARING_MECHANISM')}
          <InfoHelper info={t('tooltips.ENERGY_SHARING_MECHANISM_INFO')} />
        </div>

        <FieldContainer {...containerProps} className={s.selectField}>
          <BaseSelect
            onChange={(val) => handleSelectChange(val)}
            name="scmCoefficientAlgorithm"
            value={coefficientsType}
            options={SHARING_COEFFICIENT_OPTIONS.map((item) => ({
              label: t(item.label),
              value: item.value,
            }))}
            theme="filled-gray"
            showTooltip
            disabled={readOnly}
            tooltipText=""
          />
          {!isValidForm && <BaseErrorMessage>{t('messages.COEFFICIENTS_SUM_1')}</BaseErrorMessage>}
        </FieldContainer>
        <div className={s.headingText}>{t('labels.SET_COEFFICIENT')}</div>
        {communityMembers.length &&
          communityMembers.map((communityMember) => (
            <div key={communityMember.uuid}>
              <CoefficientAssetTile
                data={communityMember}
                onAssetValueChange={(uuid, value) => onValueChange(uuid, value)}
              />
            </div>
          ))}
        <div className={s.formButtonsWrapper}>
          <BaseButton
            type="submit"
            className={s.formButton}
            form="sharingcoefficient"
            disabled={!canSave || readOnly}>
            {t('commands.SAVE')}
          </BaseButton>
        </div>
      </form>
    </div>
  );
};
