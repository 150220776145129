import React from 'react';

import classnames from 'classnames';
import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { formatter } from 'src/utils/formatter';

import { BaseIcon } from '../BaseIcon';
import s from './BaseCounter.module.scss';
import { TBaseCounterProps } from './BaseCounter.types';

export const BaseCounter: React.FC<TBaseCounterProps> = ({
  className,
  label,
  name,
  value,
  min,
  max,
  onChange,
  disabled,
  error,
}) => {
  const handleChange = (newValue: TBaseCounterProps['value']) => {
    if (disabled) return;
    onChange({ name, value: newValue });
  };

  return (
    <div className={classnames(s.counterWrapper, className, { [s.disabled]: disabled })}>
      <label className={s.title}>{label}</label>

      <div className={s.buttonsWrapper}>
        <div
          className={s.button}
          onClick={() => {
            let newVal: number;
            if (typeof min !== 'undefined' && value <= min) {
              newVal = min;
            } else {
              newVal = value - 1;
            }

            handleChange(newVal);
          }}>
          <BaseIcon icon="minus" size={18} />
        </div>
        <div className={s.value}>{formatter.prefixWithZeros(value)}</div>
        <div
          className={s.button}
          onClick={() => {
            let newVal: number;
            if (typeof max !== 'undefined' && value >= max) {
              newVal = max;
            } else {
              newVal = value + 1;
            }

            handleChange(newVal);
          }}>
          <BaseIcon icon="plus" size={18} />
        </div>
      </div>

      <BaseErrorMessage>{error}</BaseErrorMessage>
    </div>
  );
};
