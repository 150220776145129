import React, { useState } from 'react';

import classnames from 'classnames';
import { BaseButton } from 'src/components/BaseButton';
import { BaseErrorMessage, TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import { BaseInput } from 'src/components/BaseInput';
import { BaseSelect } from 'src/components/BaseSelect';
import { initFormData, interestOptions } from 'src/components/FormSignup/useSignupFormData';
import { PASSWORD_MIN_LENGTH } from 'src/constants/application';
import { useSignupMutation } from 'src/graphql';
import { useGraphQLMessage } from 'src/hooks/useGraphQLMessage';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toast.slice';
import { TValidationObject, rules, validateFields } from 'src/utils/fieldValidation';

import s from './FormAddUser.module.scss';
import { TFormSignupProps } from './FormAddUser.types';

const validators = () => ({
  name: [...((n) => [rules.required(n)])('Name')],
  email: [...((n) => [rules.required(n), rules.email(n)])('Email')],
  password: [...((n) => [rules.required(n), rules.minLength(n, PASSWORD_MIN_LENGTH)])('Password')],
  other: [...((n) => [rules.required(n)])('Other')] || {},
});

export const FormAddUser: React.FC<TFormSignupProps> = ({
  className,
  onSuccess,
  formData,
  handleFieldChange,
}) => {
  const dispatch = useAppDispatch();
  const [interestError, setInterestError] = useState(false);
  const [signupMutation, { loading, error }] = useSignupMutation();
  const graphqlMessage = useGraphQLMessage({ error });
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!formData.interest) {
      setInterestError(true);
      return;
    }

    const { errors } = validateFields({
      validators: validators(),
      formData,
    });

    setValidationErrors(errors || {});

    if (errors) {
      const keys = Object.keys(errors),
        key = keys[0];

      if (formData.interest === 'Other' || keys.length !== 1 || key !== 'other') return;
    }

    try {
      const { data } = await signupMutation({
        variables: {
          email: formData.email,
          name: formData.name,
          organisation: '',
          password: formData.password,
          interest: formData.interest === 'Other' ? formData.other : formData.interest,
        },
      });

      if (data) {
        const token = data.registerUser?.authToken;
        if (token) {
          dispatch(
            openToast({
              message: 'User created successfully',
              type: 'success',
            }),
          );
          onSuccess?.();
        }
      }
    } catch (err) {}
  }

  return (
    <form className={className} onSubmit={handleSubmit}>
      <BaseInput
        type="text"
        name="name"
        value={formData.name}
        onChange={handleFieldChange}
        label="Name"
        theme="line-light"
        className={s.input}
        error={validationErrors.name}
        onBlur={(e) => handleFieldBlur(e, validators().name)}
      />
      <BaseInput
        type="email"
        name="email"
        value={formData.email}
        onChange={handleFieldChange}
        label="Email"
        theme="line-light"
        className={s.input}
        error={validationErrors.email}
        onBlur={(e) => handleFieldBlur(e, validators().email)}
      />
      <BaseInput
        type="password"
        name="password"
        value={formData.password}
        onChange={handleFieldChange}
        label="Password"
        theme="line-light"
        className={s.input}
        error={validationErrors.password}
        onBlur={(e) => handleFieldBlur(e, validators().password)}
      />
      <div className={s.input}>
        <BaseSelect
          label="What will the user use the map for?"
          name="interest"
          onChange={(e) => {
            handleFieldChange(e);
            setInterestError(false);
          }}
          value={formData.interest}
          options={interestOptions}
          error={interestError ? 'Choose your interest' : ''}
        />
      </div>
      {formData.interest === 'Other' && (
        <div className={classnames(s.inputsWrapper, s.rightDirection)}>
          <BaseInput
            type="text"
            name="other"
            value={formData.other}
            onChange={handleFieldChange}
            label="Other Interest"
            theme="line-light"
            className={s.input}
            error={validationErrors.other}
            onBlur={(e) => handleFieldBlur(e, validators().other)}
          />
        </div>
      )}

      <BaseButton type="submit" isLoading={loading} className={s.buttonRegister}>
        Save
      </BaseButton>

      {graphqlMessage && <BaseErrorMessage>{graphqlMessage}</BaseErrorMessage>}
    </form>
  );
};
