import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Chart, ChartDataset, ScatterDataPoint } from 'chart.js';
import classnames from 'classnames';
import { meanBy, throttle } from 'lodash';
import { useSelector } from 'react-redux';
import vars from 'src/assets/styles/utils/vars.module.scss';
import { EChartName } from 'src/components/_charts/chartsData';
import { useChartJS } from 'src/hooks/useChartJS';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { formatter } from 'src/utils/formatter';

import s from './ChartHouseSummary.module.scss';
import { TChartHouseSummaryProps } from './ChartHouseSummary.types';


const chartHeight = 50;

export const colors = {
  prices: vars['color-oh-so-green'],
  netEnergy: vars['color-ace-blue'],
  avgDays: vars['color-black'],
};

const datasetIndex = {
  netEnergy: 0,
  prices: 1,
};

export const ChartHouseSummary: React.FC<TChartHouseSummaryProps> = ({
  className,
  netEnergyData,
  pricesData,
  isLoading,
  avgDays = '',
  onClick,
  endUnix,
  startUnix,
}) => {
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [averageValues, setAverageValues] = useState({ prices: 0, netEnergy: 0 });
  const [hoveredValues, setHoveredValues] = useState<typeof averageValues | null>(null);
  const [chartHovered, setChartHovered] = useState(false);
  const { currency } = useSelector(selectSettingsData);

  const datasets = useMemo(() => {
    const commonDatasetOptions: Partial<ChartDataset<'line'>> = {
      borderWidth: 1,
      fill: true,
      pointHoverRadius: 0,
      spanGaps: true,
      tension: 0.4,
    };

    return [
      {
        ...commonDatasetOptions,
        data: netEnergyData,
        borderColor: colors.netEnergy,
        yAxisID: 'y-net-energy',
      },
      {
        ...commonDatasetOptions,
        data: pricesData,
        borderColor: colors.prices,
        yAxisID: 'y-prices',
      },
    ];
  }, [netEnergyData, pricesData]);

  useChartJS(
    EChartName.HouseSummary,
    canvasRef,
    chartRef,
    {
      datasets,
    },
    {
      chartOptions: {
        scales: {
          x: {
            max: endUnix * 1000,
            min: startUnix * 1000,
          },
        },
      },
      datasetsLengthWillChange: true,
    },
  );

  useEffect(() => {
    if (!chartRef.current) return;

    chartRef.current.config.options!.plugins!.tooltip!.external = throttle((context) => {
      const values: number[] = [];
      if (!context.tooltip.dataPoints) return;

      context.tooltip.dataPoints.forEach((item) => {
        values[item.datasetIndex] = item.parsed.y;
      });

      setHoveredValues({
        netEnergy: formatter.toOptionalFixed(values[0], 2) || 0,
        prices: formatter.toOptionalFixed(values[1], 2) || 0,
      });
    }, 100);
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d') as CanvasRenderingContext2D;
      const gradientFills = {
        netEnergy: 'transparent' as CanvasGradient | string,
        prices: 'transparent' as CanvasGradient | string,
      };
      gradientFills.netEnergy = ctx.createLinearGradient(0, 0, 0, chartHeight);
      gradientFills.netEnergy.addColorStop(1, colors.netEnergy);
      gradientFills.netEnergy.addColorStop(0, 'transparent');

      gradientFills.prices = ctx.createLinearGradient(0, 0, 0, chartHeight);
      gradientFills.prices.addColorStop(0, colors.prices);
      gradientFills.prices.addColorStop(1, 'transparent');

      if (chartRef.current?.data.datasets) {
        chartRef.current.data.datasets[datasetIndex.netEnergy].backgroundColor =
          gradientFills.netEnergy;
        chartRef.current.data.datasets[datasetIndex.prices].backgroundColor = gradientFills.prices;
        chartRef.current.update();
      }
    }
  }, []);

  useEffect(() => {
    setAverageValues({
      netEnergy: formatter.toOptionalFixed(
        meanBy(netEnergyData, (p) => (p as ScatterDataPoint).y) || 0,
        2,
      ),
      prices: formatter.toOptionalFixed(
        meanBy(pricesData, (p) => (p as ScatterDataPoint).y) || 0,
        2,
      ),
    });
  }, [netEnergyData, pricesData]);

  const renderLegends = () => {
    const legends = [
      {
        color: colors.prices,
        label: 'Price',
        value: formatter.formatCents(
          chartHovered && hoveredValues ? hoveredValues.prices : averageValues.prices,
        ),
        unit: `${formatter.getCurrencySymbol(currency)} / kWh`,
      },
      {
        color: colors.netEnergy,
        label: 'Net Energy',
        value: chartHovered && hoveredValues ? hoveredValues.netEnergy : averageValues.netEnergy,
        unit: 'kWh',
      },
    ];
    if (avgDays) {
      legends.unshift({
        color: '',
        label: 'Avg',
        value: avgDays,
        unit: `days`,
      });
    }
    return (
      <div className={s.legend}>
        {legends.map((item, index) => (
          <div key={index} className={s.legendItem}>
            <div className={s.legendBadge} style={{ ['--bg-color' as string]: item.color }}></div>
            <div className={s.legendLabel}>{item.label}</div>
            <div>
              {item.value} {item.unit}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={classnames(s.container, className)} onClick={onClick}>
      <div
        className={classnames(s.canvasContainer, {
          'loading-shimmer': isLoading,
        })}>
        <canvas
          ref={canvasRef}
          height={chartHeight}
          onMouseEnter={() => setChartHovered(true)}
          onMouseLeave={() => setChartHovered(false)}
        />
      </div>
      {renderLegends()}
    </div>
  );
};
