import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import { batch, useSelector } from 'react-redux';
import { Route, Switch, matchPath } from 'react-router';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { MapSidebarCommunitiesListWrapper } from 'src/components/MapSidebar/components/MapSidebarCommunitiesList/MySidebarCommunitiesListWrapper';
import { MapSidebarConfigurationSettings } from 'src/components/MapSidebar/components/MapSidebarConfigurationSettings';
import {
  EActiveTabState,
  MapSidebarResults,
} from 'src/components/MapSidebar/components/MapSidebarResults';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import {
  selectIsEmbed,
  selectModalMapHeroDismissed,
  selectScreenMode,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import { selectTutorialStep } from 'src/redux/application/application.selectors';
import {
  setModalMapHeroDismissed,
  setSidebarExpanded,
} from 'src/redux/application/application.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectUsername } from 'src/redux/auth/auth.selectors';
import { selectCommunityUser } from 'src/redux/configuration/configuration.selectors';
import { selectActiveModals } from 'src/redux/modals/modals.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { setSCMsidebarTab } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { routesConfig } from 'src/routes/routes.config';
import { EScreenMode } from 'src/typings/configuration.types';

import { ConfigurationManagementCentral } from '../ConfigurationManagementCentral';
import s from './MapSidebar.module.scss';
import { TMapSidebarProps } from './MapSidebar.types';

export const MapSidebar: React.FC<TMapSidebarProps> = ({
  showResultsTabOnClick,
  hideResultsTab,
}) => {
  const dispatch = useAppDispatch();
  const location = useAppLocation();
  const isEmbed = useSelector(selectIsEmbed);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const sidebarModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR),
  );
  const { sidebarOpen } = useConfigurationFlowState();
  const modalMapHeroDismissed = useSelector(selectModalMapHeroDismissed);
  const tutorialStep = useSelector(selectTutorialStep);
  const [containerWidth, setContainerWidth] = useState<EModalSize | 'perc'>(EModalSize.L370);
  const [activeTab, setActiveTab] = useState<EActiveTabState>(EActiveTabState.Community);
  const activeModals = useSelector(selectActiveModals);
  const { isUserACommunityMember } = useIsUserACommunityMember();
  const communityUser = useSelector(selectCommunityUser);

  const userName = useSelector(selectUsername);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const screenMode = useSelector(selectScreenMode);

  const modalTopMargin = useMemo(
    () =>
      isUserACommunityMember && screenMode === EScreenMode.Mobile
        ? '120px'
        : isUserACommunityMember || !(communityUser == '' || userName === communityUser)
        ? '75px'
        : '120px',
    [isUserACommunityMember, communityUser, userName, screenMode],
  );

  const toggleModal = useCallback(
    (value: boolean) => {
      if (value) {
        dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
      } else {
        batch(() => {
          dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
          dispatch(setSCMsidebarTab(undefined));
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      !activeModals.includes(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY) &&
      !sidebarModalOpened
    ) {
      dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
    }
  }, [sidebarModalOpened, dispatch, activeModals]);
  useEffect(() => {
    if (sidebarModalOpened && !modalMapHeroDismissed) {
      dispatch(setModalMapHeroDismissed(true));
    }
  }, [dispatch, modalMapHeroDismissed, sidebarModalOpened]);

  useEffect(() => {
    toggleModal(sidebarOpen);
  }, [sidebarOpen, toggleModal]);

  useEffect(() => {
    if (!isLoggedIn) dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
  });

  const handleSettingsTab = (activeTab) => {
    setActiveTab(activeTab);
  };

  const getContainerSize = useCallback(() => {
    if (
      sidebarExpanded &&
      (activeTab == EActiveTabState.Community || activeTab == EActiveTabState.MessagingPortal)
    ) {
      return 'perc';
    } else if (sidebarExpanded) {
      return EModalSize.XL795;
    } else {
      return EModalSize.L425;
    }
  }, [sidebarExpanded, activeTab]);

  useEffect(() => {
    const containerSize = getContainerSize();
    setContainerWidth(containerSize);
  }, [activeTab, getContainerSize]);

  useEffect(() => {
    const isSimulationResultsPath = !!matchPath(location.pathname, {
      path: routesConfig.scmMapResults('', ''),
    });

    if (!isSimulationResultsPath) {
      dispatch(setSidebarExpanded(false));
    }
  }, [dispatch, location.pathname]);

  return (
    <>
      <Switch>
        <Route path={`(${routesConfig.scmMap()}|${routesConfig.singularityMap()})`}>
          <BaseGenericModal
            className={classnames(s.container, {
              [s.containerEmbed]: isEmbed,
              [s.sidebarWithoutNav]: !!matchPath(location.pathname, {
                path: [routesConfig.scmMapCreate(), routesConfig.scmMapResults()],
              }),
            })}
            closedClassName={s.closed}
            size={containerWidth}
            style={{
              zIndex: tutorialStep === 1 ? 99999 : 100,
              pointerEvents: tutorialStep === 1 ? 'none' : 'auto',
              top: modalTopMargin,
            }}
            modalId={EPredefinedModalIds.MODAL_MAP_SIDEBAR}>
            <>
              <Switch>
                <Route
                  // Use simulations list also for /create route
                  path={`(${routesConfig.scmMap()}|${routesConfig.singularityMap()})`}
                  exact>
                  <MapSidebarCommunitiesListWrapper />
                </Route>
                <ConfigurationManagementCentral>
                  {({
                    handleSettingsDataSave,
                    handleCommunityRemove,
                    handleAssetValuesSavePromise,
                    handleAssetValuesSave,
                    handleAddNewAsset,
                    handleAddAssetUnderUuid,
                    handleAssetRemove,
                  }) => {
                    return (
                      <>
                        <Route path={routesConfig.scmMapResults('', '')}>
                          <MapSidebarResults
                            hideResultsTab={hideResultsTab}
                            showResultsTabOnClick={showResultsTabOnClick}
                            onSettingsDataSave={handleSettingsDataSave}
                            onChangeSettingsTab={handleSettingsTab}
                            onCommunityRemove={handleCommunityRemove}
                            onAssetValuesSave={handleAssetValuesSave}
                            onAddNewAsset={handleAddNewAsset}
                            onAddNewAssetUnderUuid={handleAddAssetUnderUuid}
                            onAssetValuesSavePromise={handleAssetValuesSavePromise}
                            onAssetRemove={handleAssetRemove}
                          />
                        </Route>
                        <Route path={routesConfig.scmMapCreate()}>
                          <MapSidebarConfigurationSettings
                            onSettingsDataSave={handleSettingsDataSave}
                          />
                        </Route>
                      </>
                    );
                  }}
                </ConfigurationManagementCentral>
              </Switch>
            </>
          </BaseGenericModal>
        </Route>
      </Switch>
    </>
  );
};
